import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import dateUtils from '../../../../../utils/dateUtils';
import TextFieldComponent from '../../../../generic/inputs/textField/TextFieldComponent';
import PrimaryButton from '../../../../generic/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICaseLink } from '../../../../../utils/entities/case/CaseHeader/ICaseLink';
import { ILinkCase } from '../../../../../utils/entities/case/CaseHeader/ILinkCase';
import { ICaseLinkTag } from '../../../../../utils/entities/case/CaseHeader/ICaseLinkTag';
import GeneralUtils from '../../../../../utils/generalUtils';
import { PermissionTypes } from '../../../../../utils/entities/role/IPermission';

const CaseLink = (props: ICaseLink) => {
  const {
    showModal,
    closeLinkCasesDlg,
    caseId,
    caseDisplayId,
    linkCaseDetails,
    timeZone,
    dateFormat,
    deleteLink,
    typedCase,
    retrieveCases,
    setTypedCase,
    valueInfo,
    setValueInfo,
    caseLinkInfo,
    table,
    linkingCase,
    linkCaseSelected,
    userInfo,
    internalStatus,
  } = props;
  const { t } = useTranslation();
  const canLinkCases = GeneralUtils.checkUserPermissions(PermissionTypes.LINK_CASES_CREATE);
  const canDeleteLinkCases = GeneralUtils.checkUserPermissions(PermissionTypes.LINK_CASES_DELETE);

  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={showModal} onClose={() => closeLinkCasesDlg()}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box
            flexGrow={
              1
            }>{`${t('LINK_CASES')} (${GeneralUtils.getCaseDisplayId(caseId, caseDisplayId)})`}</Box>
          <Box>
            <Button onClick={() => closeLinkCasesDlg()} className="color-secondary">
              <CloseIcon />
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Table
          className="link-case-table"
          size="small"
          sx={{ display: linkCaseDetails.length ? '' : 'none' }}>
          <TableHead>
            <TableRow>
              {CONSTANTS.LINK_COLUMN.map((col: string, i: number) => (
                <TableCell key={col} data-testid={`column-header-${i}`}>
                  <b>{col}</b>
                </TableCell>
              ))}
              {canDeleteLinkCases && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {linkCaseDetails.map((row: ILinkCase) => (
              <TableRow
                data-testid="row-1"
                key={`${row.caseId}-${row.linkCaseId}-${row.relation}-${row.createdOn}`}>
                <TableCell>{row.linkCaseId}</TableCell>
                <TableCell>{row.relation}</TableCell>
                <TableCell>
                  {dateUtils.dateFormating(
                    dateUtils.timeZone(Number(row.createdOn), timeZone) as string,
                    dateFormat
                  )}
                </TableCell>
                <TableCell>{row.createdBy}</TableCell>
                {canDeleteLinkCases && (
                  <TableCell>
                    <IconButton onClick={() => deleteLink(row)} size="large" className="padding-0">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {canLinkCases && (
          <>
            <p>{t('CASE_DESCRIPTION')}</p>
            <Stack direction="row" spacing={2}>
              <Stack sx={{ width: '50%' }}>
                {t('CASE_NUMBER')}
                <TextFieldComponent
                  type="text"
                  value={typedCase}
                  setData={(value: string) => {
                    if (value && value.length >= 1) {
                      retrieveCases(value);
                      setTypedCase(value);
                    } else {
                      retrieveCases('');
                    }
                  }}
                  disabled={internalStatus}
                />
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <div style={{ marginBlockEnd: '1em', marginBottom: '1%' }}>{t('RELATIONSHIP')}</div>
                <FormControl variant="standard" size="small">
                  <Select
                    displayEmpty
                    variant="outlined"
                    data-testid="select"
                    value={valueInfo}
                    onChange={(event: SelectChangeEvent) => setValueInfo(event.target.value)}
                    disabled={internalStatus}>
                    <MenuItem disabled value="">
                      <em>{t('SELECT_OPTION')}</em>
                    </MenuItem>
                    {caseLinkInfo.map((relationship: ICaseLinkTag) => (
                      <MenuItem
                        data-testid="select-opt1"
                        key={`${relationship.targetCaseLinkType}-${relationship.sourceCaseLinkType}`}
                        value={relationship.targetCaseLinkType}>
                        {relationship.targetCaseLinkType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <Stack>{table}</Stack>
          </>
        )}
      </DialogContent>
      <DialogActions className="upload-dialog-action">
        {canLinkCases && (
          <PrimaryButton
            text={t('LINK')}
            onClick={() => {
              linkingCase().then(() => setTypedCase(''));
            }}
            isDisabled={!linkCaseSelected || !valueInfo || !userInfo}
            isPositiveButton
          />
        )}
        <PrimaryButton text={t('CANCEL')} onClick={closeLinkCasesDlg} />
      </DialogActions>
    </Dialog>
  );
};

export default CaseLink;

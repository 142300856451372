export enum PermissionTypes {
  READ = 'READ',
  WRITE = 'WRITE',
  DELETE = 'DELETE',
  DOWNLOAD_JSON = 'DOWNLOAD_JSON',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
  UPLOAD = 'UPLOAD',
  TASK_REASSIGNMENT = 'TASK_REASSIGNMENT',
  FULL_TASK_REASSIGNMENT = 'FULL_TASK_REASSIGNMENT',
  TASK_UNASSIGNMENT = 'TASK_UNASSIGNMENT',
  FULL_TASK_UNASSIGNMENT = 'FULL_TASK_UNASSIGNMENT',
  FULL_ACCESS = 'FULL_ACCESS',
  TASK_AGGREGATE_VIEW = 'TASK_AGGREGATE_VIEW',
  AGGREGATE_VIEW_CASE_ROLES = 'AGGREGATE_VIEW_CASE_ROLES',
  AGGREGATE_EDIT_CASE_ROLES = 'AGGREGATE_EDIT_CASE_ROLES',
  AGGREGATE_SELF_EDIT_CASE_ROLES = 'AGGREGATE_SELF_EDIT_CASE_ROLES',
  CASE_LIST = 'CASE_LIST_VIEW',
  ABOUT_PAGE = 'ABOUT_PAGE_VIEW',
  ABOUT_PAGE_CONFIG = 'ABOUT_PAGE_CONFIG_VIEW',
  CONFIG_PAGE = 'CONFIG_PAGE_VIEW',
  CREATE_CASE = 'CREATE_CASE',
  UPDATE_DOCUMENT_STATUS = 'UPDATE_DOCUMENT_STATUS',
  DYNAMIC_QUESTIONNAIRE_EDIT = 'DYNAMIC_QUESTIONNAIRE_EDIT',
  DYNAMIC_QUESTIONNAIRE_ADD = 'DYNAMIC_QUESTIONNAIRE_ADD',
  DYNAMIC_QUESTIONNAIRE_DELETE = 'DYNAMIC_QUESTIONNAIRE_DELETE',
  CASE_ROLES_ADMIN = 'CASE_ROLES_ADMIN',
  DOCUMENT_CENTER_DELETE_DOCUMENTS = 'DOCUMENT_CENTER_DELETE_DOCUMENTS',
  DOCUMENT_CENTER_RESTORE_DOCUMENTS = 'DOCUMENT_CENTER_RESTORE_DOCUMENTS',
  DOCUMENT_CENTER_EDIT_DOCUMENTS = 'DOCUMENT_CENTER_EDIT_DOCUMENTS',
  DOCUMENT_CENTER_DOWNLOAD_DOCUMENTS = 'DOCUMENT_CENTER_DOWNLOAD_DOCUMENTS',
  DOCUMENT_CENTER_DOWNLOAD_SOFT_DELETED_DOCUMENTS = 'DOCUMENT_CENTER_DOWNLOAD_SOFT_DELETED_DOCUMENTS',
  NARRATIVE_SAVE_TO_PDF = 'NARRATIVE_SAVE_TO_PDF',
  LINK_CASES_VIEW = 'LINK_CASES_VIEW',
  LINK_CASES_CREATE = 'LINK_CASES_CREATE',
  LINK_CASES_DELETE = 'LINK_CASES_DELETE',
  CAPTURE_CASE_DATA = 'CAPTURE_CASE_DATA',
  CHART_CARD = 'CHART_CARD',
  LANGUAGE = 'LANGUAGE',
  RTJ_REQUEST = 'RTJ_REQUEST',
  CREATE_CASE_GLOBAL_SEARCH = 'CREATE_CASE_GLOBAL_SEARCH',
  TASK_HEADER_CASE_ROLES = 'TASK_HEADER_CASE_ROLES',
  CASE_DETAIL_CASE_360 = 'CASE_DETAIL_CASE_360',
  TASK_CLAIM = 'TASK_CLAIM',
  SAVE_SYNC = 'SAVE_SYNC',
  CASE_CREATION_DUPLICATE = 'CASE_CREATION_DUPLICATE',
}

interface IPermission {
  id: string;
  name: string;
}

export default IPermission;

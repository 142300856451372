import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { useTranslation } from 'react-i18next';
import { IPaginationProps } from '../../../../utils/entities/ag-grid/IPagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import './Pagination.scss';

const Pagination = ({
  pageNo,
  totalPages,
  pageSize,
  totalElements,
  setPageNo,
  setPageSize,
  dataLoading,
}: IPaginationProps) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState<Array<number>>([
    ...CONSTANTS.AG_GRID.ROWS_PER_PAGE,
  ]);

  useEffect(() => {
    if (rowsPerPage.findIndex((e: number) => e === pageSize) < 0) {
      setRowsPerPage([...rowsPerPage, pageSize]);
    }
  }, [pageSize]);

  const pageChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setPageSize(parseInt(e.target.value));
  };

  const previousPage: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    if (pageNo > 0) {
      setPageNo(pageNo - 1);
    }
  };

  const nextPage: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    if (pageNo !== totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const goToFirstPage: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    if (pageNo > 0) {
      setPageNo(0);
    }
  };

  const goToLastPage: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    if (pageNo !== totalPages) {
      setPageNo(totalPages);
    }
  };

  return (
    <div className="pagination right-aligned-items">
      <div className="center-aligned">
        <div className="rows-per-page">
          <label className="label">{t('ROW_PAGE')}</label>
          <select value={pageSize} onChange={pageChange} className="select">
            {rowsPerPage.map((value: number) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div className="label rows-per-page">
          <b>{pageSize * pageNo + 1}</b>
          <span className="padding-left-5 padding-right-5">{t('PAGINATION.TO')}</span>
          <b>{pageSize * (pageNo + 1) > totalElements ? totalElements : pageSize * (pageNo + 1)}</b>
          <span className="padding-left-5 padding-right-5">{t('PAGINATION.OF')}</span>
          <b>{totalElements}</b>
        </div>

        <FirstPageIcon
          data-testid="FirstIcon"
          fontSize="small"
          className={pageNo === 0 || dataLoading || totalPages < 0 ? 'disabled' : 'enabled'}
          onClick={pageNo !== 0 ? goToFirstPage : undefined}
        />
        <NavigateBeforeIcon
          data-testid="previosusIcon"
          fontSize="small"
          className={pageNo === 0 || dataLoading || totalPages < 0 ? 'disabled' : 'enabled'}
          onClick={pageNo !== 0 ? previousPage : undefined}
        />

        <div className="label">
          <span className="padding-left-5 padding-right-5">{t('PAGINATION.PAGE')}</span>
          <b>{pageNo + 1}</b>
          <span className="padding-left-5 padding-right-5">{t('PAGINATION.OF')}</span>
          <b>{totalPages + 1}</b>
        </div>

        <NavigateNextIcon
          data-testid="nextIcon"
          fontSize="small"
          className={pageNo == totalPages || dataLoading || totalPages < 0 ? 'disabled' : 'enabled'}
          onClick={pageNo !== totalPages ? nextPage : undefined}
        />
        <LastPageIcon
          data-testid="LastIcon"
          fontSize="small"
          className={pageNo == totalPages || dataLoading || totalPages < 0 ? 'disabled' : 'enabled'}
          onClick={pageNo !== totalPages ? goToLastPage : undefined}
        />
      </div>
    </div>
  );
};

export default Pagination;

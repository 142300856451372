import { createContext } from 'react';
import { CONSTANTS } from '../utils/constants/constants';
import LocalStorageUtils from '../utils/localStorageUtils';
import IAgGridTblPayload from '../utils/entities/IAgGridTblPayload';

const TaskListContext = createContext<IAgGridTblPayload>(
  LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD)
);

export default TaskListContext;

import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosConfig, { axiosBlob } from './axios.config';
import IAgGridTblPayload from '../utils/entities/IAgGridTblPayload';
import IAgGridRowsTaskRes from '../utils/entities/screen/IAgGridRowsTaskRes';
import serviceUtils from '../utils/serviceUtils';
import { ViewTypes } from '../utils/constants/enums';
import { IExportPayload } from '../utils/entities/ag-grid/IExport';
import ITask from '../utils/entities/screen/ITask';
import { IGenericNumberObject } from '../utils/entities/IGenericObject';

/**
 * This api will retrieve the list of tasks based on filter and sort at server side
 * @param taskPayload
 * @returns
 */
export const getTasksWithServerSideFilterSort = (
  payload: IAgGridTblPayload
): Promise<IAgGridRowsTaskRes> => {
  return axios
    .post(`/getFilteredTasks`, payload, axiosConfig)
    .then((res: AxiosResponse<IAgGridRowsTaskRes>) => {
      return serviceUtils.processSuccess<IAgGridRowsTaskRes>(res);
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });
};

export const getCasesWithServerSideFilterSort = (
  payload: IAgGridTblPayload
): Promise<IAgGridRowsTaskRes> => {
  return axios
    .post(`/getFilteredCases`, payload, axiosConfig)
    .then((res: AxiosResponse<IAgGridRowsTaskRes>) => {
      return serviceUtils.processSuccess<IAgGridRowsTaskRes>(res);
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });
};

/**
 * This API function will be used to assign bulk tasks to user
 * @returns An observable for the List of tasks
 */
export const getTaskList = (): Promise<Array<ITask>> =>
  axios
    .get(`/getTasksByAssignee`, axiosConfig)
    .then((res: AxiosResponse<Array<ITask>>) => {
      return serviceUtils.processSuccess<ITask[]>(res);
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });

export const getViewsCountByListType = (views: Array<IAgGridTblPayload>, type: ViewTypes) =>
  axios
    .post(`/getFilteredCount/${type}`, views, axiosConfig)
    .then((res: AxiosResponse<IGenericNumberObject>) => {
      return serviceUtils.processSuccess<IGenericNumberObject>(res);
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });

export const exportByListType = (payload: IExportPayload, type: ViewTypes) =>
  axios
    .post(`/exportListContent/${type}`, payload, axiosBlob)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });

export const getTasksByCaseId = (caseId: string): Promise<Array<ITask>> =>
  axios
    .get(`/getTasksByCaseId/${caseId}`, axiosConfig)
    .then((res: AxiosResponse<Array<ITask>>) => {
      return serviceUtils.processSuccess<ITask[]>(res);
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });

import { Divider } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { IArticle } from '../../../utils/entities/dueDiligence/IArticle';
import dateUtils from '../../../utils/dateUtils';

/**
 * This function will responsible for display list of article
 * @param props
 * @returns
 */
const ArticleList = (props: {
  isActive: boolean;
  setActiveId: () => void;
  article: IArticle;
  setSelectedArticle: React.Dispatch<React.SetStateAction<IArticle | undefined>>;
}): JSX.Element => {
  return (
    <>
      <div
        onClick={props.setActiveId}
        className={props.isActive ? 'article-list-option active' : 'article-list-option'}>
        <div className="padding-inline-10 margin-vertical-10">
          <div className="center-aligned-space-between width-100-per lighter-text">
            <div>{dateUtils.getFormatedDate(props.article.date)}</div>
            <div>
              {t('DD.ARTICLE_SCORE')}: {Number(props.article.articleScore * 100).toFixed(2)}%
            </div>
          </div>
          <div
            className="link"
            onClick={() => {
              props.setSelectedArticle(props.article);
            }}>
            {props.article.title}
          </div>
        </div>
      </div>

      <Divider />
    </>
  );
};

export default ArticleList;

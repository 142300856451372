import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import InfoIcon from '@mui/icons-material/Info';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ActiveIcon from '../../assets/icons/Active.svg';
import InactiveIcon from '../../assets/icons/Inactive.svg';
import { getStyleColors } from '../../service/style.service';
import { PermissionTypes } from '../entities/role/IPermission';
import IVerticalBarItem from '../entities/IVerticalBarItem';
import { IGraph } from '../entities/chart/IChart';

const styles = getStyleColors();

const PAGES_URL = {
  INBOX: '/inbox',
  TASK_LIST: '/task-list',
  TASK: '/task',
  CASE_LIST: '/case-list',
  UNAUTHORIZE: '/unauthorize',
  CASE_DETAILS: '/case-details',
  TENANT: 'tenant',
  ABOUT_PAGE: '/about-page',
  CONFIG_PAGE: '/config-page',
};

const URL_PARAMS = {
  CASE_CREATION: 'caseCreation',
};

const COLORS = {
  PRIMARY: styles['color-primary'],
  SECONDARY: styles['color-secondary'],
  YELLOW: styles['color-yellow'],
  RED: styles['color-red'],
  ORANGE: styles['color-orange'],
  GREY: styles['color-grey'],
};

const LOCAL_STORAGE_KEYS = {
  TIME_ZONE: 'TIME_ZONE',
  DATE_FORMAT: 'DATE_FORMAT',
  DYNAMIC_QUESTIONNAIRE: 'DYNAMIC_QUESTIONNAIRE',
  SELECT_ALL_FLAG: 'SELECT_ALL_FLAG',
  HEADER_COLLAPSE: 'HEADER_COLLAPSE',
  CASE_PERMISSIONS: 'CASE_PERMISSIONS',
  IS_DOCUMENT_SCAN_ENABLED: 'IS_DOCUMENT_SCAN_ENABLED',
  HEADER_EXPAND_STATUS: 'HEADER_EXPAND_STATUS',
  PROJECT_SETTINGS: {
    PROJECT_NAME: 'PROJECT_NAME',
    TENANT: 'TENANT',
    NON_EXISTENT_TASKS: 'NON_EXISTENT_TASKS',
  },
  USER_INFO: 'USER_INFO',
  TASK_VIEW: 'TASK_VIEW',
  TASK_LIST_PAYLOAD: 'TASK_LIST_PAYLOAD',
  CASE_DETAILS_ACTIVE_TAB: 'CASE_DETAILS_ACTIVE_TAB',
  ROUTING: 'ROUTING',
  USER: {
    ACTIVE_ROLE: 'ACTIVE_ROLE',
  },
  DEFAULT_VIEW: 'DEFAULT_VIEW',
  AG_GRID: {
    ROW_GROUP_COLS: 'ROW_GROUP_COLS',
    GROUP_TOTAL_ELEMENTS: 'GROUP_TOTAL_ELEMENTS',
    CASE_LIST: { PAYLOAD: 'PAYLOAD' },
    AUDIT: { PAYLOAD: 'PAYLOAD' },
    PAGE_SIZE: 'PAGE_SIZE',
  },
  TASK: {
    SUBMIT_OPTION: 'TASK_SUBMIT_OPTION',
  },
};

const COOKIES = {
  ID_TOKEN: 'token',
  AUTHENTICATION: 'authentication',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  ASSETS: 'assets',
};

const ATTRIBUTES = {
  RISK_SCORE_VAL: 'riskScoreValue',
  RISK_SCORE_RANK: 'riskScoreRank',
  RISK_SCORE_COMBINED: 'riskScoreCombined',
  FOCAL_ENTITY_NAME: 'focalEntity',
  TASK_DUE_DATE: 'dueDate',
  CREATED_DATE: 'createdDate',
  ASSIGNED_DATE: 'assignedOn',
  SLA_STATUS: 'slaStatus',
  CASE_ID: 'caseId',
  CASE_DISPLAY_ID: 'caseDisplayId',
  TASK_STATUS: 'taskStatus',
  ASSIGNED_TO: 'assignee',
  CASE_TAGS: 'caseTags',
  PROCESS_BAR: 'processBar',
  BLANK: 'blank',
};

const TOP_VERTICAL_BAR_ITEMS: IVerticalBarItem[] = [
  {
    label: 'DASHBOARD',
    icon: <DashboardIcon />,
    url: PAGES_URL.INBOX,
    internalUrl: true,
  },
  {
    label: 'ALL_CASES',
    icon: <WorkIcon />,
    url: PAGES_URL.CASE_LIST,
    internalUrl: true,
    permissionType: PermissionTypes.CASE_LIST,
  },
  {
    label: 'ABOUT_PAGE',
    icon: <InfoIcon />,
    url: PAGES_URL.ABOUT_PAGE,
    internalUrl: true,
    permissionType: PermissionTypes.ABOUT_PAGE,
  },
];

const BOTTOM_VERTICAL_BAR_ITEMS: IVerticalBarItem[] = [
  {
    label: 'CONFIGURATIONS',
    icon: <AdminPanelSettingsIcon />,
    url: PAGES_URL.CONFIG_PAGE,
    internalUrl: true,
    permissionType: PermissionTypes.CONFIG_PAGE,
  },
];

const TAB_TYPES = {
  DYNAMIC_QUESTIONNAIRE: 'DYNAMIC_QUESTIONNAIRE',
  SCREENING: 'SCREENING',
  CASE_OVERVIEW: 'CASE_OVERVIEW',
  CASE_360: 'CASE_360',
  RFI: 'RFI',
  TRANSACTION_REVIEWER: 'TRANSACTION_REVIEWER',
  SAR: 'SAR',
  DUE_DILIGENCE: 'DUE_DILIGENCE',
  NARRATIVE: 'NARRATIVE',
  TIMER: 'TIMER',
};

const COLUMN_TYPES = {
  TEXT: 'TEXT',
  CURRENCY: 'CURRENCY',
  LIST: 'LIST',
  DATE: 'DATE',
  EPOCH_DATE: 'EPOCH_DATE',
  AGING: 'AGING',
  ASSIGNEE_HISTORY: 'ASSIGNEE_HISTORY',
  TASK_LINK: 'TASK_LINK',
  CASE_LINK: 'CASE_LINK',
  RISK_RATING: 'RISK_RATING',
  CASE_TAGS: 'CASE_TAGS',
  CASE_GAPS: 'CASE_GAPS',
  DATE_COUNTDOWN: 'DATE_COUNTDOWN',
  SHOW_MORE: 'SHOW_MORE',
};

const DOC_CENTER = {
  UPLOAD_DOC_COLUMN: [
    { label: 'DOC_NAME', value: 'documentName' },
    { label: 'FILE_NAME', value: '' },
    { label: 'FILE_TYPE', value: 'documentType' },
    { label: 'VALID_DATE', value: 'expirationDate' },
    { label: 'VIRUS_SCAN', value: '' },
    { label: '', value: '' },
    { label: '', value: '' },
  ],
  EDIT_DOC_COLUMN: [
    { label: 'DOC_NAME', value: 'documentName' },
    { label: 'FILE_NAME', value: '' },
    { label: 'FILE_TYPE', value: 'documentType' },
    { label: 'VALID_DATE', value: 'expirationDate' },
    { label: 'DOC_CENTER.DOCUMENT_ACTIVE', value: 'documentActive' },
    { label: '', value: '' },
    { label: '', value: '' },
  ],
  FILE_SECTION_COLS: [
    { label: '', size: 1 },
    { label: 'FILE_NAME', size: 3 },
    { label: 'FILE_TYPE', size: 2 },
    { label: 'DATE_ADDED', size: 2 },
    { label: 'VALID_DATE', size: 2 },
    { label: '', size: 3 },
  ],
  DOC_TYPE: 'Document', // TODO - this needs to be configurable
  DOC_NAME_MAX_LENGTH: 50,
  DECIMALS: 2,
  BYTE_TRESHOLD: 1024,
};

const DATE_FORMATS = [
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'YYYY/MM/DD',
  'MM/DD/YYYY HH:mm',
  'DD/MM/YYYY HH:mm',
  'YYYY/MM/DD HH:mm',
  'YYYY/MM/DD HH:mm:ss',
  'MM/DD/YYYY HH:mm:ss',
];

// By color ordered by tint
const CHART_COLORS = {
  MAROON: ['#C981B2', '#B14891', '#922B73', '#750E5C', '#5A0A42', '#42152D', '#351C21'],
  RED: ['#FF9A91', '#FF736A', '#F95D54', '#FF4136', '#E0362C', '#B9251C', '#7A130D'],
  ORANGE: ['#FFB46A', '#FF9831', '#FF810A', '#FF6D00', '#F76900', '#EB4F00', '#BC2F00'],
  GREEN: ['#8CE8AD', '#57E188', '#34C768', '#2DB757', '#189D3E', '#168736', '#13652A'],
  TEAL: ['#93F0E6', '#60E6E1', '#42C9C2', '#27ACAA', '#109090', '#0D7575', '#004F4F'],
  BLUE: ['#87D3F2', '#4EBEEB', '#35A4E8', '#188CE5', '#1777CF', '#155CB4', '#082C65'],
  PURPLE: ['#9C82D4', '#724BC3', '#542EA5', '#3D108A', '#240D75', '#0A095A', '#15173E'],
};

const ICONS = {
  ACTIVE_ICON: ActiveIcon,
  DEACTIVE_ICON: InactiveIcon,
};

const DATES = {
  DATE_FORMAT: 'en',
  DEFAULT_DATE_FORMAT_WITH_TIME: 'MM/DD/YYYY HH:mm:ss',
  DATE_FORMAT_CA: 'en-CA',
  DOCUMNET_DATE_FORMAT: 'MM/DD/YYYY',
  FORMAT_TIME: 'YYYY-MM-DD HH:mm:ss',
  DATE_FORMATS: DATE_FORMATS,
  DEFAULT: {
    DATE_FORMAT: 'YYYY-MM-DD',
    TIME_FORMAT: 'HH:mm:ss',
  },
  DATE_SHORTCUTS: [
    { label: 'Week', value: 7 },
    { label: 'Month', value: 30 },
    { label: 'Year', value: 365 },
  ],
  INVALID_DATE: 'Invalid Date',
};

const EXPORT_OPTIONS = [
  { key: 'JSON', permissionType: PermissionTypes.DOWNLOAD_JSON },
  { key: 'EXCEL', permissionType: PermissionTypes.DOWNLOAD_EXCEL },
  { key: 'PDF', permissionType: PermissionTypes.DOWNLOAD_PDF },
];

const CHART = {
  CHART_COLORS: CHART_COLORS,
  RANGE: 'RANGE',
  REQUEST: [
    {
      chartType: 'bar',
      stacked: false,
      dataset: 'Cases By Status',
    },
    {
      chartType: 'bar',
      stacked: true,
      dataset: 'Tasks By Status',
    },
  ] as IGraph[], // TODO: This is static for now, in future should come from configurations
};

const SLA = {
  ON_TRACK: { label: 'On Track', value: 'ON_TRACK' },
  AT_RISK: { label: 'At Risk', value: 'AT_RISK' },
  OFF_TRACK: { label: 'Off Track', value: 'OFF_TRACK' },
};

const AG_GRID = {
  COLUMNS: {
    CHECKBOX: 'Checkbox_Selection',
    GROUP_BY: 'ag-Grid-AutoColumn',
    SHOW_MORE: 'SHOW_MORE',
    DEFAULT_WIDTH: 50,
  },
  CONTEXT_MENU_ITEMS: ['copy', 'copyWithHeaders', 'copyWithGroupHeaders'],
  DEFAULT_COL_DEF: {
    flex: 1,
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  },
  ROWS_PER_PAGE: [5, 8, 10, 15, 25, 50],
  FILTER: {
    TEXT: 'agTextColumnFilter',
    DATE: 'agDateColumnFilter',
    NUMBER: 'agNumberColumnFilter',
    DATE_FILTER_OPTIONS: ['contains', 'blank', 'notBlank'],
  },
};

const DD = {
  DUE_DILIGENCE_EMPTY_PARTY_NAME: 'Due_Diligence',
  DUE_DILIGENCE_NN: 'Negative News',
  DUE_DILIGENCE_IR: 'IR',
  DUE_DILIGENCE_AV: 'AV',
  DUE_DILIGENCE_TR: 'TR Clear',
  DD_IR_STATUS_COMPLETE: 'Complete',
  DD_IR_STATUS_INPROGRESS: 'In Progress',
  STATUS: 'SUCCESS',
};

const SLA_STATUS = {
  [SLA.ON_TRACK.value]: {
    className: 'color-sla-on-track',
    label: SLA.ON_TRACK.label,
    labelKey: 'ON_TRACK',
  },
  [SLA.OFF_TRACK.value]: {
    className: 'color-sla-off-track',
    label: SLA.OFF_TRACK.label,
    labelKey: 'OFF_TRACK',
  },
  [SLA.AT_RISK.value]: {
    className: 'color-sla-at-risk',
    label: SLA.AT_RISK.label,
    labelKey: 'AT_RISK',
  },
};

const TASK_INTERNAL_STATUS = {
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const CONSTANTS = {
  NON_EXISTENT: 'Non Existent',
  DATES: DATES,
  ATTRIBUTES: ATTRIBUTES,
  VERTICAL_BAR_ITEMS: {
    TOP: TOP_VERTICAL_BAR_ITEMS,
    BOTTOM: BOTTOM_VERTICAL_BAR_ITEMS,
  },
  LOCAL_STORAGE_KEYS: LOCAL_STORAGE_KEYS,
  COOKIES: COOKIES,
  TEXT_FIELD_LENGTH: 1000,
  DRAFT_NAME_LENGTH: 100,
  EMAIL_REGEX: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
  MANUAL: 'MANUAL',
  PAGES_URL: PAGES_URL,
  SLA: SLA,
  QUESTION_VALUE_SEPARATOR: '|',
  IDENTIFICATIONSTATUS: {
    NOT_REQUIRED: 'Not_Required',
    NOT_IDENTIFIED: 'Not_Identified',
    IDENTIFIED: 'Identified',
  },
  VERIFICATIONSTATUS: {
    NOT_REQUIRED: 'Not_Required',
    NOT_VERIFIED: 'Not_Verified',
    VERIFIED: 'Verified',
    PARTIALLY_VERIFIED: 'Partially_Verified',
  },
  ICONS: ICONS,
  COLORS: COLORS,
  CHART: CHART,
  TAB_TYPES: TAB_TYPES,
  COLUMNTYPES: COLUMN_TYPES,
  DOC_CENTER: DOC_CENTER,
  EXPORT_OPTIONS: EXPORT_OPTIONS,
  FILE_SIZE: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  LINK_COLUMN: ['Linked Case ID', 'Link Type', 'Link Created On', 'Link Created By'],
  DATE_FORMATE: 'DDMMYYYY',
  TEMPLATE_TYPE: 'Email',
  RFI_TABLE: ['Date Created', 'Email', 'Created By', 'Type', 'Status'],
  CONFIG_DATA_MODEL_OPTIONS: ['Item', 'Relationship'],
  DILIGENCE_ASSOCIATED: ['Yes', 'No'],
  RESET_FILTERS: ['Filtering', 'Sorting', 'Searching', 'Grouping', 'Columns'],
  MASTER: 'MASTER',
  DRAWER_WIDTH: 240,
  NULL_EPOCH: 0,
  AG_GRID: AG_GRID,
  DD: DD,
  SLA_STATUS: SLA_STATUS,
  TASK_INTERNAL_STATUS: TASK_INTERNAL_STATUS,
  URL_PARAMS: URL_PARAMS,
} as const;

import React from 'react';
import { Grid, Stack } from '@mui/material';
import IQuestionType from '../../../../../utils/entities/IQuestionType';
import QuestionTooltip from '../../QuestionTooltip';
import SpelUtils from '../../../../../utils/spelUtils';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import IAccessToken from '../../../../../utils/entities/authentication/IAccessToken';
import LocalStorageUtils from '../../../../../utils/localStorageUtils';
import './GuidanceTextQuestion.scss';
import RichTextComponent from '../../../../richText/RichTextComponent';

const GuidanceTextQuestion = (props: IQuestionType) => {
  const { question, locals, contextLocals } = props;
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, context) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }} className="guidance-text">
          <Stack direction="row" alignItems="center" spacing={2}>
            <span className="margin-top-20 margin-bottom-20 MuiFormControl-root label">
              <RichTextComponent required value={question.label} readOnly inline />
            </span>
            <Grid className="questionnaire-icons">
              <div className="manage-idv" />
              <QuestionTooltip question={question} />
            </Grid>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default GuidanceTextQuestion;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CONSTANTS } from '../../../../utils/constants/constants';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
} from '@mui/material';
import GeneralUtils from '../../../../utils/generalUtils';
import DocumentsPanel from '../document/DocumentsPanel';
import { getCaseLinkOptions } from '../../../../service/config-manager.service';
import {
  getLinkCase,
  deleteCaseLink,
  searchCases,
  linkCase,
} from '../../../../service/case-manager.service';
import { ISearchCase } from '../../../../utils/entities/case/ISearchCase';
import { ILink } from '../../../../utils/entities/case/ILink';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import CaseAudit from '../../CaseAudit';
import IPermission from '../../../../utils/entities/role/IPermission';
import './CaseHeader.scss';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { addAlert } from '../../../../store/actions/alerts.actions';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';
import CaseHeaderBanner from './case-header-banner/CaseHeaderBanner';
import CaseHeaderAccordion from './case-header-accordion/CaseHeaderAccordion';
import CaseLink from './case-link/CaseLink';
import { ICaseHeaderProps } from '../../../../utils/entities/case/CaseHeader/ICaseHeaderProps';
import { ICaseLinkTag } from '../../../../utils/entities/case/CaseHeader/ICaseLinkTag';
import { ILinkCase } from '../../../../utils/entities/case/CaseHeader/ILinkCase';
import CaseLinkTable from './case-link/CaseLinkTable';

const CaseHeader = (props: ICaseHeaderProps) => {
  const {
    attributes,
    formId,
    setSubmitClick,
    setSaveTaskAsDraftClick,
    setClaimClick,
    setSaveTaskAsDraftAndExitClick,
    setStatus,
    tenant,
    slaLabels,
    statusOptions,
    disabledActions,
    hasAssignee,
    assignee,
    loggedUser,
    caseId,
    caseDisplayId,
    cancelTask,
    fileType,
    maxFileSize,
    dateFormat,
    hasNoSets,
    isDirty,
    setHeaderHeight,
    requestBody,
    setRequestBody,
    caseRoles,
    processBar,
    contextLocals,
    setToExit,
  } = props;

  const navigate = useNavigate();
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const timeZone: string = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const headerCollapse = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.HEADER_COLLAPSE
  );
  const { t } = useTranslation();

  const [openHeader, setOpenHeader] = useState<boolean>(false);
  const [tryOpenAccordion, setTryOpenAccordion] = useState<boolean>(false);
  const [holdAccordion, setHoldAccordion] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [valueInfo, setValueInfo] = useState<string>('');
  const [caseLinkInfo, setCaseLinkInfo] = useState<Array<ICaseLinkTag>>([]);
  const [cases, setCases] = useState<ISearchCase | null>(null);
  const [linkCaseSelected, setLinkCaseSelected] = useState<number>(0);
  const [typedCase, setTypedCase] = useState<string>('');
  const [openAudit, setOpenAudit] = useState<boolean>(false);
  const [linkCaseDetails, setLinkCaseDetails] = useState<Array<ILinkCase>>([]);
  const [tooltipStatus, setTooltipStatus] = useState<boolean>(false);

  const userIsNotAssignee =
    disabledActions || !(hasAssignee && GeneralUtils.userHasAccess(assignee, loggedUser));

  useEffect(() => {
    getCaseLinkOptions()
      .then((res: Array<ICaseLinkTag>) => {
        setCaseLinkInfo(res);
      })
      .catch((error) => {
        console.error('Error :', error);
      });
  }, []);

  const submitClick = () => {
    setHoldAccordion(true);
    setSubmitClick(true);
  };

  const saveTaskAsDraftAndExitClick = () => {
    if (isDirty) {
      setHoldAccordion(true);
      setSaveTaskAsDraftAndExitClick(true);
    } else {
      setToExit(true);
      addAlert({
        type: 'info',
        primaryText: t('TASK.SAVE_NO_CHANGES'),
      });
    }
  };

  const saveTaskAsDraftClick = () => {
    if (isDirty) {
      setSaveTaskAsDraftClick(true);
    } else {
      addAlert({
        type: 'info',
        primaryText: t('TASK.SAVE_NO_CHANGES'),
      });
    }
  };

  const deleteLink = (data: ILinkCase) => {
    deleteCaseLink(data)
      .then(() => {
        retrieveLinks(caseId);
        addAlert({
          type: 'success',
          primaryText: t('LINK_DELETED'),
        });
      })
      .catch((error) => {
        console.error('Error getting Delete Case Link response :', error);
        addAlert({
          type: 'error',
          primaryText: t('ERROR_MESSAGE'),
        });
      });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    GeneralUtils.triggerEvt(
      EVENTS_CONSTANTS.HEADER_EXPAND_STATUS,
      GeneralUtils.deepCopy(headerCollapse)
    );
    setTimeout(() => {
      //Needed since there is no way to check when the accordion ends its animation
      setHeaderHeight((componentRef.current?.offsetHeight as number) + 100);
    }, 1000);
  }, [headerCollapse]);

  const closeAlert = () => {
    navigate(CONSTANTS.PAGES_URL.INBOX);
  };

  const retrieveCases = (id: string) => {
    searchCases(id)
      .then((res: ISearchCase) => {
        setCases(res);
      })
      .catch(() => {
        setCases(null);
      });
  };

  const retrieveLinks = (caseId: string | undefined) => {
    getLinkCase(caseId)
      .then((caseLink: Array<ILinkCase>) => {
        setLinkCaseDetails(caseLink);
      })
      .catch((error) => {
        console.error('Error getting LinkCase response :', error);
      });
  };

  useEffect(() => {
    retrieveLinks(props.caseId);
  }, [props.caseId]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      if (!holdAccordion) {
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.HEADER_COLLAPSE,
          headerCollapse
        );
      }
      setHoldAccordion(false);
    }
  }, [tryOpenAccordion]);

  const linkingCase = async () => {
    const current = new Date();
    const request: ILink = {
      caseId: caseId,
      linkCaseId: linkCaseSelected,
      relation: valueInfo,
      createdBy: userInfo.user,
      createdOn: moment.tz(current, timeZone).format(CONSTANTS.DATE_FORMATE),
    };
    linkCase(request)
      .then(() => {
        setLinkCaseSelected(0);
        setValueInfo('');
        setCases(null);
        retrieveLinks(caseId);
        addAlert({
          type: 'success',
          primaryText: t('CASE_LINKED'),
        });
      })
      .catch((error) => {
        console.error('ERROR:', error);
        addAlert({
          type: 'error',
          primaryText: t('ERROR_MESSAGE'),
        });
      });
  };

  const closeLinkCasesDlg = () => {
    setShowModal(false);
    setLinkCaseSelected(0);
    setValueInfo('');
    setCases(null);
    setTypedCase('');
  };

  const dynamicColumns: GridColDef[] = cases
    ? cases.columns.map((x) => {
        return {
          field: x,
          headerName: x,
          width: 130,
        };
      })
    : [];

  const columns: GridColDef[] = [
    {
      field: 'caseId',
      headerName: 'Case ID',
      width: 130,
    },
    ...dynamicColumns,
  ];

  // TODO: Check this logic
  const rows = cases
    ? cases.cases.map((x) => {
        const row: any = {};
        row.id = x.caseId;
        row.caseId = x.caseId;
        x.attributes.forEach((at: { label: string; value: string }) => {
          row[at.label] = at.value;
        });
        return row;
      })
    : [];

  const handleRowSelection = (e: GridRowSelectionModel) => {
    setLinkCaseSelected(e[0] as number);
  };

  const renderElement = () => (
    <Box className="case-header font-size-12" ref={componentRef}>
      <CaseHeaderBanner
        hasAssignee={hasAssignee}
        setClaimClick={setClaimClick}
        formId={formId}
        assignee={assignee}
        userInfo={userInfo}
        internalTaskStatus={disabledActions}
      />
      {cancelTask && (
        <Dialog fullWidth={true} maxWidth={'xs'} open={true} aria-labelledby="confirm-dialog">
          <DialogTitle id="confirm-dialog">{t('CASE_CLOSE')}</DialogTitle>
          <DialogContent dividers>{t('CASE_CONTENT')}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className="button-style"
              onClick={() => {
                closeAlert();
              }}>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <CaseHeaderAccordion
        tenant={tenant}
        attributes={attributes}
        tryOpenAccordion={tryOpenAccordion}
        headerCollapse={headerCollapse}
        setTryOpenAccordion={setTryOpenAccordion}
        openHeader={openHeader}
        setOpenHeader={setOpenHeader}
        tooltipStatus={tooltipStatus}
        setTooltipStatus={setTooltipStatus}
        saveTaskAsDraftClick={saveTaskAsDraftClick}
        formId={formId}
        userIsNotAssignee={userIsNotAssignee}
        saveTaskAsDraftAndExitClick={saveTaskAsDraftAndExitClick}
        submitClick={submitClick}
        caseId={caseId}
        casePermissions={casePermissions}
        setShowModal={setShowModal}
        retrieveLinks={retrieveLinks}
        setOpenAudit={setOpenAudit}
        setOpenModal={setOpenModal}
        setStatus={setStatus}
        setHoldAccordion={setHoldAccordion}
        statusOptions={statusOptions}
        slaLabels={slaLabels}
        caseRoles={caseRoles}
        internalStatus={disabledActions}
        processBar={processBar}
        requestBody={requestBody}
        setRequestBody={setRequestBody}
        contextLocals={contextLocals}
      />
      <Drawer
        className="drawer-layout"
        anchor="right"
        open={openModal}
        onClose={() => setOpenModal(false)}>
        <DocumentsPanel
          caseId={caseId}
          caseDisplayId={caseDisplayId}
          openModal={setOpenModal}
          hasAssignee={hasAssignee}
          tenant={tenant}
          maxFileSize={maxFileSize}
          fileType={fileType}
          requestBody={requestBody}
          internalStatus={disabledActions}
        />
      </Drawer>
      <Drawer
        className="drawer-layout"
        anchor="right"
        open={openAudit}
        onClose={() => setOpenAudit(false)}>
        <CaseAudit caseId={caseId} openAudit={setOpenAudit} />
      </Drawer>
      <CaseLink
        showModal={showModal}
        closeLinkCasesDlg={closeLinkCasesDlg}
        caseId={caseId}
        caseDisplayId={caseDisplayId}
        linkCaseDetails={linkCaseDetails}
        timeZone={timeZone}
        dateFormat={dateFormat}
        deleteLink={deleteLink}
        typedCase={typedCase}
        retrieveCases={retrieveCases}
        setTypedCase={setTypedCase}
        valueInfo={valueInfo}
        setValueInfo={setValueInfo}
        caseLinkInfo={caseLinkInfo}
        table={
          <CaseLinkTable
            typedCase={typedCase}
            cases={cases}
            rows={rows}
            columns={columns}
            handleRowSelection={handleRowSelection}
          />
        }
        linkingCase={linkingCase}
        linkCaseSelected={linkCaseSelected}
        userInfo={userInfo}
        internalStatus={disabledActions}
      />
    </Box>
  );
  return !hasNoSets ? renderElement() : <></>;
};

export default CaseHeader;

import React from 'react';
import ILinkButton from '../../../../utils/entities/genericComponents/button/link/ILinkButton';
import TooltipComp from '../../tooltip/Tooltip';
import './LinkButton.scss';

const LinkButton = ({ url, text }: ILinkButton) => {
  return (
    <a href={url} rel="noopener noreferrer" className="hyper-link">
      <TooltipComp label={text} />
    </a>
  );
};

export default LinkButton;

import React, { useContext } from 'react';
import dateUtils from '../../../utils/dateUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import GenericContext from './GenericContext';

interface IProps {
  epoch: number | undefined;
  timeZone?: string;
}

const EpochToDateText = (props: IProps) => {
  const { epoch, timeZone } = { ...props };
  const { dateFormat } = useContext(GenericContext);
  return (
    <span>
      {!!epoch &&
        dateUtils.epochToDateFormated(
          epoch,
          dateFormat,
          timeZone ?? LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
        )}
    </span>
  );
};

export default EpochToDateText;

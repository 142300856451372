import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosConfig from './axios.config';
import IAgGridTblPayload from '../utils/entities/IAgGridTblPayload';
import serviceUtils from '../utils/serviceUtils';
import IAgGridRowsTaskRes from '../utils/entities/screen/IAgGridRowsTaskRes';

export const getAuditRowsWithServerSideFilterSort = (
  auditPayload: IAgGridTblPayload
): Promise<IAgGridRowsTaskRes> => {
  return axios
    .post(`/getFilteredAudits`, auditPayload, axiosConfig)
    .then((res: AxiosResponse<IAgGridRowsTaskRes>) => {
      return serviceUtils.processSuccess<IAgGridRowsTaskRes>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import INotification from '../utils/entities/header/notification-center/INotification';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/serviceUtils';
import { IPagination } from '../utils/entities/ag-grid/IPagination';

export const getPaginatedNotifications = (
  pageNo: number,
  pageSize: number
): Promise<IPagination> => {
  return axios
    .get(
      `/getPaginatedNotifications${serviceUtils.QueryParamsSerializer({ page: pageNo, pageSize: pageSize })}`,
      axiosConfig
    )
    .then((res: AxiosResponse<IPagination>) => {
      return serviceUtils.processSuccess<IPagination>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

export const dismissNotifications = (id: string): Promise<Array<INotification>> =>
  axios
    .delete(`/dismissNotificationById/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<INotification[]>) => {
      return serviceUtils.processSuccess<INotification[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const dismissAllUserNotifications = (): Promise<Array<INotification>> =>
  axios
    .delete(`/dismissAllNotificationsByUser`, axiosConfig)
    .then((res: AxiosResponse<INotification[]>) => {
      return serviceUtils.processSuccess<INotification[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const notitifcationRefresh = () =>
  `${
    window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT
  }/notificationManagerSvc/notifications/refresh`;

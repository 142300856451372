import { gql, useQuery } from '@apollo/client';
import IAgGridTblPayload from '../../utils/entities/IAgGridTblPayload';
import { IGraph } from '../../utils/entities/chart/IChart';
import { ICardGraphData, IChartGraphData } from '../../utils/entities/chart/IGraphData';

const QUERY_DASHBOARD_WORKLOAD_CARD_DATA = gql`
  query Query($tenant: String) {
    cardViewDetails(tenant: $tenant) {
      inProgress
      investigated
      caseClosed
      caseOutstanding
      overallCompletedPercentage
    }
  }
`;

const QUERY_CHARTS_DATA = gql`
  query ChartsQuery($charts: Charts) {
    chartsQuery(charts: $charts) {
      datasets {
        data
        label
        backgroundColor
      }
      labels
      stacked
      tenant
      title
      type
      indexAxis
    }
  }
`;

export const GQL_CHARTS_DATA = (
  graphs: IGraph[],
  taskListFilter: IAgGridTblPayload | undefined
) => {
  const newFilter: any = { ...taskListFilter }; // TODO
  let toSkip = true;
  if (taskListFilter?.filterModel) {
    toSkip = false;

    const keys = Object.keys(taskListFilter.filterModel);
    const filters = [];
    for (const key of keys) {
      filters.push({
        key: key,
        value: taskListFilter.filterModel[key],
      });
    }

    newFilter.filterModel = filters;
  }
  const { error, data, loading, refetch } = useQuery<IChartGraphData>(QUERY_CHARTS_DATA, {
    variables: {
      charts: {
        graphs,
        workItems: newFilter,
      },
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    refetchWritePolicy: 'overwrite',
    skip: toSkip,
  });
  return {
    data,
    error,
    loading,
    refetch,
  };
};

export const GQL_DASHBOARD_WORKLOAD_CARD_DATA = (tenant: string | number, toSkip: boolean) => {
  const { error, data, loading } = useQuery<ICardGraphData>(QUERY_DASHBOARD_WORKLOAD_CARD_DATA, {
    skip: toSkip,
    variables: {
      tenant,
    },
  });
  return {
    data,
    error,
    loading,
  };
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_CONSTANTS } from '../utils/constants/api_constants';
import INarrative from '../utils/entities/narrative/INarrative';
import INarrativeVersionDetail from '../utils/entities/narrative/INarrativeVersionDetail';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/serviceUtils';
import INarrativeToFile from '../utils/entities/narrative/INarrativeToFile';
import LocalStorageUtils from '../utils/localStorageUtils';
import { CONSTANTS } from '../utils/constants/constants';

/**
 * This api will retrieve the dropdown list for template
 * @param tenantId
 * @returns
 */
export const getTemplateDropdown = (
  tenantId: string,
  tabType: string
): Promise<Array<INarrative>> => {
  return axios
    .get(
      `/getNarrativeTemplatesDropdownByTenant/${encodeURIComponent(tenantId)}/${encodeURIComponent(
        tabType
      )}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<INarrative>>) => {
      return serviceUtils.processSuccess<INarrative[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

/**
 *This api will retrieve the dropdown list version based on selected templateName
 * @param templateName
 * @param tenantId
 * @param caseId
 * @returns
 */
export const getTemplateVersionsByTemplateId = (
  templateName: string,
  tenantId: string,
  caseId: string,
  projectName: string,
  timeZone: string,
  tabType: string
): Promise<Array<INarrative>> =>
  axios
    .get(
      `/getNarrativeVersionsDropdown/${encodeURIComponent(
        templateName
      )}/tenantId/${encodeURIComponent(tenantId)}/case/${encodeURIComponent(
        caseId
      )}/tabType/${tabType}?projectName=${encodeURIComponent(
        projectName
      )}&timeZone=${encodeURIComponent(timeZone)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<INarrative>>) => {
      return serviceUtils.processSuccess<INarrative[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

/**
 *This API will return the selected version constant
 * @param templateVersionId
 * @returns
 */
export const getTemplateContentByVersionId = (
  templateVersionId: string
): Promise<INarrativeVersionDetail> =>
  axios
    .get(`/getNarrativeTemplateById/${encodeURIComponent(templateVersionId)}`, axiosConfig)
    .then((res: AxiosResponse<INarrativeVersionDetail>) => {
      return serviceUtils.processSuccess<INarrativeVersionDetail>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

/**
 *This API will update the WIP and create a new template version
 * @param templateVersionId
 * @param reqBody
 * @returns
 */
export const updateNarrative = (templateVersionId: string, reqBody: INarrativeVersionDetail) =>
  axios
    .put(
      `/updateNarrativeTemplateById/${encodeURIComponent(templateVersionId)}`,
      reqBody,
      axiosConfig
    )
    .then((res: AxiosResponse) => {
      if (res.status == 400) {
        throw res;
      }
      if (res.status == 200) {
        console.debug('Updated WIP and created new version :', reqBody);
      }
    })
    .catch(function (error: AxiosError<any>) {
      console.error('Error updating WIP and creating new version: ', error.toJSON());
      throw error;
    });

/**
 * This function is responsible for delete version
 * @param id
 * @returns
 */
export const deleteNarrative = (id: string) =>
  axios
    .delete(`/deleteNarrativeTemplateById/${encodeURIComponent(id)}`, axiosConfig)
    .then((res) => {
      return res.data;
    });

/**
 *This api will restore the narrative to the selected version state
 * @param templateVersionId
 * @returns
 */
export const restoreNarrativeByVersion = (templateVersionId: string): Promise<Array<INarrative>> =>
  axios
    .get(`/restoreNarrativeTemplateById/${encodeURIComponent(templateVersionId)}`, axiosConfig)
    .then((res: AxiosResponse<Array<INarrative>>) => {
      return serviceUtils.processSuccess<INarrative[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

/**
 *This api will reset the narrative to the default template content
 * @param templateVersionId
 * @returns
 */
export const resetNarrativeByVersion = (templateVersionId: string): Promise<Array<INarrative>> =>
  axios
    .get(`/resetNarrativeTemplateById/${encodeURIComponent(templateVersionId)}`, axiosConfig)
    .then((res: AxiosResponse<Array<INarrative>>) => {
      return serviceUtils.processSuccess<INarrative[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const generateNarrative = (templateVersionId: string): Promise<INarrativeVersionDetail> =>
  axios
    .post(
      `/generateNarrativeTemplateById/${encodeURIComponent(templateVersionId)}`,
      {},
      axiosConfig
    )
    .then((res: AxiosResponse<INarrativeVersionDetail>) => {
      return serviceUtils.processSuccess<INarrativeVersionDetail>(res);
    })
    .catch(function (error) {
      console.error('Error generating a Narrative: ', error.toJSON());
      throw error.message;
    });

export const narrativeRefresh = (caseId: string) =>
  `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/narrativeGenerationService${API_CONSTANTS.SCREEN_REFRESH}/${caseId}`;

export const saveNarrativeToFile = (reqBody: INarrativeToFile) => {
  return axios
    .post(
      `/narrativePdfUpload/${LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
      )}`,
      reqBody,
      axiosConfig
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });
};

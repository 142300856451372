import React, { SyntheticEvent } from 'react';
import {
  TextField,
  Autocomplete,
  Chip,
  TextFieldProps,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { IMetadataInputs } from '../../../../utils/entities/IMetadata';

const AutocompleteMetadata = (props: IMetadataInputs) => {
  const onValChanged = (event: SyntheticEvent, val: Array<string>) => {
    props.attribute.values = val;
    props.valueUpdated();
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={props.attribute.values || []}
      onChange={onValChanged}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            key={option + '-' + index}
          />
        ))
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...(params as TextFieldProps)}
          variant="standard"
          label={props.showLabel ? props.attribute.title : ''}
          name={props.attribute.name}
        />
      )}
    />
  );
};

export default AutocompleteMetadata;

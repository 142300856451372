import React, { ChangeEvent, useEffect, useState } from 'react';
import { IRefData } from 'fso-fincrime-sdk-ui';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import {
  Stack,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
} from '@mui/material';
import QuestionTooltip from '../QuestionTooltip';
import SpelUtils from '../../../../utils/spelUtils';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import { useTranslation } from 'react-i18next';
import IQuestionType from '../../../../utils/entities/IQuestionType';
import DynamicQuestionnaireUtils from '../../../../utils/functions/DynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion, { IValue } from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';

const RadioQuestion = (props: IQuestionType) => {
  const {
    question,
    updateValue,
    updateLocals,
    disabled,
    locals,
    contextLocals,
    entity,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;
  const { data, updatedRefreshData } = useIScreen();
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };
  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    context
  );
  const storedValue: string | undefined = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  );

  const oldValue = data?.dynamicQuestionnaire?.[activeTabId!]?.sets[0].questions.find(
    (currentQuestion: IQuestion) => currentQuestion.id == question.id
  );

  const [value, setValue] = useState<string>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
  );
  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value?.toString()
    );
    const valueArray = [value?.toString()];
    updateValue(question.attributeName, valueArray);

    locals[question?.attributeName] = value;

    updateLocals(locals);

    if (value) {
      setRuntimeValidationError(
        DynamicQuestionnaireUtils.hasRuntimeError(question.validation, value, {
          ...context,
          [question.attributeName]: value,
        })
      );
    }
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
    );
  }, [entity]);

  useEffect(() => {
    if (question.refresh) {
      setValue(question.value[entity][0]);
    }
  }, [question.refresh]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, context) && (
        <>
          <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormControl>
                <FormLabel id={'question-' + question.id} required={requiredResult}>
                  {question.label}
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby={'question-' + question.id}
                  name={'radio-buttons-group-' + question.id}
                  value={value}
                  onChange={onChangeHandler}>
                  {question.responseOptions.map((opt: IRefData) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={
                        <Radio
                          size="small"
                          disabled={
                            disabled
                              ? disabled
                              : SpelUtils.expressionValidation(
                                  question.readOnly,
                                  question.readOnlyExpression,
                                  context
                                )
                          }
                        />
                      }
                      label={opt.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
          </Stack>

          <ErrorStack
            value={value}
            entity={entity}
            error={error}
            runtimeValidationError={runtimeValidationError}
            required={requiredResult}
          />
        </>
      )}
    </>
  );
};

export default RadioQuestion;

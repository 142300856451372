import React, { useContext, useEffect, useState } from 'react';
import Transaction from 'fso-fincrime-transaction-reviewer-ui';
import 'fso-fincrime-transaction-reviewer-ui/dist/index.css';
import { useIScreen } from '../../pages/task/Task';
import GeneralUtils from '../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import axiosConfig from '../../service/axios.config';
import { CONSTANTS } from '../../utils/constants/constants';
import { useParams } from 'react-router-dom';
import IScreen from '../../utils/entities/screen/IScreen';
import SpelUtils from '../../utils/spelUtils';
import GenericContext from '../generic/timer/GenericContext';

function TransactionReviewer() {
  const {
    data,
    setLoading,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
    activeTabId,
    requestBody,
    setRequestBody,
    setSaveTaskAsDraftClick,
  } = useIScreen();
  const { tabId } = useParams();
  const [expression, setExpression] = useState<string | null>(
    data.transactionReviewer && data.transactionReviewer[data.activeTabId].preSelectionCondition
  );

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 1000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  useEffect(() => {
    if (data && activeTabId && !requestBody) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (
      data?.transactionReviewer &&
      expression == '' &&
      data.transactionReviewer[data.activeTabId].preSelectionCondition != expression
    ) {
      data.transactionReviewer[data.activeTabId].preSelectionCondition = expression;
      setRequestBody(data);
      setSaveTaskAsDraftClick(true);
    }
  }, [expression]);

  useEffect(() => {
    if (data.transactionReviewer) {
      setExpression(data.transactionReviewer[data.activeTabId].preSelectionCondition);
    }
  }, [data.transactionReviewer && data.transactionReviewer[data.activeTabId].tabType]);

  const { caseHeaderToggle } = useContext(GenericContext);
  return (
    <div data-testid="app-main" key={tabId}>
      {data.transactionReviewer &&
        data.transactionReviewer[data.activeTabId].caseId &&
        data.transactionReviewer[data.activeTabId].focalPartyKey && (
          <Transaction
            caseid={data.caseId}
            transactionReviewerData={data.transactionReviewer[data.activeTabId]}
            tenant={data.tenant}
            axiosConfig={axiosConfig}
            baseUrl={window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}
            expressionValidation={SpelUtils.expressionValidation}
            getValueFromExpression={SpelUtils.getValueFromExpression}
            expression={expression}
            setExpression={setExpression}
          />
        )}
    </div>
  );
}

export default TransactionReviewer;

import React, { useContext } from 'react';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import { DateUtils, ICaseHeaderAttributes, IStatusOptions } from 'fso-fincrime-sdk-ui';
import { Typography, Grid, Box, MenuItem, MenuList, Stack } from '@mui/material';
import DropdownComponent from '../../../../generic/dropdown/Dropdown';
import dateUtils from '../../../../../utils/dateUtils';
import DateText from '../../../../generic/timer/DateText';
import LocalStorageUtils from '../../../../../utils/localStorageUtils';
import './HeaderAttribute.scss';
import GeneralUtils from '../../../../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../../../../utils/constants/event_constants';
import { IHeaderAttributeProps } from '../../../../../utils/entities/case/CaseHeader/IHeaderAttributeProps';
import TooltipComp from '../../../../generic/tooltip/Tooltip';
import CaseTags from '../case-tags/CaseTags';
import { ITagSubmit } from '../../../../../utils/entities/case/CaseHeader/ITagSubmit';
import RiskScore from '../RiskScore';
import FocalEntity from '../FocalEntity';
import CopyButton from '../../../../generic/button/copy/CopyButton';
import GenericContext from '../../../../generic/timer/GenericContext';
import ISlaStatusMap from '../../../../../types/ISlaStatus';
import EpochToDateText from '../../../../generic/timer/EpochToDateText';

const HeaderAttribute = ({
  attribute,
  setStatus,
  slaLabels,
  statusOptions,
  setHoldAccordion,
  setTooltipStatus,
  tooltipStatus,
  caseId,
  internalStatus,
}: IHeaderAttributeProps) => {
  const timeZone: string = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);
  const { dateFormat } = useContext(GenericContext);

  const changeStatusHandler = (status: string) => {
    setStatus(status);
    setHoldAccordion(true);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: true });
    setTooltipStatus(false);
  };

  const getSLAStatusValue = (status: ISlaStatusMap): string =>
    slaLabels ? slaLabels[status.labelKey] : status.label;

  const getSLAStatus = (
    track?: string,
    noColor?: boolean,
    cssClasses?: string
  ): React.JSX.Element | undefined => {
    if (!track) {
      return <></>;
    }
    const status: ISlaStatusMap = CONSTANTS.SLA_STATUS[track];
    if (!status) return undefined;

    const className: string = noColor ? '' : `${status.className} `;
    const label: string = getSLAStatusValue(status);

    return <span className={`${className}${cssClasses || ''}`}>{label}</span>;
  };

  const handleTooltipOpen = () => {
    setTooltipStatus(true);
  };

  const handleTooltipClose = () => {
    setTooltipStatus(false);
  };
  const getCaseAttrValue = (attribute: ICaseHeaderAttributes): React.JSX.Element | undefined => {
    switch (attribute.attributeName) {
      case CONSTANTS.ATTRIBUTES.SLA_STATUS:
        return getSLAStatus(attribute?.attributeValue, false, 'font-size-12');

      case CONSTANTS.ATTRIBUTES.CREATED_DATE:
      case CONSTANTS.ATTRIBUTES.TASK_DUE_DATE:
        return dateUtils.timeStampWithDate(attribute?.attributeValue, timeZone) !=
          CONSTANTS.DATES.INVALID_DATE ? (
          <DateText date={attribute?.attributeValue} />
        ) : (
          <></>
        );
      case CONSTANTS.ATTRIBUTES.ASSIGNED_DATE:
        return !isNaN(Number(attribute?.attributeValue)) ? (
          <EpochToDateText epoch={Number(attribute?.attributeValue)} />
        ) : (
          <></>
        );

      case CONSTANTS.ATTRIBUTES.TASK_STATUS:
        return (
          <DropdownComponent
            setClickAway={handleTooltipClose}
            className="status-wrapper"
            setClose={handleTooltipClose}
            setOpen={handleTooltipOpen}
            open={tooltipStatus}
            list={
              <Box>
                <MenuList className="version-list">
                  {statusOptions?.map((option: IStatusOptions, index: number) => (
                    <MenuItem
                      key={index}
                      value={option.label}
                      onClick={() => {
                        changeStatusHandler(option.label);
                      }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Box>
            }
            selectedOption={status}
          />
        );
      case CONSTANTS.ATTRIBUTES.CASE_TAGS:
        return (
          <CaseTags
            caseId={caseId}
            setFormTagRes={(arg0: ITagSubmit) =>
              GeneralUtils.triggerEvt(EVENTS_CONSTANTS.HEADER_CASE_TAG_UPDATE, arg0)
            }
            showTitle={true}
            showAddTags={true}
            tagsCount={1}
            internalStatus={internalStatus}
          />
        );
      case CONSTANTS.ATTRIBUTES.BLANK:
        return <></>;
      case CONSTANTS.ATTRIBUTES.FOCAL_ENTITY_NAME:
        return <FocalEntity attribute={attribute} />;
      case CONSTANTS.ATTRIBUTES.RISK_SCORE_VAL:
        return <RiskScore attribute={attribute} type="value" />;
      case CONSTANTS.ATTRIBUTES.RISK_SCORE_RANK:
        return <RiskScore attribute={attribute} type="rank" />;
      case CONSTANTS.ATTRIBUTES.RISK_SCORE_COMBINED:
        return <RiskScore attribute={attribute} type="combined" />;
      default:
        return <TooltipComp label={attribute.attributeValue} />;
    }
  };

  const getCaseAttrTitle = (attribute: ICaseHeaderAttributes): React.JSX.Element => {
    switch (attribute.attributeName) {
      case CONSTANTS.ATTRIBUTES.BLANK:
        return <></>;
      case CONSTANTS.ATTRIBUTES.CASE_TAGS:
        return <></>;
      default:
        return <TooltipComp label={attribute.attributeLabel.toUpperCase()} />;
    }
  };

  const getCopyButtonValue = (attribute: ICaseHeaderAttributes): string => {
    switch (attribute.attributeName) {
      case CONSTANTS.ATTRIBUTES.SLA_STATUS: {
        const status: ISlaStatusMap = CONSTANTS.SLA_STATUS[attribute.attributeValue];
        return getSLAStatusValue(status);
      }
      case CONSTANTS.ATTRIBUTES.ASSIGNED_DATE:
        return dateUtils.epochToDateFormated(
          Number(attribute?.attributeValue),
          dateFormat,
          timeZone ?? LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
        );
      case CONSTANTS.ATTRIBUTES.CREATED_DATE:
      case CONSTANTS.ATTRIBUTES.TASK_DUE_DATE:
        return dateUtils.getDateText(attribute.attributeValue, dateFormat);
      case CONSTANTS.ATTRIBUTES.RISK_SCORE_COMBINED:
        return JSON.parse(attribute.attributeValue)?.riskScoreValue;
      default:
        return attribute.attributeValue;
    }
  };

  return (
    <Grid item className="attribute-container box-label-value" key={JSON.stringify(attribute)}>
      <Typography className="bold-font header-title font-size-12">
        {getCaseAttrTitle(attribute)}
      </Typography>
      <Stack direction="row">
        {getCaseAttrValue(attribute)}
        {attribute.attributeValue && <CopyButton value={getCopyButtonValue(attribute)} />}
      </Stack>
    </Grid>
  );
};

export default HeaderAttribute;

import { CONSTANTS } from '../constants/constants';
import IExpressionContext from '../entities/expressions/IExpressionContext';
import IValidation from '../entities/IValidation';
import IQuestion, { IValue } from '../entities/screen/IQuestion';
import IQuestionnaire from '../entities/screen/IQuestionnaire';
import IScreen from '../entities/screen/IScreen';
import LocalStorageUtils from '../localStorageUtils';
import SpelUtils from '../spelUtils';

interface IDynamicQuestionnaireUtils {
  getDynamicQuestionnaire: (requestBody: IScreen, tabId: string) => IQuestionnaire | undefined;
  cleanDynamicQuestionnaireStorage: () => void;
  hasRuntimeError: (
    validations: IValidation[],
    valueToCheck: string,
    context?: IExpressionContext
  ) => boolean;
  questionIsEmpty: (q: IQuestion, entityId: string, checkLocalStorage?: boolean) => boolean;
  getQuestionValueAsString: (
    storedValue: string | undefined,
    value: IValue,
    entityId: string
  ) => string;
  getQuestionValueAsArray: (
    storedValue: string[] | undefined,
    value: IValue,
    entityId: string
  ) => string[];
}

const DynamicQuestionnaireUtils: IDynamicQuestionnaireUtils = {
  getDynamicQuestionnaire: (requestBody: IScreen, tabId: string) => {
    return requestBody?.dynamicQuestionnaire?.[tabId];
  },
  cleanDynamicQuestionnaireStorage: () => {
    const keys = Object.keys(localStorage);
    for (const k of keys) {
      if (k.includes(CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE)) {
        LocalStorageUtils.removeSavedItem(k);
      }
    }
  },
  hasRuntimeError: (
    validations: IValidation[],
    valueToCheck: string,
    context?: IExpressionContext
  ) => {
    if (valueToCheck == '') {
      return false;
    }

    for (const valObj of validations) {
      switch (valObj.type) {
        case 'REGEX':
          if (!new RegExp(valObj.value).test(valueToCheck)) {
            return true;
          }
          return false;
        case 'SPELL':
          if (context) {
            return !SpelUtils.expressionValidation(true, valObj.value, context);
          }
          return false;
      }
    }

    return false;
  },
  questionIsEmpty: (q: IQuestion, entityId: string, checkLocalStorage?: boolean) => {
    const qIsEmpty =
      q.value[entityId] == null || q.value[entityId][0] == null || q.value[entityId][0] == '';
    const storedValue = LocalStorageUtils.getSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entityId}.${q.attributeName}`
    );
    if (checkLocalStorage) {
      return storedValue !== null ? storedValue == '' : qIsEmpty;
    }
    return qIsEmpty;
  },
  getQuestionValueAsString: (storedValue: string | undefined, value: IValue, entityId: string) => {
    return storedValue
      ? storedValue
      : Object.keys(value).includes(entityId)
        ? value[entityId].length != 0
          ? value[entityId][0]
          : ''
        : '';
  },
  getQuestionValueAsArray: (storedValue: string[] | undefined, value: IValue, entityId: string) => {
    return storedValue
      ? storedValue
      : Object.keys(value).includes(entityId)
        ? value[entityId].length != 0
          ? value[entityId]
          : []
        : [];
  },
};

export default DynamicQuestionnaireUtils;

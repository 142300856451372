import React from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';
import { ITag } from '../../../utils/entities/ITag';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TooltipComp from '../tooltip/Tooltip';
import { CONSTANTS } from '../../../utils/constants/constants';
import { useTranslation } from 'react-i18next';

interface ITagProps {
  tag: ITag;
  showTooltip?: boolean;
  handleDelete?: ((chipToDelete: ITag) => void) | undefined;
}

interface IProps {
  tags: Array<ITag>;
  tagsCount: number;
  handleDelete?: ((chipToDelete: ITag) => void) | undefined;
}

export const Tag = (props: ITagProps) => {
  const { tag, showTooltip, handleDelete } = { ...props };
  const nameMaxLength = 11;

  return (
    <Chip
      label={
        showTooltip && tag.name.length > nameMaxLength ? (
          <TooltipComp label={tag.name} />
        ) : (
          <span className="ellipsis">{tag.name}</span>
        )
      }
      className="fc-nexus-chip"
      deleteIcon={<ClearOutlinedIcon />}
      onDelete={handleDelete ? () => handleDelete(tag) : undefined}
    />
  );
};

export const RestAllTags = (props: IProps) => {
  const { tags, tagsCount, handleDelete } = { ...props };
  const { t } = useTranslation();

  return (
    <div className="view-more">
      <Tooltip
        title={
          <Stack direction="column" spacing="0">
            {tags.map((tag: ITag, index: number) => {
              if (index >= tagsCount) {
                return (
                  <Tag
                    tag={tag}
                    key={tag.index ?? `${tag.name}-${index}`}
                    handleDelete={tag.method === CONSTANTS.MANUAL ? handleDelete : undefined}
                  />
                );
              }
            })}
          </Stack>
        }>
        <Chip
          label={
            tagsCount === 0
              ? `${tags.length - tagsCount} ${t('TAGS')}`
              : `+ ${tags.length - tagsCount} ${t('MORE')}`
          }
          className="fc-nexus-chip"
        />
      </Tooltip>
    </div>
  );
};

export const TagsContainer = (props: IProps) => {
  const { tags, tagsCount, handleDelete } = { ...props };

  return (
    <div className="align-vertical-center">
      {tags?.map((tag: ITag, i: number) => {
        if (i < tagsCount) {
          return (
            <Tag
              showTooltip
              tag={tag}
              key={tag.index}
              handleDelete={tag.method === CONSTANTS.MANUAL ? handleDelete : undefined}
            />
          );
        }
      })}
    </div>
  );
};

import React, { useEffect, useState, useRef } from 'react';
import { IConfigTag } from '../../../../../utils/entities/case/CaseHeader/IConfigTag';
import './CaseTags.scss';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GQL_TAGS_BY_ID } from '../../../../../service/graphql/gql.service';
import { getAllCaseTags } from '../../../../../service/config-manager.service';
import GeneralUtils from '../../../../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../../../../utils/constants/event_constants';
import { TagsContainer, RestAllTags } from '../../../../generic/chip/Tag';
import { ITag } from '../../../../../utils/entities/ITag';
import { caseRefresh } from '../../../../../service/case-manager.service';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AddTags from './AddTags';
import StringUtils from '../../../../../utils/stringUtils';
import { ITagsProps } from '../../../../../utils/entities/case/CaseHeader/ITagsProps';

const CaseTags = (props: ITagsProps) => {
  const { t } = useTranslation();
  const { data, refetch } = GQL_TAGS_BY_ID(props.caseId);
  const addBtnRef = useRef(null);

  const [showAddButton, setShowAddButton] = useState<boolean>(true);
  const [allTags, setAllTags] = useState<Array<string>>([]);
  const [value, setValue] = useState<string>('');
  const [valueStatus, setValueStatus] = useState<string>('');
  const [finalTagRes, setFinalTagRes] = useState<Array<ITag>>([]);
  const [commingData, setCommingData] = useState([]);
  const [tagsArr, setTagsArr] = useState<Array<ITag>>([]);

  const sseEventListener = (e: MessageEvent) => {
    setCommingData(JSON.parse(e.data));
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.CASE_TAG_UPDATE, caseRefresh(props.caseId), true, {
    case_tags_update: sseEventListener,
  });

  useEffect(() => {
    setCommingData(data?.case?.tags ? data?.case?.tags : []);
  }, [data]);

  useEffect(() => {
    setTagsArr(
      commingData?.map(
        (
          ele: {
            name: string;
            method: string;
          },
          i: number
        ) => {
          return { ...ele, ...{ index: i } };
        }
      )
    );
  }, [commingData]);

  useEffect(() => {
    const updateFormRes: any = {};
    finalTagRes.forEach((ele: ITag) => {
      updateFormRes[ele.name] = ele.added;
    });
    props.setFormTagRes(updateFormRes);
  }, [finalTagRes]);

  useEffect(() => {
    // Execute a function when the user presses a key on the keyboard
    document.addEventListener('keypress', enterBtnClick);
    // Execute a function when the user do mouse click
    document.addEventListener('click', handleClickOutside);

    getAllCaseTags()
      .then((res: Array<IConfigTag>) => {
        setAllTags(
          res.map((tag: IConfigTag) => {
            return tag.tag;
          })
        );
      })
      .catch((error) => {
        console.error('Error :', error);
      });
  }, []);

  useEffect(() => {
    const validValue = tagsArr?.filter((tag: ITag) => {
      return StringUtils.compareStrings(value, tag.name);
    });

    if (value && validValue && !validValue.length) {
      const maxIndex = Math.max(
        ...tagsArr.map((o: ITag) => {
          if (o.index) {
            return o.index;
          } else {
            return 0;
          }
        })
      );
      const newValue = {
        index: maxIndex && maxIndex != -Infinity ? maxIndex + 1 : 1,
        name: value,
        method: CONSTANTS.MANUAL,
      };
      const updatedTagsArr = [...tagsArr, newValue];

      setTagsArr(updatedTagsArr);
      setValue('');
    }
  }, [valueStatus]);

  useEffect(() => {
    if (tagsArr?.length) {
      generateTagsRes();
    }
  }, [tagsArr]);

  const handleDelete = (chipToDelete: ITag) => {
    setTagsArr(tagsArr.filter((chip: ITag) => chip.index !== chipToDelete.index));
  };

  const generateTagsRes = () => {
    const res1: Array<ITag> = tagsArr
      ?.filter((item1: ITag) => !commingData.some((item2: ITag) => item2.name === item1.name))
      .map((e: ITag) => {
        return { ...e, ...{ added: true } };
      });
    const res2: Array<ITag> = commingData
      ?.filter((item1: ITag) => !tagsArr.some((item2) => item2.name === item1.name))
      .map((e: ITag) => {
        return { ...e, ...{ added: false } };
      });
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: true });
    setFinalTagRes([...res1, ...res2]);
  };

  const constSetUpdatedVal = () => {
    const addInputEle = document.getElementById('add_tag') as HTMLInputElement;
    if (addInputEle?.value) {
      setValueStatus(addInputEle?.value);
    }
    setShowAddButton(true);
  };

  const handleClickOutside = (e: { target: any }) => {
    const addBtnNode = addBtnRef.current as any;

    if (!addBtnNode?.contains(e.target) && e.target.classList[0] != 'add-button') {
      constSetUpdatedVal();
    }
  };

  const enterBtnClick = (event: { preventDefault(): unknown; key: string; target: any }) => {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      constSetUpdatedVal();
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="chip-container">
      {props.showTitle && (
        <div className="tag-label">
          <LocalOfferOutlinedIcon fontSize="small" />
          <div>{t('TAGS')}</div>
        </div>
      )}
      <div className="align-vertical-center">
        <TagsContainer tags={tagsArr} tagsCount={props.tagsCount} handleDelete={handleDelete} />
        {tagsArr?.length > props.tagsCount ? (
          <RestAllTags tags={tagsArr} tagsCount={props.tagsCount} handleDelete={handleDelete} />
        ) : null}
        {props.showAddTags && !props.internalStatus && (
          <AddTags
            showAddButton={showAddButton}
            setShowAddButton={setShowAddButton}
            addBtnRef={addBtnRef}
            value={value}
            setValue={setValue}
            allTags={allTags}
          />
        )}
      </div>
    </div>
  );
};

CaseTags.propTypes = {
  tags: PropTypes.array,
  setTagsArr: PropTypes.func,
  tagsCount: PropTypes.number,
};

export default CaseTags;

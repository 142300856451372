import React, { useState } from 'react';
import { exportByListType } from '../../../../service/global-query.service';
import { addAlert } from '../../../../store/actions/alerts.actions';
import GeneralUtils from '../../../../utils/generalUtils';
import StringUtils from '../../../../utils/stringUtils';
import PlainTextButton from '../../button/PlainTextButton';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { IExportProps } from '../../../../utils/entities/ag-grid/IExport';
import { ViewTypes } from '../../../../utils/constants/enums';
import ITask, { ICase } from '../../../../utils/entities/screen/ITask';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';

const ExportComponent = ({
  viewType,
  selectedAll,
  rows,
  payload,
  currentCols,
  nowRows,
}: IExportProps) => {
  const { t } = useTranslation();

  const [exporting, setExporting] = useState<boolean>(false);
  const key = viewType === ViewTypes.TASK_LIST ? 'taskId' : 'caseId';

  const selectedTaskRowsToFilter = (rows: ITask[]) => {
    return {
      filterType: 'text',
      type: rows.length > 1 ? 'isIn' : 'equals',
      terms: rows.length > 1 ? rows.map((row) => row.taskId) : [],
      filter: rows.length > 1 ? '' : rows[0].taskId,
    };
  };

  const selectedCaseRowsToFilter = (rows: ICase[]) => {
    return {
      filterType: 'text',
      type: rows.length > 1 ? 'isIn' : 'equals',
      terms: rows.length > 1 ? rows.map((row) => row.caseId) : [],
      filter: rows.length > 1 ? '' : rows[0].caseId,
    };
  };

  const exportToExcel = () => {
    if (nowRows) {
      addAlert({ type: 'info', primaryText: t('EXPORT.NO_ROWS') });
      return;
    }

    const exportPayload = { ...payload };
    // TODO: Replace should be done by a dictionarie in const file
    exportPayload.dateFormat = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT
    )
      ?.replace('YYYY', 'yyyy')
      ?.replace('DD', 'dd');
    if (!selectedAll && rows.length > 0) {
      switch (viewType) {
        case ViewTypes.TASK_LIST:
          exportPayload['filterModel'] = { taskId: selectedTaskRowsToFilter(rows as ITask[]) };
          break;
        case ViewTypes.CASE_LIST:
          exportPayload['filterModel'] = { caseId: selectedCaseRowsToFilter(rows as ICase[]) };
          break;
      }
    }

    setExporting(true);
    exportByListType(
      {
        ...exportPayload,
        currentCols: currentCols,
      },
      viewType
    )
      .then((res) => {
        GeneralUtils.downloadByBlob(
          res.data,
          res.headers['content-type'],
          StringUtils.getSubStringAfterKey(res.headers['content-disposition'], 'filename=')
        );
      })
      .catch(() => {
        addAlert({
          type: 'error',
          primaryText: t('EXPORT.ERROR_MESSAGE'),
        });
      })
      .finally(() => setExporting(false));
  };

  return (
    <PlainTextButton
      handleClick={exportToExcel}
      loading={exporting}
      classes="text-transform-capitalize export-btn bold-font"
      icon={<DownloadIcon />}
      label={t('EXPORT.EXPORT')}
    />
  );
};

export default ExportComponent;

import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React from 'react';
import { CONSTANTS } from '../../../utils/constants/constants';
import LocalStorageUtils from '../../../utils/localStorageUtils';

const TimerDatepickerComponent = (props: {
  label: React.ReactNode;
  value: string | dayjs.Dayjs | null | undefined;
  disabled?: boolean;
  minDate?: string | dayjs.Dayjs;
  onChange?: (
    value: string | dayjs.Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
}): JSX.Element => {
  return (
    <div className="fc-nexus-col-xs-4 fc-nexus-col-lg fc-nexus-form-field">
      <DatePicker
        label={props.label}
        value={props.value}
        className="Datepicker-option"
        disabled={props.disabled}
        minDate={props.minDate}
        onChange={props.onChange}
        slotProps={{ textField: { variant: 'standard' } }}
        format={LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT)}
      />
    </div>
  );
};

export default TimerDatepickerComponent;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { getTasksWithServerSideFilterSort } from '../../service/global-query.service';
import { CONSTANTS } from '../../utils/constants/constants';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  ColGroupDef,
  Column,
  ColumnState,
  ColumnVO,
  FilterModel,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import Pagination from '../../components/generic/AgGrid/pagination/Pagination';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import { getColumnConfig } from '../../service/config-manager.service';
import { IUser } from '../../utils/entities/role/IUser';
import { getUser, getUsersByProjectName } from '../../service/role-manager.service';
import GeneralUtils from '../../utils/generalUtils';
import ITask from '../../utils/entities/screen/ITask';
import { PermissionTypes } from '../../utils/entities/role/IPermission';
import IAgGridRowsTaskRes from '../../utils/entities/screen/IAgGridRowsTaskRes';
import { ViewTypes } from '../../utils/constants/enums';
import IAgGridTblPayload from '../../utils/entities/IAgGridTblPayload';
import TextFieldComponent from '../../components/generic/inputs/textField/TextFieldComponent';
import DateText from '../../components/generic/timer/DateText';
import Views from '../../components/generic/AgGrid/views/Views';
import LocalStorageUtils from '../../utils/localStorageUtils';
import { addAlert } from '../../store/actions/alerts.actions';
import CheckboxComponent from '../../components/generic/inputs/checkbox/CheckboxComponent';
import IAccessToken from '../../utils/entities/authentication/IAccessToken';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import Loader from '../../components/loader/Loader';
import EpochToDateText from '../../components/generic/timer/EpochToDateText';
import LinkButton from '../../components/generic/button/link/LinkButton';
import SlaAging from '../../components/sla/SlaAging';
import { DotType } from '../../types/dotType';
import Dot from '../../components/generic/chip/dot/Dot';
import ISlaSettingsEntity from '../../utils/entities/config/ISlaSettingsEntity';
import DateCountdown from '../../components/generic/AgGrid/columns/DateCountdown';
import ExportComponent from '../../components/generic/AgGrid/export/Export';
import agGridUtils from '../../utils/agGridUtils';
import AssignTask from '../../components/task-list/assign-task/AssignTask';
import { IRole } from '../../utils/entities/role/IRole';
import IDataModelColumn from '../../utils/entities/ag-grid/IDataModelColumn';
import IGroupKeyPageRequest from '../../utils/entities/ag-grid/pagination/IGroupKeyPageRequest';
import IconButtonComponent from '../../components/generic/button/IconButtonComponent';
import IGroupkeyPagination from '../../utils/entities/ag-grid/pagination/IGroupkeyPagination';
import TooltipComp from '../../components/generic/tooltip/Tooltip';
import Reset from '../../components/generic/AgGrid/reset/Reset';
import { useDebouncedCallback } from 'use-debounce';

const TaskList = (props: { slaSettingsEntity: ISlaSettingsEntity | undefined }) => {
  const { slaSettingsEntity } = props;
  const { t } = useTranslation();
  const viewType = ViewTypes.TASK_LIST;
  const gridRef = useRef<AgGridReact>(null);
  const projName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );
  const tenant: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const gridOptions: GridOptions = {
    autoGroupColumnDef: {
      headerName: t('AG_GRID.GROUP_HEADER'),
      field: 'group',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      enableRowGroup: false,
      suppressMenu: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    getRowId: (params: GetRowIdParams) => {
      /* All rows will have an unique taskId, 
      Excpet the group rows, that rows will have always a single unique element
       in taskListColumnConfigurations
      */
      const col = params.data.taskListColumnConfigurations[0];
      const parentKeys = (params.parentKeys ?? []).join('-');
      return params.data.taskId ?? `${parentKeys}-${col.attributeName}-${col.attributeValue}`;
    },
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<ColDef | ColGroupDef>>([]);
  const [columnsConfigs, setColumnsConfigs] = useState<Array<IDataModelColumn>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageNo, setPageNo] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridReadyEvent | undefined>(undefined);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [gridSelectedRows, setGridSelectedRows] = useState<Array<ITask>>([]);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [searchField, setSearchField] = useState<string | null>(null);
  const [payload, setPayload] = useState<IAgGridTblPayload | undefined>(undefined);
  const [columnsViews, setColumnsViews] = useState<Array<IDataModelColumn>>([]);
  const [activateFilters, setActivateFilters] = useState<boolean>(false);
  const [viewsPayload, setViewsPayload] = useState<IAgGridTblPayload | undefined>(undefined);
  const [defaultView, setDefaultView] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [selectAllTasks, setSelectAllTasks] = useState<boolean>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG) ?? false
  );
  const [groupByParams, setGroupByParams] = useState<Array<ColumnVO> | []>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [activeRole, setActiveRole] = useState<IRole>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)
  );
  const [noRows, setNoRows] = useState<boolean>(false);

  /***************************** Needed for the Clear button *****************************/
  const gridNode = gridRef.current as any;
  const columnState = gridNode?.columnApi?.api?.getColumnState();
  const filterModel = gridApi?.api.getFilterModel();
  const [columnFiltering, setColumnFiltering] = useState<boolean>(true);
  const [columnSorting, setColumnSorting] = useState<boolean>(true);
  const [columnGroup, setColumnGroup] = useState<boolean>(true);
  const [resetColumns, setResetColumns] = useState<boolean>(true);
  const displayedColumns = gridNode?.columnApi?.api?.columnModel?.displayedColumns;

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        reloadGrid();
        setLoading(false);
      }, 5000);
    }
  }, [loading]);

  useEffect(() => {
    const checkIfGroup = gridNode?.columnApi?.api?.columnModel?.gridColumns?.find((column: any) => {
      return !!column.rowGroupActive;
    });
    setColumnGroup(!checkIfGroup);

    columnState?.forEach((state: ColumnState) => {
      if (state.sort === 'asc' || state.sort === 'desc') {
        setColumnSorting(false);
      }
    });

    if (filterModel && Object.keys(filterModel).length) {
      setColumnFiltering(false);
    }
  }, [columnState, filterModel]);

  const columnsMoved = () => {
    setResetColumns(false);
  };

  /***************************** End of Needed for the Clear button *****************************/

  const usercanTaskUnassign = GeneralUtils.checkUserPermissions(PermissionTypes.TASK_UNASSIGNMENT);
  const usercanFullTaskUnassign = GeneralUtils.checkUserPermissions(
    PermissionTypes.FULL_TASK_UNASSIGNMENT
  );
  const usercanTaskAssign = GeneralUtils.checkUserPermissions(PermissionTypes.TASK_REASSIGNMENT);
  const usercanFullTaskAssign = GeneralUtils.checkUserPermissions(
    PermissionTypes.FULL_TASK_REASSIGNMENT
  );

  useEffect(() => {
    if (tenant) {
      getConfigsData();
    }
  }, [gridApi, tenant]);

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    });

    retrieveUsers();

    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE);
      window.removeEventListener('beforeunload', function () {
        return;
      });
    };
  }, []);

  useEffect(() => {
    reloadGrid();
  }, [pageNo, pageSize, searchField, tenant, activeRole]);

  useEffect(() => {
    retrieveUsers();
  }, [activeRole]);

  useEffect(() => {
    toggleAllTasks();
  }, [totalPages, pageNo]);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE, pageSize);
    setPageNo(0);
  }, [pageSize]);

  useEffect(() => {
    if (activateFilters && viewsPayload) {
      const gridNode = gridRef.current as any;

      if (viewsPayload.rowGroupCols.length) {
        viewsPayload.rowGroupCols.forEach((group) => {
          gridNode.columnApi.api.applyColumnState({
            state: [{ colId: group.id, rowGroup: true, hide: true }],
          });
        });
      }

      if (viewsPayload?.filterModel?.assignee?.filter === 'currentUser') {
        gridApi?.api.setFilterModel({
          assignee: {
            filterType: 'text',
            type: 'contains',
            filter: viewsPayload.assignee,
          },
        });
      } else {
        gridApi?.api.setFilterModel(viewsPayload.filterModel);
      }

      if (viewsPayload.sortModel.length) {
        gridNode.columnApi.api.applyColumnState({
          state: [viewsPayload.sortModel[0]],
        });
      }

      if (viewsPayload?.filterModel?.taskId?.type === 'searchAllFields') {
        setSearchField(viewsPayload.filterModel.taskId?.filter ?? null);
      }

      setPageNo(viewsPayload.pageNumber);
      setTotalPages(viewsPayload.pageSize - 1);
      setActivateFilters(false);
    }
  }, [activateFilters]);

  document.addEventListener(EVENTS_CONSTANTS.ACTIVE_ROLE_UPDATED, (e: any) => {
    setActiveRole(GeneralUtils.deepCopy(e.detail));
  });

  const getConfigsData = () => {
    gridApi?.api.showLoadingOverlay();
    getColumnConfig(tenant, viewType)
      .then((res: Array<IDataModelColumn>) => {
        const checkboxFirstCol: IDataModelColumn = {
          attributeLabel: '',
          attributeValue: '',
          attributeName: CONSTANTS.AG_GRID.COLUMNS.CHECKBOX,
          attributeType: '',
          columnType: CONSTANTS.COLUMNTYPES.TEXT,
          listType: 'TASK_LIST',
        };
        const showMoreCol: IDataModelColumn = {
          attributeLabel: '',
          attributeValue: '',
          attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
          attributeType: '',
          columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
          listType: 'TASK_LIST',
        };
        if (res.length) {
          res.unshift(checkboxFirstCol);
          res.unshift(showMoreCol);
        }
        setColumnsConfigs(res);
        configureColumns(res);
        gridApi?.api.hideOverlay();
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') }));
  };

  const getCellData = (params: ICellRendererParams, colLabel: string) => {
    return (
      params.value ??
      params?.data?.taskListColumnConfigurations?.find(
        (col: IDataModelColumn) => colLabel === col.attributeLabel
      )?.attributeValue
    );
  };

  const configureColumns = (colConfigs: Array<IDataModelColumn>) => {
    const columns: Array<ColDef | ColGroupDef> = [];
    colConfigs.forEach((c: IDataModelColumn) => {
      const column: ColDef | ColGroupDef = {
        field: c.attributeName,
        headerName: c.attributeLabel,
        headerTooltip: c.attributeLabel,
        valueGetter: (params) => getCellData(params as ICellRendererParams, c.attributeLabel),
        filter: agGridUtils.isColDateType(c.columnType)
          ? CONSTANTS.AG_GRID.FILTER.DATE
          : CONSTANTS.AG_GRID.FILTER.TEXT,
        filterParams: !agGridUtils.isColDateType(c.columnType) && { suppressAndOrCondition: true },
      };
      if (c.columnStateProperties) {
        column.hide = c.columnStateProperties.hide ?? undefined;
        column.width = c.columnStateProperties.width;
        column.flex = c.columnStateProperties.width
          ? 0
          : (c.columnStateProperties.flex ?? undefined);
        column.sort = c.columnStateProperties.sort;
        column.sortIndex = c.columnStateProperties.sortIndex;
        column.aggFunc = c.columnStateProperties.aggFunc;
        column.pivotIndex = c.columnStateProperties.pivotIndex;
        column.pinned = c.columnStateProperties.pinned;
        column.rowGroupIndex = c.columnStateProperties.rowGroupIndex;
        column.initialWidth = c.columnStateProperties.width;
      } else {
        column.hide = false;
        column.width = undefined;
        column.flex = undefined;
        column.sort = undefined;
        column.sortIndex = undefined;
        column.aggFunc = undefined;
        column.pivotIndex = undefined;
        column.pinned = false;
        column.rowGroupIndex = undefined;
        column.initialWidth = undefined;
      }
      if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.CHECKBOX) {
        column.checkboxSelection = function () {
          return !selectAllTasks;
        };
        column.showDisabledCheckboxes = true;
      }
      if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE) {
        column.cellStyle = { alignContent: 'center' };
        column.resizable = false;
      }
      if (
        c.attributeName === CONSTANTS.AG_GRID.COLUMNS.CHECKBOX ||
        c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE
      ) {
        column.enableRowGroup = false;
        column.suppressMenu = true;
        column.sortable = false;
        column.suppressMovable = true;
        column.maxWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
        column.minWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
        column.suppressColumnsToolPanel = true;
      }
      switch (c.columnType) {
        case CONSTANTS.COLUMNTYPES.EPOCH_DATE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            return <EpochToDateText epoch={Number(value)} />;
          };
          break;
        case CONSTANTS.COLUMNTYPES.TASK_LINK:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            return (
              <LinkButton url={`${CONSTANTS.PAGES_URL.TASK}/${params.data.taskId}`} text={value} />
            );
          };
          break;
        case CONSTANTS.COLUMNTYPES.DATE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            return <DateText date={value} />;
          };
          break;
        case CONSTANTS.COLUMNTYPES.AGING:
          column.cellRenderer = (params: ICellRendererParams) => {
            const slaAging = params?.value;

            if (slaAging) {
              return <SlaAging status={slaAging} slaSettingsEntity={slaSettingsEntity} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.RISK_RATING:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            if (value) {
              let color: DotType;
              switch (value) {
                case 'Low':
                  color = 'SUCCESS';
                  break;
                case 'Medium':
                  color = 'WARNING';
                  break;
                case 'High':
                  color = 'FAIL';
                  break;
                default:
                  color = 'OFF';
                  break;
              }

              return <Dot type={color} message={value} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.DATE_COUNTDOWN:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            if (value && c.additionalProperties?.length) {
              return <DateCountdown timer={value} colorTresholds={c.additionalProperties} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.SHOW_MORE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const key = params.node.key;
            if (params.node.group && params.node.expanded && key) {
              const savedGroupPagination: IGroupkeyPagination[] | undefined =
                LocalStorageUtils.getSavedItem(
                  CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
                );
              const totalElements =
                savedGroupPagination?.find((gr) => gr.keyName === params.node.key)?.totalElements ??
                0;
              const childsNumber = getChildsCount(params.node);
              const canLoadMore = childsNumber < totalElements;

              return (
                <>
                  {canLoadMore && (
                    <TooltipComp
                      label={t('AG_GRID.SHOW_MORE', {
                        num: childsNumber,
                        total: totalElements,
                      })}>
                      <IconButtonComponent
                        icon={'add'}
                        handleClick={() => handleShowMoreClick(key, childsNumber, params)}
                        size={'small'}
                        disableRipple
                        disableFocusRipple
                      />
                    </TooltipComp>
                  )}
                </>
              );
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.TEXT:
          column.cellRenderer = (params: ICellRendererParams) => {
            return <div>{params?.value}</div>;
          };
          break;
      }

      columns.push(column);
    });

    setColumnsViews(colConfigs);
    setColumns([...columns]);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params);
    params.api.setGridOption('domLayout', 'autoHeight');
    params.api.setGridOption('serverSideDatasource', datasource);
  };

  const toggleAllTasks = () => {
    const selectFlag = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    const gridNode = gridRef.current as any;
    if (gridNode?.api && selectFlag != null) {
      gridNode.api.forEachNode((node: any) => node.setSelected(selectFlag));
    }
  };

  const onFilterTextBoxChanged = (word: string) => {
    setSearchField(word);
  };

  const reloadGrid = () => {
    gridApi?.api?.updateGridOptions({ serverSideDatasource: datasource });
  };

  const retrieveUsers = () => {
    if (usercanFullTaskUnassign || usercanFullTaskAssign) {
      getUsersByProjectName(projName).then((res: Array<IUser>) => {
        setUsers(res);
      });
    } else {
      getUser().then((user) => setUsers([user]));
    }
  };

  const getRowClass = (params: any) => {
    if (params?.data?.status != null && (params?.data?.status as string).toUpperCase() == 'NEW') {
      return 'bold-font';
    }
  };

  const onRowSelected = () => {
    if (gridApi?.api?.getSelectedRows()) {
      const selectedRows = gridApi?.api?.getSelectedRows().filter((ele) => ele.taskId) as ITask[];
      setGridSelectedRows(selectedRows);
    }
  };

  const keepGroupByTotalElements = (groupKeyPagination: IGroupkeyPagination | undefined) => {
    if (!groupKeyPagination) {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      return;
    }

    const savedGroupPagination: IGroupkeyPagination[] | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
    );

    if (savedGroupPagination) {
      const indexOf = savedGroupPagination
        .map((gr) => gr.keyName)
        .indexOf(groupKeyPagination.keyName);

      if (indexOf < 0) {
        savedGroupPagination.push(groupKeyPagination);
      } else {
        savedGroupPagination[indexOf] = groupKeyPagination;
      }

      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS,
        savedGroupPagination
      );
    } else {
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS, [
        groupKeyPagination,
      ]);
    }
  };

  const getTasks = async (payload: IAgGridTblPayload) => {
    return await getTasksWithServerSideFilterSort(payload).then((response: IAgGridRowsTaskRes) => {
      return response;
    });
  };

  const getDateFormatToGroupBy = (rowGroupCols: Array<ColumnVO>) => {
    let hasDate = false;
    for (const groupCol of rowGroupCols) {
      const isDate =
        gridNode.columnApi.api.columnModel.columnDefs?.find(
          (col: ColDef) => col.field === groupCol?.id
        )?.filter === CONSTANTS.AG_GRID.FILTER.DATE;

      if (isDate) {
        hasDate = true;
        break;
      }
    }

    return hasDate
      ? LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT)
      : null;
  };

  // Function to fetch data (simulate an API call or data source fetch)
  const fetchData = useCallback(
    async (params: IServerSideGetRowsParams, taskPayload: IAgGridTblPayload) => {
      const { request, success, fail } = params;
      const { filterModel, groupKeys } = request;
      const filter = filterModel as FilterModel;
      getTasks(taskPayload)
        .then((response: IAgGridRowsTaskRes) => {
          setPayload(structuredClone(taskPayload));

          if (groupKeys.length === 0) {
            setPageNo(response.pageable.pageNumber);
            setTotalPages(response.totalPages - 1);
            setTotalElements(response.totalElements);
          }

          success({
            rowData: prepareTableData(response.content as Array<ITask>),
            rowCount: response.content.length,
          });
          toggleAllTasks();
          if (!GeneralUtils.isEmpty(filter) && !response.content.length) {
            setPageNo(0);
          }
          if (!pageNo && !response.content.length) {
            gridApi?.api.showNoRowsOverlay();
          } else {
            gridApi?.api.hideOverlay();
          }
          setNoRows(!response.content.length);

          keepGroupByTotalElements(response.groupKeyPagination);
        })
        .catch(() => {
          addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          fail();
        })
        .finally(() => setDataLoading(false));
    },
    []
  );
  // Debounce fetchData function with correct parameters
  const debouncedFetchData = useDebouncedCallback(
    (params: IServerSideGetRowsParams, taskPayload: IAgGridTblPayload) => {
      fetchData(params, taskPayload);
    },
    500
  );

  const datasource: IServerSideDatasource = {
    getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { filterModel, sortModel, rowGroupCols, groupKeys } = request;
      setGroupByParams([...rowGroupCols]);
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS,
        rowGroupCols
      );
      const filter = filterModel as FilterModel;
      if (filter) {
        for (const filterKey in filter) {
          if (filter[filterKey].filterType === 'date') {
            const matchingColumnConfig = columnsConfigs.find(
              (config) => config.attributeName === filterKey
            );
            if (matchingColumnConfig) {
              filter[filterKey].dateType = matchingColumnConfig.columnType;
            }

            const traverseAndConvert = (filterObj: any) => {
              for (const key in filterObj) {
                if (filterObj[key] === null) {
                  filterObj[key] = '';
                } else if (typeof filterObj[key] === 'object' && filterObj[key] !== null) {
                  traverseAndConvert(filterObj[key]);
                }
              }
            };

            if (Array.isArray(filter[filterKey].conditions)) {
              delete filter[filterKey].conditions;
            }
            traverseAndConvert(filter[filterKey]);
          }
        }
      }

      const taskPayload: IAgGridTblPayload = {
        filterModel: filter,
        sortModel: sortModel,
        pageNumber: pageNo,
        pageSize: pageSize,
        rowGroupCols: rowGroupCols,
        groupKeys: groupKeys,
        assignee: userInfo.user,
        tenant: tenant,
        dateFormat: getDateFormatToGroupBy(rowGroupCols),
      };

      if (searchField) {
        filter.taskId = {
          filterType: typeof searchField,
          type: 'searchAllFields',
          filter: searchField,
        };
      } else if (filter.taskId) {
        delete filter.taskId;
      }
      saveNewPayload(taskPayload);

      setDataLoading(true);
      debouncedFetchData(params, taskPayload);
    },
  };

  const saveNewPayload = (taskPayload: IAgGridTblPayload) => {
    const payload = GeneralUtils.deepCopy(taskPayload);

    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD, payload);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LIST_PAYLOAD_UPDATED, payload);
  };

  const prepareTableData = (content: ITask[]): Array<{ [key: string]: any }> => {
    const data: Array<{ [key: string]: any }> = [...content];
    content.forEach((row) => {
      row.taskListColumnConfigurations.forEach((col) => {
        (row as { [key: string]: any })[col.attributeName] = col.attributeValue;
      });
    });
    return data;
  };

  const handleSelectView = (
    payload: IAgGridTblPayload,
    filteredColumns: Array<IDataModelColumn>
  ) => {
    const gridNode = gridRef.current as any;
    setViewsPayload(payload);

    gridApi?.api.setFilterModel(null);
    gridNode.columnApi.api.resetColumnState();
    gridApi?.api.onFilterChanged();
    setSearchField('');

    setPageNo(payload.pageNumber);
    setPageSize(payload.pageSize);
    setTotalPages(payload.pageSize - 1);

    configureColumns([...filteredColumns]);

    setActivateFilters(true);
    reloadGrid();
  };

  const getRemovedColumns = () => {
    const gridNode = gridRef.current as any;
    const displayedColumns = gridNode.columnApi.api.columnModel.displayedColumns;

    getColumnConfig(tenant, ViewTypes.TASK_LIST).then((res: Array<IDataModelColumn>) => {
      const showMoreCol: IDataModelColumn = {
        attributeLabel: '',
        attributeValue: '',
        attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
        attributeType: '',
        columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
        listType: 'TASK_LIST',
      };
      const checkboxFirstCol: IDataModelColumn = {
        attributeLabel: '',
        attributeValue: '',
        attributeName: CONSTANTS.AG_GRID.COLUMNS.CHECKBOX,
        attributeType: '',
        columnType: CONSTANTS.COLUMNTYPES.TEXT,
        listType: 'TASK_LIST',
      };
      const columnsToAdd: Array<IDataModelColumn> = [];
      columnsToAdd.push(showMoreCol);
      columnsToAdd.push(checkboxFirstCol);
      res.forEach((column: IDataModelColumn) => {
        displayedColumns?.forEach((displayedColumn: any) => {
          if (column.attributeName === displayedColumn.colId) {
            columnsToAdd.push(column);
          }
        });
      });
      setColumnsViews(columnsToAdd);
    });
  };

  const handleSelectAllTasks = () => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG, !selectAllTasks);
    setSelectAllTasks(!selectAllTasks);
    toggleAllTasks();
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const id: string | undefined = params?.node?.data?.taskId;
    if (id) {
      return [
        {
          name: 'Open in a new tab',
          action: () => {
            window.open(`${CONSTANTS.PAGES_URL.TASK}/${id}`, '_blank');
          },
        },
        'separator',
        ...CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS,
      ];
    }
    return CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS;
  };

  const findNode = (nodes: IRowNode[], nodeId: string): IRowNode | undefined => {
    return nodes.find((node) => node.id === nodeId);
  };

  const getExpandedGroupKeysRecursive = (
    nodes: IRowNode[],
    expandedGroupKeys: string[],
    nodeId: string
  ) => {
    const node = findNode(nodes, nodeId);
    if (node?.key) {
      expandedGroupKeys.push(node.key);
    }

    const parentKey = node?.parent?.id;
    if (parentKey) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, parentKey);
    } else {
      return expandedGroupKeys;
    }
  };

  const getExpandedGroupKeys = (nodeId: string) => {
    const expandedGroupKeys: string[] = [];

    const nodes = gridApi?.api.getRenderedNodes();
    if (nodes) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, nodeId);
    }

    return expandedGroupKeys.reverse();
  };

  const getChildsCount = (node: any) => {
    return node.childStore.displayIndexEnd - node.childStore.displayIndexStart;
  };

  const increaseGroupKeyPagination = (key: string, childsCount: number): IGroupKeyPageRequest => {
    const pageNumber = Math.round(
      childsCount / LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE)
    );

    return {
      keyName: key,
      loadMoreRequest: pageNumber,
    };
  };

  const handleShowMoreClick = (key: string, childsCount: number, params: ICellRendererParams) => {
    if (params.node.id) {
      const groupKeys: string[] = getExpandedGroupKeys(params.node.id);
      const groupByParams: ColumnVO[] = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS
      );
      const payload: IAgGridTblPayload = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD
      );

      if (payload && key) {
        const newPayload = structuredClone(payload);
        newPayload.groupKeys = groupKeys;
        newPayload.rowGroupCols = groupByParams;
        newPayload.groupKeyPageRequest = increaseGroupKeyPagination(key, childsCount);
        saveNewPayload(newPayload);
        setDataLoading(true);
        getTasks(newPayload)
          .then((res) => {
            gridApi?.api?.applyServerSideTransaction({
              route: groupKeys,
              add: prepareTableData(res.content as Array<ITask>),
            });
          })
          .catch(() => {
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          })
          .finally(() => setDataLoading(false));
      }
    }
  };

  return (
    <>
      {loading && <Loader isMainLoader />}
      <Grid container spacing={2} className="padding-top-40">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {t('WORK_ITEMS')}
        </Typography>
        <Paper variant="outlined" className="transaction-paper full-width">
          <div className="center-aligned-space-between padding-left-right-10">
            <Stack spacing={2} direction="row" className="center-aligned">
              <FormControlLabel
                control={
                  <CheckboxComponent
                    isDisabled={noRows}
                    checked={selectAllTasks}
                    onClick={handleSelectAllTasks}
                  />
                }
                label={<span className="label bold-font">{t('TASK.SELECT_ALL')}</span>}
              />

              {(usercanFullTaskUnassign || usercanTaskUnassign) && (
                <AssignTask
                  isUnAssign
                  gridSelectedRows={gridSelectedRows}
                  bulkAssign={selectAllTasks}
                  reloadGrid={reloadGrid}
                  taskListLoading={loading}
                  setTaskListLoading={setLoading}
                />
              )}
              {(usercanFullTaskAssign || usercanTaskAssign) && (
                <AssignTask
                  gridSelectedRows={gridSelectedRows}
                  bulkAssign={selectAllTasks}
                  users={users}
                  reloadGrid={reloadGrid}
                  taskListLoading={loading}
                  setTaskListLoading={setLoading}
                />
              )}

              {gridApi && (
                <Views
                  payload={payload as IAgGridTblPayload}
                  columnsViews={columnsViews}
                  defaultColumns={columnsConfigs}
                  onSelectView={handleSelectView}
                  tenant={tenant}
                  type={viewType}
                  gridApi={gridApi}
                />
              )}
            </Stack>
            <Stack spacing={2} direction="row" className="center-aligned">
              <Box className="label bold-font search-box margin-bottom-10">
                <TextFieldComponent
                  placeholder={t('SEARCH_3_DOTS')}
                  type="text"
                  value={payload && searchField ? searchField : ''}
                  setData={(value: string) => onFilterTextBoxChanged(value)}
                  icon={<SearchIcon />}
                />
              </Box>
              {payload && (
                <ExportComponent
                  nowRows={noRows}
                  viewType={viewType}
                  rows={gridSelectedRows}
                  payload={payload}
                  selectedAll={selectAllTasks}
                  currentCols={displayedColumns
                    ?.filter(
                      (col: Column) =>
                        col.getColId() != CONSTANTS.AG_GRID.COLUMNS.CHECKBOX &&
                        col.getColId() != CONSTANTS.AG_GRID.COLUMNS.GROUP_BY &&
                        col.getColId() != CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE
                    )
                    ?.map((col: Column) => {
                      const columnType = columnsConfigs.find(
                        (colConfig) => colConfig.attributeName === col.getColId()
                      )?.columnType;

                      if (columnType) {
                        return {
                          id: col.getColId(),
                          field: col.getColId(),
                          displayName: col.getUserProvidedColDef()?.headerName ?? '',
                          columnType,
                        };
                      }
                    })}
                />
              )}
              <Reset
                searchField={searchField}
                setSearchField={setSearchField}
                setDefaultView={setDefaultView}
                setColumnSorting={setColumnSorting}
                setColumnGroup={setColumnGroup}
                setColumnFiltering={setColumnFiltering}
                setSelectAllChecked={setSelectAllChecked}
                setResetColumns={setResetColumns}
                defaultView={defaultView}
                columnFiltering={columnFiltering}
                columnSorting={columnSorting}
                columnGroup={columnGroup}
                selectAllChecked={selectAllChecked}
                resetColumns={resetColumns}
                grid={gridRef}
                api={gridApi}
                columnsConfigs={columnsConfigs}
                displayedColumns={displayedColumns ?? []}
              />
            </Stack>
          </div>
        </Paper>
        <Box width="100%">
          <div className="ag-theme-alpine table-height">
            <AgGridReact
              ref={gridRef}
              gridOptions={gridOptions}
              defaultColDef={CONSTANTS.AG_GRID.DEFAULT_COL_DEF}
              rowGroupPanelShow="always"
              rowSelection="multiple"
              domLayout="autoHeight"
              rowModelType="serverSide"
              suppressRowClickSelection={true}
              suppressServerSideInfiniteScroll={false}
              animateRows={true}
              columnDefs={columns}
              groupSelectsChildren={!(searchField && !groupByParams.length)}
              onGridReady={onGridReady}
              getRowClass={getRowClass}
              onRowSelected={onRowSelected}
              onColumnVisible={getRemovedColumns}
              onColumnMoved={columnsMoved}
              getContextMenuItems={getContextMenuItems}
              onColumnRowGroupChanged={() => {
                setPageNo(0);
              }}
              onPaginationChanged={() => {
                if (!selectAllTasks) {
                  gridApi?.api.deselectAll();
                  setGridSelectedRows([]);
                }
              }}
              paginationPageSize={pageSize}
              paginationAutoPageSize={false}
              cacheBlockSize={0}
            />
            <Pagination
              pageSize={pageSize}
              pageNo={pageNo}
              totalPages={totalPages}
              totalElements={totalElements}
              setPageSize={setPageSize}
              setPageNo={setPageNo}
              dataLoading={dataLoading}
            />
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default TaskList;

interface IObjectUtils {
  compareObjects: <T>(obj1: T, obj2: T) => boolean;
}

const ObjectUtils: IObjectUtils = {
  compareObjects: <T>(obj1: T, obj2: T) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  },
};

export default ObjectUtils;

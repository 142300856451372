import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderCarousel.scss';
import ArrowButton from '../button/ArrowButton';

interface SlickCarouselProps {
  toShow: React.ReactNode[];
}

const SliderCarousel = (props: SlickCarouselProps) => {
  const { toShow } = { ...props };

  return (
    <div className="carousel-container">
      <Slider
        infinite
        centerPadding="35px"
        slidesToShow={Math.min(toShow.length, 2)}
        speed={500}
        prevArrow={<ArrowButton type="prev" />}
        nextArrow={<ArrowButton type="next" />}
        className="carousel">
        {toShow}
      </Slider>
    </div>
  );
};

export default SliderCarousel;

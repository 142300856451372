import React from 'react';
import DarkButton from '../../../../generic/buttons/DarkButton';
import { useTranslation } from 'react-i18next';
import { ICaseHeaderActionButtons } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderActionButtons';
import { ICaseHeaderButtons } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderButtons';
import ButtonDropdown from '../../../../generic/button/button-dropdown/ButtonDropdown';
import { addAlert } from '../../../../../store/actions/alerts.actions';
import LocalStorageUtils from '../../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../../utils/constants/constants';

const CaseHeaderActionButtons = ({
  formId,
  userIsNotAssignee,
  saveTaskAsDraftClick,
  saveTaskAsDraftAndExitClick,
  submitClick,
  requestBody,
  setRequestBody,
}: ICaseHeaderActionButtons) => {
  const { t } = useTranslation();

  const buttons: Array<ICaseHeaderButtons> = [
    {
      text: 'SAVE',
      onClick: saveTaskAsDraftClick,
      formId: formId,
      classes: 'margin-right-5',
      isDisabled: userIsNotAssignee,
      isVisible: true,
      type: 'button',
    },
    {
      text: 'SAVE&EXIT',
      onClick: saveTaskAsDraftAndExitClick,
      formId: formId,
      classes: 'margin-right-5',
      isDisabled: userIsNotAssignee,
      isVisible: true,
      type: 'button',
    },
    {
      text: 'SUBMIT',
      onClick: submitClick,
      formId: formId,
      isDisabled: userIsNotAssignee,
      isVisible: !requestBody?.submitOptions?.submitOptionsList?.length,
      type: 'button',
    },
    {
      text: 'SUBMIT_DROPDOWN',
      onClick: submitClick,
      formId: formId,
      isDisabled: userIsNotAssignee,
      isVisible: !!requestBody?.submitOptions?.submitOptionsList?.length,
      type: 'dropdown',
    },
  ];

  const handleSubmitOptionClick = (buttonClick: () => void) => {
    const submitOption = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.TASK.SUBMIT_OPTION
    );
    if (requestBody?.submitOptions?.selectedSubmitOption && submitOption) {
      buttonClick();
    } else if (!requestBody?.submitOptions?.selectedSubmitOption && submitOption) {
      const resquestBodyCopy = structuredClone(requestBody);
      resquestBodyCopy.submitOptions!['selectedSubmitOption'] = submitOption;
      setRequestBody({ ...resquestBodyCopy });
      buttonClick();
    } else {
      addAlert({
        type: 'info',
        primaryText: t('SUBMIT_SELECT_OPTION'),
      });
    }
  };

  const getButton = (button: ICaseHeaderButtons) => {
    switch (button.type) {
      case 'button':
        return (
          <DarkButton
            key={button.text}
            text={t(button.text)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              button.onClick();
            }}
            formId={button.formId}
            classes={button.classes}
            isDisabled={button.isDisabled}
          />
        );
      case 'dropdown':
        return (
          <>
            {requestBody?.submitOptions?.submitOptionsList && (
              <ButtonDropdown
                submitOptions={requestBody.submitOptions.submitOptionsList}
                initialButtonLabel={requestBody.submitOptions.emptyText ?? t(button.text)}
                isDisabled={button.isDisabled}
                onSubmitOptionClick={() => handleSubmitOptionClick(button.onClick)}
                requestBody={requestBody}
                setRequestBody={setRequestBody}
              />
            )}
          </>
        );
    }
  };

  return <>{buttons.filter((button) => button.isVisible).map((button) => getButton(button))}</>;
};

export default CaseHeaderActionButtons;

import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosConfig from './axios.config';
import { IError } from '../utils/entities/IError';
import serviceUtils from '../utils/serviceUtils';
import IDocumentMetadata from '../utils/entities/case/IDocumentMetadata';

export const getDocumentMetadataList = (
  tenant: string,
  guids: Array<string>
): Promise<IDocumentMetadata[]> => {
  return axios
    .post(`/getDocumentMetadataList/${encodeURIComponent(tenant)}`, guids, axiosConfig)
    .then((res: AxiosResponse<Array<IDocumentMetadata>>) => {
      return serviceUtils.processSuccess<IDocumentMetadata[]>(res);
    })
    .catch((error: AxiosError) => {
      throw serviceUtils.processError(error);
    });
};

export const uploadDocument = (
  file: File,
  documentName: string,
  projectName: string,
  tags: Array<string>,
  documentType?: string,
  documentSubType?: string,
  uploadedBy?: string
) => {
  const bodyFormData: FormData = new FormData();
  bodyFormData.append('file', file, documentName);
  bodyFormData.append('projectName', projectName);
  bodyFormData.append('tags', JSON.stringify(tags));
  bodyFormData.append('documentName', documentName);
  documentType && bodyFormData.append('documentType', documentType);
  documentSubType && bodyFormData.append('documentSubType', documentSubType);
  uploadedBy && bodyFormData.append('uploadedBy', uploadedBy);
  return axios
    .postForm(`/uploadContentDocument`, bodyFormData, axiosConfig)
    .then((res: AxiosResponse<string>) => {
      return serviceUtils.processSuccess<string>(res);
    })
    .catch((error: AxiosError) => {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return {
          status: error.response?.status,
          msg: error.response?.data?.documentGUID,
        } as IError;
      } else {
        throw serviceUtils.processError(error);
      }
    });
};

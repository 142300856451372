import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import QuestionTooltip from '../QuestionTooltip';
import { CONSTANTS } from '../../../../utils/constants/constants';
import TextFieldComponent from '../../../generic/inputs/textField/TextFieldComponent';
import SpelUtils from '../../../../utils/spelUtils';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import IQuestionType from '../../../../utils/entities/IQuestionType';
import DynamicQuestionnaireUtils from '../../../../utils/functions/DynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion, { IValue } from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';
import ISet from '../../../../utils/entities/screen/ISet';

const TextQuestion = (props: IQuestionType) => {
  const {
    question,
    updateValue,
    updateLocals,
    disabled,
    locals,
    contextLocals,
    entity,
    maxLength,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;
  const { data, updatedRefreshData, updatedBody } = useIScreen();
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };
  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    context
  );

  const storedValue: string | undefined = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  );

  const oldValue = data?.dynamicQuestionnaire?.[activeTabId!]?.sets[0].questions.find(
    (currentQuestion: IQuestion) => currentQuestion.id == question.id
  );

  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  const [value, setValue] = useState<string>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
  );
  const [specialCharsError, setSpecialCharsError] = useState<string>('');

  const checkValidationError = (value: string) => {
    return DynamicQuestionnaireUtils.hasRuntimeError(question.validation, value, {
      ...context,
      [question.attributeName]: value,
    });
  };

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value?.toString()
    );

    const valueArray = [value?.toString()];

    updateValue(question.attributeName, valueArray);

    locals[question?.attributeName] = value;

    updateLocals(locals);
    setRuntimeValidationError(checkValidationError(value));
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
    );
  }, [entity]);

  useEffect(() => {
    if (question.refresh) {
      setValue(question.value[entity][0]);
    }
  }, [question.refresh]);

  const onBlurHandler = () => {
    setRuntimeValidationError(checkValidationError(value));
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, context) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextFieldComponent
                maxLength={maxLength}
                required={requiredResult}
                value={value}
                setData={(value: string) => setValue(value)}
                label={question.label}
                type="text"
                disabled={
                  disabled
                    ? disabled
                    : SpelUtils.expressionValidation(
                        question.readOnly,
                        question.readOnlyExpression,
                        context
                      )
                }
                blur={onBlurHandler}
                errorExternal={true}
                setErrorMessageValue={setSpecialCharsError}
              />
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={value} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
              specialCharsError={specialCharsError}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default TextQuestion;

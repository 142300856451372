import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import TextFieldComponent from '../generic/inputs/textField/TextFieldComponent';
import GeneralUtils from '../../utils/generalUtils';
import { CONSTANTS } from '../../utils/constants/constants';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import { useIScreen } from '../../pages/task/Task';
import {
  getRFIValue,
  getRFITemplateDetail,
  IEmailTemaplateDetail,
  getAllDrafts,
  getDraftData,
  saveDraftFromTemplate,
  updateDraft,
  deleteDraft,
} from '../../service/content-generator.service';
import IDraftDetail from '../../utils/entities/draft/IDraftDetail';
import { mailSend, getRFIMailDetail } from '../../service/email.service';
import IMailDetail from '../../utils/entities/screen/IMailDetail';
import RichTextComponent from '../richText/RichTextComponent';
import DefaultModal from '../generic/modal/Modal';
import { addAlert } from '../../store/actions/alerts.actions';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import IScreen from '../../utils/entities/screen/IScreen';
import GenericContext from '../generic/timer/GenericContext';
import PrimaryButton from '../generic/buttons/PrimaryButton';
import ConfirmationModal from '../generic/modal/ConfirmationModal';
import RfiMaiList from './rfi/RfiMaiList';
import LocalStorageUtils from '../../utils/localStorageUtils';

function Rfi() {
  const {
    data,
    setLoading,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
    saveTaskAsDraftClick,
    setSaveTaskAsDraftClick,
    activeTabId,
    isCompletedTask,
    hasAssignee,
    assignee,
    loggedUser,
    requestBody,
    setRequestBody,
  } = useIScreen();
  const { t } = useTranslation();
  const dateFormat = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT);

  const [to, setTo] = useState<string>('');
  const [template, setTemplate] = useState<Array<IEmailTemaplateDetail>>([]);
  const [subject, setSubject] = useState<string>('');
  const [emailText, setEmailText] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDraftModal, setOpenDraftModal] = useState<boolean>(false);
  const [okayStatus, setOkayStatus] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<string>('');
  const [valueDraft, setValueDraft] = useState<string>('');
  const [rfiDropdown, setRfiDropdown] = useState<Array<string>>([]);
  const [rfiMailList, setRfiMailList] = useState<Array<IMailDetail>>([]);
  const [drafts, setDrafts] = useState<string[]>([]);
  const [draftName, setDraftName] = useState<string>('');
  const [openedDraft, setOpenedDraft] = useState<IDraftDetail>({
    draftId: '',
    draftName: '',
    caseId: '',
    subject: '',
    messageBody: '',
    toAddress: '',
  });
  const [createDraftCheck, setCreateDraftCheck] = useState<boolean>(false);
  const [deleteDraftCheck, setDeleteDraftCheck] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(activeTabId);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>('');
  const [checkIfChanged, setCheckIfChanged] = useState<boolean>(false);

  useEffect(() => {
    getRFIValue(data.tenant, CONSTANTS.TEMPLATE_TYPE).then((value: Array<string>) => {
      setRfiDropdown(value);
    });

    getRFIMailDetail(data.caseId).then((value: Array<IMailDetail>) => {
      setRfiMailList(value);
    });

    retrieveDrafts(data.caseId);
  }, []);

  useEffect(() => {
    if (data && activeTabId && !requestBody) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
    }

    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (GeneralUtils.strip_html_tags(emailText) && to && subject) {
      setOkayStatus(false);
      setModalBody('BEFORE_SEND_CONFIRMATION');
    } else {
      setOkayStatus(false);
      setModalBody('EMPTY_FIELD_VALIDATION');
    }
    setCheckIfChanged(
      openedDraft.messageBody !== emailText ||
        openedDraft.subject !== subject ||
        openedDraft.toAddress !== to
    );
  }, [emailText, to, subject]);

  useEffect(() => {
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: checkIfChanged ?? false });
  }, [checkIfChanged]);

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      editOrCreateDraft(openedDraft.draftId);
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 2000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  useEffect(() => {
    if (saveTaskAsDraftClick) {
      editOrCreateDraft(openedDraft.draftId);
      setSaveTaskAsDraftClick(false);
    }
  }, [saveTaskAsDraftClick]);

  const validationCheck = () => {
    return !new RegExp(CONSTANTS.EMAIL_REGEX).test(to);
  };

  const getEmptyFields = () => {
    const emptyFields = [];

    if (!GeneralUtils.strip_html_tags(emailText)) {
      emptyFields.push(t('EMAIL_TEXT'));
    }
    if (!to) {
      emptyFields.push(t('TO'));
    }
    if (!subject) {
      emptyFields.push(t('SUBJECT'));
    }

    return emptyFields;
  };

  const verifyAndSend = () => {
    if (validationCheck()) {
      setModalBody('INVALID_EMAIL');
      setOkayStatus(true);
    } else {
      const apiPayload = {
        recipient: to,
        messageBody: emailText,
        subject,
        caseId: data.caseId,
        createdBy: loggedUser,
        tenant: data.tenant,
        method: CONSTANTS.MANUAL,
      };
      mailSend(apiPayload)
        .then(() => {
          addAlert({ type: 'success', primaryText: t('MAIL_SEND') });
          getRFIMailDetail(data.caseId).then((value: Array<IMailDetail>) => {
            setTimeout(() => {
              setRfiMailList(value || []);
            }, 1000);
          });
        })
        .catch((err) => {
          addAlert({ type: 'error', primaryText: t('MAIL_NOT_SEND') });
        });
      setOpenModal(false);
    }
  };

  const onDropdownChange = (event: SelectChangeEvent<string>) => {
    setSelectedTemplateName(event.target.value);
    getRFITemplateDetail(data.caseId, CONSTANTS.TEMPLATE_TYPE, event.target.value)
      .then((res: IEmailTemaplateDetail) => {
        setTemplate([res]);
        setEmailText(res.templateBody ?? '');
        setSubject(res.templateSubject ?? '');
        setTo(res.toAddress ?? '');

        setOpenedDraft({
          draftId: '',
          draftName: '',
          caseId: '',
          subject: '',
          messageBody: '',
          toAddress: '',
        });
        setValueDraft('');
      })
      .catch((error) => {
        addAlert({ type: 'error', primaryText: t('ERROR_MESSAGE') });
      });
  };

  const getRFIDropDownValues = () => {
    return (
      <Stack>
        <FormControl>
          <Select
            id={'draft-select'}
            key={`select-${rfiDropdown.length}`}
            size="small"
            displayEmpty
            disabled={checkDisabled()}
            data-testid="native-select"
            value={selectedTemplateName}
            onChange={onDropdownChange}
            attr-id="select-1">
            <MenuItem disabled value="">
              {t('SELECT_OPTION')}
            </MenuItem>
            {rfiDropdown.map((rfiValue: string) => (
              <MenuItem data-testid="select-opt1" key={rfiValue} value={rfiValue}>
                {rfiValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  };

  const retrieveDrafts = (caseId: string) => {
    getAllDrafts(caseId).then((drafts: string[]) => {
      setDrafts(drafts);
    });
  };

  const draftsComponent = () => {
    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              data-testid="native-select"
              key={`select-${drafts?.length}`}
              size="small"
              displayEmpty
              disabled={checkDisabled()}
              value={valueDraft}
              onChange={draftSelected}
              attr-id="select-2">
              <MenuItem disabled value="">
                {t('SELECT_OPTION')}
              </MenuItem>
              {drafts?.map((draftName: string, index: number) => (
                <MenuItem data-testid="select-opt1" key={index} value={draftName}>
                  {draftName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} className="button-rfi">
          <button
            data-testid="default-button"
            className="nexus-btn  "
            disabled={checkDisabled()}
            onClick={() => {
              setOpenDraftModal(true);
              setCreateDraftCheck(true);
            }}>
            {t('RFI.CREATE_DRAFT')}
          </button>
          <button
            data-testid="default-button"
            className="nexus-btn "
            disabled={!valueDraft || checkDisabled()}
            onClick={() => {
              setOpenDraftModal(true);
              setDeleteDraftCheck(true);
            }}>
            {t('RFI.DELETE_DRAFT')}
          </button>
        </Grid>
      </Grid>
    );
  };

  const draftSelected = (event: SelectChangeEvent<string>) => {
    //Action when a draft is selected in the dropdown
    getDraftData(data.caseId, event.target.value)
      .then((res: IDraftDetail) => {
        setOpenedDraft(res);
        setValueDraft(event.target.value);
        setEmailText(res.messageBody ?? '');
        setSubject(res.subject ?? '');
        setTo(res.toAddress ?? '');
        if (checkIfChanged) {
          editOrCreateDraft(openedDraft.draftId);
        }
      })
      .catch((error) => {
        addAlert({ type: 'error', primaryText: t('Draft_Change_Error') });
      });
  };

  const closeCreateDraft = () => {
    setOpenDraftModal(false);
    setCreateDraftCheck(false);
    setDeleteDraftCheck(false);
  };

  const parseText = (text: string, limit: number) => {
    if (text.length > limit) {
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' || text.charAt(i - 1) != '.' || text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
      return text.substring(0, limit) + '...';
    } else return text;
  };

  const createDraft = () => {
    //Create draft click
    if (template.length) {
      //If a template was selected
      const apiPayload = {
        tenant: template[0].tenant,
        templateType: template[0].templateType,
        templateName: template[0].templateName,
        templateSubject: subject || template[0].templateSubject,
        templateBody: emailText || template[0].templateBody,
        toAddress: to || template[0].toAddress,
      };
      const newDraft = draftName;
      saveDraftFromTemplate(data.caseId, draftName, apiPayload)
        .then((res) => {
          setOpenedDraft(res);
          setDrafts([...drafts, newDraft]);
          setOpenDraftModal(false);
          setCreateDraftCheck(false);
          setValueDraft(newDraft);
          addAlert({
            type: 'success',
            primaryText: t('RFI.DRAFT_SUCCESS', { draftName: draftName ?? openedDraft.draftName }),
          });
        })
        .catch((error) => {
          if (error === 'Request failed with status code 409') {
            addAlert({ type: 'error', primaryText: t('RFI.DRAFT_NAME_EXISTS') });
          }
        });
    } else {
      //If no template was selected
      const apiPayload = {
        tenant: data.tenant,
        templateType: '',
        templateName: '',
        templateSubject: '',
        templateBody: '',
      };
      const newDraft = draftName;
      saveDraftFromTemplate(data.caseId, draftName, apiPayload)
        .then(() => {
          setDrafts([...drafts, newDraft]);
          setOpenDraftModal(false);
          setCreateDraftCheck(false);
          addAlert({
            type: 'success',
            primaryText: t('RFI.DRAFT_SUCCESS', { draftName: draftName ?? openedDraft.draftName }),
          });
        })
        .catch((error) => {
          if (error === 'Request failed with status code 409') {
            addAlert({ type: 'error', primaryText: t('RFI.sDRAFT_NAME_EXISTS') });
          }
        });
    }
  };

  const editOrCreateDraft = (draftId: string) => {
    if (draftId) {
      editDraft(draftId);
    }

    // TODO: Add verification to see if it's to create a new draft
  };

  const editDraft = (draftId: string) => {
    updateDraft(draftId, {
      draftId: openedDraft?.draftId,
      draftName: openedDraft?.draftName,
      caseId: data.caseId,
      subject: subject,
      messageBody: emailText,
      toAddress: to,
    }).then(() => {
      addAlert({
        type: 'success',
        primaryText: t('RFI.DRAFT_SUCCESS', { draftName: draftName ?? openedDraft.draftName }),
      });
    });
  };

  const deletingDraft = (draftId: string) => {
    deleteDraft(draftId).then(() => {
      setDrafts((prevOptions: string[]) =>
        prevOptions.filter((option: string) => option !== openedDraft.draftName)
      );
      setOpenDraftModal(false);
      setDeleteDraftCheck(false);
      addAlert({
        type: 'success',
        primaryText: t('RFI.DRAFT_DELETED'),
      });
      setOpenedDraft({
        draftId: '',
        draftName: '',
        caseId: '',
        subject: '',
        messageBody: '',
        toAddress: '',
      });
      setEmailText('');
      setSubject('');
    });
  };

  const checkDisabled = () => {
    return isCompletedTask || !hasAssignee || assignee != loggedUser;
  };
  const { caseHeaderToggle } = useContext(GenericContext);

  return (
    <div
      className={
        caseHeaderToggle ? 'nexus-container due-diligence-wrapper' : 'nexus-container wrapper'
      }>
      {rfiMailList.length ? (
        <RfiMaiList rfiMailList={rfiMailList} loggedUser={loggedUser} dateFormat={dateFormat} />
      ) : (
        ''
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Grid item xs={4}>
          <p>{t('TEMPLATE')}</p>
          {getRFIDropDownValues()}
        </Grid>

        <Grid item justifyContent="flex-end" xs={6}>
          <p>{t('RFI.DRAFTS')}</p>
          {draftsComponent()}
        </Grid>
      </Grid>
      <TextFieldComponent
        required
        setData={(value: string) => {
          setTo(value);
          setDraftName(openedDraft.draftName);
        }}
        value={to}
        disabled={checkDisabled()}
        type={'email'}
        label={t('TO')}
        maxLength={10000}
      />
      <TextFieldComponent
        required
        setData={(value: string) => {
          setSubject(value);
          setDraftName(openedDraft.draftName);
        }}
        disabled={checkDisabled()}
        value={subject}
        type={'text'}
        label={t('SUBJECT')}
        maxLength={10000}
      />
      <RichTextComponent
        required
        value={emailText}
        label={t('EMAIL_TEXT')}
        setData={(value: string) => {
          setEmailText(value);
          setDraftName(openedDraft.draftName);
        }}
        readOnly={checkDisabled()}
      />
      <PrimaryButton
        text={t('SEND')}
        onClick={() => {
          setOpenModal(true);
        }}
        isDisabled={checkDisabled()}
        classes="margin-top-25"
      />
      <ConfirmationModal
        showConfirmButton
        openModal={openModal}
        setOpenModal={setOpenModal}
        content={{
          title: t('CONFIRM'),
          text: t(modalBody, {
            replacedContent: `${getEmptyFields().toString()} ${t('RFI.FIELD', {
              count: getEmptyFields().length,
            })}`,
          }),
        }}
        buttonsTextOverride={{ yes: okayStatus ? t('OKAY') : t('CONFIRM'), no: t('CANCEL') }}
        action={okayStatus ? () => setOpenModal(false) : verifyAndSend}
      />
      <DefaultModal
        open={openDraftModal}
        onClose={() => {
          closeCreateDraft();
        }}
        title={createDraftCheck ? t('RFI.SELECT_DRAFT_NAME') : t('RFI.DELETE_DRAFT')}
        body={
          createDraftCheck ? (
            <TextField
              inputProps={{ maxLength: CONSTANTS.DRAFT_NAME_LENGTH }}
              id="draftName-basic"
              label={t('RFI.SELECT_DRAFT_NAME')}
              variant="standard"
              onChange={(e) => {
                setDraftName(e.target.value);
              }}
            />
          ) : (
            <Tooltip title={openedDraft.draftName}>
              <span>
                {t('RFI.DELETE_DRAFT_QUESTION', {
                  draftName: parseText(openedDraft.draftName, 5),
                })}
              </span>
            </Tooltip>
          )
        }
        confirmText={t('CONFIRM')}
        setConfirm={() => {
          createDraftCheck ? createDraft() : deletingDraft(openedDraft.draftId);
        }}
        denyText={t('CANCEL')}
        setCancel={() => {
          closeCreateDraft();
        }}
      />
    </div>
  );
}

export default Rfi;

import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import PrimaryButton from '../generic/buttons/PrimaryButton';
import { IGlobalSearchProps, ISource } from '../../utils/entities/global-search/IGlobalSearch';
import { IExtendedAttribute } from '../../utils/entities/genericComponents/IAutocomplete';
import './GlobalSearch.scss';
import AutocompleteServerComponent from '../generic/inputs/autocomplete/AutocompleteServerComponent';
import { GQL_GLOBAL_SEARCH } from '../../service/graphql/global-search-term.service';
import LocalStorageUtils from '../../utils/localStorageUtils';
import { CONSTANTS } from '../../utils/constants/constants';
import { addAlert } from '../../store/actions/alerts.actions';
import { ApolloError } from '@apollo/client';

const GlobalSearch = (props: IGlobalSearchProps) => {
  const { setResult } = { ...props };
  const { t } = useTranslation();
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const tenant = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );

  const [sourceData, setSourceData] = useState<ISource[]>([]);
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState<boolean>(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<IExtendedAttribute[]>([]);
  const [selectedOption] = useState<IExtendedAttribute | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const handleSearchData = (sources: ISource[]) => {
    setSourceData([...sources]);
    setOptions([...convertSourceToAutocompleteOptions([...sources])]);
    setIsAutocompleteLoading(false);
  };

  const handleSearchError = (error: ApolloError) => {
    addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
    setIsAutocompleteOpen(false);
    setIsAutocompleteLoading(false);
  };

  const { fetch: searchFetch } = GQL_GLOBAL_SEARCH(handleSearchData, handleSearchError);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autocompleteRef]);

  const handleClickOutside = (event: MouseEvent) => {
    const element = document.getElementById('create-case-modal');
    if (element?.contains(event.target as Node)) {
      setIsAutocompleteOpen(false);
    }
  };

  const handleSearch = () => {
    setIsAutocompleteLoading(true);
    setIsAutocompleteOpen(true);
    setResult(undefined);
    setOptions([]);
    setSourceData([]);
    searchFetch({
      variables: {
        tenant,
        term: inputValue,
      },
    });
  };

  const handleDataSelected = (option: IExtendedAttribute) => {
    const selectedOption = sourceData
      .find((source) => source.source === option.groupBy)
      ?.values?.find((val) => val.id === option.value);

    setResult(selectedOption ? { ...selectedOption, source: option.groupBy } : selectedOption);
  };

  const convertSourceToAutocompleteOptions = (sources: ISource[]): IExtendedAttribute[] => {
    const options: IExtendedAttribute[] = [];
    for (const source of sources) {
      options.push(
        ...source.values.map((val) => {
          return {
            name: val.displayLabel,
            value: val.id,
            groupBy: source.source,
          };
        })
      );
    }

    return options;
  };

  return (
    <Stack direction="row" spacing={2} className="global-search">
      <AutocompleteServerComponent
        autocompleteRef={autocompleteRef}
        isLoading={isAutocompleteLoading}
        open={isAutocompleteOpen}
        onClose={(e: SyntheticEvent) => {
          e.stopPropagation();
          setIsAutocompleteOpen(false);
        }}
        options={[...options]}
        value={selectedOption}
        setData={(e) => handleDataSelected(e as IExtendedAttribute)}
        setInputValue={setInputValue}
        getOptionLabel={(option) => (option as IExtendedAttribute).name ?? ''}
        noOptionsText={t('GLOBAL_SEARCH.NO_OPTIONS_MSG')}
        groupBy={(option) => (option as IExtendedAttribute).groupBy ?? ''}
      />
      <PrimaryButton isPositiveButton text={t('SEARCH')} onClick={handleSearch} />
    </Stack>
  );
};

export default GlobalSearch;

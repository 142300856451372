import { createContext } from 'react';
import { CONSTANTS } from '../../../utils/constants/constants';
import LocalStorageUtils from '../../../utils/localStorageUtils';
interface IContext {
  dateFormat: string;
  caseHeaderToggle: boolean;
  routing: boolean;
  setSelectedProject: React.Dispatch<React.SetStateAction<string>>;
  selectedProject: string;
}
const GenericContext = createContext<IContext>({
  dateFormat:
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT) ??
    window.__RUNTIME_CONFIG__?.REACT_APP_DEFAULT_DATE_FORMAT,
  caseHeaderToggle: true,
  routing: LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING),
  setSelectedProject: () => {},
  selectedProject: '',
});

export default GenericContext;

import { Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArticleList from './ArticleList';
import ArticleDetail from './ArticleDetail';
import { IArticle } from '../../../utils/entities/dueDiligence/IArticle';
import { useTranslation } from 'react-i18next';
import { IRequest } from '../../../utils/entities/dueDiligence/IRequest';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PlainTextButton from '../../generic/button/PlainTextButton';

const ArticleViewer = (props: {
  articles: Array<IArticle> | undefined;
  selectedArticle: IArticle | undefined;
  setSelectedArticle: React.Dispatch<React.SetStateAction<IArticle | undefined>>;
  totalOverallScore: string | number | undefined;
  linksReturned: string | number | undefined;
  linksAfterFilter: string | number | undefined;
  selectedRequestDetail: IRequest | undefined;
}) => {
  const { t } = useTranslation();
  const {
    articles,
    selectedArticle,
    setSelectedArticle,
    totalOverallScore,
    linksReturned,
    linksAfterFilter,
  } = props;
  const [articlesData, setArticlesData] = useState<Array<IArticle> | undefined>(articles);
  const [activeId, setActiveId] = useState<number | null>(0);

  useEffect(() => {
    setArticlesData(articles);
    setActiveId(null);
  }, [articles]);

  const [sortFlag, setSortFlag] = useState<boolean>(false);
  const sortArticles = () => {
    const sortedProducts = sortFlag
      ? articlesData &&
        articlesData.sort((p1, p2) =>
          p1.articleScore > p2.articleScore ? -1 : p1.articleScore < p2.articleScore ? 0 : -1
        )
      : articlesData &&
        articlesData.sort((p1, p2) =>
          p1.articleScore < p2.articleScore ? -1 : p1.articleScore > p2.articleScore ? 0 : 1
        );
    setArticlesData(sortedProducts);
    setSortFlag(!sortFlag);
  };

  return (
    <>
      <div className="card-view search-criteria width-100-per search-results-list show-scroll overflow-y-scroll">
        <Grid container justifyContent="flex-end">
          <PlainTextButton
            label={t('DD.ARTICLE_SCORE')}
            handleClick={sortArticles}
            icon={<ImportExportIcon />}
          />
        </Grid>

        <div className="display-inline-flex margin-inline-10 margin-vertical-10 sticky">
          <div className="header-title font-size-14 margin-inline-10">
            <div className="bold-font">{t('DD.TOTAL_OVERALL_SCORE')}</div>
            <div className="color-red">{totalOverallScore}%</div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="header-title font-size-14 margin-inline-10">
            <div className="bold-font">{t('DD.LINKS_RETURNED')}</div>
            <div className="color-green">{linksReturned}</div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="header-title font-size-14 margin-inline-10">
            <div className="bold-font">{t('DD.LINKS_AFTER_FILTER')}</div>
            <div className="color-red">{linksAfterFilter}</div>
          </div>
        </div>
        <Divider />
        <div className="show-scroll overflow-y-scroll">
          {articlesData?.map((article: IArticle, index: number) => (
            <ArticleList
              key={index}
              isActive={activeId === index}
              setActiveId={() => setActiveId(index)}
              article={article}
              setSelectedArticle={setSelectedArticle}
            />
          ))}
        </div>
      </div>
      <div className="card-view search-criteria width-100-per search-results-details show-scroll overflow-y-scroll">
        {selectedArticle && (
          <ArticleDetail
            articleDetail={selectedArticle}
            selectedRequestDetail={props.selectedRequestDetail}
          />
        )}
      </div>
    </>
  );
};
export default ArticleViewer;

import React, { useEffect, useState } from 'react';
import { Grid, ThemeProvider } from '@mui/material';
import { CONSTANTS } from '../../utils/constants/constants';
import { useIScreen } from '../../pages/task/Task';
import GeneralUtils from '../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import TaskQuestionSets from './TaskQuestionSets';
import { useTranslation } from 'react-i18next';
import { ICaseHeaderAttributes } from 'fso-fincrime-sdk-ui';
import IQuestion from '../../utils/entities/screen/IQuestion';
import IQuestionnaire from '../../utils/entities/screen/IQuestionnaire';
import SpelUtils from '../../utils/spelUtils';
import './TaskQuestionnaire.scss';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import LocalStorageUtils from '../../utils/localStorageUtils';
import IAccessToken from '../../utils/entities/authentication/IAccessToken';
import ISet from '../../utils/entities/screen/ISet';
import Loader from '../loader/Loader';
import IContextLocalsEntity from '../../utils/entities/screen/IContextLocalsEntity';
import IContextLocals from '../../utils/entities/screen/IContextLocals';
import TaskViewContainer from './dynamic-questionaire/TaskViews/TaskViewContainer';
import { themeEY } from '../../utils/constants/theme';

let headerData: ICaseHeaderAttributes[];

const TaskQuestionnaire = () => {
  const { t } = useTranslation();
  const [setIsVisble, setSetIsVisble] = useState<boolean>(false);
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const taskView = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_VIEW);

  const {
    loading,
    setLoading,
    hasAssignee,
    activeTabId,
    requestBody,
    contextLocals,
    setContextLocals,
    isCompletedTask,
    bodyAnswerUpdate,
    setIndex,
    setSetIndex,
    questionIsRequired,
    updateContextLocals,
    selectedEntity,
    setSelectedEntity,
    assignee,
    loggedUser,
    headerHeight,
    setSelectedEntityIndex,
    selectedEntityIndex,
    setIsSetEntityChanged,
    isSetEntityChanged,
    badgeHighlight,
  } = useIScreen();

  const locals =
    contextLocals?.[
      requestBody?.dynamicQuestionnaire?.[activeTabId]?.sets?.[setIndex]?.root &&
      selectedEntity == requestBody?.dynamicQuestionnaire?.[activeTabId]?.sets[setIndex]?.rootId
        ? 'rootEntity'
        : selectedEntity
    ];

  useEffect(() => {
    if (!hasNoSets()) {
      const set = requestBody?.dynamicQuestionnaire[activeTabId].sets[setIndex];
      setSetIsVisble(checkSetVisibilty(set));
    }
  }, [setIndex, requestBody]);

  useEffect(() => {
    const questionnaire: IQuestionnaire | undefined =
      requestBody?.dynamicQuestionnaire?.[activeTabId];
    if (questionnaire != null && contextLocals != null && selectedEntity != '') {
      updateContextLocals(questionnaire);
    }
  }, [selectedEntity]);

  useEffect(() => {
    if (hasNoSets()) {
      setTimeout(() => {
        return !hasNoSets();
      }, 3000);
    }
  });

  const checkSetVisibilty = (set: ISet): boolean =>
    SpelUtils.expressionValidation(set.visible, set.visibilityExpression, {
      contextLocals: contextLocals,
      userRole: userInfo.role,
    });

  const hasNoSets = (): boolean => {
    const quest = DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);
    return quest?.sets == undefined || quest?.sets?.length == 0;
  };

  const updateHeader = () => {
    const dynamicQuestionnaire: IQuestionnaire | undefined =
      DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);
    if (headerData !== undefined && dynamicQuestionnaire?.sets[setIndex].root) {
      GeneralUtils.triggerEvt(EVENTS_CONSTANTS.SET_CASE_HEADER_DATA_EVT, {
        caseHeaderData: headerData,
      });
    }
  };

  const contextLocalsUpdate = (newContextLocals: IContextLocalsEntity) => {
    const auxContextLocals = GeneralUtils.deepCopy<IContextLocals>(contextLocals);
    const dynamicQuestionnaire: IQuestionnaire | undefined =
      DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);
    const isRoot =
      dynamicQuestionnaire?.sets[setIndex].root &&
      dynamicQuestionnaire?.sets[setIndex].rootId === selectedEntity;

    if (auxContextLocals != undefined) {
      auxContextLocals[isRoot ? 'rootEntity' : selectedEntity] = newContextLocals;
      setContextLocals(auxContextLocals);
    }
  };

  const getLoggedUser = () => {
    return userInfo.user;
  };

  if (loading || requestBody?.dynamicQuestionnaire?.[activeTabId] == null) {
    return (
      <>
        <Loader />
        <p>{t('LOADING')}</p>
      </>
    );
  }

  const getUnansweredCount = (questions: IQuestion[], entityId: string, setIndex: number) => {
    let count = 0;
    questions.forEach((q: IQuestion) => {
      const questionRequiresAnswer =
        questionIsRequired(q, setIndex, entityId) &&
        (DynamicQuestionnaireUtils.questionIsEmpty(q, entityId, true) ||
          DynamicQuestionnaireUtils.hasRuntimeError(q.validation, q.value?.[entityId]?.[0], {
            contextLocals,
            userRole: userInfo.role,
            ...locals,
          }));
      if (questionRequiresAnswer) {
        count++;
      }
    });
    return count;
  };

  const getSetTotalUnansweredCount = (set: ISet, setIndex: number) => {
    let totalCount = 0;

    const entityIds = set.entityIds;

    entityIds.forEach((entityId) => {
      totalCount = totalCount + getUnansweredCount(set.questions, entityId, setIndex);
    });

    return totalCount;
  };

  return (
    <div
      className="margin-inline-20 taskQuestionnaire"
      style={{ height: `calc(100vh - ${headerHeight}px)` }}>
      <ThemeProvider theme={themeEY}>
        {!hasNoSets() && (
          <Grid container spacing={1} style={{ justifyContent: taskView ? 'center' : '' }}>
            {!taskView && (
              <TaskQuestionSets
                dynamicQuestionnaire={requestBody?.dynamicQuestionnaire[activeTabId]}
                setIndex={setIndex}
                setSetIndex={setSetIndex}
                selectedEntity={selectedEntity}
                setLoading={setLoading}
                setSelectedEntity={setSelectedEntity}
                contextLocals={contextLocals}
                getSetTotalUnansweredCount={getSetTotalUnansweredCount}
                checkSetVisibilty={checkSetVisibilty}
                setSelectedEntityIndex={setSelectedEntityIndex}
                selectedEntityIndex={selectedEntityIndex}
                setIsSetEntityChanged={setIsSetEntityChanged}
                isSetEntityChanged={isSetEntityChanged}
                badgeHighlight={badgeHighlight}
              />
            )}
            {setIsVisble && (
              <Grid item xs={9}>
                <TaskViewContainer
                  contextLocals={contextLocals}
                  requestBody={requestBody}
                  assignee={requestBody?.assignee}
                  loggedUser={getLoggedUser()}
                  disabled={isCompletedTask || !hasAssignee || assignee != loggedUser}
                  updatedLocals={contextLocalsUpdate}
                  updateValue={bodyAnswerUpdate}
                  updateHeader={updateHeader}
                  activeTabId={activeTabId}
                  setIndex={setIndex}
                  count={getSetTotalUnansweredCount}
                  userInfo={userInfo}
                  selectedEntity={selectedEntity}
                  locals={locals}
                  taskView={taskView}
                  headerHeight={headerHeight}
                />
              </Grid>
            )}
          </Grid>
        )}
      </ThemeProvider>
    </div>
  );
};

export default TaskQuestionnaire;

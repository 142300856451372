import { useTheme } from '@mui/material/styles';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IVerticalBarItem from '../../../utils/entities/IVerticalBarItem';
import GeneralUtils from '../../../utils/generalUtils';
import IDrawerList from '../../../utils/entities/layouts/IDrawerList';

const DrawerList = (props: IDrawerList) => {
  const { open, setOpenDrawer, items, classes } = { ...props };
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const itemOnClick = (item: IVerticalBarItem) => {
    item.internalUrl ? navigate(item.url) : (window.location.href = item.url);
    setOpenDrawer(false);
  };

  return (
    <List className={classes}>
      {items
        .filter(
          (item) => !item.permissionType || GeneralUtils.checkUserPermissions(item.permissionType)
        )
        .map((item: IVerticalBarItem) => (
          <ListItem
            key={item.label}
            disablePadding
            sx={
              item.url == location.pathname
                ? { display: 'block', background: '#23232F' }
                : { display: 'block' }
            }>
            {item.url == location.pathname && (
              <div
                className="seleted-bar"
                style={{
                  background: theme.palette.primary.main,
                }}
              />
            )}
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
              }}
              onClick={() => itemOnClick(item)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'white',
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(item.label)}
                sx={{ opacity: open ? 1 : 0, marginBottom: 0, color: 'white' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

export default DrawerList;

import React, { useEffect, useRef, useState } from 'react';
import { Divider, IconButton, Menu, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import INotification from '../../../utils/entities/header/notification-center/INotification';
import {
  dismissAllUserNotifications,
  getPaginatedNotifications,
  notitifcationRefresh,
} from '../../../service/notification_manager.service';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import IconButtonComponent from '../../generic/button/IconButtonComponent';
import NotificationContainer from './notification/NotificationContainer';
import GeneralUtils from '../../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import TextButton from '../../generic/button/text/TextButton';
import '../../../styles/FCNexus.scss';
import Loader from '../../loader/Loader';

const NotificationCenter = () => {
  const { t } = useTranslation();
  const pageSize = 10;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState<Array<INotification>>([]);
  const [updateNotifications, setUpdateNotifications] = useState<INotification>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [notificationsLoading, setNotificationsLoading] = useState<boolean>(false);

  const openMenu = Boolean(anchorEl);
  const pageNumberRef = useRef(pageNumber);

  useEffect(() => {
    pageNumberRef.current = pageNumber;

    setNotificationsLoading(true);
    getPaginatedNotifications(pageNumber, pageSize)
      .then((paginatedNotifications) => {
        const notificationsTemp: INotification[] =
          paginatedNotifications.content as INotification[];
        setNotifications([...notifications, ...notificationsTemp]);
      })
      .finally(() => setNotificationsLoading(false));
  }, [pageNumber]);

  const handleMenuEntered = () => {
    const element = document.getElementById('your-scrollable-element');

    const handleScroll = () => {
      if (element) {
        const atBottom = element.scrollHeight - element.scrollTop <= element.clientHeight;
        if (atBottom) {
          setPageNumber(pageNumberRef.current + 1);
        }
      }
    };

    element?.addEventListener('scroll', handleScroll);

    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  };

  useEffect(() => {
    if (updateNotifications) {
      const notificationsTemp = [...notifications];
      notificationsTemp.unshift(updateNotifications);
      setNotifications(notificationsTemp);
    }
  }, [updateNotifications]);

  const sseEventListener = (e: MessageEvent) => {
    setUpdateNotifications(JSON.parse(e.data));
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.TASK_LOADING_EVT, notitifcationRefresh(), false, {
    notifications_refresh: sseEventListener,
  });

  const dismissAllNotifications = () => {
    dismissAllUserNotifications().then(() => setNotifications([]));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="notification-center">
      <IconButtonComponent
        color="inherit"
        icon={'notifications'}
        handleClick={handleClick}
        badgeContent={notifications.length}
      />

      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        TransitionProps={{
          onEntered: handleMenuEntered,
        }}
        onClose={handleClose}
        slotProps={{ paper: { className: 'nexus-shadow' } }}>
        <div className="margin-left-10">
          <h3>{t('NOTIFICATION_CENTER.TITLE')}</h3>
          {notifications.length > 0 && (
            <TextButton
              label={t('NOTIFICATION_CENTER.DISMISS_ALL')}
              handleClick={dismissAllNotifications}
            />
          )}
        </div>
        <Divider className="thicker-divider" />
        <div style={{ width: 500, height: 400 }}>
          {notifications.length != 0 ? (
            <div
              id="your-scrollable-element"
              className="show-scroll"
              style={{ height: 'inherit', overflowY: 'scroll' }}>
              {notifications.map((notification: INotification, index: number) => (
                <NotificationContainer
                  key={notification.id}
                  notification={notification}
                  index={index}
                  handleMenuClose={handleClose}
                  notifications={notifications}
                  setNotifications={setNotifications}
                />
              ))}
              {notificationsLoading && (
                <div style={{ height: 20 }}>
                  <Loader />
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}>
              <Stack justifyContent="center" alignItems="center">
                <IconButton disabled>
                  <NotificationsIcon style={{ fontSize: 200 }} />
                </IconButton>
                <Typography className="color-grey" component="p">
                  {t('NOTIFICATION_CENTER.NO_NOTIFICATIONS')}
                </Typography>
              </Stack>
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
};

export default NotificationCenter;

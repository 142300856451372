import React from 'react';
import { Link } from 'react-router-dom';
import { CONSTANTS, CONSTANTS as GLOBAL_CONSTANTS } from '../../../../../utils/constants/constants';
import IDescriptionWithLinkProps from '../../../../../utils/entities/header/notification-center/INotificationText';

const NotificationText = ({
  notification,
  handleMenuClose,
  expanded,
}: IDescriptionWithLinkProps) => {
  const descPieces: Array<string> = notification.notificationMessage.split('|');

  const getNotificationText = () => {
    switch (notification.action) {
      case 'OPEN_CASE':
        return (
          <>
            {descPieces[0]}
            <Link
              className="link"
              to={`${GLOBAL_CONSTANTS.PAGES_URL.CASE_DETAILS}/${notification.variables.caseId}`}
              onClick={handleMenuClose}>
              {descPieces[1]}
            </Link>
            {descPieces[2]}
          </>
        );
      case 'OPEN_TASK':
        return (
          <>
            {descPieces[0]}
            <Link
              className="link"
              to={`${CONSTANTS.PAGES_URL.TASK}/${notification.variables.taskId}`}
              onClick={handleMenuClose}>
              {descPieces[1]}
            </Link>
            {descPieces[2]}
            <Link
              className="link"
              to={`${GLOBAL_CONSTANTS.PAGES_URL.CASE_DETAILS}/${notification.variables.caseId}`}
              onClick={handleMenuClose}>
              {descPieces[3]}
            </Link>
            {descPieces[4]}
            {descPieces[5]}
            {descPieces[6]}
            {descPieces[7]}
          </>
        );
      case 'OPEN_DOCUMENT':
        return (
          <>
            {descPieces[0]}
            <a className="link" onClick={handleMenuClose}>
              {descPieces[1]}
            </a>
            {descPieces[2]}
          </>
        );
      case 'NONE':
        return <>{notification.notificationMessage}</>;
    }
  };

  return (
    <div className={`lighter-text ${!expanded ? 'cut-text' : ''}`}>{getNotificationText()}</div>
  );
};

export default NotificationText;

import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { IMetadataAttr, IMetadataInputs } from '../../../../utils/entities/IMetadata';
import DatePickerComponent from '../../../generic/inputs/datePicker/DatePickerComponent';

const DatePickerMetadata = (props: IMetadataInputs) => {
  const { attribute, valueUpdated, showLabel } = props;
  const onFilterTextBoxChanged = (newValue: { $d: moment.MomentInput }) => {
    const formatDate = moment(newValue && newValue.$d).format(CONSTANTS.DATES.DEFAULT.DATE_FORMAT);
    attribute.value = formatDate
      ? formatDate === CONSTANTS.DATES.INVALID_DATE
        ? ''
        : formatDate
      : '';
    valueUpdated();
  };

  const formatValue = (attr: IMetadataAttr) => {
    switch (attr.format) {
      case 'EPOCH_MILI':
        return moment(attr.value).format(CONSTANTS.DATES.DEFAULT.DATE_FORMAT);
      case 'EPOCH_SEC':
        return moment(Number(attr.value) * 1000).format(CONSTANTS.DATES.DEFAULT.DATE_FORMAT);
      default:
        return moment(attr.value, attr.format).format(CONSTANTS.DATES.DEFAULT.DATE_FORMAT);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerComponent
        defaultValue={attribute.value ? formatValue(attribute) : undefined}
        onChange={(value: any) => onFilterTextBoxChanged(value)}
        label={showLabel ? attribute.title : ''}
      />
    </LocalizationProvider>
  );
};

export default DatePickerMetadata;

import React from 'react';
import { IconButton } from '@mui/material';
import IIconButtonComponentProps from '../../../utils/entities/genericComponents/button/icon/IIconButtonComponent';
import NotificationBadge from '../badge/notification-badge/NotificationBadge';

const IconButtonComponent = (props: IIconButtonComponentProps) => {
  const {
    handleClick,
    isDisabled,
    icon,
    classes,
    sx,
    size,
    ref,
    disableRipple,
    disableFocusRipple,
    value,
    edge,
    color,
    badgeContent,
  } = {
    ...props,
  };

  return (
    <IconButton
      color={color}
      edge={edge}
      value={value}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      ref={ref}
      disabled={isDisabled}
      size={size}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick?.(e)}
      className={`${classes || ''}`}
      sx={sx}>
      <NotificationBadge icon={icon} badgeContent={badgeContent ?? 0} />
    </IconButton>
  );
};

export default IconButtonComponent;

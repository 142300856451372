import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { query } from '../../../utils/entities/Query';
import { getExportCaseData } from '../../../service/case-manager.service';
import { CONSTANTS } from '../../../utils/constants/constants';
import GeneralUtils from '../../../utils/generalUtils';
import { addAlert } from '../../../store/actions/alerts.actions';
import IPdfType, { IPdfDetails } from '../../../utils/entities/case/IPdfType';
import { getPdfInfo } from '../../../service/config-manager.service';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import IPermission from '../../../utils/entities/role/IPermission';
import ExportPDF from './ExportPDF';
import DropdownMenuButtonExport from './DrowdownMenuButton';
import { IExportCaseDataProps } from '../../../utils/entities/case/export/IExport';
import dateUtils from '../../../utils/dateUtils';
import StringUtils from '../../../utils/stringUtils';

const ExportCaseData = (props: IExportCaseDataProps) => {
  const { caseId, tenant } = { ...props };
  const { t } = useTranslation();
  const { data } = query(caseId);
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const dateFormat: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT
  );

  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [pdfList, setPdfList] = useState<Array<IPdfDetails>>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const options = CONSTANTS.EXPORT_OPTIONS.filter((option) =>
    GeneralUtils.checkUserPermissions(option.permissionType, casePermissions)
  ).map((option) => option.key);

  useEffect(() => {
    if (tenant) {
      getPdfList(tenant);
    }
  }, [tenant]);

  const exportData = async (value: string) => {
    if (value == 'JSON') {
      if (data) {
        GeneralUtils.downloadByBlob(
          JSON.stringify(data, null, 2),
          'application/json',
          `Export_${caseId}.json`
        );
      } else {
        addAlert({
          type: 'error',
          primaryText: t('NULL_DATA'),
        });
      }
      closeExportDropdown();
    } else if (value == 'PDF') {
      setShowExportModal(true);
    } else {
      getExportCaseData(caseId, value, dateUtils.getDateFormatFromDateFormat(dateFormat))
        .then((res) => {
          GeneralUtils.downloadByBlob(
            res.data,
            res.headers['content-type'],
            StringUtils.getSubStringAfterKey(res.headers['content-disposition'], 'filename=')
          );
        })
        .catch(() => {
          addAlert({
            type: 'error',
            primaryText: t('NULL_DATA'),
          });
        })
        .finally(() => closeExportDropdown());
    }
  };

  const getPdfList = (tenant: string) => {
    getPdfInfo(tenant).then((pdfs: IPdfType) => {
      setPdfList(pdfs.content);
    });
  };

  const closeExportDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DropdownMenuButtonExport
        title={t('EXPORT.EXPORT')}
        options={options}
        clickOption={exportData}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <ExportPDF
        showExportModal={showExportModal}
        setShowExportModal={setShowExportModal}
        pdfList={pdfList}
        caseId={caseId}
        closeExportDropdown={closeExportDropdown}
      />
    </>
  );
};

export default ExportCaseData;

import React from 'react';
import { PermissionTypes } from '../../../../../utils/entities/role/IPermission';
import LinkIcon from '@mui/icons-material/Link';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import GeneralUtils from '../../../../../utils/generalUtils';
import { useTranslation } from 'react-i18next';
import { ICaseHeaderFeatureButtonsProps } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderFeatureButtonsProps';
import { ICaseHeaderFeatureProps } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderFeatureProps';
import PlainTextButton from '../../../../generic/button/PlainTextButton';

const CaseHeaderFeatureButtons = (props: ICaseHeaderFeatureButtonsProps) => {
  const { setShowModal, retrieveLinks, setOpenAudit, setOpenModal, caseId, casePermissions } =
    props;
  const { t } = useTranslation();

  const featureButtons: Array<ICaseHeaderFeatureProps> = [
    {
      text: 'LINK_CASES',
      onClick: () => {
        setShowModal(true);
        retrieveLinks(caseId);
      },
      icon: <LinkIcon />,
      classes: 'case-header-icons',
      size: 1,
      permission: PermissionTypes.LINK_CASES_VIEW,
    },
    {
      text: 'AUDIT_HISTORY',
      onClick: () => setOpenAudit(true),
      icon: <ContentPasteIcon />,
      classes: 'case-header-icons',
      size: 1,
    },
    {
      text: 'DOCUMENTS',
      onClick: () => setOpenModal(true),
      icon: <AssignmentOutlinedIcon />,
      classes: 'case-header-icons',
      size: 1,
    },
  ];

  return (
    <>
      {featureButtons.map((button: ICaseHeaderFeatureProps) => {
        if (
          button.permission &&
          !GeneralUtils.checkUserPermissions(button.permission, casePermissions)
        ) {
          return null;
        }

        return (
          <PlainTextButton
            key={button.text}
            icon={button.icon}
            label={t(button.text)}
            handleClick={button.onClick}
          />
        );
      })}
    </>
  );
};

export default CaseHeaderFeatureButtons;

import React, { useEffect } from 'react';
import ChartDisplay from '../components/task-list/chart-carousel/ChartCarousel';
import TaskList from './task-list/TaskList';
import { ApolloProvider } from '@apollo/client';
import { client } from '../service/apollo-client.data-gateway';
import ErrorBoundary from '../components/generic/error-boundary/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ISlaSettingsEntity from '../utils/entities/config/ISlaSettingsEntity';

const Inbox = (props: { slaSettingsEntity: ISlaSettingsEntity | undefined; withChart?: boolean }) => {
  const { slaSettingsEntity: slaSettingsEntity, withChart } = { ...props };
  const params = useParams();
  const history = createBrowserHistory();

  useEffect(() => {
    //Add title to the browser tab
    document.title = 'Fin Crime 3.0 ';
  }, []);

  return (
    <div className="padding-top-20 custom-container">
      {withChart && (
        <ApolloProvider client={client}>
          <ErrorBoundary history={history} params={params}>
            <ChartDisplay />
          </ErrorBoundary>
        </ApolloProvider>
      )}
      <ErrorBoundary history={history} params={params}>
        <TaskList slaSettingsEntity={slaSettingsEntity} />
      </ErrorBoundary>
    </div>
  );
};

export default Inbox;

import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { AVSearchResult } from '../../../utils/entities/dueDiligence/AVSearchResult';
import { AVReportData } from '../../../utils/entities/dueDiligence/IRequest';
import {
  getAllDueDiligenceRequests,
  generateAVReport,
  refreshResults
} from '../../../service/due-diligence.service';
import Loader from '../../loader/Loader';
import BingMap from './BingMap';
import { useTranslation } from 'react-i18next';
import IAccessToken from '../../../utils/entities/authentication/IAccessToken';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import { addAlert } from '../../../store/actions/alerts.actions';
import CheckboxComponent from '../../generic/inputs/checkbox/CheckboxComponent';
import PrimaryButton from '../../generic/buttons/PrimaryButton';
import dateUtils from '../../../utils/dateUtils';
import GeneralUtils from '../../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';

interface IMapProps {
  mapOptions?: any;
}

const AVSearchResults = (props: { caseId: string; tenant: string; searchResults: any }) => {
  const { t } = useTranslation();

  const [resultsRefreshing, setResultsRefreshing] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<AVSearchResult>>([]);
  const [activeMapData, setActiveMapData] = useState<IMapProps>();
  const [generateReportList, setGenerateReportList] = useState<Array<AVReportData>>([]);

  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  useEffect(() => {
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.AV_RESULTS_LOADING_EVT, false);
  }, []);

  useEffect(() => {
    setResultsRefreshing(true);
    getAVSearchResults();
  }, [props.caseId]);

  useEffect(() => {
    if (props.searchResults) {
      setSearchResults(props.searchResults);
      setResultsRefreshing(false);
      // clear the active map data and generate report list
      setActiveMapData(undefined);
      setGenerateReportList([]);
    }
  }, [props.searchResults]);

  const updateLiveAVDataEventListener = (e: MessageEvent) => {
    setSearchResults(JSON.parse(e.data));
  };


  GeneralUtils.sseEvent(EVENTS_CONSTANTS.AV_RESULTS_LOADING_EVT, refreshResults(props.caseId, 'AV'), true, {
    av_search: updateLiveAVDataEventListener,
  });

  const getAVSearchResults = () => {
    getAllDueDiligenceRequests(props.caseId, 'AV')
      .then((apiResponse: any) => {
        if (apiResponse) {
          setSearchResults(apiResponse);
          setResultsRefreshing(false);
        }
      })
      .catch((error: string | number) => {
        addAlert({ type: 'error', primaryText: t('ERROR_MESSAGE') });
      });
  };

  const getLinksCount = () => {
    return searchResults && searchResults.length > 0 && searchResults[0]?.Data
      ? searchResults[0]?.Data?.length
      : 0;
  };

  const handleGenerateReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (generateReportList.length != 0) {
      generateAVReport({
        apiName: 'AV-REPORT',
        app: projectName,
        applicationName: projectName,
        caseId: props.caseId,
        color: '1',
        partyName: '',
        projectName: projectName,
        tenant: props.tenant,
        userName: userInfo.user,
        reportData: generateReportList,
      })
        .then((apiResponse: any) => {
          addAlert({
            type: 'success',
            primaryText: t('REQUEST_SUCCESS'),
          });
          setGenerateReportList([]);
        })
        .catch((error: string | number) => {
          addAlert({ type: 'error', primaryText: t('ERROR_MESSAGE') });
        });
    }
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLDivElement>, el: any) => {
    event.preventDefault();
    setActiveMapData({
      mapOptions: {
        coordinatesX: el.bingData?.point?.coordinates[0],
        coordinatesY: el.bingData?.point?.coordinates[1],
        address: el.bingData?.name,
        apiKey: window.__RUNTIME_CONFIG__?.REACT_APP_BING_MAPS_API_KEY,
        confidence: el.bingData?.confidence,
        searchDate: el.searchDate,
        httpStatus: el.httpStatus,
        message: el.message,
      },
    });
  };

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    searchDate: string,
    formattedAddress: string,
    finalAddress: string,
    imageDocPath: string,
    documentManagerID: string,
    partyName: string,
    userInputAddress: string
  ) => {
    if (event.target.checked) {
      setGenerateReportList([
        ...generateReportList,
        {
          searchDate: searchDate,
          formattedAddress: formattedAddress,
          finalAddress: finalAddress,
          imageDocID: imageDocPath,
          documentManagerID: documentManagerID,
          partyName: partyName ? partyName : CONSTANTS.DD.DUE_DILIGENCE_EMPTY_PARTY_NAME,
          userInputAddress: userInputAddress,
        },
      ]);
    } else {
      setGenerateReportList(generateReportList.filter((el) => el.imageDocID != imageDocPath));
    }
  };

const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.checked) {
    const filteredGenerateReportList = searchResults[0]?.Data
      .filter(el => el.httpStatus === '200' && el.bingData.confidence !== 'Low')
      .map(el => ({
        searchDate: el.searchDate,
        formattedAddress: el.bingData.address.formattedAddress,
        finalAddress: el.finalAddress,
        imageDocID: el.imageDocPath,
        documentManagerID: el.documentManagerId,
        partyName: el.partyName ? el.partyName : CONSTANTS.DD.DUE_DILIGENCE_EMPTY_PARTY_NAME,
        userInputAddress: el.userInputAddress,
      }));

    setGenerateReportList(filteredGenerateReportList);
  } else {
    setGenerateReportList([]);
  }
};

  const isSelectAllChecked = () => {
    return searchResults[0]?.Data.length == generateReportList.length;
  };

  return (
    <>
      <div data-testid='av-search-results' className="card-view search-criteria width-100-per search-results-list show-scroll overflow-y-scroll">
        <div className="av-ir-tr-clear">
          {resultsRefreshing && <Loader isMainLoader={true} />}
          {searchResults.map((searchResult, idx) => {
            return (
              <>
                <Grid container className="padding-10">
                  <Grid item xs={12} md={12} lg={12} xl={8} sm={12}>
                    <div className="links-returned">Links Returned</div>
                    <div className="color-green">{getLinksCount()}</div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={4}
                    sm={12}
                    id="generateReportAV"                    
                    className="padding-0 text-align-right">
                    <PrimaryButton
                      isDisabled={generateReportList.length == 0}
                      onClick={handleGenerateReport}
                      classes="generate-repot-btn"
                      text={t('DD.GENERATE_REPORT')}
                      isPositiveButton={true}
                    />
                  </Grid>
                </Grid>
                <Grid container className="padding-left-right-10">
                  {searchResult.Data && searchResult.Data.length > 0 && (
                    <Grid container>
                      <Grid item xs={9} md={9} lg={9} xl={9} sm={9} />
                      <Grid item xs={3} md={3} lg={3} xl={3} sm={3} className="text-align-right">
                        <span>Select</span>
                        <CheckboxComponent
                          checked={isSelectAllChecked()}
                          onClick={(e) => {
                            handleSelectAllClick(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {searchResult.Data &&
                    Array.isArray(searchResult.Data) &&
                    searchResult.Data.length > 0 &&
                    searchResult.Data.slice()
                      .reverse()
                      .map((el, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sm={12}
                            key={index}
                            className={
                              activeMapData?.mapOptions.searchDate +
                                activeMapData?.mapOptions.documentManagerID ==
                                el.searchDate + el.documentManagerId
                                ? 'border-top active'
                                : 'border-top'
                            }>
                            <Grid container>
                              <Grid item xs={10} md={10} lg={10} xl={10} sm={10} key={index}>
                                <div className="margin-vertical-10">
                                  <div className="lighter-text">
                                    {dateUtils.getFormatedDate(el.searchDate.slice(0, 10))}
                                  </div>
                                  <div
                                    id='address-link'
                                    onClick={(e) => handleLinkClick(e, el)}
                                    className="link padding-left-0">
                                    {el.userInputAddress}
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                md={2}
                                lg={2}
                                xl={2}
                                sm={2}
                                className="text-align-right">
                                <CheckboxComponent
                                  isDisabled={
                                    el.httpStatus == '500' ||
                                    (el.httpStatus == '200' && el.bingData.confidence == 'Low')
                                  }
                                  checked={
                                    generateReportList.filter((e) => {
                                      return (
                                        el.httpStatus == '200' &&
                                        e.searchDate +
                                        e.documentManagerID +
                                        e.formattedAddress +
                                        e.finalAddress ===
                                        el.searchDate +
                                        el.documentManagerId +
                                        el.bingData.address.formattedAddress +
                                        el.finalAddress
                                      );
                                    }).length > 0 ?? false
                                  }
                                  onClick={(e) => {
                                    handleCheckboxClick(
                                      e,
                                      el.searchDate,
                                      el.bingData.address.formattedAddress,
                                      el.finalAddress,
                                      el.imageDocPath,
                                      el.documentManagerId,
                                      el.partyName,
                                      el.userInputAddress
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                </Grid>
              </>
            );
          })}
        </div>
      </div>
      <div className="card-view search-criteria width-100-per search-results-details show-scroll overflow-y-scroll">
        {activeMapData &&
          activeMapData?.mapOptions.httpStatus == '200' &&
          activeMapData?.mapOptions.confidence !== 'Low' && (
            <BingMap mapOptions={activeMapData?.mapOptions} />
          )}
        {activeMapData &&
          (activeMapData?.mapOptions.httpStatus == '500' ||
            activeMapData?.mapOptions.confidence == 'Low') && (
            <div className="av-invalid-search-container color-red">
              <span>{t('DD.INVALID_AV_SEARCH_ERROR')}</span>
            </div>
          )}
      </div>
    </>
  );
};

export default AVSearchResults;
import { Box, MenuList, MenuItem, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IVersionListTemplateProps } from '../../../utils/entities/narrative/INarravtiveProps';
import IVersionItem from '../../../utils/entities/narrative/IVersionItem';
import EpochToDateText from '../../generic/timer/EpochToDateText';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const VersionListTemplate = (props: IVersionListTemplateProps) => {
  const {
    versionList,
    checkIfChanged,
    setVersionToEdit,
    setTemplateToEdit,
    setNarrativeTextToEdit,
    setShowConfirmationModal,
    selectedVersion,
    narrativeText,
    setSelectedVersion,
    setSelectedVersionText,
    templateList,
    getVersionsByTemplate,
    selectedTemplate,
    handleTooltipClose,
    deleteVersionById,
    checkRestore,
    setCheckRestore,
  } = { ...props };

  const { t } = useTranslation();

  return (
    <>
      <Box>
        <MenuList className="version-list">
          {versionList.map((version: IVersionItem, index: number) => {
            return (
              <MenuItem
                key={index}
                value={version.version}
                onClick={() => {
                  if (checkIfChanged) {
                    setVersionToEdit(selectedVersion?.id);
                    setTemplateToEdit(selectedVersion?.templateId);
                    setNarrativeTextToEdit(narrativeText);
                    setShowConfirmationModal(true);
                  }

                  if (version) {
                    setSelectedVersion(version);
                    if (version.version) {
                      setSelectedVersionText(version.version);
                    }
                  }
                  handleTooltipClose();
                }}>
                <div className="version-item">
                  <div className="version-code">{version.version}</div>
                  <div className="version-time">
                    <div className="small-fonts">
                      <EpochToDateText epoch={version.creationTime} />
                    </div>
                  </div>
                  <div className="delete-button">
                    {version.version !== 'WIP' && (
                      <IconButton
                        className="delete-link link"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteVersionById(version.id ?? '');
                        }}>
                        <DeleteOutlineIcon className="dark-icon-default" />
                      </IconButton>
                    )}
                  </div>
                </div>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
    </>
  );
};

export default VersionListTemplate;

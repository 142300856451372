import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import { t } from 'i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IFileUrl from '../../../../utils/entities/IFileUrl';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IFile from '../../../../utils/entities/document/IFile';
import { addAlert } from '../../../../store/actions/alerts.actions';
import mime from 'mime';
import { useMsal } from '@azure/msal-react';
import { CONSTANTS } from '../../../../utils/constants/constants';
import docUtils from '../../../../utils/docUtils';
import IAddOrDragDropProps from '../../../../utils/entities/document/IAddOrDragDrop';

const AddOrDragDrop = (props: IAddOrDragDropProps) => {
  const {
    hasAssignee,
    setScanLoader,
    fileUrls,
    setFileUrls,
    selectedFiles,
    setSelectedFiles,
    setScanFileRes,
    addFileTrigger,
  } = {
    ...props,
  };
  const inputRef = useRef(null);
  const { accounts } = useMsal();

  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files?.[0]) {
      const files: Array<File> = Array.from(e.dataTransfer.files);
      createFileUrls(files);
      scanDocument(files);
      handleUploadFiles(files);
    }
  };

  const selectFile = (event: any) => {
    const files: Array<File> = Array.from(event.target.files);
    createFileUrls(files);
    scanDocument(files);
    handleUploadFiles(files);

    event.target.value = null;
  };

  const createFileUrls = (files: Array<File>) => {
    const urls: Array<IFileUrl> = files.map((file: File) => {
      return {
        url: URL.createObjectURL(file),
        type: file.type,
      };
    });
    setFileUrls([...fileUrls, ...urls]);
  };

  const handleUploadFiles = (files: Array<File>) => {
    const uploaded = [...selectedFiles];
    files.some((file: IFile) => {
      file.active = true;
      file.documentName = file.name;
      file.documentType = CONSTANTS.DOC_CENTER.DOC_TYPE;
      file.documentSubType = '';
      file.expirationDate = CONSTANTS.NULL_EPOCH;
      file.uploadedBy = accounts[0]?.username;

      if (uploaded.findIndex((f) => f.name === file.name) != -1) {
        addAlert({
          type: 'info',
          primaryText: t('DOC_CENTER.FILE_DUPLICATED'),
        });
      } else if (isDoubleExtension(file.name)) {
        addAlert({
          type: 'error',
          primaryText: t('DOC_CENTER.FILE_INVALID'),
        });
      } else {
        uploaded.push(file);
      }
    });

    setSelectedFiles(uploaded);
  };

  const isDoubleExtension = (fileName: string) => {
    const namePieces = fileName.split('.');
    if (namePieces.length == 1) {
      return true;
    }
    if (namePieces.length == 2) {
      return false;
    }
    return namePieces.length > 2 && mime.getType(namePieces[namePieces.length - 2].toLowerCase());
  };

  const scanDocument = (files: Array<File>) => {
    setScanLoader(true);
    docUtils
      .scanDocument(files)
      .then((passed: boolean) => {
        files.forEach(() => virusResponse(passed));
      })
      .catch(() => {
        virusResponse(false);
      });
  };

  const virusResponse = (passed: boolean) => {
    setScanFileRes((prevCount) => [...prevCount, passed]);
    setScanLoader(false);
    if (!passed) {
      addAlert({
        type: 'error',
        primaryText: t('DOCUMENT_FAILED_VIRUS_SCAN'),
      });
    }
  };

  const outsideButtonClick = () => {
    document.getElementById('selectImage')?.click();
    addFileTrigger();
  };

  return (
    <form id="form-file-upload" onDragEnter={handleDrag}>
      <div
        className={dragActive ? 'drag-active border-style' : 'border-style'}
        id="drag-file-element"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}>
        <Button
          onClick={outsideButtonClick}
          disabled={!hasAssignee}
          className="link-cursor add-document">
          <CloudUploadIcon data-testid="icon-size-md" />
          <span className="margin-left-right-10 text-transform-capitalize">
            {t('DOC_CENTER.DRAG_DROP')}
          </span>
          <span style={{ marginLeft: 10, marginRight: 20 }}>{t('OR')}</span>

          <AddCircleOutlineIcon />
          <span className="margin-left-right-10 text-transform-capitalize">
            {t('DOC_CENTER.ADD_FILES')}
          </span>

          <input
            id="selectImage"
            style={{ display: 'none' }}
            onChange={selectFile}
            type="file"
            multiple
            ref={inputRef}
          />
        </Button>
      </div>
    </form>
  );
};

export default AddOrDragDrop;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import DrawerLayout from '../DrawerLayout/DrawerLayout';
import Navbar from '../Navbar/Navbar';
import ErrorBoundary from '../../components/generic/error-boundary/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './BaseLayout.scss';
import { CONSTANTS } from '../../utils/constants/constants';
import IBaseLayout from '../../utils/entities/layouts/IBaseLayout';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: CONSTANTS.DRAWER_WIDTH,
    width: `calc(100% - ${CONSTANTS.DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const BaseLayout = (props: IBaseLayout) => {
  const { children, projects, selectedProject, setSelectedProject } = { ...props };
  const params = useParams();
  const history = createBrowserHistory();

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" color="secondary" elevation={0}>
          <ErrorBoundary history={history} params={params}>
            <Navbar
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              projects={projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
            />
          </ErrorBoundary>
        </AppBar>
        <ErrorBoundary history={history} params={params}>
          <DrawerLayout open={openDrawer} setOpenDrawer={setOpenDrawer} />
        </ErrorBoundary>
      </Box>
      <div className="main-body-children">{children}</div>
    </>
  );
};
export default BaseLayout;

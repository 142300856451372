import React, { useContext, useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import { CONSTANTS } from '../../utils/constants/constants';
import GeneralUtils from '../../utils/generalUtils';
import IPermission, { PermissionTypes } from '../../utils/entities/role/IPermission';
import { getCaseRolesByProject } from '../../service/role-manager.service';
import ICaseRoles from '../../utils/entities/case/ICaseRoles';
import { addAlert } from '../../store/actions/alerts.actions';
import { getCase } from '../../service/case-manager.service';
import LocalStorageUtils from '../../utils/localStorageUtils';
import IAccessToken from '../../utils/entities/authentication/IAccessToken';
import './CaseDetail.scss';
import { Tab, Tabs } from 'react-tabs-scrollable';
import { ICaseTabs } from '../../utils/entities/case/CaseDetail/ICaseTabs';
import CaseDetailContent from './CaseDetailContent';
import { getProjectSettingByTenant } from '../../service/config-manager.service';
import GenericContext from '../../components/generic/timer/GenericContext';
import CaseDetailHeader from './case-detail-header/CaseDetailHeader';

const CaseDetail = () => {
  const { setSelectedProject } = useContext(GenericContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const toggleOpen = (status: string) => setOpenStatus(status === openStatus ? '' : status);
  const { caseId } = useParams();

  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );

  const checkIfUserCanAccess = (caseRolePermissions: IPermission[]) => {
    return GeneralUtils.checkUserPermissions(
      PermissionTypes.TASK_AGGREGATE_VIEW,
      caseRolePermissions
    );
  };

  const [noData, setNoData] = useState<boolean>(false);
  const [isCompletedTask, setIsCompletedTask] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState('');
  const [selectedTaskName, setSelectedTaskName] = useState('');
  const [isWorkflowHidden, setIsWorkflowHidden] = useState(false);
  const [taskLoading, setTaskLoading] = useState<boolean>(false);
  const [caseRoles, setCaseRoles] = useState<Array<ICaseRoles>>([]);
  const [assignedCaseRoles, setAssignedCaseRoles] = useState<ICaseRoles[]>([]);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  const [userCanAccessAggregate, setUserCanAccessAggregate] = useState<boolean>(
    GeneralUtils.checkUserPermissions(PermissionTypes.TASK_AGGREGATE_VIEW, casePermissions)
  );
  const [userCanAccess360, setUserCanAccess360] = useState<boolean>(
    GeneralUtils.checkUserPermissions(PermissionTypes.CASE_DETAIL_CASE_360, casePermissions)
  );
  const [selectedTab, setSelectedTab] = useState<Array<ICaseTabs>>();
  const defaultTabId = '2';
  const [selectedTabId, setSelectedTabId] = useState<string>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_DETAILS_ACTIVE_TAB) ||
      defaultTabId
  );

  const [tabs, setTabs] = useState<Array<ICaseTabs>>([
    {
      tabId: '1',
      title: 'Case 360',
      visible: userCanAccess360,
      isActive: selectedTabId === '1',
    },
    {
      tabId: '2',
      title: 'Aggregate View',
      visible: userCanAccessAggregate,
      isActive: selectedTabId === '2',
    },
  ]);

  const [visibleTabs, setVisibleTabs] = useState<Array<ICaseTabs>>(
    tabs.filter((tab) => tab.visible)
  );

  const ChangeActive = (newValue: string) => {
    const changedTab = tabs.filter((tab: ICaseTabs) => tab.tabId == selectedTabId);
    const tabSelected = tabs.filter((tab: ICaseTabs) => tab.tabId == newValue);

    setSelectedTab(tabSelected);
    setSelectedTabId(tabSelected[0].tabId);
    if (!changedTab[0] || changedTab[0]?.tabId === selectedTabId) {
      changeTab(tabSelected);
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.CASE_DETAILS_ACTIVE_TAB,
        newValue
      );
    }
  };

  const changeTab = (selectedTab: Array<ICaseTabs>) => {
    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      isActive: tab.tabId === selectedTab[0].tabId,
    }));
    setTabs(updatedTabs);
  };

  const onTabClickHandler = (e: any, newValue: string) => {
    ChangeActive(e.target.value);
  };

  useEffect(() => {
    setVisibleTabs(tabs.filter((tab) => tab.visible));
  }, [tabs]);

  useEffect(() => {
    fetchCaseRolesByProject();

    const checkIfExists = visibleTabs.find((tab) => tab.tabId == selectedTabId);
    if (selectedTabId) {
      setSelectedTab(visibleTabs.filter((tab) => tab.tabId == selectedTabId));
    }
    if (!checkIfExists) {
      if (visibleTabs.length) {
        const visibleTab = visibleTabs.filter((tab) => tab.visible);
        setSelectedTab(visibleTab);
        setSelectedTabId(visibleTab[0].tabId);
        changeTab(visibleTab);
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.CASE_DETAILS_ACTIVE_TAB,
          visibleTab[0].tabId
        );
      } else {
        LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_DETAILS_ACTIVE_TAB);
      }
    }

    return () => LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS);
  }, []);

  useEffect(() => {
    if (isDataUpdated) {
      addUserGrand();
    }
  }, [isDataUpdated]);

  useEffect(() => {
    if (isDataUpdated) {
      if (!userCanAccessAggregate && !userCanAccess360) {
        navigate(CONSTANTS.PAGES_URL.INBOX);
        addAlert({
          type: 'error',
          primaryText: t('ACCESS_NOT_GRANTED'),
        });
      }
    }
  }, [userCanAccessAggregate, userCanAccess360, isDataUpdated]);

  const fetchCaseRolesByProject = async () => {
    await getCaseRolesByProject(projectName).then((caseRoles) => {
      const filteredCaseRoles = caseRoles.filter((role: ICaseRoles) => role.max > 0);
      filteredCaseRoles.forEach((item: ICaseRoles, i: number) => {
        if (GeneralUtils.checkIfOwner(item)) {
          filteredCaseRoles.splice(i, 1);
          filteredCaseRoles.unshift(item);
        }
      });
      setCaseRoles(filteredCaseRoles);
      fetchCase();
    });
  };

  const updateTenant = async (tenant: string) => {
    const currentProjSetting = await getProjectSettingByTenant(tenant);
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT, tenant);
    setSelectedProject(currentProjSetting.projectName);
  };

  const fetchCase = () => {
    if (caseId) {
      getCase(caseId)
        .then((data) => {
          const tenant = data?.tenant;

          if (
            LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT) !==
            tenant
          ) {
            updateTenant(tenant);
          }

          if (data?.caseRoles?.length) {
            const caseRoles = data.caseRoles.filter((role) => role.project === projectName);
            setAssignedCaseRoles(structuredClone(caseRoles));
            setIsDataUpdated(true);
          } else {
            LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS);
          }
        })
        .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_GETTING_CASE') }));
    }
  };

  const addUserGrand = () => {
    if (isDataUpdated) {
      const actPermissions = GeneralUtils.getCasePermissionsFromCaseRoles(
        assignedCaseRoles,
        caseRoles,
        userInfo.user.toLowerCase()
      );
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS, actPermissions);
      setUserCanAccessAggregate(checkIfUserCanAccess(actPermissions));
      setUserCanAccess360(checkIfUserCanAccess(actPermissions));
    }
  };

  const clickHandler = (taskWorkflowItem: any) => {
    setSelectedTaskName(taskWorkflowItem.taskName);
    if (taskWorkflowItem.taskId) {
      setNoData(false);
      navigate(`task/${taskWorkflowItem.taskId}`);
      if (taskWorkflowItem.status == CONSTANTS.TASK_INTERNAL_STATUS.COMPLETED) {
        setIsCompletedTask(true);
      } else {
        setIsCompletedTask(false);
      }
    } else {
      history.replace(`/aggregateView/${caseId}`);
      setNoData(true);
    }
  };

  document.addEventListener(EVENTS_CONSTANTS.TASK_LOADING_EVT, (e: any) => {
    setTaskLoading(e.detail);
  });

  return (
    <div className="aggregate-view-wrapper">
      {caseId && (
        <CaseDetailHeader
          caseId={caseId}
          caseRoles={caseRoles}
          casePermissions={casePermissions}
          selectedTab={selectedTab}
        />
      )}

      <Tabs
        activeTab={visibleTabs.findIndex((el) => el.isActive)}
        onTabClick={onTabClickHandler}
        rightBtnIcon={'>'}
        leftBtnIcon={'<'}
        tabsScrollAmount={2}>
        {visibleTabs.map((tab: ICaseTabs, tabIndex: number) => (
          <Tab key={tab.tabId} value={tab.tabId}>
            {tab.title}
          </Tab>
        ))}
      </Tabs>
      {caseId && (
        <CaseDetailContent
          isWorkflowHidden={isWorkflowHidden}
          setIsWorkflowHidden={setIsWorkflowHidden}
          toggleOpen={toggleOpen}
          openStatus={openStatus}
          noData={noData}
          isCompletedTask={isCompletedTask}
          taskLoading={taskLoading}
          clickHandler={clickHandler}
          selectedTaskName={selectedTaskName}
          selectedTab={selectedTab}
          caseId={caseId}
        />
      )}
    </div>
  );
};

export default CaseDetail;

export function useAggregateViewContext() {
  return useOutletContext<{
    isCompletedTask: boolean;
  }>();
}

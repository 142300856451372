import React from 'react';
import {
  IFuzzyHighlightProps,
  IParts,
} from '../../../utils/entities/fuzzy-highlight/IFuzzyHighlight';

const FuzzyHighlight = ({ matches, option }: IFuzzyHighlightProps) => {
  const parts: IParts[] = [];
  let lastIndex = 0;

  for (const [start, end] of matches) {
    if (start !== lastIndex) {
      parts.push({ text: option.substring(lastIndex, start), highlight: false });
    }

    parts.push({ text: option.substring(start, end + 1), highlight: true });
    lastIndex = end + 1;
  }

  if (lastIndex < option.length) {
    parts.push({ text: option.substring(lastIndex), highlight: false });
  }

  return (
    <span>
      {parts.map(({ text, highlight }, index) => (
        <span key={`option-${text}-${index}`} className={highlight ? 'color-red-900' : ''}>
          {text}
        </span>
      ))}
    </span>
  );
};

export default FuzzyHighlight;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Stack,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextFieldProps,
  Grid,
} from '@mui/material';
import QuestionTooltip from '../QuestionTooltip';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import SpelUtils from '../../../../utils/spelUtils';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import IQuestionType from '../../../../utils/entities/IQuestionType';
import DynamicQuestionnaireUtils from '../../../../utils/functions/DynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion, { IValue } from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';

const TypeAheadTextQuestion = (props: IQuestionType) => {
  const {
    question,
    entity,
    locals,
    contextLocals,
    updateLocals,
    updateValue,
    disabled,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;
  const { data, updatedRefreshData } = useIScreen();
  const { t } = useTranslation();
  const [input, setInput] = useState<string>('');
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const storedValue = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  );

  const oldValue = data?.dynamicQuestionnaire?.[activeTabId!]?.sets[0].questions.find(
    (currentQuestion: IQuestion) => currentQuestion.id == question.id
  );

  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };
  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    context
  );
  const responseOptions = question.responseOptions;

  const [value, setValue] = useState<string>(
    storedValue
      ? storedValue
      : Object.keys(question.value).includes(entity)
        ? question.value[entity].length != 0
          ? question.value[entity][0]
          : ''
        : ''
  );
  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value?.toString()
    );
    const valueArray = [value?.toString()];
    updateValue(question.attributeName, valueArray);

    locals[question?.attributeName] = value;

    updateLocals(locals);

    if (value) {
      setRuntimeValidationError(
        DynamicQuestionnaireUtils.hasRuntimeError(question.validation, value, {
          ...context,
          [question.attributeName]: value,
        })
      );
    }
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
    );
  }, [entity]);

  const onChangeHandler = (newInput: string | null) => {
    if (newInput !== null) {
      setValue(newInput);
    } else {
      setValue('');
    }
  };

  useEffect(() => {
    if (question.refresh) {
      setValue(question.value[entity][0]);
    }
  }, [question.refresh]);

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, context) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <span>
                {question.label}
                {requiredResult ? '*' : ''}
              </span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Autocomplete
                disabled={
                  disabled
                    ? disabled
                    : SpelUtils.expressionValidation(
                        question.readOnly,
                        question.readOnlyExpression,
                        context
                      )
                }
                id="autocomplete"
                value={value}
                onChange={(event, newInputValue: string | null) => onChangeHandler(newInputValue)}
                inputValue={input}
                onInputChange={(event, newInputValue) => setInput(newInputValue)}
                options={responseOptions.map((options) => options.label)}
                sx={{ width: '90%', margin: 'normal' }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField variant="standard" {...(params as TextFieldProps)} />
                )}
              />
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={value} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default TypeAheadTextQuestion;

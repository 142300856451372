import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { useTranslation } from 'react-i18next';
import GeneralUtils from '../../../../utils/generalUtils';
import { addAlert } from '../../../../store/actions/alerts.actions';
import ITextFieldComponentProps from '../../../../utils/entities/genericComponents/input/input-label/ITextFieldComponent';

const TextFieldComponent: React.FC<ITextFieldComponentProps> = (props) => {
  const {
    disabled,
    max,
    maxLength,
    min,
    minLength,
    placeholder,
    required,
    type,
    value,
    label,
    setData,
    blur,
    icon,
    errorExternal,
    setErrorMessageValue,
  } = { ...props };
  const { t } = useTranslation();

  const onChange = (newValue: string) => {
    const newChar = newValue;

    if (GeneralUtils.checkIfStrValidByInfoSecChars(newChar)) {
      setData(newValue);
      if (setErrorMessageValue) {
        setErrorMessageValue('');
      }
    } else {
      addAlert({ type: 'error', primaryText: t('INFOSEC_CHARS_ERROR') });
      if (errorExternal && setErrorMessageValue) {
        setErrorMessageValue(t('INFOSEC_CHARS_ERROR'));
      }
    }
    // setPreviousValue(value);
  };

  return (
    <FormControl fullWidth margin="normal">
      <span data-testid="label-first-name">
        {label}
        {required ? '*' : ' '}
      </span>
      <TextField
        variant="standard"
        disabled={disabled}
        placeholder={placeholder ?? ''}
        required={required}
        type={type}
        data-testid="input-text-box"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        inputProps={{ minLength: minLength, maxLength: maxLength, min: min, max: max }}
        onBlur={blur}
        InputProps={{
          endAdornment: icon,
        }}
      />
    </FormControl>
  );
};

TextFieldComponent.defaultProps = {
  disabled: false,
  max: '',
  maxLength: CONSTANTS.TEXT_FIELD_LENGTH,
  min: '',
  minLength: 0,
  placeholder: '',
  readonly: false,
  required: false,
  type: 'text',
  label: '',
  cols: 100,
};

export default TextFieldComponent;

import { virusScanDocument } from '../service/document-scan-manager.service';
import { CONSTANTS } from './constants/constants';
import LocalStorageUtils from './localStorageUtils';

interface IDocUtils {
  scanDocument: (files: Array<File>) => Promise<boolean>;
}

const docUtils: IDocUtils = {
  scanDocument: async (files: Array<File>) => {
    return (
      (LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED) &&
        virusScanDocument(files)
          .then((res: any) => {
            return true;
          })
          .catch((error: unknown) => {
            console.error(error);
            return false;
          })) ||
      true
    );
  },
};

export default docUtils;

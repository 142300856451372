import React, { useContext, useEffect, useReducer, useState } from 'react';
import { SarApp } from 'fso-fincrime-sar-frontend-ui';
import 'fso-fincrime-sar-frontend-ui/build/index.css';
import { useParams } from 'react-router-dom';
import { useIScreen } from '../../pages/task/Task';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import GeneralUtils from '../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import { CONSTANTS } from 'fso-fincrime-sdk-ui';
interface ISarState {
  sarDocumentId: string;
  SarCaseSubmissionId: string;
  sourceName: string;
  sourceRefId: string;
}

const SarUi = () => {
  const { tabId } = useParams();
  const { data, saveTaskAsDraftAndExitClick, setSaveTaskAsDraftAndExitClick } = useIScreen();
  const initialState: ISarState = {
    sarDocumentId: '',
    SarCaseSubmissionId: '',
    sourceName: '',
    sourceRefId: '',
  };
  const { sarDocumentId, SarCaseSubmissionId, sourceName, sourceRefId } = initialState;

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 1000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  return (
    <div data-testid="app-main" key={tabId}>
      {data.sar && data.sar[data.activeTabId]?.caseId && (
        <SarApp
          sarDocumentId={sarDocumentId}
          SarCaseSubmissionId={SarCaseSubmissionId}
          sourceName={'CMT'}
          sourceRefId={data.sar[data.activeTabId]?.caseId}
        />
      )}
    </div>
  );
};

export default SarUi;

import React, { useState } from 'react';
import { Box, Button, Modal } from '@mui/material';
import IAccessToken from '../../../utils/entities/authentication/IAccessToken';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import SwitchRoleDialog from './SwitchRoleDialog';
import AvatarComponent from '../../generic/avatar/AvatarComponent';

const SwitchRole = () => {
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const getInitials = () => {
    return userInfo.user.charAt(0).toUpperCase();
  };

  return (
    <div>
      <Button
        color="inherit"
        disableRipple
        className="text-transform-none"
        onClick={() => setOpenModal(true)}>
        {userInfo.user} / {userInfo.role.name}
      </Button>
      <AvatarComponent text={getInitials()} />

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box className="modalView login">
          <SwitchRoleDialog setOpenModal={setOpenModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default SwitchRole;

import * as React from 'react';
import { loadBingApi, Microsoft } from './BingMapLoader';

interface IMapProps {
  mapOptions?: any;
}

export default class BingMap extends React.Component<IMapProps, any> {
  private mapRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    loadBingApi(window.__RUNTIME_CONFIG__?.REACT_APP_BING_MAPS_API_KEY).then(() => {
      this.showMap(
        this.props.mapOptions.apiKey,
        this.props.mapOptions.coordinatesX,
        this.props.mapOptions.coordinatesY,
        this.props.mapOptions.address
      );
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<IMapProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.mapOptions !== this.props.mapOptions) {
      loadBingApi(window.__RUNTIME_CONFIG__?.REACT_APP_BING_MAPS_API_KEY).then(() => {
        this.showMap(
          this.props.mapOptions.apiKey,
          this.props.mapOptions.coordinatesX,
          this.props.mapOptions.coordinatesY,
          this.props.mapOptions.address
        );
      });
    }
  }

  public render() {
    return <div ref={this.mapRef} className="map" style={{ height: '100%', width: '100%', minHeight: '95vh' }} />;
  }

  private showMap(bingApiKey: any, lat: any, lng: any, suggestedAddress: any) {
    const map = new Microsoft.Maps.Map(this.mapRef.current, {
      credentials: bingApiKey,
      center: new Microsoft.Maps.Location(lat, lng),
      mapTypeId: Microsoft.Maps.MapTypeId.aerial,
      zoom: 18,
      heading: 0,
      pitch: 0,
      enableCORS: true,
    });
    const center = map.getCenter();
    const infobox = new Microsoft.Maps.Infobox(center, {
      title: suggestedAddress,
    });
    infobox.setMap(map);

    localStorage.setItem('streetViewIsActive', false.toString());
    Microsoft.Maps.Events.addHandler(map, 'maptypechanged', function (handler: any) {
      if (handler.newMapTypeId == 'x') {
        localStorage.setItem('streetViewIsActive', true.toString());
      }
    });

    Microsoft.Maps.Events.addHandler(map, 'viewchangeend', function () {
      onViewchangeEnd(map);
    });

    function onViewchangeEnd(map: any) {
      localStorage.setItem('streetViewHeading', map.getHeading());
      localStorage.setItem('streetViewPitch', map.getPitch());
    }
  }
}

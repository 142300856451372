import React from 'react';
import IQuestion from '../../../../utils/entities/screen/IQuestion';
import Question from '../questions/Question';
import { Grid, Typography } from '@mui/material';
import IContextLocals from '../../../../utils/entities/screen/IContextLocals';
import IScreen from '../../../../utils/entities/screen/IScreen';
import ISet from '../../../../utils/entities/screen/ISet';
import SpelUtils from '../../../../utils/spelUtils';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import { useIScreen } from '../../../../pages/task/Task';
import IContextLocalsEntity from '../../../../utils/entities/screen/IContextLocalsEntity';

const ScrollView = (props: {
  contextLocals: IContextLocals;
  requestBody: IScreen;
  assignee: string | undefined;
  loggedUser: string | undefined;
  selectedEntity: string;
  disabled: boolean;
  locals: IContextLocalsEntity;
  updatedLocals: (newContextLocals: IContextLocals) => void;
  updateValue: (attribute: string, value: Array<string>) => void;
  updateHeader: () => void;
  activeTabId: string;
  setIndex: number;
  count: (set: ISet, setIndex: number) => number;
  userInfo: IAccessToken;
}) => {
  const {
    contextLocals,
    requestBody,
    assignee,
    locals,
    loggedUser,
    selectedEntity,
    disabled,
    updatedLocals,
    updateValue,
    updateHeader,
    activeTabId,
    setIndex,
    count,
    userInfo,
  } = props;

  const { questionErrorsState } = useIScreen();

  return (
    <>
      {contextLocals != null &&
        selectedEntity &&
        requestBody?.dynamicQuestionnaire[activeTabId].sets
          .filter(
            (set: ISet) =>
              SpelUtils.expressionValidation(set.visible, set.visibilityExpression, {
                contextLocals:
                  contextLocals[
                    set.root && selectedEntity == set.rootId ? 'rootEntity' : selectedEntity
                  ],
                userRole: userInfo.role,
              }) &&
              set.questions.some((q) =>
                SpelUtils.expressionValidation(q.visible, q.visibilityExpression, {
                  contextLocals:
                    contextLocals[
                      set.root && selectedEntity == set.rootId ? 'rootEntity' : selectedEntity
                    ],
                  userRole: userInfo.role,
                })
              )
          )
          .map((set: ISet, currentIndex: number) => (
            <Grid
              container
              key={'list-' + currentIndex}
              alignItems="flex-start"
              direction="column"
              xs={12}
              gap={2}
              style={{ marginBottom: '40px' }}>
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <Grid container xs={12}>
                    <Typography
                      variant="subtitle1"
                      style={{ backgroundColor: 'rgb(46, 46, 56)', color: '#fff' }}
                      className={
                        count(set, set.entityIds.length ? set.entityIds.length - 1 : 0) / 10 < 1
                          ? 'circle'
                          : 'circle-ellipse'
                      }>
                      {count(set, set.entityIds.length ? set.entityIds.length - 1 : 0)}
                    </Typography>
                    <Typography variant="h5" className="task_view_title">
                      {set.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {set.entityIds.map((entityId: string, index: number) => (
                <Grid container key={index}>
                  {contextLocals[entityId] && (
                    <Grid item>
                      <Typography variant="h6" className="task_view_subtitle">
                        {contextLocals[entityId]
                          ? (contextLocals[entityId][set.attributeDisplayName] as unknown as string)
                          : (contextLocals['rootEntity'][
                              set.attributeDisplayName
                            ] as unknown as string)}
                      </Typography>
                    </Grid>
                  )}
                  {set.questions.map((q: IQuestion, indexQuestion: number) => (
                    <Question
                      assignee={assignee}
                      loggedUser={loggedUser}
                      key={q.id}
                      question={q}
                      entity={entityId}
                      disabled={disabled}
                      locals={locals}
                      contextLocals={contextLocals}
                      updateLocals={updatedLocals}
                      updateValue={updateValue}
                      updateHeader={updateHeader}
                      error={questionErrorsState.get(q.id)}
                      indexQuestion={indexQuestion}
                      requestBody={requestBody}
                      activeTabId={activeTabId}
                      setIndex={setIndex}
                    />
                  ))}
                </Grid>
              ))}
            </Grid>
          ))}
    </>
  );
};

export default ScrollView;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createCaseService } from '../../../service/case-manager.service';
import { sendDataBlock } from '../../../service/data-block-builder.service';
import AssignCaseComponent from '../assignCaseRoles/AssignCaseRoles';
import { CONSTANTS } from '../../../utils/constants/constants';
import PrimaryButton from '../../generic/buttons/PrimaryButton';
import { addAlert } from '../../../store/actions/alerts.actions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ICreateCaseModalProps } from '../../../utils/entities/case/CaseCreation/ICaseCreation';
import GeneralUtils from '../../../utils/generalUtils';
import BusinessKeys from '../../../types/businessKeys';
import CaptureCaseAttributes from './captureCaseAttributes/CaptureCaseAttributes';
import Loader from '../../loader/Loader';
import ConfirmationModal from '../../generic/modal/ConfirmationModal';
import ICaseRoles from '../../../utils/entities/case/ICaseRoles';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import { useNavigate } from 'react-router-dom';
import { PermissionTypes } from '../../../utils/entities/role/IPermission';

const CreateCaseModal = ({
  isOpen,
  group,
  tenant,
  closeDlg,
  reloadGrid,
}: ICreateCaseModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routing: boolean = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING);
  const userCanCreateDuplicates = GeneralUtils.checkUserPermissions(
    PermissionTypes.CASE_CREATION_DUPLICATE
  );

  const [showMainLoader, setShowMainLoader] = useState<boolean>(false);
  const [caseAttributes, setCaseAttributes] = useState<BusinessKeys>([]);
  const [caseAttributesReady, setCaseAttributesReady] = useState<boolean>(false);
  const [assignedCaseRoles, setAssignedCaseRoles] = useState<ICaseRoles[]>([]);
  const [rolesReady, setRolesReady] = useState<boolean>(false);
  const [caseData, setCaseData] = useState<BusinessKeys>([]);
  const [caseDataReady, setCaseDataReady] = useState<boolean>(false);
  const [isConfModalOpen, setIsConfModalOpen] = useState<boolean>(false);
  const [validateBks, setValidateBks] = useState<BusinessKeys>([]);
  const [createConfirmationTitle, setCreateConfirmationTitle] = useState<string>('');
  const [createConfirmationText, setCreateConfirmationText] = useState<string>('');
  const [canCreateCase, setCanCreateCase] = useState<boolean>(userCanCreateDuplicates);

  useEffect(() => {
    setCanCreateCase(userCanCreateDuplicates);
  }, [userCanCreateDuplicates]);

  const createDataBlock = async (id: string, caseDisplayId?: string | null) => {
    sendDataBlock({
      caseId: id,
      tenant: tenant,
      source: {
        name: CONSTANTS.MASTER,
        type: CONSTANTS.MASTER,
        category: null,
        evidenceDocument: null,
      },
      structuredData: {
        group: [
          {
            groupCode: group?.itemCode,
            groupType: '*',
            root: true,
            attributes: caseAttributes[0],
          },
        ],
      },
    })
      .then(() => {
        addAlert({
          type: 'success',
          primaryText: t('CASE.CASE_CREATED_SUCCESSFULLY'),
          secondaryText: GeneralUtils.getCaseDisplayId(id, caseDisplayId),
        });
        if (routing) {
          navigate(CONSTANTS.PAGES_URL.INBOX);
        } else {
          reloadGrid();
        }
      })
      .catch((error) => {
        addAlert({
          type: 'error', // TODO: Add custom status codes
          primaryText: error.includes('business key') ? t('NO_BUSINESS_KEY') : t('ERROR_MESSAGE'),
        });
      });
  };

  const handleSubmit = async (businessKeys: BusinessKeys | null = null) => {
    if (group) {
      setShowMainLoader(true);
      createCaseService({
        tenant: tenant,
        caseData: caseData,
        caseRoles: assignedCaseRoles,
        validateBks: businessKeys
          ? {
            itemType: group.itemCode,
            businessKeys,
          }
          : null,
      })
        .then((res) => {
          createDataBlock(res.id, res.caseDisplayId);
          closeDlg();
        })
        .catch((error: string | number) => {
          if (error === 409) {
            if (userCanCreateDuplicates) {
              //Instead of showing the confirmation modal, we will directly create the case if the user has the permission to create duplicates
              handleSubmit()
            } else {
              setIsConfModalOpen(true);
              setCreateConfirmationTitle(
                t('CASE.CASE_ALREADY_EXISTS')
              );
              setCreateConfirmationText(
                t('CASE.CASE_ALREADY_EXISTS_NO_DUPLICATE')
              );
            }
          } else if (error === 422) {
            setIsConfModalOpen(true);
            setCanCreateCase(false);
            setCreateConfirmationTitle(t('CASE.CASE_ALREADY_EXISTS'));
            setCreateConfirmationText(
              userCanCreateDuplicates
                ? t('CASE.CASE_ALREADY_EXISTS_NO_DUPLICATE_BY_STATUS')
                : t('CASE.CASE_ALREADY_EXISTS_NO_DUPLICATE')
            );
          } else {
            closeDlg();
            addAlert({
              type: 'error',
              primaryText: t('ERROR_MESSAGE'),
            });
          }
        })
        .finally(() => setShowMainLoader(false));
    }
  };

  return (
    <>
      {showMainLoader && <Loader isMainLoader />}
      <Dialog
        id="create-case-modal"
        fullWidth={true}
        maxWidth={'xl'}
        open={isOpen}
        onClose={() => closeDlg()}>
        <>
          <DialogTitle id="confirm-dialog">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                {t('CREATE_CASE')} {group && '(' + group.description + ')'}
              </Box>
              <Box>
                <IconButton onClick={() => closeDlg()}>
                  <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container direction="column" gap={2}>
              {group ? (
                <Box mt={2} gap={3} display="flex" flexDirection="column">
                  <CaptureCaseAttributes
                    group={group}
                    setCaseAttributes={setCaseAttributes}
                    setAllCaseAttributesChecksPassed={setCaseAttributesReady}
                    setCaseData={setCaseData}
                    setAllCaseDataChecksPassed={setCaseDataReady}
                    setValidateBks={setValidateBks}
                  />
                  <AssignCaseComponent
                    isCaseRolesEditable
                    isCaseCreation
                    subTitle
                    assignedCaseRoles={assignedCaseRoles}
                    onChangeAssignedCaseRoles={(assignedCaseRoles) =>
                      setAssignedCaseRoles([...assignedCaseRoles])
                    }
                    setRolesReady={setRolesReady}
                  />
                </Box>
              ) : (
                <Box height="40vh">
                  <Loader />
                </Box>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className="upload-dialog-action">
            <PrimaryButton
              text={t('SUBMIT')}
              onClick={() => {
                handleSubmit(validateBks);
              }}
              isDisabled={!rolesReady || !caseDataReady || !caseAttributesReady}
              isPositiveButton
            />
            <PrimaryButton
              text={t('CANCEL')}
              onClick={() => {
                setAssignedCaseRoles([]);
                closeDlg();
              }}
            />
          </DialogActions>
        </>
      </Dialog>
      <ConfirmationModal
        showConfirmButton={canCreateCase}
        openModal={isConfModalOpen}
        setOpenModal={setIsConfModalOpen}
        action={() => handleSubmit()}
        negativeAction={() => {
          setIsConfModalOpen(false);
          closeDlg();
        }}
        content={{
          title: createConfirmationTitle,
          text: createConfirmationText,
        }}
        buttonsTextOverride={canCreateCase ? undefined : { no: t('CLOSE') }}
      />
    </>
  );
};

export default CreateCaseModal;

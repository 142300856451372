import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Paper,
  Typography,
  FormControlLabel,
  SelectChangeEvent,
} from '@mui/material';
import { CONSTANTS } from '../utils/constants/constants';
import { AgGridReact } from 'ag-grid-react';
import Pagination from '../components/generic/AgGrid/pagination/Pagination';
import { useDebouncedCallback } from 'use-debounce';
import {
  ColDef,
  ColGroupDef,
  Column,
  ColumnRowGroupChangedEvent,
  ColumnState,
  ColumnVO,
  FilterModel,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowGroupOpenedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import GeneralUtils from '../utils/generalUtils';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CreateCaseModal from '../components/cases/CreateCaseModal/CreateCaseModal';
import {
  getColumnConfig,
  getDataModelItemById,
  getDataModelItemsByTenant,
} from '../service/config-manager.service';
import IGroup from '../utils/entities/dataModel/IGroup';
import { useParams, useSearchParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ErrorBoundary from '../components/generic/error-boundary/ErrorBoundary';
import { ViewTypes } from '../utils/constants/enums';
import LabeledChip from '../components/generic/chip/LabeledChip';
import IAgGridTblPayload from '../utils/entities/IAgGridTblPayload';
import { getCasesWithServerSideFilterSort } from '../service/global-query.service';
import IAgGridRowsTaskRes from '../utils/entities/screen/IAgGridRowsTaskRes';
import { PermissionTypes } from '../utils/entities/role/IPermission';
import { ICase } from '../utils/entities/screen/ITask';
import Views from '../components/generic/AgGrid/views/Views';
import TooltipComp from '../components/generic/tooltip/Tooltip';
import { RestAllTags } from '../components/generic/chip/Tag';
import TextFieldComponent from '../components/generic/inputs/textField/TextFieldComponent';
import LocalStorageUtils from '../utils/localStorageUtils';
import EpochToDateText from '../components/generic/timer/EpochToDateText';
import { addAlert } from '../store/actions/alerts.actions';
import CheckboxComponent from '../components/generic/inputs/checkbox/CheckboxComponent';
import IAccessToken from '../utils/entities/authentication/IAccessToken';
import Loader from '../components/loader/Loader';
import LinkButton from '../components/generic/button/link/LinkButton';
import DateText from '../components/generic/timer/DateText';
import SlaAging from '../components/sla/SlaAging';
import RequestToJoin from '../components/request-to-join/RequestToJoin';
import ISlaSettingsEntity from '../utils/entities/config/ISlaSettingsEntity';
import DateCountdown from '../components/generic/AgGrid/columns/DateCountdown';
import IDataModelColumn from '../utils/entities/ag-grid/IDataModelColumn';
import ExportComponent from '../components/generic/AgGrid/export/Export';
import agGridUtils from '../utils/agGridUtils';
import IGroupkeyPagination from '../utils/entities/ag-grid/pagination/IGroupkeyPagination';
import IconButtonComponent from '../components/generic/button/IconButtonComponent';
import IGroupKeyPageRequest from '../utils/entities/ag-grid/pagination/IGroupKeyPageRequest';
import Reset from '../components/generic/AgGrid/reset/Reset';

const CaseList = (props: { slaSettingsEntity: ISlaSettingsEntity | undefined }) => {
  const { slaSettingsEntity } = props;
  const { t } = useTranslation();
  const viewType = ViewTypes.CASE_LIST;
  const gridRef = useRef<AgGridReact>(null);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const history = createBrowserHistory();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const tenant: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );
  const canAccessAggregateView = GeneralUtils.checkUserPermissions(
    PermissionTypes.TASK_AGGREGATE_VIEW
  );
  const canAccessCase360 = GeneralUtils.checkUserPermissions(PermissionTypes.CASE_DETAIL_CASE_360);
  const canRTJRequest = GeneralUtils.checkUserPermissions(PermissionTypes.RTJ_REQUEST);
  const gridOptions: GridOptions = {
    autoGroupColumnDef: {
      headerName: t('AG_GRID.GROUP_HEADER'),
      field: 'group',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      enableRowGroup: false,
      suppressMenu: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    getRowId: (params: GetRowIdParams) => {
      /* All rows will have an unique caseId,
      Excpet the group rows, that rows will have always a single unique element
       in columnConfigurations
      */
      const col = params.data.columnConfigurations?.[0];
      const parentKeys = (params.parentKeys ?? []).join('-');
      return params.data.caseId ?? `${parentKeys}-${col.attributeName}-${col.attributeValue}`;
    },
  };

  const [columns, setColumns] = useState<Array<ColDef | ColGroupDef>>([]);
  const [pageSize, setPageSize] = useState<number>(CONSTANTS.AG_GRID.ROWS_PER_PAGE[1]);
  const [pageNo, setPageNo] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridReadyEvent | undefined>(undefined);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [selectAllCases, setSelectAllCases] = useState<boolean>(false);
  const [columnsConfigs, setColumnsConfigs] = useState<Array<IDataModelColumn>>([]);
  const [gridSelectedRows, setGridSelectedRows] = useState<Array<ICase>>([]);
  const [searchField, setSearchField] = useState<string | null>(null);
  const [columnsViews, setColumnsViews] = useState<Array<IDataModelColumn>>([]);
  const [defaultView, setDefaultView] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [groupByParams, setGroupByParam] = useState<Array<ColumnVO>>([]);
  const [groupKeys, setGroupKeys] = useState<Array<string>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>(undefined);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [payload, setPayload] = useState<IAgGridTblPayload | undefined>(undefined);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [caseCreationLoading, setCaseCreationLoading] = useState<boolean>(false);
  const [noRows, setNoRows] = useState<boolean>(false);
  const [viewsPayload, setViewsPayload] = useState<IAgGridTblPayload | undefined>(undefined);

  /***************************** Needed for the Clear button *****************************/
  const gridNode = gridRef.current as any;
  const columnState = gridNode?.columnApi?.api?.getColumnState();
  const [filterModel, setFilterModel] = useState(gridApi?.api.getFilterModel());
  const [columnFiltering, setColumnFiltering] = useState<boolean>(true);
  const [columnSorting, setColumnSorting] = useState<boolean>(true);
  const [columnGroup, setColumnGroup] = useState<boolean>(true);
  const [resetColumns, setResetColumns] = useState<boolean>(true);
  const displayedColumns = gridNode?.columnApi?.api?.columnModel?.displayedColumns;
  useEffect(() => {
    columnState?.forEach((state: ColumnState) => {
      if (state.sort === 'asc' || state.sort === 'desc') {
        setColumnSorting(false);
      }
      if (state.rowGroup) {
        setColumnGroup(false);
      }
    });
    if (filterModel && Object.keys(filterModel).length) {
      setColumnFiltering(false);
    }
  }, [columnState, filterModel]);

  const columnsMoved = () => {
    setResetColumns(false);
  };
  /***************************** End of Needed for the Clear button *****************************/

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    });

    setCaseCreationLoading(true);
    getDataModelItemsByTenant(tenant, true, true)
      .then((groups: Array<IGroup>) => {
        setGroups(groups);
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') }))
      .finally(() => setCaseCreationLoading(false));

    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE);
      window.removeEventListener('beforeunload', function () {
        return;
      });
    };
  }, []);

  useEffect(() => {
    openCreateCaseByUrl(searchParams);
  }, [groups]);

  useEffect(() => {
    getTableHeaders();
  }, [gridApi]);

  useEffect(() => {
    reloadGrid();
  }, [pageNo, pageSize, searchField]);

  useEffect(() => {
    if (viewsPayload) {
      const gridNode = gridRef.current as any;

      if (viewsPayload.sortModel.length) {
        gridNode.columnApi.api.applyColumnState({
          state: [viewsPayload.sortModel[0]],
        });
      }
    }
  }, [viewsPayload]);

  useEffect(() => {
    forceSelectUpdate();
  }, [totalPages, pageNo]);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE, pageSize);
    setPageNo(0);
  }, [pageSize]);

  const openCreateCaseByUrl = (searchParams: URLSearchParams) => {
    const itemCode = searchParams?.get(CONSTANTS.URL_PARAMS.CASE_CREATION);
    if (itemCode) {
      getSelectedGroup(itemCode);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params);
    params.api.setGridOption('domLayout', 'autoHeight');
    params.api.setGridOption('serverSideDatasource', datasource);
  };

  const reloadGrid = () => {
    gridApi?.api?.updateGridOptions({ serverSideDatasource: datasource });
  };

  const onFilterTextBoxChanged = (word: string) => {
    setSearchField(word);
  };

  const onFilterChanged = () => {
    setFilterModel(gridApi?.api.getFilterModel());
  };

  const getTableHeaders = () => {
    gridApi?.api.showLoadingOverlay();

    getColumnConfig(tenant, viewType).then((res: Array<IDataModelColumn>) => {
      const checkboxFirstCol: IDataModelColumn = {
        attributeLabel: '',
        attributeValue: '',
        attributeName: CONSTANTS.AG_GRID.COLUMNS.CHECKBOX,
        attributeType: '',
        columnType: CONSTANTS.COLUMNTYPES.TEXT,
        listType: 'CASE_LIST',
      };
      const showMoreCol: IDataModelColumn = {
        attributeLabel: '',
        attributeValue: '',
        attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
        attributeType: '',
        columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
        listType: 'CASE_LIST',
      };
      if (res.length) {
        res.unshift(checkboxFirstCol);
        res.unshift(showMoreCol);
      }
      setColumnsConfigs(res);
      configureColumns(res);
      gridApi?.api.hideOverlay();
    });
  };

  const getCellData = (params: ICellRendererParams, colLabel: string) => {
    return (
      params.value ??
      params?.data?.columnConfigurations?.find(
        (col: IDataModelColumn) => colLabel === col.attributeLabel
      )?.attributeValue
    );
  };

  const configureColumns = (colConfigs: Array<IDataModelColumn>) => {
    const columns: Array<ColDef | ColGroupDef> = [];
    colConfigs.forEach((c: IDataModelColumn, index: number) => {
      const column: ColDef | ColGroupDef = {
        field: c.attributeName,
        headerName: c.attributeLabel,
        headerTooltip: c.attributeLabel,
        valueGetter: (params) => getCellData(params as ICellRendererParams, c.attributeLabel),
        filter: agGridUtils.isColDateType(c.columnType)
          ? CONSTANTS.AG_GRID.FILTER.DATE
          : CONSTANTS.AG_GRID.FILTER.TEXT,
        filterParams: !agGridUtils.isColDateType(c.columnType) && { suppressAndOrCondition: true },
      };
      if (c.columnStateProperties) {
        column.hide = c.columnStateProperties.hide ?? undefined;
        column.width = c.columnStateProperties.width;
        column.flex = c.columnStateProperties.width
          ? 0
          : (c.columnStateProperties.flex ?? undefined);
        column.sort = c.columnStateProperties.sort;
        column.sortIndex = c.columnStateProperties.sortIndex;
        column.aggFunc = c.columnStateProperties.aggFunc;
        column.pivotIndex = c.columnStateProperties.pivotIndex;
        column.pinned = c.columnStateProperties.pinned;
        column.rowGroupIndex = c.columnStateProperties.rowGroupIndex;
        column.initialWidth = c.columnStateProperties.width;
      }
      if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.CHECKBOX) {
        column.checkboxSelection = function () {
          return !selectAllCases;
        };
        column.showDisabledCheckboxes = true;
      }
      if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE) {
        column.cellStyle = { alignContent: 'center' };
        column.resizable = false;
      }
      if (
        c.attributeName === CONSTANTS.AG_GRID.COLUMNS.CHECKBOX ||
        c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE
      ) {
        column.enableRowGroup = false;
        column.suppressMenu = true;
        column.sortable = false;
        column.suppressMovable = true;
        column.maxWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
        column.minWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
        column.suppressColumnsToolPanel = true;
      }
      switch (c.columnType) {
        case CONSTANTS.COLUMNTYPES.CASE_LINK:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            if (canAccessAggregateView || canAccessCase360) {
              return (
                <LinkButton
                  url={`${CONSTANTS.PAGES_URL.CASE_DETAILS}/${params.data.caseId}`}
                  text={value}
                />
              );
            }
            return <div> {value}</div>;
          };
          break;
        case CONSTANTS.COLUMNTYPES.EPOCH_DATE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            return <EpochToDateText epoch={Number(value)} />;
          };
          break;
        case CONSTANTS.COLUMNTYPES.DATE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            return <div>{userInfo.user && <DateText date={value} />}</div>;
          };
          break;
        case CONSTANTS.COLUMNTYPES.CASE_TAGS:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            const tags = JSON.parse(value ? value : null);
            if (tags) {
              return <RestAllTags tags={JSON.parse(params.value) ?? []} tagsCount={0} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.RISK_RATING:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            const score = JSON.parse(value ? value : null);
            if (score) {
              return <TooltipComp label={score?.scoreRank} tooltipText={score?.scoreValue} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.CASE_GAPS:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            const gapReport = JSON.parse(value ? value : null);
            return (
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}>
                {gapReport?.reportStatus?.hasIdentificationGaps && (
                  <LabeledChip label={t('ID')} isRequired isIdentified />
                )}
                {gapReport?.reportStatus?.hasVerificationGaps && (
                  <LabeledChip label={t('V')} isRequired isIdentified />
                )}
              </div>
            );
          };
          break;
        case CONSTANTS.COLUMNTYPES.LIST:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            try {
              const list = JSON.parse(value ? value : null);
              return <div>{list?.join(', ')}</div>;
            } catch {
              return <div>{value}</div>;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.AGING:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            if (value) {
              return <SlaAging status={value} slaSettingsEntity={slaSettingsEntity} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.DATE_COUNTDOWN:
          column.cellRenderer = (params: ICellRendererParams) => {
            const value = params?.value;

            if (value && c.additionalProperties?.length) {
              return <DateCountdown timer={value} colorTresholds={c.additionalProperties} />;
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.SHOW_MORE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const key = params.node.key;
            if (params.node.group && params.node.expanded && key) {
              const savedGroupPagination: IGroupkeyPagination[] | undefined =
                LocalStorageUtils.getSavedItem(
                  CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
                );
              const totalElements =
                savedGroupPagination?.find((gr) => gr.keyName === params.node.key)?.totalElements ??
                0;
              const childsNumber = getChildsCount(params.node);
              const canLoadMore = childsNumber < totalElements;

              return (
                <>
                  {canLoadMore && (
                    <TooltipComp
                      label={t('AG_GRID.SHOW_MORE', {
                        num: childsNumber,
                        total: totalElements,
                      })}>
                      <IconButtonComponent
                        icon={'add'}
                        handleClick={() => handleShowMoreClick(key, childsNumber, params)}
                        size={'small'}
                        disableRipple
                        disableFocusRipple
                      />
                    </TooltipComp>
                  )}
                </>
              );
            }
          };
          break;
        case CONSTANTS.COLUMNTYPES.TEXT:
          column.cellRenderer = (params: ICellRendererParams) => {
            return <div>{params?.value}</div>;
          };
          break;
      }

      columns.push(column);
    });

    setColumnsViews(colConfigs);
    setColumns([...columns]);
  };

  const getRowClass = (params: any) => {
    if (params?.data?.status != null && (params?.data?.status as string).toUpperCase() == 'NEW') {
      return 'bold-font';
    }
  };

  const onRowSelected = () => {
    const rows: ICase[] | undefined = gridApi?.api?.getSelectedRows();
    if (rows) {
      setGridSelectedRows(rows.filter((ele) => ele.caseId));
    }
  };

  const onColumnRowGroupChanged = (event: ColumnRowGroupChangedEvent) => {
    setPageNo(0);
    setGroupByParam(
      event?.columns?.map((col: Column) => {
        return {
          id: col.getColId(),
          displayName: col.getColDef().headerName as string,
          field: col.getColDef().field as string,
        };
      }) || []
    );
  };

  const forceSelectUpdate = () => {
    toggleAllCases();
    onRowSelected();
  };

  const onRowGroupOpened = (event: RowGroupOpenedEvent) => {
    const id = event.node.key as string;
    const gr = [...groupKeys];

    const index = gr.indexOf(id);
    index > -1 ? gr.splice(index, 1) : gr.push(id);

    setGroupKeys(gr);
  };

  const handleSelectView = (
    viewsPayload: IAgGridTblPayload,
    filteredColumns?: Array<IDataModelColumn>
  ) => {
    const gridNode = gridRef.current as any;
    setViewsPayload(viewsPayload);

    gridApi?.api.setFilterModel(null);
    gridNode.columnApi.api?.resetColumnState();
    gridApi?.api.onFilterChanged();
    setSearchField('');

    setPageNo(viewsPayload.pageNumber);
    setPageSize(viewsPayload.pageSize);
    setTotalPages(viewsPayload.pageSize - 1);
    setGroupKeys(viewsPayload.groupKeys);

    if (viewsPayload?.filterModel?.id && viewsPayload.filterModel.id.type === 'searchAllFields') {
      setSearchField(viewsPayload.filterModel.id?.filter ?? null);
    }

    gridApi?.api.setFilterModel(viewsPayload.filterModel);

    const ids = viewsPayload.rowGroupCols.map((r) => r.id);
    const colDefs = gridApi?.api.getColumnDefs() || [];
    colDefs.forEach((c: ColDef) => {
      c['rowGroup'] = ids.includes(c?.colId as string);
    });
    gridApi?.api.setColumnDefs([...colDefs]);

    if (filteredColumns?.length && !viewsPayload?.rowGroupCols?.length) {
      configureColumns(filteredColumns);
    } else {
      configureColumns(columnsConfigs);
    }

    reloadGrid();
  };

  const toggleAllCases = () => {
    const selectFlag = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    const gridNode = gridRef.current as any;
    if (gridNode?.api && selectFlag != null) {
      gridNode.api.forEachNode((node: any) => node.setSelected(selectFlag));
    }
  };

  const getCases = async (payload: IAgGridTblPayload) => {
    return await getCasesWithServerSideFilterSort(payload).then((response: IAgGridRowsTaskRes) => {
      return response;
    });
  };

  const getDateFormatToGroupBy = (rowGroupCols: Array<ColumnVO>) => {
    let hasDate = false;
    for (const groupCol of rowGroupCols) {
      const isDate =
        gridNode.columnApi.api?.columnModel.columnDefs?.find(
          (col: ColDef) => col.field === groupCol?.id
        )?.filter === CONSTANTS.AG_GRID.FILTER.DATE;

      if (isDate) {
        hasDate = true;
        break;
      }
    }

    return hasDate
      ? LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT)
      : null;
  };

  const keepGroupByTotalElements = (groupKeyPagination: IGroupkeyPagination | undefined) => {
    if (!groupKeyPagination) {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      return;
    }

    const savedGroupPagination: IGroupkeyPagination[] | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
    );

    if (savedGroupPagination) {
      const indexOf = savedGroupPagination
        .map((gr) => gr.keyName)
        .indexOf(groupKeyPagination.keyName);

      if (indexOf < 0) {
        savedGroupPagination.push(groupKeyPagination);
      } else {
        savedGroupPagination[indexOf] = groupKeyPagination;
      }

      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS,
        savedGroupPagination
      );
    } else {
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS, [
        groupKeyPagination,
      ]);
    }
  };

  // Function to fetch data (simulate an API call or data source fetch)
  const fetchData = useCallback(
    async (params: IServerSideGetRowsParams, casePayload: IAgGridTblPayload) => {
      const { request, success, fail } = params;
      const { filterModel, groupKeys } = request;
      const filter = filterModel as FilterModel;
      getCases(casePayload)
        .then((response: IAgGridRowsTaskRes) => {
          setPayload(casePayload);

          if (groupKeys.length === 0) {
            setPageNo(response.pageable.pageNumber);
            setTotalPages(response.totalPages - 1);
            setTotalElements(response.totalElements);
          }

          success({
            rowData: prepareTableData(response.content as unknown as ICase[]),
            rowCount: response.content.length,
          });
          toggleAllCases();
          if (!GeneralUtils.isEmpty(filter) && !response.content.length) {
            setPageNo(0);
          }
          if (!pageNo && !response.content.length) {
            gridApi?.api.showNoRowsOverlay();
          } else {
            gridApi?.api.hideOverlay();
          }
          setNoRows(!response.content.length);

          keepGroupByTotalElements(response.groupKeyPagination);
        })
        .catch((error: string) => {
          addAlert({
            type: 'error',
            primaryText: error ?? '',
          });
          fail();
        })
        .finally(() => setDataLoading(false));
    },
    []
  );

  const debouncedFetchData = useDebouncedCallback(
    (params: IServerSideGetRowsParams, casePayload: IAgGridTblPayload) => {
      fetchData(params, casePayload);
    },
    500
  );

  const datasource: IServerSideDatasource = {
    getRows(params: IServerSideGetRowsParams) {
      const { request, success, fail } = params;
      const { filterModel, sortModel, rowGroupCols, groupKeys } = request;
      setGroupByParam(rowGroupCols);
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS,
        rowGroupCols
      );

      const filter = filterModel as FilterModel;
      if (filter) {
        for (const filterKey in filter) {
          if (filter[filterKey].filterType === 'date') {
            const matchingColumnConfig = columnsConfigs.find(
              (config) => config.attributeName === filterKey
            );
            if (matchingColumnConfig) {
              filter[filterKey].dateType = matchingColumnConfig.columnType;
            }

            const traverseAndConvert = (filterObj: any) => {
              for (const key in filterObj) {
                if (filterObj[key] === null) {
                  filterObj[key] = '';
                } else if (typeof filterObj[key] === 'object' && filterObj[key] !== null) {
                  traverseAndConvert(filterObj[key]);
                }
              }
            };

            if (Array.isArray(filter[filterKey].conditions)) {
              delete filter[filterKey].conditions;
            }
            traverseAndConvert(filter[filterKey]);
          }
        }
      }

      const casePayload: IAgGridTblPayload = {
        filterModel: filter,
        sortModel: sortModel,
        pageNumber: pageNo,
        pageSize: pageSize,
        rowGroupCols: rowGroupCols,
        groupKeys: groupKeys,
        assignee: userInfo.user,
        tenant: tenant,
        dateFormat: getDateFormatToGroupBy(rowGroupCols),
      };

      if (searchField) {
        filter.id = {
          filterType: typeof searchField,
          type: 'searchAllFields',
          filter: searchField,
        };
      } else if (!searchField && filter?.id?.type === 'searchAllFields') {
        if (filter.id) {
          delete filter.id;
        }
      }
      saveNewPayload(casePayload);

      setDataLoading(true);
      debouncedFetchData(params, casePayload);
    },
  };

  const prepareTableData = (content: Array<ICase>): Array<{ [key: string]: any }> => {
    const data: Array<{ [key: string]: any }> = [...content];
    content.forEach((row) => {
      row.columnConfigurations.forEach((col) => {
        (row as { [key: string]: any })[col.attributeName] = col.attributeValue;
      });
    });
    return data;
  };

  const getRemovedColumns = () => {
    const gridNode = gridRef.current as any;
    const displayedColumns = gridNode.columnApi.api.columnModel.displayedColumns;

    getColumnConfig(tenant, viewType).then((res: Array<IDataModelColumn>) => {
      const showMoreCol: IDataModelColumn = {
        attributeLabel: '',
        attributeValue: '',
        attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
        attributeType: '',
        columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
        listType: 'CASE_LIST',
      };
      const checkboxFirstCol: IDataModelColumn = {
        attributeLabel: '',
        attributeValue: '',
        attributeName: CONSTANTS.AG_GRID.COLUMNS.CHECKBOX,
        attributeType: '',
        columnType: CONSTANTS.COLUMNTYPES.TEXT,
        listType: 'CASE_LIST',
      };
      const columnsToAdd: Array<IDataModelColumn> = [];
      columnsToAdd.push(showMoreCol);
      columnsToAdd.push(checkboxFirstCol);
      res.forEach((column: IDataModelColumn) => {
        displayedColumns?.forEach((displayedColumn: any) => {
          if (column.attributeName === displayedColumn?.colId) {
            columnsToAdd.push(column);
          }
        });
      });
      setColumnsViews(columnsToAdd);
    });
  };

  const handleSelectAllCases = () => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG, !selectAllCases);
    setSelectAllCases(!selectAllCases);
    toggleAllCases();
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const id: string | undefined = params?.node?.data?.id;
    if (id) {
      return [
        {
          name: 'Open in a new tab',
          action: () => {
            window.open(`${CONSTANTS.PAGES_URL.CASE_DETAILS}/${id}`, '_blank');
          },
        },
        'separator',
        ...CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS,
      ];
    }
    return CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS;
  };

  const getSelectedGroup = (selectedItemCode: string) => {
    const id = groups.find((gr) => gr.itemCode === selectedItemCode)?.id;
    if (id) {
      setOpenModal(true);
      getDataModelItemById(id).then((group: IGroup) => {
        setSelectedGroup(group);
      });
    }
  };

  const saveNewPayload = (casePayload: IAgGridTblPayload) => {
    const payload = GeneralUtils.deepCopy(casePayload);

    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.CASE_LIST.PAYLOAD, payload);
  };

  const findNode = (nodes: IRowNode[], nodeId: string): IRowNode | undefined => {
    return nodes.find((node) => node.id === nodeId);
  };

  const getExpandedGroupKeysRecursive = (
    nodes: IRowNode[],
    expandedGroupKeys: string[],
    nodeId: string
  ) => {
    const node = findNode(nodes, nodeId);
    if (node?.key) {
      expandedGroupKeys.push(node.key);
    }

    const parentKey = node?.parent?.id;
    if (parentKey) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, parentKey);
    } else {
      return expandedGroupKeys;
    }
  };

  const getExpandedGroupKeys = (nodeId: string) => {
    const expandedGroupKeys: string[] = [];

    const nodes = gridApi?.api.getRenderedNodes();
    if (nodes) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, nodeId);
    }

    return expandedGroupKeys.reverse();
  };

  const getChildsCount = (node: any) => {
    return node.childStore.displayIndexEnd - node.childStore.displayIndexStart;
  };

  const increaseGroupKeyPagination = (key: string, childsCount: number): IGroupKeyPageRequest => {
    const pageNumber = Math.round(
      childsCount / LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE)
    );
    return {
      keyName: key,
      loadMoreRequest: pageNumber,
    };
  };

  const handleShowMoreClick = (key: string, childsCount: number, params: ICellRendererParams) => {
    if (params.node.id) {
      const groupKeys: string[] = getExpandedGroupKeys(params.node.id);
      const groupByParams: ColumnVO[] = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS
      );
      const payload: IAgGridTblPayload = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.CASE_LIST.PAYLOAD
      );

      if (payload && key) {
        const newPayload = structuredClone(payload);
        newPayload.groupKeys = groupKeys;
        newPayload.rowGroupCols = groupByParams;
        newPayload.groupKeyPageRequest = increaseGroupKeyPagination(key, childsCount);
        saveNewPayload(newPayload);
        setDataLoading(true);
        getCases(newPayload)
          .then((res) => {
            gridApi?.api?.applyServerSideTransaction({
              route: groupKeys,
              add: prepareTableData(res.content as unknown as ICase[]),
            });
          })
          .catch(() => {
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          })
          .finally(() => setDataLoading(false));
      }
    }
  };

  return (
    <div className="custom-container">
      <Grid container spacing={2} className="padding-top-40" justifyContent="space-between">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }} mb={1}>
          {t('CASES')}
        </Typography>

        {GeneralUtils.checkUserPermissions(PermissionTypes.CREATE_CASE) && (
          <FormControl variant="standard" sx={{ minWidth: 170, mb: 1 }} size="small">
            <InputLabel className="color-black">
              <div className="create-case-btn">
                <AddIcon className="color-blue-200" />
                <div className="btn">{t('CREATE_NEW')}</div>
              </div>
            </InputLabel>
            <Select
              label={t('CREATE_NEW')}
              value={selectedGroup?.itemCode ?? ''}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              onChange={(e: SelectChangeEvent<string>) => getSelectedGroup(e.target.value)}>
              {caseCreationLoading ? (
                <Loader />
              ) : (
                groups?.map((group) => {
                  return (
                    <MenuItem
                      key={group.itemCode}
                      className="create-case-select color-black"
                      value={group.itemCode}>
                      {group.description}
                    </MenuItem>
                  );
                })
              )}
            </Select>
          </FormControl>
        )}

        <Paper variant="outlined" className="transaction-paper full-width">
          <div className="center-aligned-space-between padding-left-right-10">
            <Stack spacing={2} direction="row" className="center-aligned">
              <FormControlLabel
                control={
                  <CheckboxComponent checked={selectAllCases} onClick={handleSelectAllCases} />
                }
                label={<span className="label bold-font">{t('SELECT_ALL_CASES')}</span>}
              />
              {payload && gridApi && (
                <Views
                  payload={payload}
                  columnsViews={columnsViews}
                  defaultColumns={columnsConfigs}
                  onSelectView={handleSelectView}
                  tenant={tenant}
                  type={viewType}
                  gridApi={gridApi}
                />
              )}
            </Stack>
            <Stack spacing={2} direction="row" className="center-aligned">
              {canRTJRequest && (
                <RequestToJoin caseIds={gridSelectedRows.map((row) => row.caseId)} />
              )}
              <Box className="label bold-font search-box margin-bottom-10">
                <TextFieldComponent
                  placeholder={t('SEARCH_3_DOTS')}
                  type="text"
                  value={payload && searchField ? searchField : ''}
                  setData={(value: string) => onFilterTextBoxChanged(value)}
                  icon={<SearchIcon />}
                />
              </Box>
              {payload && (
                <ExportComponent
                  nowRows={noRows}
                  viewType={viewType}
                  rows={gridSelectedRows}
                  payload={payload}
                  selectedAll={selectAllCases}
                  currentCols={displayedColumns
                    ?.filter(
                      (col: Column) =>
                        col.getColId() != CONSTANTS.AG_GRID.COLUMNS.CHECKBOX &&
                        col.getColId() != CONSTANTS.AG_GRID.COLUMNS.GROUP_BY
                    )
                    ?.map((col: Column) => {
                      const columnType = columnsConfigs.find(
                        (colConfig) => colConfig.attributeName === col.getColId()
                      )?.columnType;

                      if (columnType) {
                        return {
                          id: col.getColId(),
                          field: col.getColId(),
                          displayName: col.getUserProvidedColDef()?.headerName ?? '',
                          columnType,
                        };
                      }
                    })}
                />
              )}
              <Reset
                searchField={searchField}
                setSearchField={setSearchField}
                setDefaultView={setDefaultView}
                setColumnSorting={setColumnSorting}
                setColumnGroup={setColumnGroup}
                setColumnFiltering={setColumnFiltering}
                setSelectAllChecked={setSelectAllChecked}
                setResetColumns={setResetColumns}
                defaultView={defaultView}
                columnFiltering={columnFiltering}
                columnSorting={columnSorting}
                columnGroup={columnGroup}
                selectAllChecked={selectAllChecked}
                resetColumns={resetColumns}
                grid={gridRef}
                api={gridApi}
                columnsConfigs={columnsConfigs}
                displayedColumns={displayedColumns ?? []}
              />
            </Stack>
          </div>
        </Paper>
        <Box width="100%">
          <ErrorBoundary history={history} params={params}>
            <div className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                gridOptions={gridOptions}
                defaultColDef={CONSTANTS.AG_GRID.DEFAULT_COL_DEF}
                isGroupOpenByDefault={(params) => {
                  return groupKeys.includes(params.key);
                }}
                rowGroupPanelShow="always"
                rowSelection="multiple"
                domLayout="autoHeight"
                rowModelType="serverSide"
                suppressRowClickSelection={true}
                suppressServerSideInfiniteScroll={false}
                animateRows={true}
                columnDefs={columns}
                groupSelectsChildren={!!searchField}
                onGridReady={onGridReady}
                getRowClass={getRowClass}
                onRowSelected={onRowSelected}
                onFilterChanged={onFilterChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onRowGroupOpened={onRowGroupOpened}
                onColumnMoved={columnsMoved}
                onColumnVisible={getRemovedColumns}
                getContextMenuItems={getContextMenuItems}
                onPaginationChanged={() => {
                  if (!selectAllCases) {
                    gridApi?.api.deselectAll();
                    setGridSelectedRows([]);
                  }
                }}
                paginationPageSize={pageSize}
                paginationAutoPageSize={false}
                cacheBlockSize={0}
              />
              <Pagination
                pageSize={pageSize}
                pageNo={pageNo}
                totalPages={totalPages}
                totalElements={totalElements}
                setPageSize={setPageSize}
                setPageNo={setPageNo}
                dataLoading={dataLoading}
              />
            </div>
          </ErrorBoundary>
        </Box>
      </Grid>
      <ErrorBoundary history={history} params={params}>
        {openModal && (
          <CreateCaseModal
            isOpen={openModal}
            group={selectedGroup}
            tenant={tenant}
            closeDlg={() => {
              setOpenModal(false);
              setSelectedGroup(undefined);
            }}
            reloadGrid={reloadGrid}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default CaseList;

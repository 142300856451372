import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, Grid, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import { IRefData } from 'fso-fincrime-sdk-ui';
import QuestionTooltip from '../QuestionTooltip';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import SpelUtils from '../../../../utils/spelUtils';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import IQuestionType from '../../../../utils/entities/IQuestionType';
import DynamicQuestionnaireUtils from '../../../../utils/functions/DynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion, { IValue } from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';

const DropdownQuestion = (props: IQuestionType) => {
  const {
    question,
    updateValue,
    updateLocals,
    disabled,
    locals,
    contextLocals,
    entity,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;
  const { data, updatedRefreshData } = useIScreen();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const { t } = useTranslation();
  const storedValue: string | undefined = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  );
  const oldValue = data?.dynamicQuestionnaire?.[activeTabId!]?.sets[0].questions.find(
    (currentQuestion: IQuestion) => currentQuestion.id == question.id
  );
  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };
  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    context
  );

  const [value, setValue] = useState<string>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
  );
  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value?.toString()
    );
    const valueArray = [value?.toString()];
    updateValue(question.attributeName, valueArray);

    locals[question?.attributeName] = value;

    updateLocals(locals);

    if (value) {
      setRuntimeValidationError(
        DynamicQuestionnaireUtils.hasRuntimeError(question.validation, value, {
          ...context,
          [question.attributeName]: value,
        })
      );
    }
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
    );
  }, [entity]);

  useEffect(() => {
    if (question.refresh) {
      setValue(question.value[entity][0]);
    }
  }, [question.refresh]);

  const onChangeHandler = (e: SelectChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, context) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <span>
                {question.label}
                {requiredResult ? '*' : ''}
              </span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title={value} PopperProps={{ style: { zIndex: 0 } }}>
                <FormControl fullWidth margin="normal">
                  <Select
                    id={'question-' + question.id}
                    data-testid="select"
                    variant="standard"
                    size="small"
                    displayEmpty
                    fullWidth
                    value={value}
                    onChange={(event: SelectChangeEvent) => onChangeHandler(event)}
                    required={requiredResult}
                    disabled={
                      disabled
                        ? disabled
                        : SpelUtils.expressionValidation(
                            question.readOnly,
                            question.readOnlyExpression,
                            context
                          )
                    }>
                    <MenuItem value="">{t('SELECT_OPTION')}</MenuItem>
                    {question.responseOptions.map((opt: IRefData) => (
                      <MenuItem data-testid={opt.value} key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>

              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={value} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default DropdownQuestion;

import { Divider } from '@mui/material';
import React from 'react';
import './VerticalDivider.scss';

const VerticalDivider = () => {
  return (
    <div className="inline-header divider-size">
      <Divider orientation="vertical" />
    </div>
  );
};

export default VerticalDivider;

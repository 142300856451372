import React from 'react';
import IQuestion from '../../../../utils/entities/screen/IQuestion';
import Question from '../questions/Question';
import IContextLocals from '../../../../utils/entities/screen/IContextLocals';
import IScreen from '../../../../utils/entities/screen/IScreen';
import { useIScreen } from '../../../../pages/task/Task';
import IContextLocalsEntity from '../../../../utils/entities/screen/IContextLocalsEntity';

const DefaultView = (props: {
  contextLocals: IContextLocals;
  requestBody: IScreen;
  assignee: string | undefined;
  loggedUser: string | undefined;
  selectedEntity: string;
  disabled: boolean;
  locals: IContextLocalsEntity;
  updatedLocals: (newContextLocals: IContextLocals) => void;
  updateValue: (attribute: string, value: Array<string>) => void;
  updateHeader: () => void;
  activeTabId: string;
  setIndex: number;
}) => {
  const {
    contextLocals,
    requestBody,
    assignee,
    loggedUser,
    selectedEntity,
    disabled,
    locals,
    updatedLocals,
    updateValue,
    updateHeader,
    activeTabId,
    setIndex,
  } = props;

  const { questionErrorsState, updatedBody } = useIScreen();

  return (
    <>
      {contextLocals != null &&
        selectedEntity &&
        (updatedBody ? updatedBody : requestBody)?.dynamicQuestionnaire[activeTabId].sets[
          setIndex
        ].questions.map((q: IQuestion, indexQuestion: number) => (
          <Question
            assignee={assignee}
            loggedUser={loggedUser}
            key={q.id}
            question={q}
            entity={selectedEntity}
            disabled={disabled}
            locals={locals}
            contextLocals={contextLocals}
            updateLocals={updatedLocals}
            updateValue={updateValue}
            updateHeader={updateHeader}
            error={questionErrorsState.get(q.id)}
            indexQuestion={indexQuestion}
            requestBody={requestBody}
            activeTabId={activeTabId}
            setIndex={setIndex}
          />
        ))}
    </>
  );
};

export default DefaultView;

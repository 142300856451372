import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIScreen } from '../../../pages/task/Task';
import DefaultModal from '../../generic/modal/Modal';
import { dueDiligenceRemoveParty } from '../../../service/due-diligence.service';
import { IAddPartyBody } from '../../../utils/entities/screen/IAddPartyBody';
import { ICaseParty } from '../../../utils/entities/dueDiligence/ICaseParty';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const PartySelection = (props: {
  setTemplateError: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplateDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddParty: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  allParties: Array<ICaseParty> | undefined;
  setAllParties: React.Dispatch<React.SetStateAction<ICaseParty[] | undefined>>;
  newSelectedParty: ICaseParty | undefined;
  setNewSelectedParty: React.Dispatch<React.SetStateAction<ICaseParty | undefined>>;
}) => {
  const {
    setTemplateError,
    setTemplateDeleted,
    setOpenAddParty,
    disabled,
    allParties,
    setAllParties,
    setNewSelectedParty,
    newSelectedParty,
  } = props;
  const { data } = useIScreen();
  const { t } = useTranslation();
  const [party, setParty] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteParty, setDeleteParty] = useState<string | undefined>();

  const handleChange = (event: SelectChangeEvent) => {
    setParty(event.target.value);
    setNewSelectedParty(
      allParties?.find((party) => party.searchAttribute.value == event.target.value)
    );
  };

  const formatedOption = (ele: ICaseParty) => {
    return (
      <div>
        <div>{ele.searchAttribute.value}</div>
      </div>
    );
  };

  const deleteTemplate = (party: string | undefined) => {
    if (party) {
      dueDiligenceRemoveParty({ caseId: data.caseId, party: party })
        .then((template: IAddPartyBody) => {
          setTemplateDeleted(true);
        })
        .catch((error: string) => {
          if (error) {
            setTemplateError(true);
          }
        });
      setAllParties(allParties);
    }
  };

  return (
    <Grid container className="party-selection">
      <DefaultModal
        open={showDeleteModal}
        title={t('DD.DELETE_PARTY')}
        body={t('DD.CONFIRM_DELETE_PARTY')}
        setConfirm={() => {
          deleteTemplate(deleteParty);
          setShowDeleteModal(false);
        }}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        confirmText={t('CONFIRM')}
        denyText={t('CANCEL')}
      />
      <FormControl sx={{ width: '74%' }} variant="standard" className="party-dropdown">
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={newSelectedParty ? newSelectedParty?.searchAttribute.value : ''}
          onChange={handleChange}
          renderValue={(p) => p}
          disabled={disabled}>
          <MenuItem value=""></MenuItem>
          {allParties?.map((ele: ICaseParty, index: number) => {
            return (
              ele.searchAttribute.value && (
                <MenuItem
                  key={index}
                  value={ele.searchAttribute.value}
                  id="center-aligned-space-between">
                  {formatedOption(ele)}
                  {ele.origin == 'CUSTOM' ? (
                    <div className="delete-button">
                      <IconButton
                        className="delete-link link"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteParty(ele.searchAttribute.value);
                          setShowDeleteModal(true);
                          ele.searchAttribute.value === newSelectedParty?.searchAttribute.value
                            ? setNewSelectedParty(undefined)
                            : '';
                        }}>
                        <DeleteOutlineIcon className="dark-icon-default" />
                      </IconButton>
                    </div>
                  ) : null}
                </MenuItem>
              )
            );
          })}
        </Select>
      </FormControl>
      <Button onClick={() => setOpenAddParty(true)} className="party-popup add-party-button">
        {t('DD.ADD_PARTY')}
      </Button>
    </Grid>
  );
};

export default PartySelection;

import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBannerProps } from '../../../utils/entities/narrative/INarravtiveProps';
import CloseIcon from '@mui/icons-material/Close';

const Banner = (props: IBannerProps) => {
  const { t } = useTranslation();

  return (
    <Grid container className="banner-container">
      <Grid item xs={8} className="restore-text">
        {t('NARRATIVE_BANNER')}
      </Grid>
      <Grid item xs={4} className="restore-buttons">
        <Button className="banner-default-button" onClick={() => props.setRestoreModal(true)}>
          {t('RESTORE')}
        </Button>
        <Button className="banner-close" onClick={() => props.setBanner(false)}>
          <CloseIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Banner;

import React, { useState } from 'react';
import { Badge } from '@mui/material';
import INotificationBadgeProps from '../../../../utils/entities/genericComponents/badge/INotificationBadge';
import IconComponent from '../../button/IconComponent';
import './NotificationBadge.scss';

const NotificationBadge = ({ icon, badgeContent }: INotificationBadgeProps) => {
  return (
    <Badge badgeContent={badgeContent} className="notification-badge">
      <IconComponent icon={icon} />
    </Badge>
  );
};

export default NotificationBadge;

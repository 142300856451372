import React, { useContext } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IDateProps } from '../../../../utils/entities/genericComponents/IDateProps';
import dayjs from 'dayjs';
import './DatePickerComponent.scss';
import GenericContext from '../../timer/GenericContext';
import dateUtils from '../../../../utils/dateUtils';

const DatePickerComponent = (props: IDateProps) => {
  const {
    defaultValue,
    className,
    disabled,
    onChange,
    required,
    disablePast,
    label,
    fullWidth,
    actionsBar,
  } = {
    ...props,
  };
  const { dateFormat } = useContext(GenericContext);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={defaultValue ? dayjs(defaultValue) : null}
        label={label}
        disabled={disabled}
        disablePast={disablePast}
        onChange={onChange}
        className={`date-picker ${className ?? ''} ${fullWidth ? 'full-width' : ''}`}
        slotProps={{
          textField: {
            variant: 'standard',
            required: required,
          },
          actionBar: {
            actions: actionsBar,
          },
        }}
        format={dateUtils.getDateFormatFromDateFormat(dateFormat)}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;

import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosConfig, { axiosConfigAuth } from './axios.config';
import IToken from '../utils/entities/authentication/IToken';
import serviceUtils from '../utils/serviceUtils';

export const authenticateUser = (email: string): Promise<IToken> =>
  axios
    .post(`/token/authenticate`, { email: email }, axiosConfigAuth)
    .then((res: AxiosResponse<IToken>) => {
      return serviceUtils.processSuccess<IToken>(res);
    })
    .catch((error: AxiosError | string) => {
      if (axios.isAxiosError(error)) {
        throw error.message;
      } else {
        throw error;
      }
    });

/**
 * This API method is responsible to swith the user role
 * @param role
 * @returns
 */
export const revalidateUserRole = (role: string): Promise<IToken> => {
  return axios
    .patch(`/revalidateUserRole?role=${encodeURIComponent(role)}`, {}, axiosConfig)
    .then((res: AxiosResponse<IToken>) => {
      return serviceUtils.processSuccess<IToken>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

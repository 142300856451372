import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldComponent from '../../components/generic/inputs/textField/TextFieldComponent';
import { IPageListItemProps } from '../../utils/entities/screen/IAboutPage';
import ChildPages from './ChildPages';

const PageListItem = (props: IPageListItemProps) => {
  const { page, level, editMode, selectedPageId, expandList, loadPage, setNewPageName } = props;
  const { t } = useTranslation();

  page.open = page.open ?? true;

  return (
    <>
      <ListItem
        disablePadding
        sx={{
          margin: 0,
          cursor: 'pointer',
          pl: level * 4,
        }}>
        <ListItemButton
          disableGutters
          autoFocus={page.pageId === selectedPageId}
          onClick={() => (page.pageId != 0 ? loadPage(page.pageId) : null)}>
          {page.pageId != 0 ? (
            <ListItemText
              primaryTypographyProps={{
                className: `entity-el ellipsis ${
                  page.pageId === selectedPageId ? 'font-nest-display' : ''
                }`,
              }}
              primary={page.pageName}
            />
          ) : (
            <TextFieldComponent
              value={page.pageName}
              placeholder={t('TITLE')}
              type="text"
              setData={(newName: string) => setNewPageName(newName)}
            />
          )}
        </ListItemButton>
        {page.children.length > 0 && (
          <IconButton onClick={() => expandList(page.pageId)}>
            {page.open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      {page.children.length > 0 && (
        <ChildPages
          page={page}
          level={level}
          editMode={editMode}
          selectedPageId={selectedPageId}
          expandList={expandList}
          loadPage={loadPage}
          setNewPageName={setNewPageName}
        />
      )}
    </>
  );
};

export default PageListItem;

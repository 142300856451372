import { Dialog, DialogTitle, Box, DialogContent, DialogActions, IconButton } from '@mui/material';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { addDocumentToCase, sendDataBlock } from '../../../../service/data-block-builder.service';
import CaptureMetadata from './CaptureMetadata';
import { IMetadata } from '../../../../utils/entities/IMetadata';
import IFileUrl from '../../../../utils/entities/IFileUrl';
import { IDataBlock } from '../../../../utils/entities/dataBlock/IDataBlock';
import { addAlert } from '../../../../store/actions/alerts.actions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import IEvidenceDocument from '../../../../utils/entities/case/IEvidenceDocument';
import AddOrDragDrop from './AddOrDragDrop';
import CaptureDocumentInfo from './capture-document-info/CaptureDocumentInfo';
import IFile from '../../../../utils/entities/document/IFile';
import IDocumentMetadata from '../../../../utils/entities/case/IDocumentMetadata';
import { IDocumentDataBlockGroup } from '../../../../utils/entities/dataBlock/IDocumentDataBlock';
import { useMsal } from '@azure/msal-react';
import ISimpleAttribute from '../../../../utils/entities/case/ISimpleAttribute';
import { IFileUploadDialogProps } from '../../../../utils/entities/document/IDocumentsPanel';
import { addCorrelationId } from '../../../../service/task-manager.service';
import PrimaryButton from '../../../generic/buttons/PrimaryButton';
import { documentActionType } from '../../../../types/document/documentAction';

const FileUploadDialog = ({
  caseId,
  hasAssignee,
  tenant,
  fileType,
  maxFileSize,
  addFileTrigger,
  setShowLoader,
  setDocumentList,
  isFileDialogOpen,
  setIsFileDialogOpen,
  documentList,
  documentListIdxFileToEdit,
  handleUpdateDocuments,
  editMode,
  setEditMode,
  documentConfigurations,
  getDocuments,
  guidItemAttr,
  enableSaveSync,
  taskId,
  caseDisplayId,
  isVerificationProcess,
  questionToVerify,
  currentSet,
  selectedEntity,
}: IFileUploadDialogProps) => {
  const { accounts } = useMsal();

  const [fileUrls, setFileUrls] = useState<Array<IFileUrl>>([]);
  const [scanLoader, setScanLoader] = useState<boolean>(false);
  const [successDoCount, setSuccessDoCount] = useState<number>(0);
  const [selectedFiles, setSelectedFiles] = useState<Array<IFile>>([]);
  const [selectedFilesIdxFileToEdit, setSelectedFilesIdxFileToEdit] = useState<number | undefined>(
    undefined
  );
  const [scanFileRes, setScanFileRes] = useState<Array<boolean>>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<Array<IMetadata>>([]);
  const [previousEditMode, setPreviousEditMode] = useState<documentActionType>(editMode);

  useEffect(() => {
    scanChecker();
  }, [scanFileRes]);

  useEffect(() => {
    const evidenceDocument = documentList.find(
      (doc: IEvidenceDocument, idx: number) => idx === documentListIdxFileToEdit
    );
    if (editMode === 'edit' && evidenceDocument) {
      const files = [evidenceDocumentToFile(evidenceDocument)];
      setSelectedFiles([...files]);
    }
  }, []);

  const evidenceDocumentToFile = (evidenceDocument: IEvidenceDocument): IFile => {
    return {
      name: (evidenceDocument.metadata as IDocumentMetadata).documentOriginalName,
      documentName: (evidenceDocument.metadata as IDocumentMetadata).documentName,
      documentType: (evidenceDocument.metadata as IDocumentMetadata).documentType,
      documentSubType: (evidenceDocument.metadata as IDocumentMetadata).documentSubType,
      expirationDate: (evidenceDocument.metadata as IDocumentMetadata).expirationDate,
      uploadedBy: (evidenceDocument.metadata as IDocumentMetadata).uploadedBy,
      active: (evidenceDocument.metadata as IDocumentMetadata).active,
    };
  };

  const fileToEvidenceDocument = (file: IFile): IEvidenceDocument => {
    return {
      guid: documentList[documentListIdxFileToEdit as number].guid,
      issuedDate: documentList[documentListIdxFileToEdit as number].issuedDate,
      documentDescription: documentList[documentListIdxFileToEdit as number].documentDescription,
      metadata: {
        delete: false,
        active: file.active ?? false,
        documentGUID: documentList[documentListIdxFileToEdit as number].guid,
        caseId: documentList[documentListIdxFileToEdit as number]?.metadata!.caseId,
        documentOriginalName: file.name,
        documentType: file.documentType ?? '',
        documentSubType: file.documentSubType ?? '',
        expirationDate: file.expirationDate ?? 0,
        ingestionDate: documentList[documentListIdxFileToEdit as number].issuedDate,
        documentPath: documentList[documentListIdxFileToEdit as number]?.metadata!.documentPath,
        md5Hash: documentList[documentListIdxFileToEdit as number]?.metadata?.md5Hash,
        documentName: file.documentName,
        content: documentList[documentListIdxFileToEdit as number]?.metadata!.content,
        tags: documentList[documentListIdxFileToEdit as number]?.metadata!.tags,
        uploadedBy: accounts[0]?.username, // TODO: this should refactored to uploadedBy, modifiedBy, modifiedDate
      },
    };
  };

  const formatFileSize = () => {
    if (maxFileSize === 0) return '0 ';

    const i = Math.floor(
      Math.log(maxFileSize as number) / Math.log(CONSTANTS.DOC_CENTER.BYTE_TRESHOLD)
    );
    return (
      Math.round(
        parseFloat(
          ((maxFileSize as number) / Math.pow(CONSTANTS.DOC_CENTER.BYTE_TRESHOLD, i)).toFixed(
            CONSTANTS.DOC_CENTER.DECIMALS
          )
        )
      ) + `${CONSTANTS.FILE_SIZE[i]}`
    );
  };

  const addFile = (promiseList: Promise<string | null>[]) => {
    Promise.all(promiseList)
      .then((results) => {
        results?.forEach((result) => {
          if (result) {
            addAlert({
              type: 'success',
              primaryText: t('DOCUMENT_ADDED'),
            });
          }
        });
      })
      .finally(() => {
        getDocuments(caseId);
      });
  };

  const handleUploadFiles = () => {
    switch (editMode) {
      case 'upload':
        uploadFiles();
        break;
      case 'edit':
        documentList[documentListIdxFileToEdit as number] = fileToEvidenceDocument(
          selectedFiles[0]
        );
        setDocumentList([...documentList]);
        handleUpdateDocuments();
        break;
    }

    closeDialog();
  };

  const uploadFiles = () => {
    setShowLoader(true);
    const promiseList = selectedFiles.map((file: IFile | any) => {
      let correlationId: string | undefined;
      if (enableSaveSync) {
        correlationId = window.crypto.randomUUID();
        addCorrelationId(taskId, correlationId);
      }

      return addDocumentToCase(
        file,
        caseId,
        file.name,
        tenant,
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME),
        file.expirationDate ?? 0,
        correlationId,
        file.documentName,
        file.documentType,
        file.documentSubType,
        file.uploadedBy,
        file?.dataBlock,
        caseDisplayId
      )
        .then((res) => {
          return res;
        })
        .catch((error: string) => {
          addAlert({
            type: 'error',
            primaryText: error ?? '',
          });
          return null;
        });
    });
    addFile(promiseList);
  };

  const saveMetadata = (idx: number, metadata: IMetadata[]) => {
    selectedFiles[idx]['dataBlock'] = convertMetadataToDataBlock(metadata, selectedFiles[idx]);
    setSelectedFiles([...selectedFiles]);
  };

  const uploadMetadata = () => {
    if (selectedFilesIdxFileToEdit != null && selectedFilesIdxFileToEdit >= 0) {
      saveMetadata(selectedFilesIdxFileToEdit, metadata);

      if (previousEditMode === 'edit') {
        setShowLoader(true);
        const dataBlock = convertMetadataToDataBlock(
          metadata,
          selectedFiles[selectedFilesIdxFileToEdit],
          documentList[documentListIdxFileToEdit as number]
        );
        if (dataBlock) {
          sendDataBlock(dataBlock)
            .then(() => addAlert({ type: 'success', primaryText: t('DOC_CENTER.DOC_DATA_SAVED') }))
            .catch((error) => addAlert({ type: 'success', primaryText: error }))
            .finally(() => setShowLoader(false));
        } else {
          addAlert({ type: 'error', primaryText: t('ERROR') });
        }
      }

      setEditMode(previousEditMode);
    }
  };

  const convertMetadataToDataBlock = (
    metadata: Array<IMetadata>,
    file: IFile,
    evidenceDocument?: IEvidenceDocument
  ) => {
    let dataBlock: IDataBlock | undefined = undefined;
    const groups: Array<IDocumentDataBlockGroup> = [];
    const bks =
      currentSet?.businessKeys.find((bk) => bk.entityId === selectedEntity)?.businessKeys ?? [];

    for (const gr of metadata) {
      for (const lines of gr.lines.flat(1)) {
        const attrs: Array<ISimpleAttribute> = [];
        // each metadata line will be a new group
        for (const attr of lines.line) {
          if (
            (attr.value == null || attr.value.length === 0) &&
            (attr.values == null || attr.values.length === 0)
          ) {
            continue;
          }

          attrs.push({
            name: attr.name,
            value: attr.value ?? '',
            values: attr.values,
          });
        }

        if (attrs.length === 0) {
          continue;
        }

        //if is verification process, add the related entity to the group.
        if (isVerificationProcess) {
          groups.push({
            groupCode: gr.code,
            groupType: '*',
            relatedEntity: {
              entityType: currentSet?.entitySubType ?? '',
              businessKeys: bks,
              caseAttributes: null,
            },
            attributes: attrs,
          });
        } else {
          groups.push({
            groupCode: gr.code,
            groupType: '*',
            attributes: attrs,
          });
        }
      }
    }

    if (isVerificationProcess) {
      // TODO: Comment necessary to debug, delete after issue is fix
      console.log({
        questionToVerify,
        selectedEntity,
        value: questionToVerify?.value[selectedEntity ?? ''][0],
      });

      const caseAttributes: ISimpleAttribute[] = [
        {
          name: questionToVerify?.attributeName ?? '',
          value: questionToVerify?.value[selectedEntity ?? ''][0] ?? '',
          values: null,
        },
        ...bks.flatMap((bk) => bk),
      ];

      groups.push({
        groupCode: 'MASTER',
        groupType: '*',
        relatedEntity: {
          entityType: currentSet?.entitySubType ?? '',
          businessKeys: bks,
          caseAttributes: caseAttributes,
        },
      });
    }

    if (groups.length > 0) {
      dataBlock = {
        tenant: tenant,
        caseId: caseId,
        source: {
          name: (file?.documentName?.length ?? 0) > 0 ? (file.documentName as string) : file.name,
          type: file.documentSubType ?? '',
          category: file.documentType ?? '',
          evidenceDocument: evidenceDocument,
          ingestionDate: evidenceDocument?.issuedDate,
        },
        documentData: {
          groups: groups,
        },
      };
    }

    return dataBlock;
  };

  const scanChecker = () => {
    setSuccessDoCount(scanFileRes.filter((passed) => passed).length);
  };

  const getTitle = () => {
    switch (editMode) {
      case 'capture_metadata':
        return t('DOC_CENTER.CAPTURE_METADATA');
      case 'edit':
        return t('DOC_CENTER.EDIT_DOC');
      case 'upload':
        return t('DOC_CENTER.UPLOAD');
    }
  };

  const closeDialog = () => {
    setSelectedFiles([]);
    setIsFileDialogOpen(false);
    setEditMode(undefined);
  };

  const checkDocumentName = (file: IFile) => {
    return !(
      !file.documentName?.trim() &&
      documentConfigurations.find((config) => config.field === 'documentName')?.required
    );
  };

  const checkExpirationDate = (file: IFile) => {
    return !(
      !file.expirationDate &&
      documentConfigurations.find((config) => config.field === 'expirationDate')?.required
    );
  };

  const checkName = (file: IFile) => {
    return file.name;
  };

  const checkDocumentType = (file: IFile) => {
    return !(
      (!file.documentSubType || file.documentSubType === 'None') &&
      documentConfigurations.find((configs) => configs.field === 'documentType')?.required
    );
  };

  const handleRequiredFields = (files: Array<IFile>) => {
    let check: boolean = true;
    for (const file of files) {
      if (
        !(
          checkDocumentName(file) &&
          checkExpirationDate(file) &&
          checkName(file) &&
          checkDocumentType(file)
        )
      ) {
        check = false;
      }
    }
    return check;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={isFileDialogOpen}
      onClose={() => closeDialog()}
      aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{getTitle()}</Box>
          <Box>
            <IconButton onClick={() => closeDialog()}>
              <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className="Content-style">
        {editMode === 'upload' && (
          <div>
            <AddOrDragDrop
              hasAssignee={hasAssignee}
              setScanLoader={setScanLoader}
              fileUrls={fileUrls}
              setFileUrls={setFileUrls}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setScanFileRes={setScanFileRes}
              addFileTrigger={addFileTrigger}
            />
            <div>
              <InfoIcon />
              <span className="margin-left-10" style={{ verticalAlign: 'super' }}>
                {t('DOC_CENTER.INFORM', {
                  num: formatFileSize(),
                  format: fileType?.map((type) => ' ' + type.toUpperCase()),
                })}
              </span>
            </div>
          </div>
        )}
        {(editMode === 'upload' || editMode === 'edit') && selectedFiles.length > 0 && (
          <div>
            <CaptureDocumentInfo
              guidItemAttr={guidItemAttr}
              editMode={editMode}
              setEditMode={setEditMode}
              documentList={documentList}
              scanFileRes={scanFileRes}
              setScanFileRes={setScanFileRes}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              fileUrls={fileUrls}
              scanLoader={scanLoader}
              setMetadata={setMetadata}
              setSelectedFilesIdxFileToEdit={setSelectedFilesIdxFileToEdit}
              caseId={caseId}
              setPreviousEditMode={setPreviousEditMode}
              setFileUrls={setFileUrls}
              documentListIdxFileToEdit={documentListIdxFileToEdit}
              saveMetadata={saveMetadata}
              documentConfigurations={documentConfigurations}
              tenant={tenant}
              isVerificationProcess={isVerificationProcess}
              entitySubtype={currentSet?.entitySubType}
              attributeNameToVerify={questionToVerify?.attributeName}
            />
          </div>
        )}
        {editMode === 'capture_metadata' &&
          selectedFilesIdxFileToEdit != null &&
          selectedFilesIdxFileToEdit >= 0 && (
            <CaptureMetadata
              fileUrl={fileUrls[selectedFilesIdxFileToEdit]}
              metadata={metadata}
              setMetadata={setMetadata}
              setIsSaveDisabled={setIsSaveDisabled}
              documentConfigurations={documentConfigurations}
            />
          )}
      </DialogContent>
      <DialogActions>
        <>
          {(editMode === 'upload' || editMode === 'edit') && (
            <>
              <PrimaryButton
                isPositiveButton
                text={t('DOC_CENTER.UPLOAD')}
                onClick={handleUploadFiles}
                isDisabled={
                  !(
                    handleRequiredFields(selectedFiles) &&
                    !(editMode === 'upload' &&
                    LocalStorageUtils.getSavedItem(
                      CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED
                    )
                      ? !(selectedFiles.length == successDoCount && selectedFiles.length)
                      : !selectedFiles.length)
                  )
                }
              />
              <PrimaryButton text={t('CANCEL')} onClick={closeDialog} />
            </>
          )}
          {editMode === 'capture_metadata' && (
            <>
              <PrimaryButton
                isPositiveButton
                text={t('SAVE')}
                onClick={uploadMetadata}
                isDisabled={isSaveDisabled}
              />
              <PrimaryButton
                text={t('GO_BACK')}
                onClick={() =>
                  previousEditMode ? setEditMode(previousEditMode) : setIsSaveDisabled(false)
                }
              />
            </>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;

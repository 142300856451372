import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  InputLabel,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import QuestionTooltip from '../QuestionTooltip';
import { IRefData } from 'fso-fincrime-sdk-ui';
import SpelUtils from '../../../../utils/spelUtils';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';
import IAccessToken from '../../../../utils/entities/authentication/IAccessToken';
import IQuestionType from '../../../../utils/entities/IQuestionType';
import { useTranslation } from 'react-i18next';
import DynamicQuestionnaireUtils from '../../../../utils/functions/DynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion, { IValue } from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';

const TypeAheadMultiSelectQuestion = (props: IQuestionType) => {
  const {
    question,
    updateValue,
    updateLocals,
    disabled,
    locals,
    contextLocals,
    multiline,
    entity,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;
  const { data, updatedRefreshData } = useIScreen();
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };
  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    context
  );
  const storedValueStrings: string[] | undefined = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  )?.split(',');

  const oldValue = data?.dynamicQuestionnaire?.[activeTabId!]?.sets[0].questions.find(
    (currentQuestion: IQuestion) => currentQuestion.id == question.id
  );

  const getRefData = (array: string[] | undefined): IRefData[] | undefined => {
    if (!array || array.length == 0 || array[0] == '') {
      return [{ value: '', label: '' }];
    }
    return array?.map((ls: string) =>
      question.responseOptions.find((r: IRefData) => r.value == ls)
    ) as IRefData[];
  };
  const storedValue = getRefData(storedValueStrings);

  const [runtimeValidationError, setRuntimeValidationError] = useState(false);
  const [value, setValue] = useState<Array<IRefData>>(
    storedValue
      ? storedValue || [{ value: '', label: '' }]
      : Object.keys(question.value).includes(entity)
        ? question.value[entity].length != 0
          ? getRefData(question.value[entity]) || [{ value: '', label: '' }]
          : [{ value: '', label: '' }]
        : [{ value: '', label: '' }]
  );

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value.map((v: IRefData) => v.value)?.toString()
    );
    const valueArray = [...value];
    updateValue(question.attributeName, valueArray);

    locals[question.attributeName] = value?.toString();

    updateLocals(locals);

    if (value) {
      for (const val of value) {
        if (
          DynamicQuestionnaireUtils.hasRuntimeError(question.validation, val.label, {
            ...context,
            [question.attributeName]: val.label,
          })
        ) {
          setRuntimeValidationError(true);
          break;
        }
      }
    }
  }, [value]);

  useEffect(() => {
    setValue(
      storedValue
        ? storedValue || [{ value: '', label: '' }]
        : Object.keys(question.value).includes(entity)
          ? question.value[entity].length != 0
            ? getRefData(question.value[entity]) || [{ value: '', label: '' }]
            : [{ value: '', label: '' }]
          : [{ value: '', label: '' }]
    );
  }, [entity]);

  useEffect(() => {
    if (question.refresh) {
      setValue(getRefData(question.value[entity]) || [{ value: '', label: '' }]);
    }
  }, [question.refresh]);

  const onChangeHandler = (event: any, newValue: IRefData[]) => {
    if (typeof newValue == 'object') {
      newValue = newValue.filter((v: IRefData) => v.value != '');
    }
    setValue(newValue);
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      newValue.map((v: IRefData) => v.value)?.toString()
    );
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, context) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <InputLabel
                className="label-mui"
                id={'label-question-' + question.id}
                required={requiredResult}>
                {question.label}
              </InputLabel>
              <Grid container>
                <Grid item xs={10}>
                  <Autocomplete
                    multiple
                    id={'question-' + question.id}
                    value={value}
                    onChange={onChangeHandler}
                    fullWidth
                    options={question.responseOptions}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...(params as TextFieldProps)}
                        size="small"
                        multiline={multiline}
                        required={requiredResult}
                        disabled={
                          disabled
                            ? disabled
                            : SpelUtils.expressionValidation(
                                question.readOnly,
                                question.readOnlyExpression,
                                context
                              )
                        }
                        rows={4}
                        sx={{
                          marginBottom: 3,
                        }}
                        error={error?.get(entity)}
                      />
                    )}
                  />
                </Grid>
                <Grid className="questionnaire-icons">
                  <IdAndVStatus question={question} entity={entity} />
                  <QuestionTooltip question={question} />
                </Grid>
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default TypeAheadMultiSelectQuestion;

import axios, { AxiosError, AxiosResponse } from 'axios';
import IMailDetail from '../utils/entities/screen/IMailDetail';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/serviceUtils';
import { CONSTANTS } from '../utils/constants/constants';

interface IMailParams {
  recipient: string;
  subject: string;
  messageBody: string;
  caseId: string;
  createdBy: string;
  tenant: string;
}

/**
 * This is responsible to sent the mail
 * @param reqBody
 * @returns
 */
export const mailSend = (reqBody: IMailParams): Promise<void> =>
  axios
    .post(`/sendMail`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      return;
    })
    .catch(function (error: AxiosError<any>) {
      console.error('Error During Mail Send: ', error.toJSON());
      throw error.message;
    });

export const getRFIMailDetail = (caseId: string): Promise<IMailDetail[]> =>
  axios
    .get(`/getMailsByCaseId/${encodeURIComponent(caseId)}?method=${CONSTANTS.MANUAL}`, axiosConfig)
    .then((res: AxiosResponse<IMailDetail[]>) => {
      return serviceUtils.processSuccess<IMailDetail[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

import {
  Alert,
  Paper,
  Box,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  Switch,
  SelectChangeEvent,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import moment from 'moment';
import IDocumentMetadata from '../../../../../utils/entities/case/IDocumentMetadata';
import DateUtils from '../../../../../utils/dateUtils';
import { getDataParticlesByCaseIdFileId } from '../../../../../service/case-manager.service';
import Loader from '../../../../loader/Loader';
import { IMetadata, IMetadataAttr } from '../../../../../utils/entities/IMetadata';
import {
  IDocumentTypeEntity,
  ISimpleDocumentType,
  IGroupDocumentType,
} from '../../../../../utils/entities/case/IDocumentTypeEntity';
import Dot from '../../../../generic/chip/dot/Dot';
import IFile from '../../../../../utils/entities/document/IFile';
import DatePickerComponent from '../../../../generic/inputs/datePicker/DatePickerComponent';
import {
  getDataBlockModelByTenant,
  getDataModelItemsByTenant,
} from '../../../../../service/config-manager.service';
import LocalStorageUtils from '../../../../../utils/localStorageUtils';
import { addAlert } from '../../../../../store/actions/alerts.actions';
import { IDocumentDataBlockGroup } from '../../../../../utils/entities/dataBlock/IDocumentDataBlock';
import { getDocumentContent } from '../../../../../service/content-manager.service';
import IReceivedItem from '../../../../../utils/entities/case/IReceivedItem';
import ISimpleAttribute from '../../../../../utils/entities/case/ISimpleAttribute';
import IconButtonComponent from '../../../../generic/button/IconButtonComponent';
import { IDocumentInfoForm } from '../../../../../utils/entities/document/IDocumentsPanel';
import TableHeader from '../TableHeader';
import TooltipComp from '../../../../generic/tooltip/Tooltip';
import './CaptureDocumentInfo.scss';
import { datePickerType } from '../../../../../types/datePickerType';

const CaptureDocumentInfo = (props: IDocumentInfoForm) => {
  const {
    editMode,
    setEditMode,
    documentList,
    scanFileRes,
    setScanFileRes,
    selectedFiles,
    setSelectedFiles,
    fileUrls,
    scanLoader,
    setSelectedFilesIdxFileToEdit,
    caseId,
    setMetadata,
    setPreviousEditMode,
    setFileUrls,
    documentListIdxFileToEdit,
    saveMetadata,
    documentConfigurations,
    tenant,
    guidItemAttr,
    isVerificationProcess,
    entitySubtype,
    attributeNameToVerify,
  } = { ...props };
  const stateRef = useRef<ISimpleDocumentType[]>();
  const userRoleName: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  ).role.name;

  const [docTypes, setDocTypes] = useState<ISimpleDocumentType[]>([]);
  const [isParticlesLoading, setIsParticlesLoading] = useState<boolean>(false);

  useEffect(() => {
    if (editMode === 'edit') {
      setIsParticlesLoading(true);
    }

    if (isVerificationProcess) {
      getDataModelItemsByTenant(tenant).then((data) => {
        const validVerificationGroups = data.filter((group) => group.itemCode == entitySubtype);
        const validVerificationDocs = validVerificationGroups
          .map((group) =>
            group.dataModelVersionAttributes
              ?.filter((attr) => attr.name == attributeNameToVerify)
              .map((attr) => attr.verification?.[0]?.verificationPrecedence?.l2)
          )
          .flat();

        getDataBlockModelByTenant(tenant).then((docData) => {
          const docTypes = docData
            .filter((data) => {
              return (
                data.category === CONSTANTS.DOC_CENTER.DOC_TYPE &&
                (!data.rolesName?.length || data.rolesName?.includes(userRoleName)) &&
                validVerificationDocs.flat().includes(data.dataBlockSchema)
              );
            })
            .map((data) => convertDocTypeIntoSimpleDocType(data));

          docTypes.unshift({
            category: CONSTANTS.DOC_CENTER.DOC_TYPE,
            label: t('NONE'),
            value: undefined,
            groups: [],
          });
          setDocTypes(docTypes);
          stateRef.current = docTypes;

          if (editMode === 'edit') {
            editMetadata(0);
          }
        });
      });
    } else {
      getDataBlockModelByTenant(tenant).then((docData) => {
        const docTypes = docData
          .filter(
            (data) =>
              data.category === CONSTANTS.DOC_CENTER.DOC_TYPE &&
              (!data.rolesName?.length || data.rolesName?.includes(userRoleName))
          )
          .map((data) => convertDocTypeIntoSimpleDocType(data));
        docTypes.unshift({
          category: CONSTANTS.DOC_CENTER.DOC_TYPE,
          label: t('NONE'),
          value: undefined,
          groups: [],
        });
        setDocTypes(docTypes);
        stateRef.current = docTypes;

        if (editMode === 'edit') {
          editMetadata(0);
        }
      });
    }

    if (
      editMode === 'upload' &&
      documentConfigurations.find((config) => config.field === 'documentName')?.autofillExpression
    ) {
      for (const file of selectedFiles) {
        if (!file.documentName) {
          if (file.name.length > CONSTANTS.DOC_CENTER.DOC_NAME_MAX_LENGTH) {
            file.documentName = file.name.substring(0, CONSTANTS.DOC_CENTER.DOC_NAME_MAX_LENGTH);
          } else {
            file.documentName = file.name;
          }
        }
      }
    }
  }, []);

  const convertDocTypeIntoSimpleDocType = (docType: IDocumentTypeEntity): ISimpleDocumentType => {
    return {
      category: docType.category,
      label: docType.dataBlockSchema,
      value: docType.dataBlockSchema,
      groups: docType.groups,
    };
  };

  const mergeByCode = (metadata: IMetadata[]) => {
    return metadata.reduce((a: Array<IMetadata>, b: IMetadata) => {
      const found = a.find((e: IMetadata) => e.code == b.code);
      return found ? found.lines.push(...b.lines) : a.push({ ...b, lines: [...b.lines] }), a;
    }, []);
  };

  const createMetadataObj = (
    groups: IGroupDocumentType[],
    file: IFile | null | undefined = null,
    particleAttrs: Array<IReceivedItem> = []
  ) => {
    const metadata: Array<IMetadata> = [];
    for (const gr of groups) {
      for (const pAttr of particleAttrs) {
        if (pAttr.groupCode === gr.code) {
          const line: IMetadataAttr[] = [];
          for (const attr of gr.attributes) {
            line.push({
              type: attr.type,
              multiple: attr.multiple,
              enum: attr.enum,
              value: getAttrVal(gr, attr, file, pAttr).value,
              values: getAttrVal(gr, attr, file, pAttr).values,
              title: attr.description,
              name: attr.name,
              disabled: false,
            });
          }
          metadata.push({
            code: gr.code,
            mandatory: gr.mandatory,
            repeatable: gr.repeatable,
            title: gr.description,
            lines: [{ businessKeys: pAttr.businessKeys, line }],
          });
        }
      }
    }

    return mergeByCode(metadata);
  };

  const createMetadataObjInitial = (groups: IGroupDocumentType[]) => {
    const metadata: Array<IMetadata> = [];
    for (const gr of groups) {
      const line: Array<IMetadataAttr> = [];
      for (const attr of gr.attributes) {
        line.push({
          type: attr.type,
          multiple: attr.multiple,
          enum: attr.enum,
          value: '',
          values: [],
          title: attr.description,
          name: attr.name,
        });
      }

      metadata.push({
        code: gr.code,
        mandatory: gr.mandatory,
        repeatable: gr.repeatable,
        title: gr.description,
        lines: [{ line }],
      });
    }

    return mergeByCode(metadata);
  };

  const getAttrVal = (
    gr: IGroupDocumentType,
    attr: IMetadataAttr,
    file: IFile | null | undefined = null,
    particleItem: IReceivedItem
  ) => {
    const dataBlockAttr = file?.dataBlock?.documentData?.groups
      ?.find((g: IDocumentDataBlockGroup) => g.groupCode === gr.code)
      ?.attributes?.find((a: ISimpleAttribute) => a.name === attr.name);
    return particleItem?.attributes[attr.name] ?? dataBlockAttr ?? attr;
  };

  const getDocDataModel = (docSubType: string) => {
    return stateRef?.current?.find((data) => data.label === docSubType);
  };

  const setDocumentNameByIdx = (newName: string, index: number) => {
    selectedFiles[index].documentName = newName;

    setSelectedFiles([...selectedFiles]);
  };

  const setActiveByIdx = (isActive: boolean, index: number) => {
    selectedFiles[index].active = isActive;

    setSelectedFiles([...selectedFiles]);
  };

  const getSelectedFileByIdx = (index: number) => {
    return selectedFiles.find((file: IFile, idx: number) => idx == index);
  };

  const setDocumentSubTypeByIdx = (newSubType: string | undefined, index: number) => {
    const file = getSelectedFileByIdx(index);
    if (file) {
      file.documentSubType = newSubType;
      setMetadata([]);
      saveMetadata(index, []);
    }

    setSelectedFiles([...selectedFiles]);
  };

  const setExpiryDateByIdx = (newValue: datePickerType, index: number) => {
    const newExpDate = newValue
      ? moment(dayjs(newValue).toDate()).format(CONSTANTS.DATES.DEFAULT.DATE_FORMAT)
      : null;
    const file = getSelectedFileByIdx(index);
    if (file) {
      file.expirationDate = DateUtils.dateToEpoch(
        newExpDate,
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
      );
    }

    setSelectedFiles([...selectedFiles]);
  };

  const deleteFile = (row: IFile, idx: number) => {
    fileUrls.splice(idx, 1);
    setFileUrls([...fileUrls]);
    setSelectedFiles([...selectedFiles.filter((file: { name: string }) => file.name !== row.name)]);
    scanFileRes.splice(idx, 1);
    setScanFileRes([...scanFileRes]);
  };

  const initCaptureMetadata = (fileIdx: number) => {
    const file = selectedFiles[fileIdx];
    const docDataModel = getDocDataModel(file.documentSubType as string);
    if (docDataModel) {
      const metadata = createMetadataObjInitial(docDataModel.groups);
      setMetadata(metadata);
      setPreviousEditMode(editMode);
      setSelectedFilesIdxFileToEdit(fileIdx);

      const guid = documentList[documentListIdxFileToEdit ?? 0]?.metadata?.documentGUID;
      if (guid) {
        getDocumentContent(guid, caseId, tenant).then((blobData) => {
          fileUrls[fileIdx] = {
            url: URL.createObjectURL(blobData),
            type: blobData.type,
          };
          setFileUrls([...fileUrls]);
          setEditMode('capture_metadata');
        });
      } else {
        setEditMode('capture_metadata');
      }
    } else {
      addAlert({ type: 'error', primaryText: t('ERROR') });
    }
  };

  const editMetadata = async (fileIdx: number) => {
    const docType = selectedFiles[fileIdx].documentSubType;
    if (docType) {
      setSelectedFilesIdxFileToEdit(fileIdx);
      const guid = (documentList[documentListIdxFileToEdit as number].metadata as IDocumentMetadata)
        .documentGUID;

      const dataparticlesList = await getDataParticlesByCaseIdFileId(caseId, guid);
      const guidArr = dataparticlesList.map((particle) => particle.guid);

      const matchAttr: Array<IReceivedItem> | undefined = guidItemAttr
        ?.filter((item) => {
          return item.dataParticleReferences.some((element) => {
            return guidArr.includes(element);
          });
        })
        ?.map((item) => {
          let groupCode: string | undefined;

          item.dataParticleReferences.some((element) => {
            groupCode = dataparticlesList.find((particle) => particle.guid === element)?.groupCode;
            if (groupCode) {
              return true;
            }
          });

          return { ...item, groupCode };
        });

      getDocumentContent(guid, caseId, tenant).then(async (blobData) => {
        fileUrls[fileIdx] = {
          url: URL.createObjectURL(blobData),
          type: blobData.type,
        };
        setFileUrls([...fileUrls]);

        const docDataModel = getDocDataModel(docType);
        if (docDataModel) {
          const metadata = createMetadataObj(docDataModel.groups, undefined, matchAttr);
          setMetadata(metadata);
          saveMetadata(fileIdx, metadata);
        } else {
          addAlert({ type: 'error', primaryText: t('ERROR') });
        }

        setIsParticlesLoading(false);
      });
    }
  };

  const hasDataBlock = (idx: number) => {
    return !(
      selectedFiles[idx]?.dataBlock?.documentData?.groups == null ||
      selectedFiles[idx]?.dataBlock?.documentData?.groups.length === 0 ||
      (selectedFiles[idx]?.dataBlock?.documentData?.groups.length === 1 &&
        selectedFiles[idx]?.dataBlock?.documentData?.groups[0].groupCode == 'MASTER')
    );
  };

  return (
    <div className="capture-document-info body-style">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="filesTable">
          <TableHead className="header-case">
            <TableRow>
              <TableHeader
                headers={
                  editMode === 'upload'
                    ? CONSTANTS.DOC_CENTER.UPLOAD_DOC_COLUMN
                    : CONSTANTS.DOC_CENTER.EDIT_DOC_COLUMN
                }
                documentConfigurations={documentConfigurations}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedFiles.map((row: IFile, i: number) => (
              <React.Fragment key={`frag_${row.name}`}>
                {editMode !== 'edit' && scanFileRes.length > 0 && !scanFileRes[i] && (
                  <TableRow key={`error_${row.name}`}>
                    <TableCell className="padding-0" colSpan={7}>
                      <Alert severity="error">{t('DOCUMENT_FAILED_VIRUS_SCAN')}</Alert>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={`${row.name}`}>
                  <TableCell align="center">
                    <TextField
                      disabled={
                        !row.active ||
                        !documentConfigurations.find((config) => config.field === 'documentName')
                          ?.enabled
                      }
                      data-testid="input-textbox-maxlen"
                      variant="standard"
                      placeholder={t('DOCUMENT_NAME') ?? ''}
                      value={row.documentName}
                      inputProps={{
                        maxLength: CONSTANTS.DOC_CENTER.DOC_NAME_MAX_LENGTH,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box component="span" sx={{ display: 'flex', justifyContent: 'end' }}>
                              <span>
                                {`${(row.documentName ?? '').length} / ${
                                  CONSTANTS.DOC_CENTER.DOC_NAME_MAX_LENGTH
                                }`}
                              </span>
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setDocumentNameByIdx(event.target.value, i)
                      }
                    />
                  </TableCell>

                  <TableCell align="center" className="filename-cell">
                    <Button
                      className={
                        documentConfigurations.find((config) => config.field === 'documentPreview')
                          ?.enabled
                          ? 'link'
                          : 'elipsis'
                      }
                      disabled={
                        !documentConfigurations.find((config) => config.field === 'documentPreview')
                          ?.enabled
                      }
                      onClick={() => {
                        saveAs(`${fileUrls.length > 0 ? fileUrls[i].url : ''}`, row.name);
                      }}>
                      <TooltipComp label={row.name} />
                    </Button>
                  </TableCell>

                  <TableCell align="center" className="file-type-cell">
                    {docTypes.length ? (
                      <FormControl variant="standard">
                        <Select
                          disabled={
                            !row.active ||
                            !documentConfigurations.find(
                              (config) => config.field === 'documentType'
                            )?.enabled
                          }
                          value={row.documentSubType || docTypes[0].label}
                          onChange={(event: SelectChangeEvent<string>) =>
                            setDocumentSubTypeByIdx(event.target.value, i)
                          }
                          renderValue={(value: string) => <TooltipComp label={value} />}>
                          {docTypes.map((data) => {
                            return (
                              <MenuItem key={data.label} value={data.value}>
                                {data.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <Loader />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <DatePickerComponent
                      disabled={
                        !row.active ||
                        !documentConfigurations.find((config) => config.field === 'expirationDate')
                          ?.enabled
                      }
                      disablePast
                      defaultValue={DateUtils.getDateFormatToDatePickerByEpcoh(row.expirationDate)}
                      onChange={(value: datePickerType) => setExpiryDateByIdx(value, i)}
                    />
                  </TableCell>

                  <TableCell align="center">
                    {editMode === 'upload' &&
                      documentConfigurations.find((config) => config.field === 'virusScan')
                        ?.enabled &&
                      (!scanLoader ? (
                        <Dot
                          type={scanFileRes[i] ? 'SUCCESS' : 'FAIL'}
                          message={scanFileRes[i] ? t('PASS') : t('FAIL')}
                        />
                      ) : (
                        <Loader />
                      ))}
                    {editMode === 'edit' && (
                      <Tooltip
                        title={
                          <div className="tooltip-text">
                            <span className="tooltip-label">
                              {row.active
                                ? t('DOC_CENTER.DOCUMENT_ACTIVE')
                                : t('DOC_CENTER.DOCUMENT_INACTIVE')}
                            </span>
                          </div>
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'common.black',
                              '& .MuiTooltip-arrow': {
                                color: 'common.black',
                              },
                            },
                          },
                        }}
                        arrow>
                        <Switch
                          checked={row.active}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setActiveByIdx(event.target.checked, i)
                          }
                          className={`active-switch ${row.active ? 'active' : 'deactive'}`}
                        />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title={t('DOC_CENTER.CAPTURE_METADATA')}>
                      <span>
                        {isParticlesLoading ? (
                          <Loader />
                        ) : (
                          <IconButtonComponent
                            isDisabled={!selectedFiles[i].documentSubType || !row.active}
                            icon={'editNote'}
                            handleClick={() => {
                              if (hasDataBlock(i)) {
                                editMetadata(i);
                                setEditMode('capture_metadata');
                              } else {
                                initCaptureMetadata(i);
                              }
                            }}
                            classes={hasDataBlock(i) && row.active ? 'color-green' : ''}
                          />
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                  {editMode !== 'edit' && (
                    <TableCell align="center">
                      <IconButtonComponent
                        isDisabled={!row.active}
                        handleClick={() => deleteFile(row, i)}
                        icon="delete"
                      />
                    </TableCell>
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CaptureDocumentInfo;

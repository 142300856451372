import React from 'react';
import ICaseDetailsHeaderProps from '../../../utils/entities/pages/case-detail/case-header/ICaseHeader';
import AggregateViewHeader from '../aggregate-view/AggregateViewHeader';
import Case360Header from '../case-360/Case360Header';

const CaseDetailHeader = ({
  caseId,
  caseRoles,
  casePermissions,
  selectedTab,
}: ICaseDetailsHeaderProps) => {
  if (selectedTab?.[0]) {
    switch (selectedTab[0].title) {
      case 'Aggregate View':
        return (
          <AggregateViewHeader
            caseId={caseId}
            caseRoles={caseRoles}
            casePermissions={casePermissions}
          />
        );
      case 'Case 360':
        // return <Case360Header />;
        return (
          <AggregateViewHeader
            caseId={caseId}
            caseRoles={caseRoles}
            casePermissions={casePermissions}
          />
        );
      default:
        return null; // Fallback case if selectedTab doesn't match any known titles
    }
  } else {
    return null; // Handle the case where selectedTab is undefined or empty
  }
};

export default CaseDetailHeader;

import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { ISearchCriteria } from '../../../utils/entities/dueDiligence/ISearchCriteria';
import { FormControlLabel, Grid } from '@mui/material';
import FilterCriteriaPopup from './FilterCriteriaPopup';
import { useIScreen } from '../../../pages/task/Task';
import { createRequest } from '../../../service/due-diligence.service';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IRequest } from '../../../utils/entities/dueDiligence/IRequest';
import { CONSTANTS } from '../../../utils/constants/constants';
import { IFilter } from '../../../utils/entities/dueDiligence/IFilter';
import { ILocation } from '../../../utils/entities/dueDiligence/ILocation';
import { ICity, ISearchSections } from '../../../utils/entities/screen/IDueDiligence';
import { ICaseParty } from '../../../utils/entities/dueDiligence/ICaseParty';
import dateUtils from '../../../utils/dateUtils';
import { addAlert } from '../../../store/actions/alerts.actions';
import CheckboxComponent from '../../generic/inputs/checkbox/CheckboxComponent';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import IAccessToken from '../../../utils/entities/authentication/IAccessToken';
import IRefData from '../../../utils/entities/screen/IRefData';
import GeneralUtils from '../../../utils/generalUtils';
import IReceivedItem from '../../../utils/entities/case/IReceivedItem';

interface searchInfo {
  name: string;
  date: string;
}
interface lastSearch {
  [key: string]: Array<searchInfo>;
}

const SearchCriteria = (props: {
  tenant: string;
  getRequests: (caseId: string, name: string) => void;
  initialLastSearches: lastSearch;
  disabled: boolean;
  searchSections: ISearchSections[];
  newSelectedParty: ICaseParty | undefined;
  setNewSelectedParty: React.Dispatch<React.SetStateAction<ICaseParty | undefined>>;
  setCountries: React.Dispatch<React.SetStateAction<Array<IRefData> | undefined>>;
  countries: Array<IRefData> | undefined;
  setCities: React.Dispatch<React.SetStateAction<Array<ICity> | undefined>>;
  cities: Array<ICity> | undefined;
  selectedCity: ICity | undefined;
  setSelectedCity: React.Dispatch<React.SetStateAction<ICity | undefined>>;
  selectedCountry: IRefData | undefined;
  setSelectedCountry: React.Dispatch<React.SetStateAction<IRefData | undefined>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setDetailedRequests: React.Dispatch<React.SetStateAction<Array<IRequest>>>;
  detailedRequests: Array<IRequest> | undefined;
  dateFormat: string | undefined;
  addressAttributes: Array<IReceivedItem>;
}) => {
  const { t } = useTranslation();
  const { data } = useIScreen();
  const [openAcccordion, setOpenAcccordion] = useState<boolean>(false);
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const {
    tenant,
    getRequests,
    initialLastSearches,
    disabled,
    searchSections,
    newSelectedParty,
    setNewSelectedParty,
    setCountries,
    countries,
    setCities,
    cities,
    selectedCity,
    setSelectedCity,
    selectedCountry,
    setSelectedCountry,
    setShowModal,
    showModal,
    setDetailedRequests,
    detailedRequests,
    dateFormat,
  } = props;

  const searchSectionWrapper: Array<ISearchCriteria> = searchSections.map((obj) => ({
    title: obj.sectionTitle,
    openSatus: false,
    filters: obj.searchOptions.map((options) => {
      if (options.apiName == 'Negative News') {
        return {
          name: options.searchOptionTitle,
          apiName: options.apiName,
          active: false,
          lastSearch: t('DD.NO_SEARCHES'),
          filterOption: {
            searchTermsNN: '',
            partyNameNN: newSelectedParty ? newSelectedParty.searchAttribute.value : '',
            articleDate: { after: '', before: '' },
            location: [{ country: '', state: '', city: '' }],
            alias: [''],
          },
          description: { articlesFrom: '08/31/2021', articlesTo: '12/20/2022', location: 'CN' },
        };
      } else if (options.apiName == 'IR') {
        return {
          name: options.searchOptionTitle,
          apiName: options.apiName,
          active: false,
          lastSearch: t('DD.NO_SEARCHES'),
          filterOption: {
            searchTermsIR: '',
            partyNameIR: newSelectedParty ? newSelectedParty.searchAttribute.value : '',
          },
          description: { articlesFrom: '08/31/2021', articlesTo: '12/20/2022', location: 'CN' },
        };
      } else if (options.apiName == 'AV') {
        return {
          name: options.searchOptionTitle,
          apiName: options.apiName,
          active: false,
          lastSearch: t('DD.NO_SEARCHES'),
          filterOption: {
            partyName: newSelectedParty ? newSelectedParty.searchAttribute.value : '',
            addressValidation: [
              {
                Street: '',
                City: '',
                State: '',
                County: '',
                ZipCode: '',
                Province: '',
                Country: '',
              },
            ],
          },
          description: { articlesFrom: '08/31/2021', articlesTo: '12/20/2022', location: 'CN' },
        };
      } else if (options.apiName == 'TR Clear') {
        return {
          name: options.searchOptionTitle,
          apiName: options.apiName,
          active: false,
          lastSearch: t('DD.NO_SEARCHES'),
          filterOption: {
            searchType: '',
            lastName: '',
            firstName: '',
            middleInitial: '',
            emailAddress: '',
            phoneNumber: '',
            ssn: '',
            personBirthDate: '',
            personAgeFrom: '',
            personAgeTo: '',
            driverLicenseNumber: '',
            secondaryLastName: '',
            street: '',
            streetNamesSoundSimilarOption: 'false',
            city: '',
            county: '',
            state: '',
            province: '',
            zipCode: '',
            country: '',
            businessName: '',
            corporationId: '',
            filingNumber: '',
            filingDate: '',
            fein: '',
            dunsNumber: '',
            location: [{ country: '', state: '', city: '' }],
            nPINumber: '',
            worldCheckUniqueId: '',
            partyName: newSelectedParty ? newSelectedParty.searchAttribute.value : '',
          },
          description: { articlesFrom: '08/31/2021', articlesTo: '12/20/2022', location: 'CN' },
        };
      } else {
        // edge case scenario
        return {
          name: options.searchOptionTitle,
          apiName: options.apiName,
          active: false,
          lastSearch: t('DD.NO_SEARCHES'),
          filterOption: {
            searchTerms: '',
            partyName: newSelectedParty ? newSelectedParty.searchAttribute.value : '',
            articleDate: { after: '', before: '' },
            location: [{ country: '', state: '', city: '' }],
            alias: [''],
            addressValidation: [
              {
                Street: '',
                City: '',
                State: '',
                County: '',
                ZipCode: '',
                Province: '',
                Country: '',
              },
            ],
            searchType: '',
            lastName: '',
            firstName: '',
            middleInitial: '',
            emailAddress: '',
            phoneNumber: '',
            ssn: '',
            personBirthDate: '',
            personAgeFrom: '',
            personAgeTo: '',
            driverLicenseNumber: '',
            secondaryLastName: '',
            street: '',
            streetNamesSoundSimilarOption: 'false',
            city: '',
            county: '',
            state: '',
            province: '',
            zipCode: '',
            country: '',
            businessName: '',
            corporationId: '',
            filingNumber: '',
            filingDate: '',
            fein: '',
            dunsNumber: '',
            nPINumber: '',
            worldCheckUniqueId: '',
          },
          description: { articlesFrom: '08/31/2021', articlesTo: '12/20/2022', location: 'CN' },
        };
      }
    }),
  }));

  const [searchCriteria, setSearchCriteria] =
    useState<Array<ISearchCriteria>>(searchSectionWrapper);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
    setShowModal(false);
  };

  const [customIndex, setCustomIndex] = useState<number>();
  const [customInIndex, setCustomInIndex] = useState<number>();
  const searchEngine: ISearchCriteria | undefined = searchCriteria.find((item) =>
    (item.filters ?? []).some((filter) => filter?.active === true)
  );
  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const handleOpenFilterPopup = (inIndex: number, index: number) => {
    setOpen(true);
    setCustomIndex(index);
    setCustomInIndex(inIndex);
    setShowModal(true);
    filterChanged(inIndex, index);
  };

  const filterChanged = (inIndex: number, index: number): void => {
    const updatedArr = [...searchCriteria];
    updatedArr.forEach((criteria, i) => {
      if (i == index) {
        criteria.filters[inIndex] = { ...criteria.filters[inIndex], active: true };
      } else {
        criteria.filters[inIndex] = { ...criteria.filters[inIndex], active: false };
      }
    });
    setSearchCriteria(updatedArr);
  };

  const toggleEvent = (index: number) => {
    setOpenAcccordion(!openAcccordion);
    const selectedItem = searchCriteria[index];
    selectedItem.openSatus = !selectedItem.openSatus;

    //Update the last search date in the accordion
    if (initialLastSearches && initialLastSearches[selectedItem.title]) {
      const ciViaBingFilterIndex = selectedItem.filters?.findIndex(
        (filter) => filter.name === 'Negative News (CI via Bing)'
      );
      if (ciViaBingFilterIndex !== -1) {
        initialLastSearches[selectedItem.title].map((search: searchInfo) => {
          if (search.date) {
            selectedItem.filters[ciViaBingFilterIndex].lastSearch = search.date;
          }
        });
      }
    }
    const updatedArr = searchCriteria;
    updatedArr.splice(index, 1, selectedItem);
    setSearchCriteria(updatedArr);
    setOpen(false);
  };

  const search = () => {
    const searchCriteriaCopy: Array<ISearchCriteria> =
      GeneralUtils.deepCopy<Array<ISearchCriteria>>(searchCriteria);
    const filteredSearchCriteria = searchCriteriaCopy.filter((search, index) => {
      searchCriteriaCopy[index].filters = search.filters?.filter((filter) => filter.active);
      return search.filters?.filter((filter) => filter.active).length != 0;
    });
    createNewRequest(filteredSearchCriteria);
    addAlert({
      type: 'success',
      primaryText: t('REQUEST_SUCCESS'),
    });
  };

  const createNewRequest = (criteria: ISearchCriteria[]) => {
    if (criteria) {
      criteria.forEach((search) => {
        search.filters?.forEach((searchParams) => {
          const requestBody: IRequest = {
            caseId: data.caseId,
            apiName: searchParams.apiName, //String(sampleName && sampleName[1]) hardocded for now, so that we have LexisNexis in the front without the back
            applicationName: searchParams.apiName === 'Negative News' ? 'KYC' : projectName,
            partyName:
              searchParams.apiName === 'Negative News'
                ? searchParams.filterOption.partyNameNN
                : searchParams.apiName === 'IR'
                ? searchParams.filterOption.partyNameIR
                : searchParams.filterOption.partyName,
            searchTerms:
              searchParams.apiName == 'Negative News'
                ? searchParams.filterOption.searchTermsNN
                  ? String(searchParams.filterOption.searchTermsNN)
                  : ''
                : searchParams.apiName == 'IR'
                ? searchParams.filterOption.searchTermsIR
                  ? searchParams.filterOption.partyNameIR
                    ? searchParams.filterOption.partyNameIR +
                      ' AND ' +
                      String(searchParams.filterOption.searchTermsIR)
                    : String(searchParams.filterOption.searchTermsIR)
                  : ''
                : '',
            minDate: searchParams.filterOption.articleDate?.after ?? '',
            maxDate: searchParams.filterOption.articleDate?.before ?? '',
            location: searchParams.filterOption.location ?? [],
            aliases: searchParams.filterOption.alias ?? [],
            userName: userInfo.user,
            requestDate: null,
            responseDate: null,
            status: 'In Progress',
            error: '',
            response: '',
            entityAssociatedWithNN: false,
            tenant: data.tenant,
            projectName: projectName,
            searchType: searchParams.filterOption?.searchType ?? '',
            businessName: searchParams.filterOption?.businessName ?? '',
            corporationId: searchParams.filterOption?.corporationId ?? '',
            filingNumber: searchParams.filterOption?.filingNumber ?? '',
            filingDate: searchParams.filterOption?.filingDate ?? '',
            fein: searchParams.filterOption?.fein ?? '',
            dunsNumber: searchParams.filterOption?.dunsNumber ?? '',
            lastName: searchParams.filterOption?.lastName ?? '',
            firstName: searchParams.filterOption?.firstName ?? '',
            middleInitial: searchParams.filterOption?.middleInitial ?? '',
            secondaryLastName: searchParams.filterOption?.secondaryLastName ?? '',
            streetNamesSoundSimilarOption: searchParams.filterOption?.streetNamesSoundSimilarOption,
            street: searchParams.filterOption?.street ?? '',
            city: searchParams.filterOption?.city ?? '',
            state: searchParams.filterOption?.state ?? '',
            county: searchParams.filterOption?.county ?? '',
            zipCode: searchParams.filterOption?.zipCode ?? '',
            province: searchParams.filterOption?.province ?? '',
            country: searchParams.filterOption?.country ?? '',
            phoneNumber: searchParams.filterOption?.phoneNumber ?? '',
            emailAddress: searchParams.filterOption?.emailAddress ?? '',
            ssn: searchParams.filterOption?.ssn ?? '',
            personBirthDate: searchParams.filterOption?.personBirthDate ?? '',
            personAgeTo: searchParams.filterOption?.personAgeTo ?? '',
            personAgeFrom: searchParams.filterOption?.personAgeFrom ?? '',
            driverLicenseNumber: searchParams.filterOption?.driverLicenseNumber ?? '',
            nPINumber: '',
            worldCheckUniqueId: '',
            addressValidation: searchParams.filterOption?.addressValidation ?? [],
          };
          createRequest(requestBody).then(() => {
              getRequests(data.caseId, searchParams.apiName);
          });
        });
      });
    }
  };

  return (
    <div className="card-view search-criteria due-diligence-panel">
      <span className="search-creiteria-label">{t('DD.SEARCH_CRITERIA')}</span>
      <div className="search-criteria-options">
        {searchCriteria.map((criteria: ISearchCriteria, index: number) => {
          return (
            <Grid item xs={12} key={index} data-testid="search-criteria-section">
              {criteria.filters?.map((filter: IFilter, inIndex: number) => {
                return (
                  <div
                    key={inIndex}
                    className="due-diligence-detail card-view margin-top-12 display-flex">
                    <div className="flex-3 inner-filter-font">
                      <span>{filter.name}</span>
                    </div>
                    <div className="flex-1 inner-filter-font">
                      <span
                        className="enabled case-number margin-left-10"
                        onClick={() => handleOpenFilterPopup(inIndex, index)}>
                        {t('FILTER')} <FilterListIcon />
                      </span>
                      {open && customInIndex == inIndex && customIndex == index && (
                        <FilterCriteriaPopup
                          tenant={tenant}
                          filter={filter}
                          handleClose={handleTooltipClose}
                          newSelectedParty={newSelectedParty}
                          setNewSelectedParty={setNewSelectedParty}
                          setShowModal={setShowModal}
                          showModal={showModal}
                          setCountries={setCountries}
                          countries={countries}
                          setCities={setCities}
                          cities={cities}
                          selectedCity={selectedCity}
                          setSelectedCity={setSelectedCity}
                          selectedCountry={selectedCountry}
                          setSelectedCountry={setSelectedCountry}
                          dateFormat={dateFormat}
                          searchSections={searchSections}
                          handleSearch={search}
                          addressAttributes={props.addressAttributes}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default SearchCriteria;

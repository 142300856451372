import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getExportCaseData } from '../../../service/case-manager.service';
import { addAlert } from '../../../store/actions/alerts.actions';
import { CONSTANTS } from '../../../utils/constants/constants';
import dateUtils from '../../../utils/dateUtils';
import { IExportPDFProps } from '../../../utils/entities/case/export/IExport';
import GeneralUtils from '../../../utils/generalUtils';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import StringUtils from '../../../utils/stringUtils';
import DefaultModal from '../../generic/modal/Modal';
import ExportModalBody from './ExportModalBody';

const ExportPDF = ({
  showExportModal,
  setShowExportModal,
  pdfList,
  caseId,
  closeExportDropdown,
}: IExportPDFProps) => {
  const { t } = useTranslation();
  const dateFormat: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT
  );

  const [selectedPdf, setSelectedPdf] = useState<string | undefined>();

  const exportPdf = (caseId: string, value: string, pdfType: string) => {
    getExportCaseData(caseId, value, dateUtils.getDateFormatFromDateFormat(dateFormat), pdfType)
      .then((res) => {
        GeneralUtils.downloadByBlob(
          res.data,
          res.headers['content-type'],
          StringUtils.getSubStringAfterKey(res.headers['content-disposition'], 'filename=')
        );
      })
      .catch(() => {
        addAlert({
          type: 'error',
          primaryText: t('NULL_DATA'),
        });
      });
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setSelectedPdf(undefined);
    closeExportDropdown();
  };

  return (
    <DefaultModal
      open={showExportModal}
      className="confirmation-modal"
      title={t('PDF_TYPE')}
      body={
        <ExportModalBody
          selectedPdf={selectedPdf}
          setSelectedPdf={setSelectedPdf}
          pdfList={pdfList}
        />
      }
      setConfirm={() => {
        if (selectedPdf) {
          exportPdf(caseId, 'PDF', selectedPdf);
        }
        closeExportModal();
      }}
      onClose={() => {
        closeExportModal();
      }}
      confirmText={t('CONFIRM')}
      denyText={t('Cancel')}
      disabled={!selectedPdf}
    />
  );
};

export default ExportPDF;

import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosConfig from './axios.config';
import { IDataBlock } from '../utils/entities/dataBlock/IDataBlock';
import serviceUtils from '../utils/serviceUtils';
import { IUpdateDocumentMetadata } from '../utils/entities/document/IUpdateDocument';

export const addDocumentToCase = (
  file: File,
  caseId: string,
  docName: string,
  tenant: string,
  projectName: string,
  expirationDate: number,
  correlationId?: string,
  documentName?: string,
  documentType?: string,
  documentSubType?: string,
  uploadedBy?: string,
  dataBlock?: IDataBlock | null,
  caseDisplayId?: string
): Promise<string> => {
  const bodyFormData: FormData = new FormData();
  bodyFormData.append('file', file, docName);
  bodyFormData.append('caseId', caseId);
  bodyFormData.append('tenant', tenant);
  bodyFormData.append('projectName', projectName);
  bodyFormData.append('expirationDate', expirationDate.toString());
  documentName && bodyFormData.append('documentName', documentName);
  documentType && bodyFormData.append('documentType', documentType);
  documentSubType && bodyFormData.append('documentSubType', documentSubType);
  uploadedBy && bodyFormData.append('uploadedBy', uploadedBy);
  dataBlock && bodyFormData.append('dataBlock', JSON.stringify(dataBlock));
  correlationId && bodyFormData.append('correlationId', correlationId);
  caseDisplayId && bodyFormData.append('caseDisplayId', caseDisplayId);
  return axios
    .postForm(`/uploadDocument`, bodyFormData, axiosConfig)
    .then((res: AxiosResponse<string>) => {
      return serviceUtils.processSuccess<string>(res);
    })
    .catch((error: AxiosError | string) => {
      if (axios.isAxiosError(error) && (error.response?.data as string).includes('Error:')) {
        error = (error.response?.data as string).split('Error:')[1]?.trim();
      }
      throw serviceUtils.processError(error);
    });
};

export const sendDataBlock = (dataBlock: IDataBlock): Promise<IDataBlock> => {
  return axios
    .post(`/sendDataBlock`, dataBlock, axiosConfig)
    .then((res: AxiosResponse<IDataBlock>) => {
      return serviceUtils.processSuccess<IDataBlock>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

export const updateDocument = (
  metadata: IUpdateDocumentMetadata,
  tenant: string,
  projectName: string
) => {
  const bodyFormData: FormData = new FormData();
  bodyFormData.append('metadata', JSON.stringify(metadata));
  bodyFormData.append('projectName', projectName);

  return axios
    .patchForm(`/updateDocument?tenant=${tenant}`, bodyFormData, axiosConfig)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

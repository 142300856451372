import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ILink } from '../utils/entities/case/ILink';
import { ISearchCase } from '../utils/entities/case/ISearchCase';
import axiosConfig, { axiosBlob } from './axios.config';
import { ICaseCreationBody } from '../utils/entities/case/CaseCreation/ICaseCreation';
import ICase from '../utils/entities/case/ICase';
import serviceUtils from '../utils/serviceUtils';
import { IDataParticle } from '../utils/entities/case/IDataParticle';
import IAgGridTblPayload from '../utils/entities/IAgGridTblPayload';
import { API_CONSTANTS } from '../utils/constants/api_constants';
import { IRequestToJoin } from '../utils/entities/request-to-join/IRequestToJoin';
import { IJoinRequest } from '../utils/entities/case/CaseCreation/JoinRequest/IJoinRequest';

export const client: ApolloClient<any> = new ApolloClient({
  uri: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/dataGatewayService/graphql`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  cache: new InMemoryCache(),
});

export const getCase = (id: string): Promise<ICase> =>
  axios
    .get(`/getCase/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<ICase>) => {
      return serviceUtils.processSuccess<ICase>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const searchCases = (caseId: string): Promise<ISearchCase> =>
  axios
    .get(`/getSearchRelatedCaseFiles/${encodeURIComponent(caseId)}`, axiosConfig)
    .then((res: AxiosResponse<ISearchCase>) => {
      return serviceUtils.processSuccess<ISearchCase>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getLinkCase = (id: string | undefined): Promise<Array<ILink>> =>
  axios
    .get(`/getLinkedCasesList/${encodeURIComponent(id ?? '')}`, axiosConfig)
    .then((res: AxiosResponse<Array<ILink>>) => {
      return serviceUtils.processSuccess<ILink[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const deleteCaseLink = (linkCaseDetails: ILink): Promise<Array<ILink>> => {
  const config = axiosConfig;
  config.data = {
    caseId: linkCaseDetails.caseId,
    createdBy: linkCaseDetails.createdBy,
    linkCaseId: linkCaseDetails.linkCaseId,
    relation: linkCaseDetails.relation,
  };
  return axios
    .delete(`/deleteCaseLink`, config)
    .then((res: AxiosResponse<Array<ILink>>) => {
      return serviceUtils.processSuccess<Array<ILink>>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

export const linkCase = (reqBody: ILink): Promise<void> =>
  axios
    .post(`/createLinkCase`, reqBody, axiosConfig)
    .then((res: AxiosResponse): void => {
      console.debug('Case Linking successfully done :', reqBody);
    })
    .catch(function (error: AxiosError<any>): void {
      console.error('Error During Case Linking: ', error.toJSON());
      throw error.message;
    });

export const getExportCaseData = (
  caseId: string,
  fileType: string,
  dateFormat: string,
  pdfType?: string
): Promise<AxiosResponse> => {
  const pdfParam = pdfType ? `&pdfType=${pdfType}` : '';
  const query = `?dateFormat=${dateFormat}${pdfParam}`;

  return axios
    .get(
      `/exportCaseDetails/${encodeURIComponent(caseId)}/${encodeURIComponent(fileType)}${query}`,
      axiosBlob
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

export const exportCases = (reqBody: IAgGridTblPayload): Promise<Array<ICase>> =>
  axios
    .get(`/exportCasesDetails?filter=${encodeURIComponent(JSON.stringify(reqBody))}`, axiosConfig)
    .then((res: AxiosResponse<Array<ICase>>) => {
      return serviceUtils.processSuccess<ICase[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const createCaseService = async (reqBody: ICaseCreationBody): Promise<ICase> =>
  axios
    .post(`/createCase`, reqBody, axiosConfig)
    .then((res: AxiosResponse<ICase>) => {
      return serviceUtils.processSuccess<ICase>(res);
    })
    .catch((error: AxiosError | string) => {
      const status = serviceUtils.processError(error, true);
      throw status === 409 ? status : serviceUtils.processError(error);
    });

export const getDataParticlesByCaseIdFileId = (
  caseId: string,
  fileId: string
): Promise<Array<IDataParticle>> =>
  axios
    .get(
      `/getDataParticlesList/${encodeURIComponent(caseId)}/${encodeURIComponent(fileId)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<IDataParticle>>) => {
      return serviceUtils.processSuccess<IDataParticle[]>(res);
    })
    .catch((error: AxiosError | string) => {
      if (axios.isAxiosError(error)) {
        console.error('Error :', error.message);
        throw error.message;
      } else {
        console.error('Error :', error);
        throw error;
      }
    });

export const caseRefresh = (caseId: string) =>
  `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/caseManagerService${API_CONSTANTS.SCREEN_REFRESH}/${caseId}`;

export const caseRolesRefresh = (caseId: string) =>
  `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/caseManagerService${API_CONSTANTS.CASE_ROLES_REFRESH}/${caseId}`;

// #region RTJ

export const createRequestToJoin = (request: IRequestToJoin): Promise<void> =>
  axios
    .post(`/createRequestToJoin/`, request, axiosConfig)
    .then(() => {
      return;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

// #endregion RTJ

export const getRequestToJoinRequests = (id: string): Promise<IJoinRequest> =>
  axios
    .get(`/getRequestsToJoinByCaseId/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<IJoinRequest>) => {
      return serviceUtils.processSuccess<IJoinRequest>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

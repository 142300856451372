import { Checkbox } from '@mui/material';
import React from 'react';
import { ICheckboxComponentProps } from '../../../../utils/entities/genericComponents/ICheckbox';

const CheckboxComponent = (props: ICheckboxComponentProps) => {
  const { value, checked, onClick, isDisabled, indeterminate } = { ...props };

  return (
    <Checkbox
      indeterminate={indeterminate}
      value={value}
      disabled={isDisabled}
      checked={checked}
      onChange={onClick}
      style={{ color: !isDisabled ? 'black' : undefined }}
    />
  );
};

export default CheckboxComponent;

import React, { useContext, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ICase360Table, { ITableAttributesObject } from '../../utils/entities/screen/ICase360Table';
import { useIScreen } from '../../pages/task/Task';
import { ColDef, GridReadyEvent, RowEvent } from 'ag-grid-community';
import GeneralUtils from '../../utils/generalUtils';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import { CONSTANTS } from '../../utils/constants/constants';
import './TaskCase360.scss';
import { ITableHeader } from '../../utils/entities/screen/ITableHeader';
import { ITableAttributes } from '../../utils/entities/screen/ITableAttributes';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import IScreen from '../../utils/entities/screen/IScreen';
import GenericContext from '../generic/timer/GenericContext';
import EpochToDateText from '../generic/timer/EpochToDateText';
import AttributesModal from './case-360/AttributesModal';
import IconButtonComponent from '../generic/button/IconButtonComponent';
import TooltipComp from '../generic/tooltip/Tooltip';
import LocalStorageUtils from '../../utils/localStorageUtils';
import dateUtils from '../../utils/dateUtils';
import { ITableGridHeader } from '../../utils/entities/screen/tasks/ITableGridHeader';

const TaskCase360 = () => {
  const { dateFormat } = useContext(GenericContext);
  const {
    data,
    setLoading,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
    activeTabId,
    requestBody,
    setRequestBody,
  } = useIScreen();
  const { t } = useTranslation();
  const timezone: string = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);

  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [selectedModalHeaders, setSelectedModalHeaders] = useState<Array<ITableHeader>>([]);
  const [selectedModalAttributes, setSelectedModalAttributes] = useState<
    ITableAttributes | undefined
  >(undefined);

  useEffect(() => {
    if (data && activeTabId && !requestBody) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
    }

    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 1000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  const getColumnDefs = (index: number) => {
    return configureColumns(data?.case360?.[data.activeTabId]?.dataTables?.[index]?.tableHeaders);
  };

  const dateTextTootipLabel = (params: { value: string }, c: ITableHeader): string => {
    return c.displayOriginalTimezoneFormat
      ? t('CASE_360.MY_TIMEZONE_DATE') +
          ' ' +
          dateUtils.epochToDateFormated(Number(params.value) / 1000, dateFormat, timezone)
      : '';
  };

  const getTimeZone = (c: ITableHeader) => {
    if (c.displayOriginalTimezoneFormat && c.originalTimezone) {
      return c.originalTimezone;
    }
    return timezone;
  };

  const hederTimezoneTooltip = (c: ITableHeader) => {
    if (c.displayOriginalTimezoneFormat && c.originalTimezone) {
      return `${t('CASE_360.INSERTERS_TIME_ZONE')} ${c.originalTimezone}`;
    }
    return `${t('TIME_ZONE')} ${timezone}`;
  };

  /**
   * This function will return updated filter for the date column
   * @param dateMap
   * @returns
   */
  const getDateColumnParams = (dateMap: ITableHeader) => {
    return {
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = dateUtils.epochToDateFormated(
            Number(cellValue) / 1000,
            'DD/MM/YYYY',
            getTimeZone(dateMap)
          );
          if (dateAsString == null) return -1;
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
        suppressAndOrCondition: true,
      },
    };
  };

  const configureColumns = (colConfigs: Array<ITableHeader>) => {
    const columns: Array<ColDef> = [];
    colConfigs.forEach((c: ITableHeader, index: number) => {
      let column: ITableGridHeader = {
        field: c.attributeName,
        headerName: c.label,
        headerTooltip:
          c?.attributeFormat?.toUpperCase() === CONSTANTS.COLUMNTYPES.DATE ? '' : c.label,
        filter:
          c?.attributeFormat?.toUpperCase() === CONSTANTS.COLUMNTYPES.DATE
            ? CONSTANTS.AG_GRID.FILTER.DATE
            : CONSTANTS.AG_GRID.FILTER.TEXT,

        filterParams: { suppressAndOrCondition: true },
        visible: c.visible,
      };

      if (c?.attributeFormat?.toUpperCase() === CONSTANTS.COLUMNTYPES.DATE) {
        column = { ...column, ...getDateColumnParams(c) };
      }

      if (c.attributeFormat) {
        switch (c.attributeFormat.toUpperCase()) {
          case CONSTANTS.COLUMNTYPES.DATE:
            c.displayOriginalTimezoneFormat && (column.filter = CONSTANTS.AG_GRID.FILTER.DATE);
            column.cellRenderer = (params: { value: string }) => {
              return (
                <TooltipComp label={dateTextTootipLabel(params, c)}>
                  <EpochToDateText epoch={Number(params.value) / 1000} timeZone={getTimeZone(c)} />
                </TooltipComp>
              );
            };
            break;
          case CONSTANTS.COLUMNTYPES.CURRENCY:
            (column.filter = CONSTANTS.AG_GRID.FILTER.NUMBER),
              (column.cellRenderer = (params: { value: string }) => {
                return (
                  <div>
                    {GeneralUtils.currencyConvertorFun(Number(params.value), 'en-US', 'USD')}
                  </div>
                );
              });
            break;
          default:
            column.cellRenderer = (params: { value: string }) => {
              // c.attributeFormat this we will receive as `$${parseFloat(params.value).toFixed(2)} `
              return <div>{eval(c.attributeFormat ?? '')}</div>;
            };
            break;
        }
      }
      column.visible && columns.push(column);
    });

    return columns;
  };

  const getRowData = (tableAttributes?: Array<ITableAttributes>) => {
    if (tableAttributes) {
      return Object.values(tableAttributes);
    }
    return [];
  };

  const onCloseDialog = () => {
    setModalIsVisible(false);
  };

  const onGridReady = (params: GridReadyEvent) => {
    // To take full width by the AG Grid row
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };
  const { caseHeaderToggle } = useContext(GenericContext);

  const getTableLength = (tblTrribute: Array<ITableAttributes>) => {
    return Object.keys(tblTrribute).length;
  };

  const handleRowClicked = (row: RowEvent, table: ICase360Table) => {
    setModalIsVisible(true);

    const modalAttr = Object.values(table?.tableAttributes).find(
      (tblAttr: any) => tblAttr === row.data
    );

    setSelectedModalHeaders(
      table.modalHeaders.filter((ele) => {
        if (ele.visible) {
          return ele;
        } else {
          modalAttr && delete modalAttr[ele.attributeName];
        }
      })
    );
    setSelectedModalAttributes(modalAttr);
  };

  return (
    <div
      className={
        caseHeaderToggle
          ? 'margin-inline-20 case360 case360-wrapper '
          : 'margin-inline-20 case360 case360-wide-wrapper'
      }
      data-testid="case360-main">
      {data?.case360?.[data.activeTabId]?.dataTables?.map((table: ICase360Table, index: number) => (
        <div key={'table-' + index}>
          <h3>{table.tableName}</h3>
          <div
            className={`ag-theme-alpine ${
              getTableLength(table?.tableAttributes) > 10 ? 'case360-table-height' : ''
            }`}>
            <AgGridReact
              pagination={true}
              domLayout={getTableLength(table?.tableAttributes) > 10 ? 'normal' : 'autoHeight'}
              suppressRowClickSelection={true}
              columnDefs={getColumnDefs(index)}
              defaultColDef={CONSTANTS.AG_GRID.DEFAULT_COL_DEF}
              rowGroupPanelShow={'always'}
              rowData={getRowData(table.tableAttributes)}
              animateRows={true}
              onRowClicked={(row: RowEvent) => handleRowClicked(row, table)}
              onGridReady={onGridReady}
              getContextMenuItems={() => CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS}
            />
          </div>
        </div>
      ))}
      <Dialog open={modalIsVisible} onClose={onCloseDialog} classes={{ paper: 'case360-dialog' }}>
        <DialogTitle>
          {
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>{t('ENTITY_ATTRIBUTES')}</Box>
              <Box>
                <IconButtonComponent icon="close" handleClick={onCloseDialog} />
              </Box>
            </Box>
          }
        </DialogTitle>
        <DialogContent dividers>
          <div className="modal-container">
            {selectedModalAttributes && (
              <AttributesModal
                attributes={selectedModalAttributes}
                headers={selectedModalHeaders}
                index={0}
                dateFormat={dateFormat}
              />
            )}
            {selectedModalAttributes && (
              <AttributesModal
                attributes={selectedModalAttributes}
                headers={selectedModalHeaders}
                index={1}
                dateFormat={dateFormat}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
      {!data.case360 && <p>{t('TAB_DATA_MISSING')}</p>}
    </div>
  );
};

export default TaskCase360;

import React, { useContext, useEffect, useState } from 'react';
import { useIScreen } from '../../pages/task/Task';
import { useTranslation } from 'react-i18next';
import INarrative from '../../utils/entities/narrative/INarrative';
import INarrativeVersionDetail from '../../utils/entities/narrative/INarrativeVersionDetail';
import '../task/narrative/narrative.scss';
import '../generic/alert/Alert.scss';
import NarrativeTemplate from './narrative/NarrativeTemplate';
import {
  getTemplateDropdown,
  updateNarrative,
  restoreNarrativeByVersion,
  getTemplateVersionsByTemplateId,
  narrativeRefresh,
} from '../../service/narrative-generation.service';
import GeneralUtils from '../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import DefaultModal from '../generic/modal/Modal';
import Loader from '../loader/Loader';
import { CONSTANTS } from '../../utils/constants/constants';
import RichTextComponent from '../richText/RichTextComponent';
import ErrorBoundary from '../generic/error-boundary/ErrorBoundary';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';
import { addAlert } from '../../store/actions/alerts.actions';
import LocalStorageUtils from '../../utils/localStorageUtils';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import Banner from './narrative/Banner';
import IVersionItem from '../../utils/entities/narrative/IVersionItem';
import IScreen from '../../utils/entities/screen/IScreen';
import GenericContext from '../generic/timer/GenericContext';

function Narrative() {
  const {
    data,
    setLoading,
    activeTabId,
    isCompletedTask,
    hasAssignee,
    assignee,
    loggedUser,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
    saveTaskAsDraftClick,
    setSaveTaskAsDraftClick,
    modalConfirmationClick,
    setCheckConfirmationModal,
    requestBody,
    setRequestBody,
    submitClick,
  } = useIScreen();
  const { t } = useTranslation();
  const params = useParams();
  const history = createBrowserHistory();
  const currentTab = activeTabId;
  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );
  const timeZone = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);
  const [narrativeText, setNarrativeText] = useState<string>('');
  const [openedNarrativeVersion, setOpenedNarrativeVersion] = useState<INarrativeVersionDetail>();
  const [templateList, setTemplateList] = useState<Array<INarrative>>();
  const [wip, setWip] = useState<INarrativeVersionDetail>();
  const [banner, setBanner] = useState<boolean>(true);
  const [restoreModal, setRestoreModal] = useState<boolean>(false);
  const [showMainLoader, setShowMainLoader] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>('');
  const [tabType, setTabType] = useState<string | undefined>();
  const [openVersionDropdown, setOpenVersionDropdown] = useState<boolean>(false);
  const [checkTabChanged, setCheckTabChanged] = useState<boolean>(false);
  const [checkRestore, setCheckRestore] = useState<boolean>(false);
  const [versionList, setVersionList] = useState<Array<IVersionItem>>([]);
  const [checkInputClick, setCheckInputClick] = useState<boolean>(false);

  const checkIfChanged =
    //Check if the narrative template was changed, by detecting a keyboard event
    openedNarrativeVersion &&
    openedNarrativeVersion?.version === 'WIP' &&
    selectedTemplate !== '' &&
    checkInputClick;

  useEffect(() => {
    const tab = getTabType();
    if (tabType && tabType !== tab) {
      setCheckTabChanged(true);
    }
    setTabType(tab);
    if (tab) {
      getAllTemplates(tab);
    }
  }, [params.tabId]);

  useEffect(() => {
    setLoading(false);
    setTabType(getTabType());

    if (data && activeTabId) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    //Update when changing tabs
    if (activeTabId !== currentTab) {
      if (modalConfirmationClick) {
        editSelectedNarrative(openedNarrativeVersion?.id);
      }
    }
  }, [activeTabId]);

  useEffect(() => {
    if (openedNarrativeVersion && openedNarrativeVersion.version === 'WIP') {
      setWip(openedNarrativeVersion);
    }
    setBanner(true);
  }, [openedNarrativeVersion]);

  useEffect(() => {
    setCheckConfirmationModal(checkIfChanged ?? false);
  }, [narrativeText]);

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      editSelectedNarrative(openedNarrativeVersion?.id);
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 2000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  useEffect(() => {
    if (saveTaskAsDraftClick) {
      editSelectedNarrative(openedNarrativeVersion?.id);
      setSaveTaskAsDraftClick(false);
    }
  }, [saveTaskAsDraftClick]);

  useEffect(() => {
    if (submitClick) {
      editSelectedNarrative(openedNarrativeVersion?.id);
    }
  }, [submitClick]);

  useEffect(() => {
    if (checkTabChanged) {
      setNarrativeText('');
      setCheckTabChanged(false);

      if (selectedTemplate && narrativeText) {
        setSelectedTemplate('');
        setNarrativeText('');
      }
    }
  }, [checkTabChanged]);

  useEffect(() => {
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: checkIfChanged ?? false });
  }, [checkIfChanged]);

  const getTabType = () => {
    return data.narrative?.[data.activeTabId].tabType;
  };

  const getAllTemplates = (tabType: string) => {
    getTemplateDropdown(data.tenant, tabType)
      .then((res) => {
        setTemplateList(res);
      })
      .catch((error) => {
        console.error('ERROR:', error);
      });
  };

  const refreshEventListener = function (e: MessageEvent) {
    const eventData = JSON.parse(e?.data);
    setNarrativeText(eventData?.content);
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.NARRATIVE_UPDATE, narrativeRefresh(data.caseId), true, {
    narrative_update: refreshEventListener,
  });

  const getVersionsByTemplate = (template: string) => {
    getTemplateVersionsByTemplateId(
      template,
      data.tenant,
      data.caseId,
      projectName,
      timeZone,
      tabType as string
    ).then((res: Array<IVersionItem>) => {
      setVersionList(res);
    });
  };

  const clearNarrativeId = (content: string | undefined): string =>
    content
      ? content.replaceAll('&gt;', '>').replaceAll('&lt;', '<').replaceAll('&nbsp;', ' ')
      : '';

  const updateNarrativeVersion = (templateVersionId: string, content: string) => {
    //When updating the WIP version, it creates a new version equal to the last WIP
    if (openedNarrativeVersion && tabType && checkIfChanged) {
      const reqBody: INarrativeVersionDetail = {
        templateId: openedNarrativeVersion.templateId,
        content: content,
        caseId: openedNarrativeVersion.caseId,
        version: openedNarrativeVersion.version,
        timeZone: openedNarrativeVersion.timeZone,
        tabType: tabType,
      };
      updateNarrative(templateVersionId, reqBody)
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('NARRATIVE_SUCCESS'),
          });
        })
        .catch((error: string) => {
          if (error) {
            addAlert({
              type: 'error',
              primaryText: t('ERROR_MESSAGE'),
            });
          }
        })
        .finally(() => {
          templateList?.forEach((template: INarrative) => {
            if (template.id === selectedTemplate) {
              getVersionsByTemplate(template.name);
            }
          });
          setCheckInputClick(false);
        });
    } else if (!checkIfChanged) {
      addAlert({
        type: 'error',
        primaryText: t('NARRATIVE_EXISTS'),
      });
    }
  };

  const restoreNarrative = (id: string) => {
    restoreNarrativeByVersion(id)
      .then(() => {
        addAlert({
          type: 'success',
          primaryText: t('NARRATIVE_RESTORE'),
        });
        setCheckRestore(true);
      })
      .catch((error: string) => {
        if (error) {
          addAlert({
            type: 'error',
            primaryText: t('ERROR_MESSAGE'),
          });
        }
      });
  };

  const editSelectedNarrative = (versionId: string | undefined) => {
    if (versionId) {
      updateNarrativeVersion(versionId, clearNarrativeId(narrativeText));
    }
  };

  const checkDisabled = () => {
    return (
      isCompletedTask ||
      !hasAssignee ||
      assignee != loggedUser ||
      openedNarrativeVersion?.version !== 'WIP'
    );
  };
  const { caseHeaderToggle } = useContext(GenericContext);

  return (
    <ErrorBoundary history={history} params={params}>
      <div
        className={
          caseHeaderToggle
            ? 'narrative-wrapper due-diligence-wrapper'
            : 'narrative-wrapper wrapper '
        }>
        {showMainLoader && <Loader isMainLoader />}
        {banner && wip?.content !== openedNarrativeVersion?.content && (
          <Banner setBanner={setBanner} setRestoreModal={setRestoreModal} />
        )}
        <DefaultModal
          open={restoreModal}
          className="confirmation-modal"
          title={t('RESTORE_MODAL_TITLE')}
          body={
            <p>
              {t('RESTORE_MODAL_BODY', {
                version: openedNarrativeVersion?.version ?? '',
              })}
            </p>
          }
          setConfirm={() => {
            restoreNarrative(openedNarrativeVersion?.id as string);
            setRestoreModal(false);
          }}
          onClose={() => {
            setRestoreModal(false);
          }}
          confirmText={t('CONFIRM')}
          denyText={t('CANCEL')}
        />
        <NarrativeTemplate
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          templateList={templateList}
          openedNarrativeVersion={openedNarrativeVersion}
          setOpenedNarrativeVersion={setOpenedNarrativeVersion}
          narrativeText={narrativeText}
          setNarrativeText={setNarrativeText}
          checkIfChanged={checkIfChanged}
          setShowMainLoader={setShowMainLoader}
          setGenerating={setGenerating}
          clearNarrativeId={clearNarrativeId}
          tabType={tabType as string}
          openVersionDropdown={openVersionDropdown}
          setOpenVersionDropdown={setOpenVersionDropdown}
          checkRestore={checkRestore}
          setCheckRestore={setCheckRestore}
          versionList={versionList}
          getVersionsByTemplate={getVersionsByTemplate}
          setCheckInputClick={setCheckInputClick}
        />
        <RichTextComponent
          selectedTemplate={selectedTemplate}
          required={true}
          value={narrativeText}
          label={''}
          setData={setNarrativeText}
          readOnly={checkDisabled() || generating}
          setOpenVersionDropdown={setOpenVersionDropdown}
          checkInputClick={checkInputClick}
          setCheckInputClick={setCheckInputClick}
        />
      </div>
    </ErrorBoundary>
  );
}

export default Narrative;

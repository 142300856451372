import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  assignTaskToUser,
  getCompletedTask,
  getTab,
  getTask,
  sendDraft,
  submitTask,
  taskRefresh,
} from '../../service/task-manager.service';
import TabBarComponent from '../../components/task/dynamic-questionaire/TabBarComponent';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import CaseHeader from '../../components/task/dynamic-questionaire/CaseHeader/CaseHeader';
import { CONSTANTS } from '../../utils/constants/constants';
import { ApolloProvider } from '@apollo/client';
import { client } from '../../service/apollo-client.data-gateway';
import { ICaseHeaderAttributes, IClaim, IStatusOptions } from 'fso-fincrime-sdk-ui';
import ITab from '../../utils/entities/screen/ITab';
import IScreen from '../../utils/entities/screen/IScreen';
import ISet from '../../utils/entities/screen/ISet';
import IQuestion, { IValue } from '../../utils/entities/screen/IQuestion';
import GeneralUtils from '../../utils/generalUtils';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../components/generic/error-boundary/ErrorBoundary';
import IQuestionnaire from '../../utils/entities/screen/IQuestionnaire';
import { addAlert } from '../../store/actions/alerts.actions';
import LocalStorageUtils from '../../utils/localStorageUtils';
import DynamicQuestionnaireUtils from '../../utils/functions/DynamicQuestionnaireUtils';
import SpelUtils from '../../utils/spelUtils';
import IBusinessKeysErrorObject from '../../utils/entities/IBusinessKeysErrorObject';
import ICaseRoles from '../../utils/entities/case/ICaseRoles';
import { getCaseRolesByProject } from '../../service/role-manager.service';
import IAccessToken from '../../utils/entities/authentication/IAccessToken';
import ITaskError from '../../utils/entities/screen/tasks/ITaskError';
import IContextLocals from '../../utils/entities/screen/IContextLocals';
import TaskViewContext from '../../components/task/dynamic-questionaire/TaskViews/TaskViewContext';
import Loader from '../../components/loader/Loader';
import objectUtils from '../../utils/objectUtils';
import { ITagSubmit } from '../../utils/entities/case/CaseHeader/ITagSubmit';
import { AxiosError } from 'axios';
import ConfirmationModal from '../../components/generic/modal/ConfirmationModal';
import ITaskErrorCode from '../../utils/entities/screen/tasks/ITaskErrorCode';
import IPermission, { PermissionTypes } from '../../utils/entities/role/IPermission';
import { getCase } from '../../service/case-manager.service';
import questionIsRequiredFunction from '../../types/dynamic-questionnaire/question/questionIsRequired';
import updateContextLocalsFunction from '../../types/dynamic-questionnaire/question/updateContextLocals';
import bodyAnswerUpdateFunction from '../../types/dynamic-questionnaire/question/bodyAnswerUpdate';
import { IDynamicTableRemovedRows } from '../../utils/entities/screen/IDynamicTableRemovedRows';
import { getDataModelVersion } from '../../service/config-manager.service';
import { IDataModelVersion } from '../../utils/entities/dataModel/IDataModelVersion';
import {
  IAttributeObject,
  IQuestionTableAttributes,
} from '../../utils/entities/screen/IQuestionTableAttributes';
import IBusinessKeysMap from '../../utils/entities/case/IBusinessKeysMap';
import ITableError from '../../utils/entities/screen/tasks/dynamic-table/ITableError';
import { ITaskProps } from '../../utils/entities/pages/task/Itask';
import ArrayUtils from '../../utils/arrayUtils';

const Task = ({ fileType, maxFileSize, slaLabels }: ITaskProps) => {
  const disableSSEDynamicQuestionnaire: boolean =
    window.__RUNTIME_CONFIG__?.REACT_APP_DISABLE_SSE_DYNAMIC_QUESTIONNAIRE === 'true';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const history = createBrowserHistory();
  const [taskView, setTaskView] = useState<boolean>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_VIEW) ?? false
  );
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const [showMainLoader, setShowMainLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTabId, setActiveTabId] = useState<string>(params.tabId as string);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<IScreen | null>(null);
  const [caseHeaderData, setCaseHeaderData] = useState<ICaseHeaderAttributes[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [submitClick, setSubmitClick] = useState<boolean>(false);
  const [saveTaskAsDraftAndExitClick, setSaveTaskAsDraftAndExitClick] = useState<boolean>(false);
  const [claimClick, setClaimClick] = useState<boolean>(false);
  const [saveTaskAsDraftClick, setSaveTaskAsDraftClick] = useState<boolean>(false);
  const [hasAssignee, setHasAssignee] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [cancelTask, setCancelTask] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<IScreen>(GeneralUtils.deepCopy<IScreen>(data));
  const [contextLocals, setContextLocals] = useState<IContextLocals | undefined>(undefined);
  const [tabsWithError, setTabsWithError] = useState<ITaskError[]>([]);
  const [formTagRes, setFormTagRes] = useState<ITagSubmit | undefined>(undefined);
  const [loggedUser, setLoggedUser] = useState<string>();
  const [assignee, setAssignee] = useState<string>();
  const [modalConfirmationClick, setModalConfirmationClick] = useState<boolean>(false);
  const [checkConfirmationModal, setCheckConfirmationModal] = useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = useState<string[]>([]);
  const [setsWithError, setSetsWithError] = useState<string[]>([]);
  const [submitAction, setSubmitAction] = useState<boolean>(false);
  const [toExit, setToExit] = useState<boolean>(false);
  const [setIndex, setSetIndex] = useState<number>(0);
  const [selectedEntity, setSelectedEntity] = useState<string>('');
  const [selectedEntityIndex, setSelectedEntityIndex] = useState<number>(0);
  const questionErrors = new Map<string, Map<string, boolean>>();
  const [assignedCaseRoles, setAssignedCaseRoles] = useState<ICaseRoles[]>([]);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [questionErrorsState, setQuestionErrorsState] =
    useState<Map<string, Map<string, boolean>>>(questionErrors);
  //attribute used that turn true if the save was successful to know when to reset the dynamic table flags
  const [tableSaved, setTableSaved] = useState<boolean>(false);
  const [errorObject, setErrorObject] = useState<IBusinessKeysErrorObject>();
  const [updatedRefreshData, setUpdatedRefreshData] = useState<IScreen>();
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState<boolean>(false);
  const [isSetEntityChanged, setIsSetEntityChanged] = useState<boolean>(false);
  const [caseRoles, setCaseRoles] = useState<Array<ICaseRoles>>([]);
  const [internalStatus, setInternalStatus] = useState<boolean>(false);
  const [badgeHighlight, setBadgeHighlight] = useState<boolean>(false);
  const [removedRows, setRemovedRows] = useState<Array<IDynamicTableRemovedRows>>([]);
  const [dataModelVersion, setDataModelVersion] = useState<IDataModelVersion | undefined>();
  const [loadingModel, setLoadingModel] = useState<boolean>(false);
  const { isCompletedTask } = { isCompletedTask: internalStatus };

  let headerData: ICaseHeaderAttributes[] | undefined;
  document.addEventListener(EVENTS_CONSTANTS.HEADER_CASE_TAG_UPDATE, (event: any) => {
    setFormTagRes(event?.detail);
  });

  document.addEventListener(EVENTS_CONSTANTS.IS_DIRTY_EVT, (event: any) => {
    setIsDirty(event?.detail?.isDirty);
  });

  document.addEventListener(EVENTS_CONSTANTS.SET_CASE_HEADER_DATA_EVT, (event: any) => {
    setCaseHeaderData(event?.detail?.caseHeaderData);
  });

  document.addEventListener(EVENTS_CONSTANTS.NAVIGATE_EVT, (event: any) => {
    if (!event?.detail?.submitAction) {
      navigate(event?.detail?.params);
    }
  });

  const fetchDataModelVersion = (tenant: string) => {
    setLoadingModel(true);
    getDataModelVersion(tenant)
      .then((result) => {
        setDataModelVersion(result);
        setLoadingModel(false);
      })
      .catch(() => {
        setLoadingModel(false);
      });
  };

  useEffect(() => {
    fetchCaseRolesByProject();

    setRequestBody(GeneralUtils.deepCopy<IScreen>(data));
    setContextLocals(data?.contextLocals);

    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK.SUBMIT_OPTION);
    };
  }, []);

  useEffect(() => {
    if (data) {
      document.title = data.title + ' - ' + data.caseId;
      setActiveTabId(data.activeTabId);

      if (!loadingModel && !dataModelVersion) {
        fetchDataModelVersion(data.tenant);
      }

      if (data.activeTabId) {
        setError(null);
        if (data.dynamicQuestionnaire) {
          const dynamicQuestionnaire: IQuestionnaire | undefined =
            data.dynamicQuestionnaire[data.activeTabId];

          setRequestBody(GeneralUtils.deepCopy<IScreen>(data));
          setContextLocals(data.contextLocals);

          if (
            dynamicQuestionnaire &&
            !(dynamicQuestionnaire.sets == undefined || dynamicQuestionnaire.sets?.length == 0)
          ) {
            if (!(setIndex && dynamicQuestionnaire.sets[setIndex])) {
              setSetIndex(0);
            }
            if (dynamicQuestionnaire.sets[0].entityIds[0] != selectedEntity) {
              if (selectedEntity) {
                if (dynamicQuestionnaire.sets[setIndex]) {
                  setSelectedEntity(
                    dynamicQuestionnaire.sets[setIndex].entityIds[selectedEntityIndex]
                  );
                } else {
                  setSelectedEntity(dynamicQuestionnaire.sets[0].entityIds[0]);
                }
              } else {
                setSelectedEntity(dynamicQuestionnaire.sets[0].entityIds[0]);
              }
            } else {
              setLoading(false);
              GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LOADING_EVT, false);
            }
          }
        }
        setInternalStatus(
          data.internalTaskStatus == CONSTANTS.TASK_INTERNAL_STATUS.COMPLETED ||
            data.internalTaskStatus == CONSTANTS.TASK_INTERNAL_STATUS.CANCELED
        );
        setBadgeHighlight(data.badgeHighlight);
      } else {
        setError('Invalid Screen File or TabId ' + JSON.stringify(data));
      }
    } else {
      document.title = 'Fin Crime 3.0 ';
    }
  }, [data]);

  const [sseActivate, setSSEActivate] = useState<boolean>(false);
  const updateBodyWithRefreshData = (
    requestBody: IScreen,
    updatedRefreshData: IScreen,
    activeTabId: string
  ) => {
    const newBody: IScreen = GeneralUtils.deepCopy<IScreen>(requestBody);
    const oldContext = newBody.contextLocals;

    updatedRefreshData.dynamicQuestionnaire[activeTabId]?.sets?.forEach(
      (newSet: ISet, setIndex: number) => {
        const oldSetFind = newBody.dynamicQuestionnaire[activeTabId]?.sets.find(
          (findSet: ISet) => findSet.id == newSet.id
        );
        if (newBody.dynamicQuestionnaire[requestBody.activeTabId].sets[setIndex]) {
          newBody.dynamicQuestionnaire[requestBody.activeTabId].sets[setIndex].rootId =
            updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets[setIndex].rootId;
        }
        Object.keys(oldContext).forEach((entity: string) => {
          if (entity !== 'root' && entity !== 'header') {
            if (
              oldSetFind &&
              GeneralUtils.getNewEntityId(
                entity,
                newBody.dynamicQuestionnaire[activeTabId].sets[setIndex]?.businessKeys,
                newSet.businessKeys
              ).length
            ) {
              // newBody.contextLocals[
              //   GeneralUtils.getNewEntityId(
              //     entity,
              //     newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].businessKeys,
              //     newSet.businessKeys
              //   )
              // ] = newBody.contextLocals[entity];
              GeneralUtils.getNewEntityId(
                entity,
                newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].businessKeys,
                newSet.businessKeys
              ).forEach((compare: string) => {
                if (
                  entity !== compare &&
                  !newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds.includes(
                    compare
                  )
                ) {
                  const index =
                    newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds.indexOf(
                      entity
                    );
                  console.log(
                    'que se passa antes',
                    index,
                    newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds,
                    newBody.contextLocals,
                    entity,
                    compare,
                    newBody.contextLocals[entity],
                    newBody.contextLocals[compare]
                  );
                  //newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds.push(compare);
                  newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds.splice(
                    index,
                    0,
                    compare
                  );
                  newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds =
                    newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds.filter(
                      (id: string) => id !== entity
                    );
                  newBody.contextLocals[compare] = newBody.contextLocals[entity];
                  delete newBody.contextLocals[entity];
                  console.log(
                    'que se passa depois',
                    index,
                    newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].entityIds,
                    newBody.contextLocals,
                    entity,
                    compare,
                    newBody.contextLocals[entity],
                    newBody.contextLocals[compare]
                  );
                }
              });
            }
          }
          newSet.questions.forEach((newQuestion: IQuestion, questionIndex: number) => {
            if (oldSetFind) {
              if (
                newBody.dynamicQuestionnaire[activeTabId].sets[setIndex]?.id == newSet.id &&
                newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[questionIndex]
                  ?.id == newSet.questions[questionIndex]?.id
              ) {
                const oldQuestionValues =
                  newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[questionIndex]
                    ?.value;

                Object.keys(oldQuestionValues).forEach((oldEntity: string) => {
                  if (
                    GeneralUtils.getNewEntityId(
                      oldEntity,
                      newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].businessKeys,
                      newSet.businessKeys
                    ).length
                  ) {
                    GeneralUtils.getNewEntityId(
                      oldEntity,
                      newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].businessKeys,
                      newSet.businessKeys
                    ).forEach((compare: string) => {
                      if (oldEntity !== compare) {
                        newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                          questionIndex
                        ].value[compare] =
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].value[oldEntity];

                        if (
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].wasChanged
                        ) {
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].wasChanged[compare] =
                            newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                              questionIndex
                            ].wasChanged[oldEntity];
                        }

                        if (
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].oldValue
                        ) {
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].oldValue[compare] =
                            newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                              questionIndex
                            ].oldValue[oldEntity];
                        }

                        if (
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].responseHasChanged
                        ) {
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].responseHasChanged[compare] = false;
                        }

                        if (
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].identificationStatus
                        ) {
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].identificationStatus =
                            updatedRefreshData.dynamicQuestionnaire[activeTabId].sets[
                              setIndex
                            ].questions[questionIndex].identificationStatus;
                        }

                        if (
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].verificationStatus
                        ) {
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].verificationStatus =
                            updatedRefreshData.dynamicQuestionnaire[activeTabId].sets[
                              setIndex
                            ].questions[questionIndex].verificationStatus;
                        }

                        delete newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                          questionIndex
                        ].value[oldEntity];
                        delete newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                          questionIndex
                        ].responseHasChanged[oldEntity];

                        if (
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].dataTable
                        ) {
                          newBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            questionIndex
                          ].dataTable =
                            updatedRefreshData.dynamicQuestionnaire[activeTabId].sets[
                              setIndex
                            ].questions[questionIndex].dataTable;
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        });
      }
    );
    return newBody;
  };

  const [updatedBody, setUpdatedBody] = useState<IScreen>();
  useEffect(() => {
    if (
      requestBody &&
      updatedRefreshData &&
      requestBody.dynamicQuestionnaire &&
      updatedRefreshData.dynamicQuestionnaire
    ) {
      setSSEActivate(true);
      setUpdatedBody(undefined);
      if (updatedBody) {
        updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets.forEach(
          (updatedSet: ISet, updatedSetIndex: number) => {
            const findOldSet: ISet | undefined = updatedBody.dynamicQuestionnaire[
              requestBody.activeTabId
            ].sets.find((oldSet: ISet) => oldSet.id == updatedSet.id);

            const updatedEntities: Array<string> = updatedSet.entityIds;

            updatedSet.businessKeys.forEach((fullBk: IBusinessKeysMap, fullBkIndex: number) => {
              if (updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[updatedSetIndex]) {
                updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                  updatedSetIndex
                ].businessKeys[fullBkIndex].entityId = fullBk.entityId;
              }
            });

            updatedSet.questions.find(
              (updatedQuestion: IQuestion, updatedQuestionIndex: number) => {
                const findOldQuestion: IQuestion | undefined = findOldSet?.questions.find(
                  (oldQuestion: IQuestion) => oldQuestion.id == updatedQuestion.id
                );
                if (findOldQuestion && findOldQuestion.type === 'ENTITY_DATA_TABLE') {
                  const currentTableAttributes: { [x: string]: IQuestionTableAttributes } = {
                    ...findOldQuestion.dataTable.tableAttributes,
                  };
                  let currentTableEntityIds: string[] = [...findOldQuestion.dataTable.entityIds];

                  // New attributes and entity IDs from the SSE event
                  const newTableAttributes: { [x: string]: IQuestionTableAttributes } =
                    updatedQuestion.dataTable.tableAttributes;
                  const newTableEntityIds: string[] = updatedQuestion.dataTable.entityIds;

                  // Add any new attributes from the SSE event
                  Object.keys(newTableAttributes).forEach((entityId) => {
                    if (currentTableAttributes[entityId] === undefined) {
                      currentTableAttributes[entityId] = newTableAttributes[entityId];
                    }
                  });

                  // Add any new entity IDs from the SSE event
                  newTableEntityIds.forEach((entityId) => {
                    if (!currentTableEntityIds.includes(entityId)) {
                      currentTableEntityIds.push(entityId);
                    }
                  });

                  // Remove entity IDs and attributes that are not in the new data unless they are new items
                  Object.keys(currentTableAttributes).forEach((entityId) => {
                    if (
                      !newTableEntityIds.includes(entityId) &&
                      !currentTableAttributes[entityId]?.isNewItem
                    ) {
                      delete currentTableAttributes[entityId];
                    }
                  });

                  currentTableEntityIds = currentTableEntityIds.filter((entityId) => {
                    return (
                      newTableEntityIds.includes(entityId) ||
                      currentTableAttributes[entityId]?.isNewItem
                    );
                  });

                  if (
                    updatedBody.dynamicQuestionnaire?.[requestBody.activeTabId]?.sets?.[
                      updatedSetIndex
                    ]?.questions?.[updatedQuestionIndex]?.dataTable
                  ) {
                    updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                      updatedSetIndex
                    ].questions[updatedQuestionIndex].dataTable.tableAttributes =
                      currentTableAttributes;

                    updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                      updatedSetIndex
                    ].questions[updatedQuestionIndex].dataTable.entityIds = currentTableEntityIds;
                  }

                  Object.keys(currentTableAttributes).forEach((entity: string) => {
                    currentTableAttributes[entity].attributes.forEach(
                      (attribute: IAttributeObject) => {
                        Object.keys(attribute.attribute).forEach((bk: string) => {
                          if (updatedBody.contextLocals[entity]) {
                            updatedBody.contextLocals[entity][bk] = attribute.attribute[
                              bk
                            ] as string;
                          }
                          updatedBody.dynamicQuestionnaire[activeTabId]?.sets?.forEach(
                            (tableSet: ISet, tableSetIndex: number) => {
                              if (tableSet.entityIds.includes(entity)) {
                                tableSet.questions.forEach(
                                  (tableQuestion: IQuestion, tableQuestionIndex: number) => {
                                    if (
                                      tableQuestion.attributeName == bk &&
                                      updatedBody.dynamicQuestionnaire[activeTabId].sets[
                                        tableSetIndex
                                      ].questions[tableQuestionIndex].value?.[entity]?.[0]
                                    ) {
                                      updatedBody.dynamicQuestionnaire[activeTabId].sets[
                                        tableSetIndex
                                      ].questions[tableQuestionIndex].value[entity][0] = attribute
                                        .attribute[bk] as string;
                                    }
                                  }
                                );
                              }
                            }
                          );
                        });
                      }
                    );
                  });
                } else {
                  if (findOldQuestion?.wasChanged) {
                    updatedEntities.forEach((entity: string) => {
                      if (!findOldQuestion?.wasChanged?.[entity] && findOldSet) {
                        const findOldSetIndex: number = updatedBody.dynamicQuestionnaire[
                          requestBody.activeTabId
                        ].sets.findIndex((oldSet: ISet) => oldSet.id == updatedSet.id);
                        const findOldQuestionIndex: number = findOldSet?.questions.findIndex(
                          (oldQuestion: IQuestion) => oldQuestion.id == updatedQuestion.id
                        );
                        if (
                          updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                            findOldSetIndex
                          ].questions[findOldQuestionIndex] &&
                          updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets[
                            findOldSetIndex
                          ].questions[findOldQuestionIndex]
                        ) {
                          updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                            findOldSetIndex
                          ].questions[findOldQuestionIndex].value[entity] =
                            updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets[
                              findOldSetIndex
                            ].questions[findOldQuestionIndex].value[entity];
                        }
                      }
                    });
                  }
                }
              }
            );
          }
        );

        updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets.forEach(
          (set: ISet, index: number) => {
            const oldKeys: string[] = Object.keys(updatedBody.contextLocals);
            const newKeys: string[] = Object.keys(updatedRefreshData.contextLocals);
            const newKeysToAdd: string[] = newKeys.filter((key) => !oldKeys.includes(key));

            const updatedSetsLength: number =
              updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets.length;
            const oldSetsLength: number =
              updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets.length;

            const findSet: ISet | undefined = updatedRefreshData.dynamicQuestionnaire[
              requestBody.activeTabId
            ].sets.find((updatedSet: ISet) => updatedSet.id == set.id);

            if (findSet && updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[index]) {
              //Remove entity
              set.entityIds.forEach((entityId: string) => {
                if (!findSet.entityIds.includes(entityId) && set.entityIds.includes(entityId)) {
                  updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[index].entityIds =
                    set.entityIds.filter((key: string) => key !== entityId);
                  updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                    index
                  ].businessKeys = updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                    index
                  ].businessKeys.filter((bk: IBusinessKeysMap) => bk.entityId !== entityId);
                  delete updatedBody.contextLocals[entityId];
                  findSet.questions.forEach(
                    (newRemovedQuestion: IQuestion, newRemovedQuestionIndex: number) => {
                      if (updatedBody.dynamicQuestionnaire[activeTabId]) {
                        delete updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex]
                          .questions[newRemovedQuestionIndex]?.identificationStatus[entityId];

                        delete updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex]
                          .questions[newRemovedQuestionIndex]?.verificationStatus[entityId];
                      }
                    }
                  );
                }
              });
              //Add entity
              findSet.entityIds.forEach((entityId: string) => {
                if (findSet.entityIds.includes(entityId) && !set.entityIds.includes(entityId)) {
                  const findBK = updatedRefreshData.dynamicQuestionnaire[
                    requestBody.activeTabId
                  ].sets[index].businessKeys.find(
                    (bk: IBusinessKeysMap) => bk.entityId == entityId
                  );
                  updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                    index
                  ].entityIds.push(entityId);
                  if (findBK) {
                    updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                      index
                    ].businessKeys.push(findBK);
                  }

                  updatedBody.contextLocals[entityId] = updatedRefreshData.contextLocals[entityId];

                  findSet.questions.forEach(
                    (newAddedQuestion: IQuestion, newAddedQuestionIndex: number) => {
                      if (
                        updatedBody.dynamicQuestionnaire?.[requestBody.activeTabId]?.sets?.[index]
                          ?.questions?.[newAddedQuestionIndex] &&
                        updatedRefreshData.dynamicQuestionnaire?.[requestBody.activeTabId]?.sets?.[
                          index
                        ]?.questions?.[newAddedQuestionIndex]
                      ) {
                        if (
                          updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[index]
                            .questions[newAddedQuestionIndex].wasChanged &&
                          !updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[index]
                            .questions[newAddedQuestionIndex].wasChanged[entityId]
                        ) {
                          updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                            index
                          ].questions[newAddedQuestionIndex].value[entityId] =
                            updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets[
                              index
                            ].questions[newAddedQuestionIndex].value[entityId];
                        }

                        updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                          index
                        ].questions[newAddedQuestionIndex].responseHasChanged[entityId] =
                          updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets[
                            index
                          ].questions[newAddedQuestionIndex].responseHasChanged[entityId];

                        if (
                          updatedBody.dynamicQuestionnaire[activeTabId] &&
                          updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex] &&
                          updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            newAddedQuestionIndex
                          ]?.identificationStatus
                        ) {
                          updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            newAddedQuestionIndex
                          ].identificationStatus =
                            updatedRefreshData.dynamicQuestionnaire[activeTabId].sets[
                              setIndex
                            ].questions[newAddedQuestionIndex].identificationStatus;
                        }

                        if (
                          updatedBody.dynamicQuestionnaire[activeTabId] &&
                          updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex] &&
                          updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            newAddedQuestionIndex
                          ]?.verificationStatus
                        ) {
                          updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
                            newAddedQuestionIndex
                          ].verificationStatus =
                            updatedRefreshData.dynamicQuestionnaire[activeTabId].sets[
                              setIndex
                            ].questions[newAddedQuestionIndex].verificationStatus;
                        }
                      }
                    }
                  );
                }
              });

              if (updatedBody.dynamicQuestionnaire[activeTabId]?.sets?.[setIndex]?.rootId) {
                updatedBody.dynamicQuestionnaire[activeTabId].sets[setIndex].rootId =
                  updatedRefreshData.dynamicQuestionnaire[activeTabId].sets[setIndex].rootId;
              }
            }

            //Remove set
            if (findSet && updatedSetsLength < oldSetsLength) {
              updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets =
                updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets.filter(
                  (set: ISet) => set.id == findSet.id
                );
            }

            //Add set
            if (updatedSetsLength > oldSetsLength) {
              updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets.forEach(
                (updatedSet: ISet) => {
                  const existsInNewBody: boolean = updatedBody.dynamicQuestionnaire[
                    requestBody.activeTabId
                  ].sets.some((set: ISet) => set.id === updatedSet.id);
                  if (!existsInNewBody) {
                    updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets.push(updatedSet);
                    newKeysToAdd.forEach((key: string) => {
                      updatedBody.contextLocals[key] = updatedRefreshData.contextLocals[key];
                    });
                  }
                }
              );
            }

            set.questions.forEach((question: IQuestion, indexQ: number) => {
              const newQuestion: IValue =
                updatedRefreshData.dynamicQuestionnaire[requestBody.activeTabId].sets[index]
                  ?.questions[indexQ]?.value;

              if (newQuestion) {
                Object.keys(newQuestion)?.find((newEntity: string) => {
                  Object.keys(updatedBody.contextLocals).forEach((parameter: string) => {
                    if (question.wasChanged?.[newEntity]) {
                      Object.keys(question.value).forEach((valueEntity: string) => {
                        if (
                          updatedBody.contextLocals[parameter]?.[question.attributeName] &&
                          valueEntity ==
                            updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[index]
                              .rootId
                        ) {
                          updatedBody.contextLocals[parameter][question.attributeName] =
                            updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[
                              index
                            ]?.questions[indexQ].value[valueEntity]?.[0];
                        }
                      });
                    }
                  });
                });
              }
            });
          }
        );

        updatedBody.contextLocals['header'] = updatedRefreshData.contextLocals['header'];

        requestBody.dynamicQuestionnaire[requestBody.activeTabId].sets =
          updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets;

        //update contextLocals
        requestBody.contextLocals = updatedBody.contextLocals;
        setContextLocals(updatedBody.contextLocals);

        setSelectedEntity(
          updatedBody.dynamicQuestionnaire[requestBody.activeTabId].sets[setIndex].entityIds[
            selectedEntityIndex
          ]
        );

        //update caseHeader
        updatedBody.caseHeader = updatedRefreshData.caseHeader;

        const requestBodyCopy = { ...updatedBody };
        setRequestBody(requestBodyCopy);
        console.log('final requestBody', requestBodyCopy, updatedBody, updatedRefreshData);
      }
    }
  }, [updatedBody]);

  useEffect(() => {
    if (
      requestBody &&
      updatedRefreshData &&
      requestBody.dynamicQuestionnaire &&
      updatedRefreshData.dynamicQuestionnaire
    ) {
      setUpdatedBody(updateBodyWithRefreshData(requestBody, updatedRefreshData, activeTabId));
    }
  }, [updatedRefreshData]);

  useEffect(() => {
    setLoggedUserAndAssignee();

    if (tabsWithError.length > 0 && tabsWithError.map((el) => el.tabId).includes(activeTabId)) {
      if (formIsValid()) {
        const newTabsWithError = tabsWithError.filter((el) => el.tabId != activeTabId);
        setTabsWithError(newTabsWithError);
      }
    }
  }, [requestBody]);

  useEffect(() => {
    if (!isDirty && toExit) {
      DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
      GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
        params: CONSTANTS.PAGES_URL.INBOX,
        submitAction: submitAction,
      });
      setSubmitAction(false);
    }
  }, [toExit]);

  useEffect(() => {
    if (claimClick) {
      setLoggedUserAndAssignee();
      if (data && userInfo.user) {
        const claimRequestBody: IClaim = {
          taskIds: [data.id],
          user: userInfo.user,
        };
        assignTaskToUser(claimRequestBody)
          .then(() => {
            GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: false });
            addAlert({
              type: 'success',
              primaryText: t('CLAIMED'),
              secondaryText: `${t('SUBMIT_CLAIM')}`,
            });
            setHasAssignee(true);
            setAssignee(userInfo.user);
            if (requestBody) {
              const requestBodyTemp = { ...requestBody };
              requestBodyTemp.assignee = claimRequestBody.user;
              setRequestBody(requestBodyTemp);
            }
          })
          .catch(() => {
            addAlert({
              type: 'error',
              primaryText: t('ERROR'),
            });
          })
          .finally(() => {
            setClaimClick(false);
          });
      }
    }
  }, [claimClick]);

  useEffect(() => {
    DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
    setLoading(true);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LOADING_EVT, true);
    retrieveData();
  }, [params.id]);

  useEffect(() => {
    if (data?.dynamicQuestionnaire) {
      const questionnaire: IQuestionnaire | undefined =
        requestBody?.dynamicQuestionnaire[activeTabId];
      if (questionnaire != null && contextLocals != null && selectedEntity != '') {
        updateContextLocals(questionnaire);
      }
      questionnaire?.sets?.forEach((set: ISet, indexS: number) => {
        set.questions.forEach((question: IQuestion, indexQ: number) => {
          // Initialize an empty object for wasChanged
          if (!question.wasChanged) {
            question.wasChanged = {};
          }
        });
      });
    }
  }, [selectedEntity]);

  // #region TASK_BUTTONS

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      saveTaskAsDraft(true);

      return () => {
        setSaveTaskAsDraftAndExitClick(false);
      };
    }
  }, [saveTaskAsDraftAndExitClick]);

  useEffect(() => {
    if (saveTaskAsDraftClick) {
      saveTaskAsDraft(false);
      setSaveTaskAsDraftClick(false);
    } else {
      setShowMainLoader(false);
    }
  }, [saveTaskAsDraftClick]);

  useEffect(() => {
    if (submitClick) {
      if (requestBody.enableSaveSync && isDirty) {
        setOpenSubmitConfirmation(true);
      } else {
        submit();
      }
      setSubmitClick(false);
    }
  }, [submitClick]);

  const submitConfirmation = () => {
    if (requestBody.enableSaveSync) {
      requestBody.bypassSaveSync = true;
      submit();
    } else {
      submit();
    }
  };

  // #endregion

  const fetchCaseRolesByProject = () => {
    getCaseRolesByProject(projectName).then((caseRoles) => {
      const filteredCaseRoles = caseRoles.filter((role: ICaseRoles) => role.max > 0);
      filteredCaseRoles.forEach((item: ICaseRoles, i: number) => {
        if (GeneralUtils.checkIfOwner(item)) {
          filteredCaseRoles.splice(i, 1);
          filteredCaseRoles.unshift(item);
        }
      });
      setCaseRoles(filteredCaseRoles);
      fetchCase();

      const actPermissions = GeneralUtils.getCasePermissionsFromCaseRoles(
        assignedCaseRoles,
        caseRoles,
        userInfo.user.toLowerCase()
      );
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS, actPermissions);
    });
  };

  const fetchCase = () => {
    if (data) {
      getCase(data.caseId)
        .then((data) => {
          if (data?.caseRoles?.length) {
            const caseRoles = data.caseRoles.filter((role) => role.project === projectName);
            setAssignedCaseRoles(structuredClone(caseRoles));
          } else {
            LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS);
          }
        })
        .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_GETTING_CASE') }));
    }
  };

  const loadTab = (screen: IScreen, tabId: string) => {
    setAssignee(screen.assignee);
    screen.assignee ? setHasAssignee(true) : setHasAssignee(false);

    navigateToTab(screen, tabId);

    if (getTabTypeById(tabId) == CONSTANTS.TAB_TYPES.DYNAMIC_QUESTIONNAIRE) {
      const quest: IQuestionnaire | undefined = DynamicQuestionnaireUtils.getDynamicQuestionnaire(
        screen,
        tabId
      );

      quest?.sets[setIndex].questions.forEach((question: IQuestion) => {
        if (question.type == 'ENTITY_DATA_TABLE') {
          Object.keys(question.dataTable.tableAttributes).forEach((key: string) => {
            if (question.dataTable.tableAttributes[key].isNewItem) {
              question.dataTable.tableAttributes[key].isNewItem = false;
            }
          });
        }
      });

      if (!(quest?.sets || quest?.sets?.length)) {
        addAlert({
          type: 'error',
          primaryText: t('ERROR_NO_SETS'),
        });
      }
    }
  };

  const getTabById = (tabId: string = activeTabId): void => {
    if (activeTabId != tabId) {
      setSetIndex(0);
    }

    if (data || (params.id && tabId)) {
      getTab(data ? data.id : (params.id as string), tabId)
        .then((screen: IScreen) => {
          loadTab(screen, tabId);
        })
        .catch((error: AxiosError) => {
          const errorCode = error.response?.status;
          if (errorCode === 307) {
            addAlert({
              type: 'error',
              primaryText: t('DENIED_TAB'),
            });
            setShowMainLoader(true);
            loadTab(error.response?.data as IScreen, tabId);
          }
          if (errorCode === 401) {
            navigate(CONSTANTS.PAGES_URL.INBOX);
            addAlert({
              type: 'error',
              primaryText: t('ACCESS_NOT_GRANTED'),
            });
          }
          activeTabIdHandleCatches(error.message);
        })
        .finally(() => setShowMainLoader(false));
    }
  };

  const updateLiveData = (newData: string) => {
    const newScreen: IScreen = JSON.parse(newData);
    // Here if updated task id matches with the current task then only it will reload the page and update the current data
    if (newScreen.id == params.id) {
      if (!disableSSEDynamicQuestionnaire) {
        setUpdatedRefreshData(newScreen);
      }
      setCaseHeaderData(newScreen.caseHeader);
    }
  };

  /**
   * We are using a Debounce pattern so we can avoid the useeffect in here being called twice.
   * It is happening because this Task is re-rendered when redirecting on route changes.
   * This isn't an ideal solution, so it should be rethought
   * TODO
   */
  const updateCancelTask = (cancelId: string) => {
    if (cancelId == params.id) {
      setCancelTask(true);
    }
  };
  const updateLiveDataEventListener = (e: MessageEvent) => {
    updateLiveData(e.data);
  };
  const updateCancelTaskEventListener = (e: MessageEvent) => {
    updateCancelTask(e.data);
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.TASK_LOADING_EVT, taskRefresh(params.id), true, {
    screen_refresh: updateLiveDataEventListener,
    cancelled_tasks: updateCancelTaskEventListener,
  });

  const getTabTypeById = (tabId: string) => {
    return data?.tabs.find((x) => x.tabId == tabId)?.type;
  };

  const retrieveData = () => {
    console.debug('Requesting Task with ID', params.id);
    if (params.id && !params.tabId) {
      if (!internalStatus) {
        getTask(params.id)
          .then((response) => {
            if (typeof response === 'number') {
              if (response === 307) {
                setShowMainLoader(true);
              }
              if (response === 401) {
                navigate(CONSTANTS.PAGES_URL.INBOX);
                addAlert({
                  type: 'error',
                  primaryText: t('ACCESS_NOT_GRANTED'),
                });
              }
            } else {
              prepareTask(response);
              setShowMainLoader(false);
            }
          })
          .catch((error: string) => {
            activeTabIdHandleCatches(error);
            setShowMainLoader(false);
          });
      } else {
        getCompletedTask(params.id)
          .then((screen: IScreen) => {
            prepareTask(screen);
          })
          .catch((error: string) => {
            activeTabIdHandleCatches(error);
          });
      }
    } else if (params.id && params.tabId) {
      getTabById(params.tabId);
    }
  };

  const setStatusByScreen = (screen: IScreen) => {
    setStatus(
      screen?.status != '' &&
        screen.statusOptions.find((s: IStatusOptions) => s.label == screen.status)
        ? screen.status
        : (screen?.statusOptions?.find((s: IStatusOptions) => s.isDefault)?.label ?? '')
    );
  };

  const prepareTask = (screen: IScreen) => {
    setData(screen);
    if (params.tabId) {
      setActiveTabId(params.tabId);
    } else {
      setActiveTabId(screen.activeTabId);
    }
    setCaseHeaderData(screen.caseHeader);
    setAssignee(screen.assignee);
    screen.assignee ? setHasAssignee(true) : setHasAssignee(false);
    if (screen.statusOptions == null) {
      console.error(
        'Error processing screen status options. Screen Status options are null',
        screen
      );
    }
    setStatusByScreen(screen);
    setLoading(false);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LOADING_EVT, false);
    if (!activeTabId && screen.tabs.length > 0) {
      const activeTabIndex = screen.tabs.findIndex((tab: ITab) => tab.tabId == screen.activeTabId);
      const activeTab = screen.tabs[activeTabIndex];
      chooseTab(activeTab.type, activeTab.tabId);
    }
  };

  const setLoggedUserAndAssignee = () => {
    const auxLoggedUser = userInfo.user;
    if (data) {
      data.assignee = auxLoggedUser;
    }
    setLoggedUser(auxLoggedUser);
    setAssignee(requestBody?.assignee);
  };

  const updateContextLocals: updateContextLocalsFunction = (
    dynamicQuestionnaire: IQuestionnaire
  ) => {
    let isRoot = false;

    //Check if newSelectedEntity is the Root Entity
    if (
      dynamicQuestionnaire?.sets[setIndex].root &&
      selectedEntity == dynamicQuestionnaire?.sets[setIndex].rootId
    ) {
      isRoot = true;
    }

    const newContextLocals: IContextLocals = GeneralUtils.deepCopy<IContextLocals>(contextLocals);

    //Get the newSelectedEntity parent entity
    const parentGUID: string | null =
      newContextLocals[isRoot ? 'rootEntity' : selectedEntity]?.parentGUID?.toString() ?? null;

    //Set in the contextLocals the parent entity corresponding object
    if (newContextLocals[isRoot ? 'rootEntity' : selectedEntity]) {
      newContextLocals[isRoot ? 'rootEntity' : selectedEntity].parent =
        isRoot || parentGUID == null ? {} : newContextLocals[parentGUID];

      if (!isRoot) {
        newContextLocals[selectedEntity].rootEntity = newContextLocals['rootEntity'];
      }
    }

    setLoading(false);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LOADING_EVT, false);
    setContextLocals(newContextLocals);
  };

  const navigateToTab = (screen: IScreen, tabId: string) => {
    setData(screen);
    setCaseHeaderData(screen.caseHeader);
    if (screen.statusOptions == null) {
      console.error(
        'Error processing screen status options. Screen Status options are null',
        screen
      );
    }
    setStatusByScreen(screen);

    if (screen.tabs.length > 0) {
      const activeTab = screen.tabs.find((value) => value.tabId === tabId);
      if (activeTab && tabId === screen.activeTabId) {
        chooseTab(activeTab.type, activeTab.tabId);
      }
    }
  };

  const chooseTab = (tabType: string, tabId: string) => {
    if (!window.location.href.includes(CONSTANTS.PAGES_URL.TASK)) {
      return;
    }

    switch (tabType) {
      case CONSTANTS.TAB_TYPES.DYNAMIC_QUESTIONNAIRE:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`questionnaire/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.CASE_360:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`case360/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.SCREENING:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`screening/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.CASE_OVERVIEW:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`case-overview/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.RFI:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`rfi`);
        break;
      case CONSTANTS.TAB_TYPES.TRANSACTION_REVIEWER:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`transactionReviewer/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.SAR:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`sar-ui/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.DUE_DILIGENCE:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`due-diligence/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.NARRATIVE:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`narrative/${tabId}`);
        break;
      case CONSTANTS.TAB_TYPES.TIMER:
        history.replace(CONSTANTS.PAGES_URL.INBOX);
        navigate(`timer/${tabId}`);
        break;
      default:
        return null;
    }
  };

  const updateCaseHeader = (attribute: string, value: Array<string>) => {
    const dynamicQuestionnaire: IQuestionnaire | undefined =
      DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);
    if (dynamicQuestionnaire?.sets[setIndex].root) {
      if (attribute && value) {
        // TODO: This logic needs to be refactored
        headerData = caseHeaderData;
        const val: string = value.toString();
        if (headerData?.find((a: ICaseHeaderAttributes) => a.attributeName === attribute)) {
          headerData!.find(
            (a: ICaseHeaderAttributes) => a.attributeName === attribute
          )!.attributeValue = val;
        }
      }
    }
  };

  const bodyAnswerUpdate: bodyAnswerUpdateFunction = (attribute: string, value: Array<string>) => {
    const dynamicQuestionnaire: IQuestionnaire | undefined =
      DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);
    updateCaseHeader(attribute, value);
    if (
      requestBody != undefined &&
      activeTabId != undefined &&
      requestBody.dynamicQuestionnaire[activeTabId]
    ) {
      const questionList = Array.isArray(requestBody)
        ? []
        : dynamicQuestionnaire?.sets[setIndex]?.questions || [];

      const auxBody = GeneralUtils.deepCopy<IScreen>(requestBody);

      for (let i = 0; i < questionList.length; i++) {
        if (questionList[i].attributeName === attribute) {
          auxBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[i].value[
            selectedEntity
          ] = value;

          const oldValue =
            requestBody?.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[i] &&
            requestBody?.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[i].value[
              selectedEntity
            ]?.length
              ? requestBody?.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[i].value[
                  selectedEntity
                ]
              : [];

          const oldValueSseActive = auxBody?.dynamicQuestionnaire?.[activeTabId]?.sets?.[setIndex]
            ?.questions?.[i]?.value?.[selectedEntity]?.length
            ? auxBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[i].value[
                selectedEntity
              ]
            : [];

          const oldValueSseNotActive = data?.dynamicQuestionnaire?.[activeTabId]?.sets?.[setIndex]
            ?.questions?.[i]?.value?.[selectedEntity]?.length
            ? data.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[i].value[
                selectedEntity
              ]
            : [];

          const oldDataValue: string[] = sseActivate ? oldValueSseActive : oldValueSseNotActive;

          if (sseActivate) {
            setSSEActivate(false);
          }

          const hasChanged = value.toString() == oldDataValue.toString();
          auxBody.dynamicQuestionnaire[activeTabId].sets.forEach((set: ISet, index: number) => {
            let questionChanged =
              requestBody.dynamicQuestionnaire[activeTabId].sets[index].questions[i]?.wasChanged;

            if (questionChanged) {
              Object.keys(questionChanged).forEach((key: string) => {
                questionChanged = { [key]: !hasChanged };
                auxBody.dynamicQuestionnaire[activeTabId].sets[index].questions[i].wasChanged[key] =
                  questionChanged[key];
              });
            }
          });
          const newhasChanged = !ArrayUtils.compareArraysAsStr(value, oldValue);
          if (newhasChanged) {
            auxBody.dynamicQuestionnaire[activeTabId].sets[setIndex].questions[
              i
            ].responseHasChanged[selectedEntity] = newhasChanged;
          }
          break;
        }
      }
      setRequestBody(auxBody);
      if (!isSetEntityChanged) {
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, {
          isDirty: !objectUtils.compareObjects(
            requestBody.dynamicQuestionnaire,
            auxBody.dynamicQuestionnaire
          ),
        });
      } else {
        setIsSetEntityChanged(false);
      }
    }
  };

  const buildRequestBody = () => {
    const requestBodyWithUpdatedContext: IScreen = GeneralUtils.deepCopy<IScreen>(requestBody);
    requestBodyWithUpdatedContext.status = status;
    if (formTagRes && Object.keys(formTagRes)?.length) {
      requestBodyWithUpdatedContext.casePopulationFlags = {
        method: CONSTANTS.MANUAL,
        populationFlags: formTagRes,
      };
    }
    requestBodyWithUpdatedContext.caseHeader = caseHeaderData;

    if (getTabTypeById(activeTabId) == CONSTANTS.TAB_TYPES.DYNAMIC_QUESTIONNAIRE) {
      if (contextLocals !== undefined) {
        for (const [key] of Object.entries(contextLocals)) {
          if (key == 'rootEntity') {
            delete contextLocals['rootEntity'].parent;
          } else {
            delete contextLocals[key]?.parent;
            delete contextLocals[key]?.rootEntity;
          }
        }

        requestBodyWithUpdatedContext.contextLocals = contextLocals;
      }
    }

    return requestBodyWithUpdatedContext;
  };

  const newRemovedRows: Array<IDynamicTableRemovedRows> = [];
  const removeEmptyBKsRows = () => {
    let rowWasRemoved: boolean = false;
    if (requestBody?.dynamicQuestionnaire?.[activeTabId]) {
      requestBody?.dynamicQuestionnaire[activeTabId].sets.forEach((set: ISet, setIndex: number) => {
        set.questions.forEach((question: IQuestion) => {
          if (question.type == 'ENTITY_DATA_TABLE') {
            const dynamicTable = question.dataTable;
            if (dynamicTable) {
              let count = 0;

              Object.keys(dynamicTable.tableAttributes).forEach((key: string) => {
                dynamicTable.tableAttributes[key]?.attributes.forEach((attr: IAttributeObject) => {
                  Object.keys(attr.attribute).forEach((index: string) => {
                    if (!attr.attribute[index] && attr.isBusinessKey) {
                      rowWasRemoved = true;
                      delete dynamicTable.tableAttributes[key];
                      question.dataTable = dynamicTable;
                    }
                  });
                });
                if (rowWasRemoved) {
                  count++;
                  rowWasRemoved = false;
                  const existingRowIndex = newRemovedRows.findIndex(
                    (row) => row.tableName === dynamicTable.tableName
                  );

                  if (existingRowIndex !== -1) {
                    newRemovedRows[existingRowIndex].removedRowsCount = count;
                  } else if (count > 0) {
                    const deleting = {
                      removedRowsCount: count,
                      tableName: dynamicTable.tableName,
                    };
                    setRemovedRows((prevRemovedRows) => [...prevRemovedRows, deleting]);
                    newRemovedRows.push(deleting);
                  }
                }
              });
            }
          }
        });
      });
    }
  };

  const createAlertMessage = (removalInfo: Array<IDynamicTableRemovedRows>): React.JSX.Element => {
    const bulletPoints = (
      <ul>
        {removalInfo.map((info: IDynamicTableRemovedRows, index: number) => (
          <li key={index}>
            {t('DYNAMIC_TABLE.ROWS_REMOVED', {
              count: info.removedRowsCount,
              tableName: info.tableName,
            })}
          </li>
        ))}
      </ul>
    );
    return bulletPoints;
  };

  useEffect(() => {
    if (removedRows.length) {
      addAlert({
        type: 'info',
        primaryText: createAlertMessage(removedRows),
      });
      setRemovedRows([]);
    }
  }, [removedRows]);

  const saveTaskAsDraft = async (toExit: boolean) => {
    if (requestBody && activeTabId) {
      setShowMainLoader(true);
      //setIsDirty needs to be before save is finished to avoid issue with saveSync not validating
      setIsDirty(false);

      sendDraft(buildRequestBody())
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('CHANGES_SAVED'),
          });
          setToExit(toExit);
          setTableSaved(true);
          setErrorObject(undefined);
          setRemovedRows([]);
          // TODO: Do not remove this without refactoring Save & Get Logic
          getTabById();
        })
        .catch((error) => {
          setTableSaved(false);
          questionDataTableIsValid(error);
          addAlert({
            type: 'error', // TODO: Add custom status codes
            primaryText: error.includes('business key') ? t('NO_BUSINESS_KEY') : t('ERROR_MESSAGE'),
          });
        });
    }
  };

  const submit = async () => {
    if (requestBody && activeTabId) {
      const requestBodyWithUpdatedContext: IScreen = GeneralUtils.deepCopy<IScreen>(requestBody);
      requestBodyWithUpdatedContext.status = status;
      if (formTagRes && Object.keys(formTagRes)?.length) {
        requestBodyWithUpdatedContext.casePopulationFlags = {
          method: CONSTANTS.MANUAL,
          populationFlags: formTagRes,
        };
      }
      requestBodyWithUpdatedContext.caseHeader = caseHeaderData;
      if (contextLocals !== undefined) {
        for (const [key] of Object.entries(contextLocals)) {
          if (key == 'rootEntity') {
            delete contextLocals['rootEntity'].parent;
          } else {
            delete contextLocals[key].parent;
            delete contextLocals[key].rootEntity;
          }
        }
        requestBodyWithUpdatedContext.contextLocals = contextLocals;
      }
      console.debug('Submit Message body', requestBodyWithUpdatedContext);
      setIsDirty(false);
      submitTask(JSON.stringify(requestBodyWithUpdatedContext))
        .then(() => {
          setToExit(true);
          setSubmitAction(true);
          GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: false });
          if (params.id) {
            updateCancelTask(params.id);
          }
          setErrorObject(undefined);
        })
        .catch((error: ITaskErrorCode | string) => {
          setSubmitClick(false);
          if (typeof error == 'string' || error.errorCode == 500) {
            addAlert({
              type: 'error',
              primaryText: t('ERROR_MESSAGE'),
            });
          } else {
            if (requestBody?.dynamicQuestionnaire) {
              formIsValid();
              if (error.errorCode == 422) {
                questionDataTableIsValid(error.errors as ITableError[]);
              } else {
                setErrorObject(undefined);
              }
            }
            if (error.errorCode == 409) {
              //when 409 conflict, launch modal for savesync
              setOpenSubmitConfirmation(true);
            } else {
              if (error.errors?.length && error.errorCode != 422) {
                setTabsWithError(error.errors as ITaskError[]);
                addAlert({
                  type: 'error',
                  primaryText: t('TASK.TAB_VALIDATION_ERROR_MSG', {
                    count: error.errors.length,
                  }),
                  secondaryText: (error.errors as ITaskError[])
                    .map((e: ITaskError) => e.title)
                    .join(),
                });
              }
            }
          }
        });
    }
  };

  const questionHasError = (
    requestBodyQuestion: IQuestion,
    setIndex: number,
    entityId: string,
    setsWithErrorTemp: string[],
    entitiesWithErrorTemp: string[],
    hasError: boolean
  ) => {
    const dynamicQuestionnaire: IQuestionnaire | undefined =
      DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);
    if (dynamicQuestionnaire) {
      if (hasError) {
        //catch if set has errors
        if (!setsWithErrorTemp.includes(dynamicQuestionnaire.sets[setIndex].id)) {
          setsWithErrorTemp.push(dynamicQuestionnaire.sets[setIndex].id);
        }
        if (!entitiesWithErrorTemp.includes(entityId)) {
          entitiesWithErrorTemp.push(entityId);
        }
      }

      //instatiate map inside
      if (!questionErrors.has(requestBodyQuestion.id)) {
        questionErrors.set(requestBodyQuestion.id, new Map<string, boolean>());
      }

      //set error to questionErrors
      const questionValueMap = questionErrors.get(requestBodyQuestion.id) as Map<string, boolean>;
      questionErrors.set(requestBodyQuestion.id, questionValueMap.set(entityId, hasError));
      setQuestionErrorsState(questionErrors);
    }
  };

  const questionIsRequired: questionIsRequiredFunction = (
    question: IQuestion,
    parentSetIndex: number,
    entityId: string
  ) => {
    const dynamicQuestionnaire: IQuestionnaire | undefined =
      DynamicQuestionnaireUtils.getDynamicQuestionnaire(requestBody, activeTabId);

    if (
      contextLocals &&
      !SpelUtils.expressionValidation(question.visible, question.visibilityExpression, {
        contextLocals: contextLocals,
        userRole: userInfo.role,
        ...contextLocals[
          dynamicQuestionnaire?.sets[parentSetIndex].root &&
          entityId == dynamicQuestionnaire.sets[parentSetIndex].rootId
            ? 'rootEntity'
            : entityId
        ],
      })
    ) {
      return false;
    }

    return (
      contextLocals &&
      SpelUtils.expressionValidation(question.required, question.requiredExpression, {
        contextLocals: contextLocals,
        userRole: userInfo.role,
        ...contextLocals[
          dynamicQuestionnaire?.sets[parentSetIndex].root &&
          entityId == dynamicQuestionnaire.sets[parentSetIndex].rootId
            ? 'rootEntity'
            : entityId
        ],
      })
    );
  };

  const questionDataTableReadError = (errors: ITableError[], question: IQuestion) => {
    let hasError: boolean = false;
    const entityIds: Array<string> = [];
    const questionIds: Array<string> = [];
    const messages: Array<string> = [];

    errors.map((error) => {
      entityIds.push(error.entityId);
      questionIds.push(error.questionId);
      if (!messages.includes(error.errorMessage)) {
        messages.push(error.errorMessage);
      }
    });

    question.dataTable.entityIds.forEach((entityId: string) => {
      if (entityIds.includes(entityId)) {
        hasError = true;
      }
    });
    const auxErrorObject: IBusinessKeysErrorObject = {
      hasError: hasError,
      entityIds: entityIds,
      questions: questionIds,
      message: messages,
    };
    setErrorObject(auxErrorObject);

    return auxErrorObject;
  };

  const questionDataTableIsValid = (errors: ITableError[]) => {
    if (!requestBody?.dynamicQuestionnaire[activeTabId]) return false;
    const localFormErrorMessages = formErrorMessages;
    localFormErrorMessages.splice(0, localFormErrorMessages.length);
    const setsWithErrorTemp: string[] = [];
    const entitiesWithErrorTemp: string[] = [];
    let result: boolean = true;
    requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex].questions.forEach(
      (requestBodyQuestion) => {
        if (requestBodyQuestion.type === 'ENTITY_DATA_TABLE') {
          const isQuestionDataTableValid: IBusinessKeysErrorObject = questionDataTableReadError(
            errors,
            requestBodyQuestion
          );

          if (isQuestionDataTableValid.hasError) {
            isQuestionDataTableValid.entityIds.forEach((entityId: string) => {
              questionHasError(
                requestBodyQuestion,
                setIndex,
                entityId,
                setsWithErrorTemp,
                entitiesWithErrorTemp,
                true
              );

              formErrorMessages.push(
                t('TASK.FORM.FORM_ERROR_REGEX', {
                  fieldName: requestBodyQuestion.dataTable.tableName,
                  entityId:
                    requestBodyQuestion.entitySubType +
                    ' ' +
                    (requestBodyQuestion.dataTable.entityIds.indexOf(entityId) + 1),
                })
              );

              addAlert({
                type: 'error',
                primaryText: t('DYNAMIC_TABLE.TABLE_VALIDATION_ERROR_MSG', {
                  tableName: requestBodyQuestion.dataTable.tableName,
                }),
                secondaryText: isQuestionDataTableValid.message.join(),
              });
            });
            result = false;
          }
        } else {
          requestBodyQuestion?.dataTable?.entityIds?.forEach((entityId) => {
            questionHasError(
              requestBodyQuestion,
              setIndex,
              entityId,
              setsWithErrorTemp,
              entitiesWithErrorTemp,
              false
            );
          });
          result = true;
        }
      }
    );
    return result;
  };

  const questionIsValid = (
    setsWithErrorTemp: string[],
    entitiesWithErrorTemp: string[],
    setIndex: number,
    entityId: string,
    questionIndex: number
  ) => {
    let result: boolean = true;
    const requestBodyQuestion: IQuestion | undefined =
      requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex].questions[questionIndex];

    if (requestBodyQuestion) {
      if (requestBodyQuestion.type !== 'ENTITY_DATA_TABLE') {
        //required
        if (
          questionIsRequired(requestBodyQuestion, setIndex, entityId) &&
          DynamicQuestionnaireUtils.questionIsEmpty(requestBodyQuestion, entityId)
        ) {
          questionHasError(
            requestBodyQuestion,
            setIndex,
            entityId,
            setsWithErrorTemp,
            entitiesWithErrorTemp,
            true
          );
          if (
            requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex].entityIds.indexOf(
              entityId
            ) != undefined
          ) {
            formErrorMessages.push(
              t('TASK.FORMFORM_ERROR_REQUIRED', {
                fieldName: requestBodyQuestion.label,
                entityId:
                  requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex].entitySubType +
                  ' ' +
                  (requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex].entityIds.indexOf(
                    entityId
                  ) +
                    1),
              })
            );
          }
          result = false;
        }
        result = false;
      }

      //validations
      if (requestBodyQuestion.validation) {
        for (const val of requestBodyQuestion.validation) {
          switch (val.type) {
            case 'REGEX':
              if (!new RegExp(val.value).test(requestBodyQuestion.value[entityId][0])) {
                //
                questionHasError(
                  requestBodyQuestion,
                  setIndex,
                  entityId,
                  setsWithErrorTemp,
                  entitiesWithErrorTemp,
                  true
                );

                if (
                  requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex].entityIds.indexOf(
                    entityId
                  ) != undefined
                ) {
                  formErrorMessages.push(
                    t('TASK.FORM.FORM_ERROR_REGEX', {
                      fieldName: requestBodyQuestion.label,
                      entityId:
                        requestBody?.dynamicQuestionnaire[activeTabId]?.sets[setIndex]
                          .entitySubType +
                        ' ' +
                        (requestBody?.dynamicQuestionnaire[activeTabId]?.sets[
                          setIndex
                        ].entityIds.indexOf(entityId) +
                          1),
                    })
                  );
                }
                result = false;
              }
          }
        }
      } else {
        questionHasError(
          requestBodyQuestion,
          setIndex,
          entityId,
          setsWithErrorTemp,
          entitiesWithErrorTemp,
          false
        );
        result = true;
      }
    }

    return result;
  };

  const formIsValid = () => {
    if (!requestBody?.dynamicQuestionnaire?.[activeTabId]) return false;
    //clearing formErrorMessages
    const localFormErrorMessages = formErrorMessages;
    localFormErrorMessages.splice(0, localFormErrorMessages.length);
    const setsWithErrorTemp: string[] = [];
    const entitiesWithErrorTemp: string[] = [];
    requestBody?.dynamicQuestionnaire[activeTabId].sets.forEach((set: ISet, setIndex: number) => {
      set.entityIds.forEach((entityId: string) => {
        set.questions.forEach((question: IQuestion) => {
          if (question.type !== 'ENTITY_DATA_TABLE') {
            questionIsValid(
              setsWithErrorTemp,
              entitiesWithErrorTemp,
              setIndex,
              entityId,
              set.questions.indexOf(question)
            );
          }
        });
      });
    });

    setFormErrorMessages(localFormErrorMessages);
    setSetsWithError(setsWithErrorTemp);
    return setsWithErrorTemp.length == 0;
  };

  const activeTabIdHandleCatches = (error: string) => {
    setLoading(false);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LOADING_EVT, false);
    setError(error);
  };

  const tabChanged = (newActiveTabId: string) => {
    removeEmptyBKsRows();
    setActiveTabId(newActiveTabId);
    if (isDirty && hasAssignee && assignee === userInfo.user && !internalStatus) {
      setShowMainLoader(true);
      setSaveTaskAsDraftClick(true);
    } else {
      getTabById(newActiveTabId);
    }
  };

  if (error) {
    console.error('error: ', error);
    return <p>{t('ERROR_FETCHING')}</p>;
  }

  if (!data || !params.id || !activeTabId) {
    return <>{showMainLoader && <Loader isMainLoader />}</>;
  }

  const hasNoSets = (): boolean => {
    return data.tabs == undefined || data.tabs.length == 0;
  };

  document.addEventListener(EVENTS_CONSTANTS.TASK_VIEW_CHANGE, (e: any) => {
    setTaskView(e.detail);
  });

  const casePermissions: Array<IPermission> = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const saveSyncPermission: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.SAVE_SYNC,
    casePermissions
  );

  return (
    <TaskViewContext.Provider value={taskView}>
      {showMainLoader && <Loader isMainLoader />}
      <ErrorBoundary history={history} params={params}>
        <Box className={`task_container ${taskView ? 'task_view' : ''}`}>
          <ConfirmationModal
            showConfirmButton
            // Modal only shows when saveSync is active.
            // If questionnaire has changes (isDirty) Modal is refferent to changes,
            // if it doenst, modal is launched from submit error 409, which means backend is still processing
            // clicking yes in either situation, overrides back changes
            openModal={openSubmitConfirmation}
            setOpenModal={setOpenSubmitConfirmation}
            action={submitConfirmation}
            content={{
              title: t('SUBMIT'),
              text: t(
                isDirty
                  ? 'SUBMIT_CONFIRMATION_MODAL_TEXT'
                  : 'SUBMIT_CONFIRMATION_MODAL_TEXT_BEING_PROCESSED'
              ),
            }}
            disableYes={!isDirty && !saveSyncPermission}
          />
          <Box>
            <ApolloProvider client={client}>
              {data.caseId && (
                <CaseHeader
                  contextLocals={data.contextLocals}
                  assignee={assignee}
                  loggedUser={loggedUser}
                  attributes={caseHeaderData}
                  setAttributes={setCaseHeaderData}
                  caseId={data.caseId}
                  caseDisplayId={data.caseDisplayId}
                  formId={data.id}
                  setSubmitClick={setSubmitClick}
                  setSaveTaskAsDraftAndExitClick={setSaveTaskAsDraftAndExitClick}
                  setClaimClick={setClaimClick}
                  setSaveTaskAsDraftClick={setSaveTaskAsDraftClick}
                  hasAssignee={hasAssignee}
                  status={status}
                  setStatus={setStatus}
                  hasNoSets={hasNoSets()}
                  statusOptions={data.statusOptions}
                  cancelTask={cancelTask}
                  tenant={data.tenant}
                  fileType={fileType}
                  maxFileSize={maxFileSize}
                  slaLabels={slaLabels}
                  disabledActions={internalStatus}
                  isDirty={isDirty}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  requestBody={requestBody}
                  setRequestBody={setRequestBody}
                  caseRoles={caseRoles}
                  processBar={data?.processBar}
                  setToExit={setToExit}
                />
              )}
            </ApolloProvider>
          </Box>
          {data.tabs.length > 0 && (
            <TabBarComponent
              tabs={data.tabs}
              activeTabId={activeTabId}
              tabChanged={tabChanged}
              invalidTabs={tabsWithError}
              setModalConfirmationClick={setModalConfirmationClick}
              checkConfirmationModal={checkConfirmationModal}
              contextLocals={contextLocals}
            />
          )}
          <ApolloProvider client={client}>
            <Outlet
              context={{
                data,
                loading,
                setLoading,
                setCaseHeaderData,
                caseHeaderData,
                isDirty,
                submitClick,
                setSubmitClick,
                saveTaskAsDraftAndExitClick,
                setSaveTaskAsDraftAndExitClick,
                claimClick,
                setClaimClick,
                saveTaskAsDraftClick,
                setSaveTaskAsDraftClick,
                hasAssignee,
                setHasAssignee,
                status,
                setStatus,
                activeTabId,
                setError,
                requestBody,
                setRequestBody,
                contextLocals,
                setContextLocals,
                tabsWithError,
                setTabsWithError,
                formTagRes,
                isCompletedTask,
                assignee,
                loggedUser,
                modalConfirmationClick,
                setModalConfirmationClick,
                checkConfirmationModal,
                setCheckConfirmationModal,
                setsWithError,
                setSetsWithError,
                questionErrorsState,
                setQuestionErrorsState,
                formErrorMessages,
                setFormErrorMessages,
                bodyAnswerUpdate,
                setIndex,
                setSetIndex,
                questionIsRequired,
                updateContextLocals,
                selectedEntity,
                setSelectedEntity,
                errorObject,
                setErrorObject,
                tableSaved,
                setTableSaved,
                headerHeight,
                updatedRefreshData,
                setSelectedEntityIndex,
                selectedEntityIndex,
                setIsSetEntityChanged,
                isSetEntityChanged,
                badgeHighlight,
                dataModelVersion,
                removeEmptyBKsRows,
                updatedBody,
                fileType: fileType,
                maxFileSize: maxFileSize,
              }}
            />
          </ApolloProvider>
        </Box>
      </ErrorBoundary>
    </TaskViewContext.Provider>
  );
};

export function useIScreen() {
  return useOutletContext<{
    data: IScreen;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    caseHeaderData: ICaseHeaderAttributes[];
    setCaseHeaderData: React.Dispatch<React.SetStateAction<ICaseHeaderAttributes[]>>;
    isDirty: boolean;
    submitClick: boolean;
    setSubmitClick: React.Dispatch<React.SetStateAction<boolean>>;
    saveTaskAsDraftAndExitClick: boolean;
    setSaveTaskAsDraftAndExitClick: React.Dispatch<React.SetStateAction<boolean>>;
    claimClick: boolean;
    setClaimClick: React.Dispatch<React.SetStateAction<boolean>>;
    saveTaskAsDraftClick: boolean;
    setSaveTaskAsDraftClick: React.Dispatch<React.SetStateAction<boolean>>;
    hasAssignee: boolean;
    setHasAssignee: React.Dispatch<React.SetStateAction<boolean>>;
    status: string;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    activeTabId: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    requestBody: IScreen;
    setRequestBody: React.Dispatch<React.SetStateAction<IScreen>>;
    contextLocals: IContextLocals;
    setContextLocals: React.Dispatch<React.SetStateAction<IContextLocals>>;
    tabsWithError: ITaskError[];
    setTabsWithError: React.Dispatch<React.SetStateAction<ITaskError[]>>;
    formTagRes: ITagSubmit;
    isCompletedTask: boolean;
    assignee: string;
    loggedUser: string;
    checkConfirmationModal: boolean;
    setCheckConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
    modalConfirmationClick: boolean;
    setModalConfirmationClick: React.Dispatch<React.SetStateAction<boolean>>;
    setsWithError: string[];
    setSetsWithError: React.Dispatch<React.SetStateAction<string[]>>;
    questionErrorsState: Map<string, Map<string, boolean>>;
    setQuestionErrorsState: React.Dispatch<React.SetStateAction<Map<string, Map<string, boolean>>>>;
    formErrorMessages: string[];
    setFormErrorMessages: React.Dispatch<React.SetStateAction<string[]>>;
    bodyAnswerUpdate: bodyAnswerUpdateFunction;
    setIndex: number;
    setSetIndex: React.Dispatch<React.SetStateAction<number>>;
    questionIsRequired: questionIsRequiredFunction;
    updateContextLocals: updateContextLocalsFunction;
    selectedEntity: string;
    setSelectedEntity: React.Dispatch<React.SetStateAction<string>>;
    tableSaved: boolean;
    setTableSaved: React.Dispatch<React.SetStateAction<boolean>>;
    businessKeys: Array<string>;
    setBusinessKeys: React.Dispatch<React.SetStateAction<Array<string>>>;
    businessKeysChanged: Array<string>;
    setBusinessKeysChanged: React.Dispatch<React.SetStateAction<Array<string>>>;
    errorObject: IBusinessKeysErrorObject;
    setErrorObject: React.Dispatch<React.SetStateAction<IBusinessKeysErrorObject>>;
    headerHeight: number;
    updatedRefreshData: IScreen;
    setSelectedEntityIndex: React.Dispatch<React.SetStateAction<number>>;
    selectedEntityIndex: number;
    setIsSetEntityChanged: React.Dispatch<React.SetStateAction<boolean>>;
    isSetEntityChanged: boolean;
    badgeHighlight: boolean;
    dataModelVersion: IDataModelVersion | undefined;
    removeEmptyBKsRows: () => void;
    updatedBody: IScreen;
    fileType: string[];
    maxFileSize?: number;
  }>();
}

export default Task;

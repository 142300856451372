import React from 'react';
import { DotType } from '../../../../types/dotType';
import './Dot.scss';

interface IDotProps {
  type: DotType;
  message?: string;
}

const Dot = (props: IDotProps) => {
  const { type, message } = { ...props };

  return (
    <div className="dot">
      <div className={type.toLowerCase()} />
      {message && <span className="text">{message}</span>}
    </div>
  );
};

export default Dot;

import { Stepper, Step, StepLabel } from '@mui/material';
import React from 'react';
import IStepperComponent from '../../../utils/entities/genericComponents/navigation/IStepperComponent';

const StepperComponent = ({ steps, activeStep, allCompleted }: IStepperComponent) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label: string) => (
        <Step key={label} completed={allCompleted ? allCompleted : undefined}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;

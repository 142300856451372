import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ISubmitOptionsList } from '../../../../utils/entities/screen/ISubmitOptions';
import TooltipComp from '../../tooltip/Tooltip';
import IButtonDropdownProps from '../../../../utils/entities/genericComponents/button/button-dropdown/IButtonDropdown';
import DarkButton from '../../buttons/DarkButton';
import './ButtonDropdown.scss';
import LocalStorageUtils from '../../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ButtonDropdown = ({
  submitOptions,
  initialButtonLabel,
  isDisabled,
  onSubmitOptionClick,
  requestBody,
  setRequestBody,
}: IButtonDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonLabel, setButtonLabel] = useState(initialButtonLabel);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const defaultOption = submitOptions.find((option) => option.isDefault);
    if (defaultOption) {
      updateSelectedOption(defaultOption);
    }
  }, []);

  const disableEventDefaultBehaviour = (
    e:
      | React.MouseEvent<HTMLElement>
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDropdownClick = (e: React.MouseEvent<HTMLElement>) => {
    disableEventDefaultBehaviour(e);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSelectedOption = (option: ISubmitOptionsList) => {
    const resquestBodyCopy = structuredClone(requestBody);
    const selectedOption = {
      attributeName: option.attributeName,
      attributeValue: option.attributeValue,
    };
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK.SUBMIT_OPTION, selectedOption);

    if (resquestBodyCopy?.submitOptions) {
      resquestBodyCopy.submitOptions.selectedSubmitOption = selectedOption;
      setRequestBody({ ...resquestBodyCopy });
    }

    setButtonLabel(option.label);
  };

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: ISubmitOptionsList
  ) => {
    updateSelectedOption(option);
    disableEventDefaultBehaviour(e);
    handleClose();
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    disableEventDefaultBehaviour(e);
    onSubmitOptionClick();
  };

  return (
    <div className="button-dropdown">
      <DarkButton
        text={<TooltipComp label={buttonLabel} />}
        onClick={handleButtonClick}
        classes={'dropdown-button main-button'}
        isDisabled={isDisabled}
      />
      <DarkButton
        onClick={handleDropdownClick}
        classes={'dropdown-button icon-button'}
        isDisabled={isDisabled}
        endIcon={<KeyboardArrowDownIcon />}
      />

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          disableEventDefaultBehaviour(e as React.MouseEvent<HTMLButtonElement>);
          handleClose();
        }}>
        {submitOptions.map((option) => (
          <MenuItem
            key={`${option.attributeName}-${option.attributeValue}`}
            onClick={(e) => handleMenuItemClick(e, option)}
            disableRipple>
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default ButtonDropdown;

import axios, { AxiosError, AxiosResponse } from 'axios';
import { IRole } from '../utils/entities/role/IRole';
import { IUser } from '../utils/entities/role/IUser';
import { IUserTimeZone } from '../utils/entities/role/IUserTimeZone';
import IConfigView from '../utils/entities/views/IConfigView';
import ICaseRoles from '../utils/entities/case/ICaseRoles';
import axiosConfig from './axios.config';
import ICustomView from '../utils/entities/views/ICustomView';
import { ViewTypes } from '../utils/constants/enums';
import serviceUtils from '../utils/serviceUtils';
import { IProjectSecurityRoles } from '../utils/entities/authentication/IProjectSecurityRoles';

// #region USER

export const getUsersByProjectName = (projectName: string): Promise<Array<IUser>> =>
  axios
    .get(`/getAllUsersByProject/${projectName}`, axiosConfig)
    .then((res: AxiosResponse<Array<IUser>>) => {
      return serviceUtils.processSuccess<IUser[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getUser = (): Promise<IUser> =>
  axios
    .get(`/getUser`, axiosConfig)
    .then((res: AxiosResponse<IUser>) => {
      return serviceUtils.processSuccess<IUser>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getUserSettings = (): Promise<IUserTimeZone> =>
  axios
    .get(`/getUserSettings`, axiosConfig)
    .then((res: AxiosResponse<IUserTimeZone>) => {
      return serviceUtils.processSuccess<IUserTimeZone>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//#endregion

//#region ROLE

export const getRoleByName = (roleName: string): Promise<IRole> =>
  axios
    .get(`/getRoleByName/${encodeURIComponent(roleName)}`, axiosConfig)
    .then((res: AxiosResponse<IRole>) => {
      return serviceUtils.processSuccess<IRole>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//#endregion

//#region VIEW

export const createCustomView = (label: string, reqBody: ICustomView): Promise<ICustomView> =>
  axios
    .post(`/createUserCustomViewsByLabel/${encodeURIComponent(label)}`, reqBody, axiosConfig)
    .then((res: AxiosResponse<ICustomView>) => {
      return serviceUtils.processSuccess<ICustomView>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getCustomView = (id: string): Promise<ICustomView> =>
  axios
    .get(`/getUserCustomViewsById/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<ICustomView>) => {
      return serviceUtils.processSuccess<ICustomView>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const deleteCustomView = (id: string): Promise<Array<IConfigView>> =>
  axios
    .delete(`/deleteUserCustomViewsById/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<Array<IConfigView>>) => {
      return serviceUtils.processSuccess<IConfigView[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//#endregion

export const getUserCustomViewsByType = (
  tenant: string,
  type: ViewTypes
): Promise<Array<ICustomView>> =>
  axios
    .get(
      `/getUserCustomViewsByUserAndType/${encodeURIComponent(tenant)}/${encodeURIComponent(type)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<ICustomView>>) => {
      return serviceUtils.processSuccess<ICustomView[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getProjSecurityAssets = (project?: string): Promise<Array<IProjectSecurityRoles>> => {
  let queryParameters = '';
  if (project) {
    queryParameters += `?project=${project}`;
  }

  return axios
    .get(`/getProjectSecurityAssets${queryParameters}`, axiosConfig)
    .then((res: AxiosResponse<Array<IProjectSecurityRoles>>) => {
      return serviceUtils.processSuccess<IProjectSecurityRoles[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};

export const getCaseRolesByProject = (project: string): Promise<Array<ICaseRoles>> =>
  axios
    .get(`/getCaseRolesByProject/${encodeURIComponent(project)}`, axiosConfig)
    .then((res: AxiosResponse<Array<ICaseRoles>>) => {
      return serviceUtils.processSuccess<ICaseRoles[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

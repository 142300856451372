import React, { useContext } from 'react';
import GenericContext from './GenericContext';
import dateUtils from '../../../utils/dateUtils';

interface IDateText {
  date: string;
}

const DateText = (props: IDateText) => {
  const { date } = { ...props };
  const { dateFormat } = useContext(GenericContext);

  return <>{dateUtils.getDateText(date, dateFormat)}</>;
};

export default DateText;

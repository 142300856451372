import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCaseRolesByProject,
  getUsersByProjectName,
} from '../../../service/role-manager.service';
import ICaseRoles from '../../../utils/entities/case/ICaseRoles';
import { IUser } from '../../../utils/entities/role/IUser';
import { CONSTANTS } from '../../../utils/constants/constants';
import './AssignCaseRoles.scss';
import {
  Typography,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
  TextFieldProps,
  Card,
  Grid,
  Chip,
} from '@mui/material';
import { Stack } from '@mui/system';
import { Search } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import TooltipComp from '../../generic/tooltip/Tooltip';
import GeneralUtils from '../../../utils/generalUtils';
import IPermission, { PermissionTypes } from '../../../utils/entities/role/IPermission';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import IAccessToken from '../../../utils/entities/authentication/IAccessToken';
import Loader from '../../loader/Loader';
import { IAssignCaseRoles } from '../../../utils/entities/case/CaseCreation/IAssignCaseRoles';
import { validateAssignRoles } from '../../../utils/validateAssignRoles';
import { addAlert } from '../../../store/actions/alerts.actions';
import ArrayUtils from '../../../utils/arrayUtils';
import { IJoinRequest } from '../../../utils/entities/case/CaseCreation/JoinRequest/IJoinRequest';
import StringUtils from '../../../utils/stringUtils';
import JoinRequest from '../../../pages/case-detail/join-request/JoinRequest';
import { getRequestToJoinRequests } from '../../../service/case-manager.service';
import { IOpenRequests } from '../../../utils/entities/case/CaseCreation/JoinRequest/IOpenRequests';

const AssignCaseComponent = (props: IAssignCaseRoles) => {
  const [requestsToJoin, setRequestsToJoin] = useState<IJoinRequest>();

  const {
    isCaseCreation,
    assignedCaseRoles,
    subTitle,
    onChangeAssignedCaseRoles,
    setRolesReady,
    isCaseRolesEditable,
    isCaseRolesSelfEditable,
    isCaseRolesAdmin,
    caseId,
    acceptedRequests,
    setAcceptedRequests,
    deniedRequests,
    setDeniedRequests,
  } = { ...props };
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );
  const { t } = useTranslation();

  const [users, setUsers] = useState<Array<IUser>>([]);
  const [caseRoles, setCaseRoles] = useState<Array<ICaseRoles>>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isCaseLoad, setIsCaseLoad] = useState<boolean>(false);
  const [openRequests, setOpenRequests] = useState<Array<IOpenRequests>>([]);

  useEffect(() => {
    setShowLoader(true);
    getCaseRolesByProject(projectName)
      .then((caseRoles) => {
        caseRoles.forEach(function (item: ICaseRoles, i: number) {
          if (GeneralUtils.checkIfOwner(item)) {
            caseRoles.splice(i, 1);
            caseRoles.unshift(item);
          }
          setIsCaseLoad(true);
        });
        setIsCaseLoad(true);
        setCaseRoles(caseRoles);
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ERROR_MESSAGE') });
        setCaseRoles([]);
        setShowLoader(false);
      });

    if (caseId && !isCaseCreation) {
      getRequestToJoinRequests(caseId).then((requests) => {
        setRequestsToJoin(requests);
      });
    }
  }, []);

  useEffect(() => {
    if (caseRoles) {
      const rolesStructureArray: Array<IOpenRequests> = caseRoles.map((role: ICaseRoles) => {
        return { role: role.name, open: false };
      });
      setOpenRequests(rolesStructureArray);
    }
  }, [caseRoles]);

  useEffect(() => {
    fetchUsers(caseRoles);
  }, [isCaseLoad]);

  const fetchUsers = async (caseRoles: ICaseRoles[]) => {
    getUsersByProjectName(projectName)
      .then((users) => {
        setUsers(ArrayUtils.sortArrayByKey(users, 'userName'));
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ERROR_GETTING_USER') });
      })
      .finally(() => {
        setShowLoader(false);
        isCaseLoad && setRolesReady(validateAssignRoles(caseRoles, assignedCaseRoles));
        if (isCaseCreation) {
          const ownerRole = fetchUserWithOwnerPermission(caseRoles);
          if (ownerRole) {
            handleChange(ownerRole, [userInfo.user]);
          }
        }
      });
  };

  /**
   * This function will return the role which is bind with caseAdminPermission
   * @returns
   */
  const fetchUserWithOwnerPermission = (caseRoles: ICaseRoles[]) => {
    const roleBoundedWithCaseAdminPermission = caseRoles.find((e: ICaseRoles) => {
      return (
        e.permissions.findIndex((e: IPermission) => e.name === PermissionTypes.CASE_ROLES_ADMIN) >=
        0
      );
    });
    return roleBoundedWithCaseAdminPermission;
  };

  const deleteUserFromRole = (role: ICaseRoles, user: string) => {
    const machingRole = assignedCaseRoles.find((e) => e.name === role.name);
    if (!isCaseCreation && setAcceptedRequests) {
      const findUser =
        machingRole &&
        requestsToJoin?.[machingRole.name] &&
        requestsToJoin[machingRole.name].find((request) => request.userEmail === user);
      if (findUser) {
        const removeAcceptedRequest: string[] | undefined = acceptedRequests?.filter(
          (id) => id !== findUser.id
        );
        setAcceptedRequests(removeAcceptedRequest ?? []);
      }
    }

    if (machingRole) {
      const clickedChip = machingRole && machingRole.users.filter((roleUser) => roleUser !== user);
      handleChange(role, clickedChip);
    }
  };

  const handleChange = (role: ICaseRoles, values: Array<string>, event?: any): void => {
    if (event?.key !== 'Backspace' && event?.key !== 'Delete') {
      const machingRole = assignedCaseRoles.find((e) => e.name === role.name);
      if (machingRole) {
        machingRole.users = values;
        onChangeAssignedCaseRoles([...assignedCaseRoles]);
        isCaseLoad && setRolesReady(validateAssignRoles(caseRoles, assignedCaseRoles));
        return;
      }

      const newRole: ICaseRoles = {
        id: role.id,
        name: role.name,
        project: projectName,
        users: values,
        min: role.min,
        max: role.max,
        permissions: role.permissions,
      };
      const newAssignedCaseRoles = [...assignedCaseRoles, newRole];
      onChangeAssignedCaseRoles(newAssignedCaseRoles);
      isCaseLoad && setRolesReady(validateAssignRoles(caseRoles, newAssignedCaseRoles));
    }
  };

  const checkPermissions = (otherValidation: boolean, role: ICaseRoles) => {
    return (
      isCaseRolesAdmin ||
      (isCaseRolesEditable && !GeneralUtils.checkIfOwner(role)) ||
      (isCaseRolesSelfEditable && otherValidation)
    );
  };

  const getIsAutocompleteDisabled = (role: ICaseRoles) => {
    if (isCaseCreation) {
      return false;
    }

    return !checkPermissions(!GeneralUtils.checkIfOwner(role), role);
  };

  const getIsChipDisabled = (option: string, role: ICaseRoles) => {
    if (isCaseCreation) {
      return StringUtils.compareStrings(option, userInfo.user) && GeneralUtils.checkIfOwner(role);
    }

    return !checkPermissions(StringUtils.compareStrings(option, userInfo.user), role);
  };

  const getIsOptionDisabled = (option: string, role: ICaseRoles) => {
    const users =
      assignedCaseRoles.find((caseRole: ICaseRoles) => caseRole.name === role.name)?.users ?? [];

    const baseValidation =
      users.length >= role.max || users?.find((user) => user === option) != null;

    if (isCaseCreation || isCaseRolesAdmin) {
      return baseValidation;
    }

    if (!isCaseRolesEditable && isCaseRolesSelfEditable) {
      return !StringUtils.compareStrings(option, userInfo.user) || baseValidation;
    }
    return (
      !(isCaseRolesEditable && !GeneralUtils.checkIfOwner(role) && !baseValidation) ||
      baseValidation
    );
  };

  return (
    <div className="assign-case-roles">
      {showLoader && <Loader />}
      {caseRoles.length > 0 && !showLoader && (
        <>
          {subTitle && (
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textDecorationLine: 'underline' }}
              className="title">
              {t('ASSIGN_CASE_ROLES')}
            </Typography>
          )}
          <Stack spacing={2}>
            {caseRoles.map((role: ICaseRoles) => {
              const { name, id, min, max } = role;

              return (
                <Card className="assign-case-component" key={id} sx={{ padding: '10px' }}>
                  <Grid container xs={1} className="user-section">
                    <Typography sx={{ flexShrink: 0 }}>{name}</Typography>
                    <Grid className="icon-container">
                      <GroupIcon sx={{ height: 20, width: 20 }} />
                      <Typography className="user-count-label">
                        <TooltipComp
                          label={`${min} - ${max}`}
                          tooltipText={`minimum ${min} users maximum ${max} users`}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container xs={11} alignItems="center" justifyContent="center">
                    <Grid item xs={11}>
                      <Autocomplete
                        disableClearable={GeneralUtils.checkIfOwner(role) && isCaseCreation}
                        multiple
                        limitTags={4}
                        getOptionDisabled={(option: string) => getIsOptionDisabled(option, role)}
                        disabled={getIsAutocompleteDisabled(role)}
                        options={users.map((user) => user.userName)}
                        popupIcon={<Search />}
                        size="small"
                        onChange={(event, values) => handleChange(role, values, event)}
                        onKeyDown={(event: any) => {
                          if (event.key === 'Backspace' || event.key === 'Delete') {
                            event.stopPropagation();
                          }
                        }}
                        filterSelectedOptions
                        value={
                          assignedCaseRoles
                            .find((e: ICaseRoles) => e.name === name)
                            ?.users?.map((user) => user) || []
                        }
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => {
                            return (
                              <Chip
                                {...getTagProps({ index })}
                                key={option}
                                label={option}
                                disabled={getIsChipDisabled(option, role)}
                                onDelete={() => deleteUserFromRole(role, option)}
                              />
                            );
                          })
                        }
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...(params as TextFieldProps)}
                            label={t('ASSIGN_TO')}
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    {!isCaseCreation &&
                      acceptedRequests &&
                      setAcceptedRequests &&
                      deniedRequests &&
                      setDeniedRequests && (
                        <Grid item xs={1}>
                          <JoinRequest
                            caseRoles={caseRoles}
                            role={role}
                            requests={requestsToJoin}
                            setRequestsToJoin={setRequestsToJoin}
                            handleChange={handleChange}
                            assignedCaseRoles={assignedCaseRoles}
                            acceptedRequests={acceptedRequests}
                            setAcceptedRequests={setAcceptedRequests}
                            deniedRequests={deniedRequests}
                            setDeniedRequests={setDeniedRequests}
                            isCaseRolesAdmin={isCaseRolesAdmin}
                            onChangeAssignedCaseRoles={onChangeAssignedCaseRoles}
                            openRequests={openRequests}
                            setOpenRequests={setOpenRequests}
                          />
                        </Grid>
                      )}
                  </Grid>
                </Card>
              );
            })}
          </Stack>
        </>
      )}
    </div>
  );
};

export default AssignCaseComponent;

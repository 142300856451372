import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Stack } from '@mui/material';
import { CONSTANTS } from '../../utils/constants/constants';
import './DrawerLayout.scss';
import { getBaseLayoutSizes } from '../../service/style.service';
import DrawerList from './drawer-list/DrawerList';
import IDrawerLayout from '../../utils/entities/layouts/IDrawerLayout';

const baseStyle = (theme: Theme): CSSObject => ({
  background: theme.palette.secondary.main,
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: CONSTANTS.DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  ...baseStyle(theme),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: getBaseLayoutSizes()['size-width'],
  ...baseStyle(theme),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: CONSTANTS.DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const DrawerLayout = (props: IDrawerLayout) => {
  const { open, setOpenDrawer } = { ...props };

  return (
    <Drawer variant="permanent" open={props.open} className="drawer-layout">
      <Stack direction="column" justifyContent="space-between" height={'100%'}>
        {/* Top List */}
        <DrawerList
          open={open}
          setOpenDrawer={setOpenDrawer}
          items={CONSTANTS.VERTICAL_BAR_ITEMS.TOP}
        />

        {/* Bottom List */}
        <DrawerList
          open={open}
          setOpenDrawer={setOpenDrawer}
          items={CONSTANTS.VERTICAL_BAR_ITEMS.BOTTOM}
          classes="bottom-list"
        />
      </Stack>
    </Drawer>
  );
};

export default DrawerLayout;

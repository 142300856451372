import { ICompare } from './entities/ICompare';

interface IArrayUtils {
  compareArraysAsStr: <T>(arr1: T[], arr2: T[]) => boolean;
  sortArrayByKey: <T>(arr1: T[], key: keyof T) => T[];
  getArrayDifference: (arr1: string[], arr2: string[]) => ICompare;
}

const ArrayUtils: IArrayUtils = {
  compareArraysAsStr: <T>(arr1: T[], arr2: T[]) => {
    return arr1.join() == arr2.join();
  },
  sortArrayByKey: <T>(arr: T[], key: keyof T) => {
    const arrCopied = [...arr];
    return arrCopied.sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        if (aValue.toLowerCase() < bValue.toLowerCase()) return -1;
        if (aValue.toLowerCase() > bValue.toLowerCase()) return 1;
        return 0;
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }

      return 0;
    });
  },
  getArrayDifference: (arr1: string[], arr2: string[]) => {
    const added = arr2.filter((item) => !arr1.includes(item));
    const removed = arr1.filter((item) => !arr2.includes(item));

    return { added, removed };
  },
};

export default ArrayUtils;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICaseParty } from '../../../utils/entities/dueDiligence/ICaseParty';
import ISimpleAttribute from '../../../utils/entities/case/ISimpleAttribute';

const PartyDetailPanel = (props: {
  setNewSelectedParty: React.Dispatch<React.SetStateAction<ICaseParty | undefined>>;
  newSelectedParty: ICaseParty | undefined;
}) => {
  const { setNewSelectedParty, newSelectedParty } = props;
  const { t } = useTranslation();

  return (
    <div
      className="due-diligence-detail card-view due-diligence-panel"
      style={{ display: newSelectedParty ? 'block' : 'none' }}>
      {newSelectedParty &&
        newSelectedParty.displayAttributes.map((attribute: ISimpleAttribute, index: number) => {
          return (
            <>
              <div className="link" key={index}>
                {attribute?.value}
              </div>
              <div className="party-details">{attribute.values ? attribute.values : ''}</div>
            </>
          );
        })}
    </div>
  );
};

export default PartyDetailPanel;

import React from 'react';
import { Button } from '@mui/material';
import Loader from '../../loader/Loader';
import IPlainTextButtonProps from '../../../utils/entities/genericComponents/button/IPlainTextButton';

const PlainTextButton = ({
  label,
  handleClick,
  isDisabled,
  loading,
  icon,
  classes,
  marginClass,
}: IPlainTextButtonProps) => {
  return (
    <Button
      disabled={isDisabled || loading}
      onClick={handleClick}
      className={`link-cursor ${classes ?? ''}`}>
      {loading ? <Loader /> : icon}
      <span className={marginClass ?? 'margin-left-right-10'}>{label}</span>
    </Button>
  );
};

export default PlainTextButton;

import React from 'react';
import { InputLabel } from '@mui/material';
import './LabeledChip.scss';

interface LabeledChipProps {
  label: string;
  handleClick?: (() => void) | (() => null);
  isRequired?: boolean;
  isIdentified?: boolean;
  isPartially?: boolean;
  isClickable?: boolean;
  isNotRequired?: boolean;
}

/*
TODO: Comp needs to be refactored
it's a generic component with specific code logic
we should also be able to create a label that don't exist to maintan the space on the UI
colors need to be rethinked 
 */

const LabeledChip = (props: LabeledChipProps) => {
  const { label, handleClick, isRequired, isIdentified, isPartially, isClickable, isNotRequired } =
    { ...props };

  return (
    <InputLabel
      onClick={handleClick}
      className={`labeledChip 
            ${isRequired ? 'required' : ''}
            ${isIdentified ? 'identified' : ''}
            ${isNotRequired ? 'not-required' : ''}
            ${isClickable ? 'cursor-pointer' : ''}
            ${isPartially ? 'partially' : ''}`}>
      {label}
    </InputLabel>
  );
};

export default LabeledChip;

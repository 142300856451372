import axios, { AxiosError, AxiosResponse } from 'axios';
import serviceUtils from '../utils/serviceUtils';
import { axiosConfigScan } from './axios.config';

export const virusScanDocument = async (files: Array<File>) => {
  const bodyFormData: FormData = new FormData();
  files.forEach((file: File) => {
    bodyFormData.append('files', file);
  });
  return axios
    .postForm(`/scanDocument`, bodyFormData, axiosConfigScan)
    .then((res: AxiosResponse<string>) => {
      return serviceUtils.processSuccess<string>(res);
    })
    .catch((error: AxiosError) => {
      throw serviceUtils.processError(error);
    });
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails, Stack, Button, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoopIcon from '@mui/icons-material/Loop';
import {
  getAllDueDiligenceRequests,
  postTRClearPersonQuickAnalysisFlag,
  postTRClearBusinessQuickAnalysisFlag,
  getTRClearPersonQuickAnalysisFlag,
  getTRClearBusinessQuickAnalysisFlag,
  generateTRReport,
  refreshResults,
} from '../../../service/due-diligence.service';
import Loader from '../../loader/Loader';
import { TRClearQAFlagRequest } from '../../../utils/entities/dueDiligence/IRequest';
import {
  TRClearSearchResult,
  TRClearQuickAnalysisResult,
} from '../../../utils/entities/dueDiligence/TRClearSearchResult';
import { addAlert } from '../../../store/actions/alerts.actions';
import LocalStorageUtils from '../../../utils/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import PrimaryButton from '../../generic/buttons/PrimaryButton';
import GeneralUtils from '../../../utils/generalUtils';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';

const TRClearSearchResults = (props: { caseId: string; tenant: string; searchResults: any }) => {
  const { t } = useTranslation();
  const [resultsRefreshing, setResultsRefreshing] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<TRClearSearchResult>>([]);
  const [flagsRefreshing, setFlagsRefreshing] = useState<boolean>(false);
  const [quickAnalysis, setQuickAnalysis] = useState<TRClearQuickAnalysisResult>();
  const [activeGroupIdData, setActiveGroupIdData] = useState<TRClearQAFlagRequest>();
  const [quickAnalysisFlagsList, setQuickAnalysisFlagsList] = useState<
    Array<{
      RiskFlag?: string;
      Category: string;
    }>
  >([]);
  const [expandedList, setExpandedList] = React.useState<Array<string>>([]);
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (expandedList.includes(panel)) {
        setExpandedList(expandedList.filter((item) => item !== panel));
      } else {
        setExpandedList([...expandedList, panel]);
      }
    };

  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  useEffect(() => {
    setResultsRefreshing(true);
    getTRClearSearchResults();
  }, [props.caseId]);

  useEffect(() => {
    if (props.searchResults) {
      setSearchResults(props.searchResults);
      setResultsRefreshing(false);
      // clear the quick analysis flags and set the accordion to default state
      setFlagsRefreshing(false);
      setQuickAnalysis(undefined);
      setActiveGroupIdData(undefined);
      setQuickAnalysisFlagsList([]);
      setExpandedList([]);
    }
  }, [props.searchResults]);

  useEffect(() => {
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TR_RESULTS_LOADING_EVT, false);
  }, []);

  useEffect(() => {
    if (quickAnalysis) {
      getQuickAnalysisFlagsFromData();
    }
  }, [quickAnalysis]);

  const updateLiveTRDataEventListener = (e: MessageEvent) => {
    setSearchResults(JSON.parse(e.data))
  };

  const updateLiveTRQADataEventListener = (e: MessageEvent) => {
    setQuickAnalysis(JSON.parse(e.data));
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.TR_RESULTS_LOADING_EVT, refreshResults(props.caseId, 'TR'), true, {
    tr_search: updateLiveTRDataEventListener,
    tr_quickanalysis: updateLiveTRQADataEventListener,
  });

  // get searchResults data for TR Clear
  const getTRClearSearchResults = () => {
    getAllDueDiligenceRequests(props.caseId, 'TR Clear').then((apiResponse: any) => {
      if (apiResponse) {
        setSearchResults(apiResponse);
        setResultsRefreshing(false);
      }
    });
  };

  const handleGroupClick = (
    groupId: string | null,
    searchType: string | null,
    personEntityId: string | null,
    companyEntityId: string | null
  ) => {
    setFlagsRefreshing(true);
    const requestBody: TRClearQAFlagRequest = {
      caseId: props.caseId,
      apiName: 'TR Clear',
      searchType: searchType,
      groupId: groupId,
      tenant: props.tenant,
    };
    if (searchType == 'Person') {
      requestBody.personEntityId = personEntityId;
    }
    if (searchType == 'Business') {
      requestBody.companyEntityId = companyEntityId;
    }
    setActiveGroupIdData(requestBody);
    postQuickAnalysisFlags(requestBody);
  };

  const getQuickAnalysisFlags = (requestBody: TRClearQAFlagRequest) => {
    if (requestBody.searchType == 'Person') {
      getTRClearPersonQuickAnalysisFlag(
        requestBody.caseId ?? '',
        requestBody.apiName ?? '',
        requestBody.searchType ?? '',
        requestBody.groupId ?? '',
        requestBody.personEntityId ?? ''
      ).then((apiResponse: any) => {
        if (apiResponse && apiResponse.PersonQuickAnalysisFlag) {
          setQuickAnalysis(apiResponse);
        }
      });
    } else if (requestBody.searchType == 'Business') {
      getTRClearBusinessQuickAnalysisFlag(
        requestBody.caseId ?? '',
        requestBody.apiName ?? '',
        requestBody.searchType ?? '',
        requestBody.groupId ?? '',
        requestBody.companyEntityId ?? ''
      ).then((apiResponse: any) => {
        if (apiResponse && apiResponse.BusinessQuickAnalysisFlag) {
          setQuickAnalysis(apiResponse);
        }
      });
    }
  };

  const postQuickAnalysisFlags = (requestBody: TRClearQAFlagRequest) => {
    if (requestBody.searchType == 'Person') {
      postTRClearPersonQuickAnalysisFlag(requestBody).then((apiResponse: any) => {
        if (apiResponse && apiResponse.id) {
          setFlagsRefreshing(false);
          getQuickAnalysisFlags(requestBody);
          GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TR_QA_RESULTS_LOADING_EVT, false);
        }
      });
    } else if (requestBody.searchType == 'Business') {
      postTRClearBusinessQuickAnalysisFlag(requestBody).then((apiResponse: any) => {
        if (apiResponse && apiResponse.id) {
          setFlagsRefreshing(false);
          getQuickAnalysisFlags(requestBody);
          GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TR_QA_RESULTS_LOADING_EVT, false);
        }
      });
    }
  };

  const getQuickAnalysisFlagsFromData = () => {
    const quickAnalysisFlagsList = [];
    const personRiskFlags =
      quickAnalysis?.PersonQuickAnalysisFlag &&
      quickAnalysis?.PersonQuickAnalysisFlag[0]?.TRClearParsonQuickAnalysis
        ?.PersonQuickAnalysisFlagResultsPage?.ResultGroup?.RecordDetails
        ?.PersonQuickAnalysisFlagResponseDetail?.PersonRiskFlags;
    const personRiskFlagsWithDocguids =
      quickAnalysis?.PersonQuickAnalysisFlag &&
      quickAnalysis?.PersonQuickAnalysisFlag[0]?.TRClearParsonQuickAnalysis
        ?.PersonQuickAnalysisFlagResultsPage?.ResultGroup?.RecordDetails
        ?.PersonQuickAnalysisFlagResponseDetail?.PersonRiskFlagsWithDocguids;

    if (personRiskFlags) {
      for (const riskFlag in personRiskFlags) {
        quickAnalysisFlagsList.push({
          Category: 'Real Time Incarceration And Arrest',
          RiskFlag: personRiskFlags['RealTimeIncarcerationAndArrest'],
        });
      }
    }

    if (personRiskFlagsWithDocguids) {
      quickAnalysisFlagsList.push({
        Category: 'Address Reported Less Ninety Days',
        RiskFlag: personRiskFlagsWithDocguids.AddressReportedLessNinetyDays?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Age Younger Than SSN',
        RiskFlag: personRiskFlagsWithDocguids.AgeYoungerThanSSN?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Arrest',
        RiskFlag: personRiskFlagsWithDocguids.Arrest?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Associated Relative With PO Box Address',
        RiskFlag: personRiskFlagsWithDocguids.AssocRelativeWithPOBoxAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Associated Relative With Prison Address',
        RiskFlag: personRiskFlagsWithDocguids.AssocRelativeWithPrisonAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Associated Relative With Residential Used As Business',
        RiskFlag: personRiskFlagsWithDocguids.AssocRelativeWithResidentialUsedAsBusiness?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Associated With OFAC Global PEP',
        RiskFlag: personRiskFlagsWithDocguids.AssocWithOFACGlobalPEP?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Associated With Marijuana Business',
        RiskFlag: personRiskFlagsWithDocguids.AssociatedWithMarijuanaBusiness?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Bankruptcy',
        RiskFlag: personRiskFlagsWithDocguids.Bankruptcy?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Criminal',
        RiskFlag: personRiskFlagsWithDocguids.Criminal?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Criminal Low Level Traffic Offense',
        RiskFlag: personRiskFlagsWithDocguids.CriminalLowLevelTrafficOffense?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Criminal Uncategorized',
        RiskFlag: personRiskFlagsWithDocguids.CriminalUncategorized?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Global Sanctions',
        RiskFlag: personRiskFlagsWithDocguids.GlobalSanctions?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Healthcare Sanction',
        RiskFlag: personRiskFlagsWithDocguids.HealthcareSanction?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Multiple SSN',
        RiskFlag: personRiskFlagsWithDocguids.MultipleSSN?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'OFAC',
        RiskFlag: personRiskFlagsWithDocguids.OFAC?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'PO Box Address',
        RiskFlag: personRiskFlagsWithDocguids.POBoxAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Phone Number Inconsistent Address',
        RiskFlag: personRiskFlagsWithDocguids.PhoneNumberInconsistentAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Prison Address',
        RiskFlag: personRiskFlagsWithDocguids.PrisonAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Recorded As Deceased',
        RiskFlag: personRiskFlagsWithDocguids.RecordedAsDeceased?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Residential Used As Business',
        RiskFlag: personRiskFlagsWithDocguids.ResidentialUsedAsBusiness?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'SSN Format Invalid',
        RiskFlag: personRiskFlagsWithDocguids.SSNFormatInvalid?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'SSN Is ITIN',
        RiskFlag: personRiskFlagsWithDocguids.SSNIsITIN?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'SSN Multiple Individuals',
        RiskFlag: personRiskFlagsWithDocguids.SSNMultipleIndividuals?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'WorldCheck',
        RiskFlag: personRiskFlagsWithDocguids.WorldCheck?.RiskFlag,
      });
    }

    const companyRiskFlagsWithDocguids =
      quickAnalysis?.BusinessQuickAnalysisFlag &&
      quickAnalysis?.BusinessQuickAnalysisFlag[0]?.TRClearBusinessQuickAnalysis
        ?.CompanyQuickAnalysisFlagResultsPage?.ResultGroup?.RecordDetails
        ?.CompanyQuickAnalysisFlagResponseDetail?.CompanyRiskFlagsWithDocguids;

    if (companyRiskFlagsWithDocguids) {
      quickAnalysisFlagsList.push({
        Category: 'Bankruptcy',
        RiskFlag: companyRiskFlagsWithDocguids.Bankruptcy?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Business Used As Residential Address',
        RiskFlag: companyRiskFlagsWithDocguids.BusinessUsedAsResidentialAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Global Sanctions',
        RiskFlag: companyRiskFlagsWithDocguids.GlobalSanctions?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Going Concern',
        RiskFlag: companyRiskFlagsWithDocguids.GoingConcern?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Healthcare Sanction',
        RiskFlag: companyRiskFlagsWithDocguids.HealthcareSanction?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Key Nature Of Suit',
        RiskFlag: companyRiskFlagsWithDocguids.KeyNatureOfSuit?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Listing Linked To Business Address',
        RiskFlag: companyRiskFlagsWithDocguids.ListingLinkedToBusinessAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Listing Linked To Business Phone',
        RiskFlag: companyRiskFlagsWithDocguids.ListingLinkedToBusinessPhone?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Listing Linked To Same FEIN',
        RiskFlag: companyRiskFlagsWithDocguids.ListingLinkedToSameFEIN?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'MSB Listing',
        RiskFlag: companyRiskFlagsWithDocguids.MSBListing?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Marijuana Related Business',
        RiskFlag: companyRiskFlagsWithDocguids.MarijuanaRelatedBusiness?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'OFAC',
        RiskFlag: companyRiskFlagsWithDocguids.OFAC?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'PO Box Address',
        RiskFlag: companyRiskFlagsWithDocguids.POBoxAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Pending Class Action',
        RiskFlag: companyRiskFlagsWithDocguids.PendingClassAction?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'Prison Address',
        RiskFlag: companyRiskFlagsWithDocguids.PrisonAddress?.RiskFlag,
      });
      quickAnalysisFlagsList.push({
        Category: 'WorldCheck',
        RiskFlag: companyRiskFlagsWithDocguids.WorldCheck?.RiskFlag,
      });
    }
    setQuickAnalysisFlagsList(quickAnalysisFlagsList);
  };

  const getStatus = (status: string | null) => {
    if (status?.toLowerCase() == 'success' || status?.toLowerCase() == 'complete') {
      return <span className="color-green">{status}</span>;
    } else {
      return <span className="color-red">{status}</span>;
    }
  };

  const handleGenerateReport = (partyName: string | null) => {
    generateTRReport({
      caseId: props.caseId,
      apiName: 'TR Clear',
      app: projectName,
      applicationName: projectName,
      partyName: partyName ? partyName : 'due_diligence',
      projectName: projectName,
      tenant: props.tenant,
      searchType: activeGroupIdData?.searchType ?? '',
      reportChoice:
        activeGroupIdData?.searchType == 'Person'
          ? 'Individual'
          : activeGroupIdData?.searchType == 'Business'
          ? 'Business'
          : 'Individual',
      groupId: activeGroupIdData?.groupId ?? '',
      reportData: [{}],
      addressValidation: [{}],
    }).then((apiResponse: any) => {
      if (apiResponse) {
        addAlert({
          type: 'success',
          primaryText: t('REQUEST_SUCCESS'),
        });
      }
    });
  };

  return (
    <>
      <div data-testid='TRClearSearchResults'
        className={
          'card-view search-criteria width-100-per search-results-list show-scroll overflow-y-scroll ' +
          (resultsRefreshing || searchResults.length == 0 ? 'border-none' : '')
        }>
        <div className="av-ir-tr-clear">
          {resultsRefreshing && <Loader isMainLoader={true} />}
          {searchResults.map((searchResult) => {
            return (
              searchResult.Data &&
              Array.isArray(searchResult.Data) &&
              searchResult.Data.length > 0 &&
              searchResult.Data.slice()
                .reverse()
                .map((el, index) => {
                  return (
                    <Accordion
                      key={index}
                      data-testid={`searchType${el.searchType}`}
                      className="width-100-per margin-0 border-top"
                      expanded={expandedList.includes(index + (el.searchType ?? '')) ? true : false}
                      onChange={handleAccordionChange(index + (el.searchType ?? ''))}>
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="margin-0">
                        <Stack direction="column">
                          <div className="sub-list-title">Search Type: {el.searchType}</div>
                          <div>Search Terms: {el.searchTerms}</div>
                          <div>Result: {getStatus(el.status)}</div>
                          <div>Results Found: {el.data?.length}</div>
                        </Stack>
                      </AccordionSummary>
                      {el.data && Array.isArray(el.data) && el.data.length > 0 && (
                        <AccordionDetails className="tr-sub-accordion-wrapper">
                          {el.data?.map((result, resultIndex) => (
                            <div
                              key={index + '' + resultIndex}
                              className={
                                activeGroupIdData?.groupId == result.groupId
                                  ? 'tr-sub-accordion active'
                                  : 'tr-sub-accordion'
                              }>
                              <Grid container spacing={3} className="margin-0">
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  xl={8}
                                  sm={12}
                                  className="padding-0">
                                  <div
                                    id={`postTRClear${el.searchType}`}
                                    className="link sub-list-title"
                                    onClick={() =>
                                      handleGroupClick(
                                        result.groupId,
                                        el.searchType,
                                        el.personEntityId,
                                        el.companyEntityId
                                      )
                                    }>
                                    Group Id: {result.groupId}
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  xl={4}
                                  sm={12}
                                  className="padding-0">
                                  <PrimaryButton
                                    isDisabled={
                                      !(
                                        activeGroupIdData?.groupId == result.groupId &&
                                        quickAnalysis?.groupId == result.groupId
                                      )
                                    }
                                    onClick={() => handleGenerateReport(el.partyName)}
                                    isPositiveButton={true}
                                    classes="generate-repot-btn"
                                    text={t('DD.GENERATE_REPORT')}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        </AccordionDetails>
                      )}
                    </Accordion>
                  );
                })
            );
          })}
        </div>
      </div>
      <div
        className={
          'card-view search-criteria width-100-per search-results-details show-scroll overflow-y-scroll ' +
          (resultsRefreshing || searchResults.length == 0 ? 'border-none' : '')
        }>
        <div className="av-ir-tr-clear">
          {flagsRefreshing && <Loader isMainLoader={true} />}
       
          {!flagsRefreshing && quickAnalysisFlagsList.length > 0 && (
            <TableContainer component={Paper}>
              <Table data-testid='TRQuickAnalysis' sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="bold-font">
                      {t('DD.RISK_FLAG')}
                    </TableCell>
                    <TableCell align="center" className="bold-font">
                      {t('DD.CATEGORY')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quickAnalysisFlagsList.map((quickAnalysisFlag) => (
                    <TableRow
                      key={quickAnalysisFlag.Category}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        align="center"
                        className={
                          quickAnalysisFlag.RiskFlag?.toLowerCase() == 'yes'
                            ? 'color-red bold-font'
                            : quickAnalysisFlag.RiskFlag?.toLowerCase() == 'no'
                            ? 'color-green bold-font'
                            : ''
                        }>
                        {quickAnalysisFlag.RiskFlag}
                      </TableCell>
                      <TableCell align="center">{quickAnalysisFlag.Category}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default TRClearSearchResults;

import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { ICaseLinkTable } from '../../../../../utils/entities/case/CaseHeader/ICaseLinkTable';

const CaseLinkTable = (props: ICaseLinkTable) => {
  const { typedCase, cases, rows, columns, handleRowSelection } = props;
  return typedCase.length > 1 ? (
    <div
      className="caseLinkingTableWrapper show-scroll border"
      style={{
        height: '100%',
        width: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
      }}>
      <div style={{ height: '100%', minWidth: '400px' }}>
        {cases ? (
          <DataGrid
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            hideFooter={true}
            disableDensitySelector={true}
            disableColumnMenu={true}
            columnHeaderHeight={49}
            rowHeight={42}
            rows={rows}
            columns={columns}
            pageSizeOptions={[5]}
            onRowSelectionModelChange={handleRowSelection}
            sx={{ overflowX: 'scroll' }}
            style={{ height: 300, width: '100%', border: '0' }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '20px',
            }}>
            <p>No cases available</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CaseLinkTable;

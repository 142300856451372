import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import GeneralUtils from '../../../../../utils/generalUtils';
import ExportCaseData from '../../../../cases/export/ExportCaseData';
import LocalStorageUtils from '../../../../../utils/localStorageUtils';
import CaseHeaderActionButtons from './CaseHeaderActionButtons';
import VerticalDivider from '../../../../generic/divider/VerticalDivider';
import CaseHeaderFeatureButtons from './CaseHeaderFeatureButtons';
import { ICaseHeaderAccordion } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderAccordion';
import AssignCaseContainer from '../../../../cases/assign-case-container/AssignCaseContainer';
import HeaderAttribute from '../header-attribute/HeaderAttribute';
import ICaseHeaderAttributes from '../../../../../utils/entities/screen/ICaseHeaderAttributes';
import WorklowBar from '../workflow-bar/WorklowBar';

const CaseHeaderAccordion = (props: ICaseHeaderAccordion) => {
  const {
    tenant,
    attributes,
    tryOpenAccordion,
    headerCollapse,
    setTryOpenAccordion,
    openHeader,
    setOpenHeader,
    tooltipStatus,
    setTooltipStatus,
    saveTaskAsDraftClick,
    formId,
    userIsNotAssignee,
    saveTaskAsDraftAndExitClick,
    submitClick,
    caseId,
    casePermissions,
    setShowModal,
    retrieveLinks,
    setOpenAudit,
    setOpenModal,
    setStatus,
    setHoldAccordion,
    statusOptions,
    slaLabels,
    caseRoles,
    internalStatus,
    processBar,
    requestBody,
    setRequestBody,
    contextLocals,
  } = props;

  const handleAccordion = () => {
    setTryOpenAccordion(!tryOpenAccordion);
    setOpenHeader(!openHeader);

    if (tooltipStatus) {
      setTooltipStatus(false);
    }
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.HEADER_COLLAPSE, !headerCollapse);
  };

  const gridOneEnd = 9;
  const gridTwoEnd = gridOneEnd + 6;

  const getBreakpointSize = (attributeName: string, breakpoint: 'xs' | 'md' | 'lg' | 'xl') => {
    switch (breakpoint) {
      case 'xs':
        if (attributeName === CONSTANTS.ATTRIBUTES.CASE_TAGS) {
          return 5;
        } else if (attributeName === CONSTANTS.ATTRIBUTES.PROCESS_BAR) {
          return 12;
        }
        return 4;
      case 'md':
        if (attributeName === CONSTANTS.ATTRIBUTES.CASE_TAGS) {
          return 4;
        } else if (attributeName === CONSTANTS.ATTRIBUTES.PROCESS_BAR) {
          return 12;
        }
        return 3;
      case 'lg':
        if (attributeName === CONSTANTS.ATTRIBUTES.CASE_TAGS) {
          return 4;
        } else if (attributeName === CONSTANTS.ATTRIBUTES.PROCESS_BAR) {
          return 12;
        }
        return 2;
      case 'xl':
        if (attributeName === CONSTANTS.ATTRIBUTES.CASE_TAGS) {
          return 2;
        } else if (attributeName === CONSTANTS.ATTRIBUTES.PROCESS_BAR) {
          return 12;
        }
        return 1;
    }
  };
  return (
    <>
      <Accordion
        elevation={0}
        expanded={headerCollapse}
        onChange={handleAccordion}
        className={`case-header-accordion ${processBar ?? `border-accordion`}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="padding-0">
          <Grid container sx={{}}>
            <Grid item className="case-header-left-container">
              <Grid container className={headerCollapse ? 'openAccordion' : 'closedAccordion'}>
                {attributes.slice(0, gridOneEnd).map((attr) => (
                  <Grid
                    item
                    className="full-width-progress-bar"
                    xs={getBreakpointSize(attr.attributeName, 'xs')}
                    md={getBreakpointSize(attr.attributeName, 'md')}
                    lg={getBreakpointSize(attr.attributeName, 'lg')}
                    xl={getBreakpointSize(attr.attributeName, 'xl')}
                    key={attr.attributeLabel}>
                    <HeaderAttribute
                      attribute={attr}
                      setStatus={setStatus}
                      setHoldAccordion={setHoldAccordion}
                      setTooltipStatus={setTooltipStatus}
                      tooltipStatus={tooltipStatus}
                      statusOptions={statusOptions}
                      slaLabels={slaLabels}
                      caseId={caseId as string}
                      internalStatus={internalStatus}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item className="text-align-right case-header-right-container">
              <VerticalDivider />
              <div className="inline-header vertical-align-top">
                <CaseHeaderActionButtons
                  formId={formId}
                  userIsNotAssignee={userIsNotAssignee}
                  saveTaskAsDraftClick={saveTaskAsDraftClick}
                  saveTaskAsDraftAndExitClick={saveTaskAsDraftAndExitClick}
                  submitClick={submitClick}
                  requestBody={requestBody}
                  setRequestBody={setRequestBody}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="padding-0">
          <Grid container spacing={0}>
            {attributes
              .slice(gridOneEnd, gridTwoEnd)
              .map((attr: ICaseHeaderAttributes, index: number) => (
                <Grid
                  item
                  key={attr.attributeLabel}
                  xs={getBreakpointSize(attr.attributeName, 'xs')}
                  md={getBreakpointSize(attr.attributeName, 'md')}
                  lg={getBreakpointSize(attr.attributeName, 'lg')}
                  xl={getBreakpointSize(attr.attributeName, 'xl')}
                  order={index + 1}>
                  <HeaderAttribute
                    key={attr.attributeLabel}
                    attribute={attr}
                    setStatus={setStatus}
                    setHoldAccordion={setHoldAccordion}
                    setTooltipStatus={setTooltipStatus}
                    tooltipStatus={tooltipStatus}
                    statusOptions={statusOptions}
                    slaLabels={slaLabels}
                    caseId={caseId as string}
                    internalStatus={internalStatus}
                  />
                </Grid>
              ))}
            <Grid item order={gridTwoEnd + 1} style={{ marginLeft: 'auto' }}>
              {caseId && <AssignCaseContainer caseId={caseId} caseRoles={caseRoles} />}
              <CaseHeaderFeatureButtons
                setShowModal={setShowModal}
                retrieveLinks={retrieveLinks}
                setOpenAudit={setOpenAudit}
                setOpenModal={setOpenModal}
                caseId={caseId}
                casePermissions={casePermissions}
              />
              {caseId &&
                CONSTANTS.EXPORT_OPTIONS.filter((option) =>
                  GeneralUtils.checkUserPermissions(option.permissionType, casePermissions)
                ).length > 0 && <ExportCaseData caseId={caseId} tenant={tenant} />}
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            {attributes.slice(gridTwoEnd).map((attr) => (
              <Grid
                item
                key={attr.attributeLabel}
                xs={getBreakpointSize(attr.attributeName, 'xs')}
                md={getBreakpointSize(attr.attributeName, 'md')}
                lg={getBreakpointSize(attr.attributeName, 'lg')}
                xl={getBreakpointSize(attr.attributeName, 'xl')}>
                <HeaderAttribute
                  key={attr.attributeLabel}
                  attribute={attr}
                  setStatus={setStatus}
                  setHoldAccordion={setHoldAccordion}
                  setTooltipStatus={setTooltipStatus}
                  tooltipStatus={tooltipStatus}
                  statusOptions={statusOptions}
                  slaLabels={slaLabels}
                  caseId={caseId as string}
                  internalStatus={internalStatus}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {processBar && processBar.steps.length >= 2 ? (
        <WorklowBar
          steps={processBar.steps}
          allCompleted={processBar.allCompletedCondition}
          contextLocals={contextLocals}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CaseHeaderAccordion;

import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import IAccessToken from '../../../../../utils/entities/authentication/IAccessToken';
import { Alert, Button } from '@mui/material';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import { useTranslation } from 'react-i18next';
import GeneralUtils from '../../../../../utils/generalUtils';
import IPermission, { PermissionTypes } from '../../../../../utils/entities/role/IPermission';
import LocalStorageUtils from '../../../../../utils/localStorageUtils';

interface ICaseHeaderBanner {
  hasAssignee: boolean | undefined;
  setClaimClick: (value: React.SetStateAction<boolean>) => void;
  formId: string | undefined;
  assignee: string | undefined;
  userInfo: IAccessToken;
  internalTaskStatus: boolean;
}

const CaseHeaderBanner = (props: ICaseHeaderBanner) => {
  const { hasAssignee, setClaimClick, formId, assignee, userInfo, internalTaskStatus } = props;
  const { t } = useTranslation();

  const caseRolePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const userCanClaim = GeneralUtils.checkUserPermissions(
    PermissionTypes.TASK_CLAIM,
    caseRolePermissions
  );

  const claimClick = () => {
    setClaimClick(true);
  };

  return (
    <>
      {!hasAssignee && !internalTaskStatus && (
        <Alert
          severity="info"
          icon={false}
          className="claim-alert"
          action={
            <>
              {userCanClaim && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={claimClick}
                  form={formId}
                  disableElevation
                  className="margin-right-5 text-transform-none border-radius-0"
                  size="small">
                  {t('TASK_ASSIGNMENT.CLAIM')}
                </Button>
              )}
              <Button
                component={ReactLink}
                to={CONSTANTS.PAGES_URL.INBOX}
                variant="contained"
                color="secondary"
                disableElevation
                size="small"
                className="text-transform-none border-radius-0 margin-right-10">
                {t('GO_BACK')}
              </Button>
            </>
          }>
          {userCanClaim
            ? t('TASK_ASSIGNMENT.CLAIM_TASK_WARNING')
            : t('TASK_ASSIGNMENT.CLAIM_ACCESS_NOT_GRANTED')}
        </Alert>
      )}
      {hasAssignee && assignee != userInfo.user && (
        <Alert
          severity="info"
          icon={false}
          className="claim-alert"
          action={
            <Button
              component={ReactLink}
              to={CONSTANTS.PAGES_URL.INBOX}
              variant="contained"
              color="secondary"
              disableElevation
              size="small"
              className="text-transform-none border-radius-0">
              {t('GO_BACK')}
            </Button>
          }>
          {`${t('TASK_ASSIGNMENT.TASK_IS_CLAIMED', { user: assignee })}`}
        </Alert>
      )}
    </>
  );
};

export default CaseHeaderBanner;
